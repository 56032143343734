:root {
    --header-bg: #ffffff;
    --header-top-bg: #2d1152;
    --header-text-color: #ffffff;
    --header-bottom-bg: #cbc5d4;
    --header-top-green: #077075;
    --header-bottom-color: #171717;
    --header-bottom-green: #bfe5e6;
    --common-color: #2d1152;
    --save-color: #046b29;
    --cancel-color: #b22222;
    --green-color: #008000;
    --brown-color: #710600;
    --heading-txt-color: #000;
}

@font-face {
    font-family: "OpenSans-Regular";
    src: url(../fonts/OpenSans-Regular.ttf);
}

@font-face {
    font-family: "OpenSans-Bold";
    src: url(../fonts/OpenSans-Bold.ttf);
}

@font-face {
    font-family: "Montserrat-Regular";
    src: url(../fonts/Montserrat-Regular.ttf);
}

@font-face {
    font-family: "Montserrat-SemiBold";
    src: url(../fonts/Montserrat-SemiBold.ttf);
}

@font-face {
    font-family: "Montserrat-ExtraLight";
    src: url(../fonts/Montserrat-ExtraLight.ttf);
}

@font-face {
    font-family: "Montserrat-Light";
    src: url(../fonts/Montserrat-Light.ttf);
}


@font-face {
    font-family: "Arial";
    src: url(../fonts/Arial.ttf);
}

@font-face {
    font-family: "SourceSansPro-Regular";
    src: url(../fonts/SourceSansPro-Regular.ttf);
}

@font-face {
    font-family: "Verdana";
    src: url(../fonts/Sans.ttf);
}

body {
    font-family: "OpenSans-Regular";
    font-size: 14px;
    overflow-x: hidden;
    width: 100%;
}

a {
    color: #337ab7;
}

::selection {
    background: #8300ff;
    color: white;
}


/**************** header ****************/

.header {
    background: var(--header-bg);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    /* z-index: 1; */
    /* z-index: 9999; */
}

.headerTop {
    background: var(--header-top-bg);
    position: relative;
}

.logo img {
    max-height: 50px;
}

.logo {
    position: absolute;
    left: 20px;
    top: 15px;
}

.logo a {
    outline: 0;
}

.headerMenu {
    width: 60%;
    margin: auto;
    text-align: center;
}

.headerRight {
    position: absolute;
    top: 8px;

    right: 5px;
}

.headerIcon {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    position: relative;
    outline: -webkit-focus-ring-color auto 0px;
}

.headerIcon a {
    display: block;
    padding: 18px 10px;
    text-decoration: none;
    color: var(--header-text-color);
    font-family: "Montserrat-Regular";
    color: #bdbdbd;
    font-weight: 600;
}

.headerIcon a:hover {
    color: var(--header-text-color);
}

.headerTopGreen .headerIcon a {
    color: var(--header-text-color);
    font-family: "Montserrat-SemiBold";
}

.headerTopGreen .headerRightList {
    /* padding: 5px 8px 0; */
    padding: 11px 8px 0;
}

.headerIcon img {
    display: block;
    width: 21px;
    margin: 0 auto 4px;
}

.headerRightList {
    display: inline-block;
    vertical-align: middle;
    padding: 14px 8px 0;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    font-weight: 600;
}

.headerRightList img {
    width: 28px;
}

.userClick {
    display: inline-block;
    width: 28px;
    height: 28px;
    background: #fff;
    border-radius: 50%;
    text-align: center;
    padding: 3px 0 0;
}

.userClick img {
    width: 18px;
    margin: auto;
}

.roleChoose {
    color: var(--header-text-color);
}

.downCaret {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: var(--header-text-color) transparent transparent transparent;
    display: inline-block;
    margin: 0 0 0 5px;
}

.userDropDiv {
    min-width: 160px;
    max-width: 285px;
    position: absolute;
    right: 0;
    border-radius: 0px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    background: var(--header-top-bg);
    top: 54px;
    padding: 0 0 5px;
    display: none;
    z-index: 999;
    font-weight: normal;
}

.userDropDiv ul {
    padding: 0;
    list-style: none;
    margin: 0;
}

.userDropDiv ul li a {
    color: #fff;
    display: block;
    padding: 5px 10px;
    text-decoration: none;
}

.userDropDiv ul li {
    color: #fff;
    font-size: 13px;
}

.userDropChild {
    border-bottom: 1px solid #dcdcdc;
    margin: 0 0 7px;
}

.userDropDiv ul li span {
    display: inline-block;
    font-family: "Montserrat-SemiBold";
}

.userDropDiv ul li span.practiceuserName span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 140px;
}

.practiceuserName small {
    font-family: "OpenSans-Regular";
}

.practiceuserName {
    padding: 10px 10px 8px;
}

.practiceuserEmail {
    padding: 0 10px 10px;
    color: #e6e6e6;
    word-break: break-all;
}

.headerIconActive:after {
    content: "";
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: 12px;
    background: var(--header-text-color);
    height: 3px;
}

.headerBottom {
    background: var(--header-bottom-bg);
    padding: 8px 0 0;
    text-align: center;
    overflow: hidden;
}

.headerBottomIcon {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    border-radius: 5px 5px 0 0;
    margin: 6px 10px 0 !important;
    font-family: "Montserrat-SemiBold";
}

.headerBottomIcon img {
    width: 22px;
    display: block;
    margin: 0 auto 2px;
}

.headerBottomIcon a,
.headerBottomIcon a:hover {
    color: var(--header-bottom-color);
    text-decoration: none;
    display: block;
    padding: 8px 15px 5px;
}

.headerListActive {
    background: #fff;
    box-shadow: 0px 0px 5px #bdbdbd;
    -webkit-box-shadow: 0px 0px 5px #bdbdbd;
    -moz-box-shadow: 0px 0px 5px #bdbdbd;
    -ms-box-shadow: 0px 0px 5px #bdbdbd;
}


/**************** header ****************/

.contentWraper {
    padding: 127px 0 0px;
    background: #f7f7f7;
    min-height: 450px;
}

.leftmenu {
    position: fixed;
    left: 0;
    /* z-index: 2; */
    z-index: 9999999;
    /* z-index: 99; */
    background: var(--header-top-bg);
    padding: 5px 0 5px 0;
    /* top: 63px; */
    top: 0px;
    bottom: 0;
    width: 70px;
    overflow: hidden;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}

.leftmenu:hover {
    width: 275px;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}

.leftmenu ul {
    padding: 0;
    list-style: none;
    margin: 65px 0 0;
    width: 275px;
    max-height: 550px;
    overflow: auto;
    overflow-x: hidden;
}

.leftmenu ul li {
    font-size: 17px;
    font-weight: 300;
}

.leftmenu li a {
    color: #fff;
    text-decoration: none;
    opacity: 0.9;
    padding: 8px 10px 8px 25px;
    display: block;
    cursor: pointer;
}

.leftmenu ul img {
    width: 22px;
    height: auto;
    vertical-align: top;
}

.leftmenu li span {
    display: inline-block;
    padding-left: 23px;
    font-size: 14px;
    vertical-align: top;
}

.rightSection {
    padding: 15px 15px 0px 80px;
    background: #fff;
    margin: 0px 0 -25px;
}

.breadcumbDiv .breadcrumb {
    padding: 10px 30px;
    background: transparent;
    margin: 0;
    font-size: 14px;
}

.breadcumbDiv a {
    color: #000;
    text-decoration: none;
}

.searchSection {
    padding: 10px 23px;
}

.searchItem {
    width: 14%;
    display: inline-block;
    padding: 7px;
    vertical-align: top;
}

body .inputField,
body .textareaField {
    width: 100%;
    height: 28px;
    background: #ffffff;
    border: 1px solid #ced4da;
    font-size: 12px;
    padding: 2px 8px;
    border-radius: 5px;
    outline: 0;
    margin-bottom: 5px;
}

body .textareaField {
    height: 115px;
    resize: none;
}

body .commonButton {
    padding: 5px 12px;
    background: #5bc0de;
    border-radius: 5px;
    line-height: 21px;
    display: inline-block;
    height: 35px;
    cursor: pointer;
    margin: 0 5px 0 0;
}

.commonButton img {
    width: 14px;
}

.patientTopSection {
    border-bottom: 1px solid #d4d4d4;
}

.pagination {
    display: inline-block;
    padding: 0;
    margin: 0;
    text-align: center;
}

.pagination li {
    display: inline;
    font-size: 13px;
}

.pagination li a,
.pagination li span {
    position: relative;
    padding: 10px 0px;
    text-decoration: none;
    color: var(--common-color);
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin: 0 4px;
    display: inline-block;
    vertical-align: middle;
    line-height: 21px;
}

.pagination .disabled a {
    color: #777;
    background-color: #fff;
    border-color: #ddd;
    cursor: not-allowed;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    z-index: 1;
    color: #fff;
    background-color: var(--common-color);
    border-color: var(--common-color);
    cursor: default;
}

.paginationSection {
    text-align: right;
    margin: 0px 0 10px;
    position: relative;
    z-index: 0;
    /* width: 100%;
    float: right; */
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
    color: var(--common-color);
    background-color: #eee;
    border-color: #ddd;
}

.titleSection {
    margin: 25px 0 40px;
    clear: both;
    overflow: hidden;
}

h4 {
    margin: 0;
    font-family: camphor, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #545454;
}

body .borderButton,
body .borderButtonCancel {
    background: 0;
    border: 1px solid var(--save-color);
    padding: 5px 17px;
    color: #393939;
    text-align: center;
    font-family: "montserratregular", sans-serif;
    font-size: 14px;
    border-radius: 5px;
    outline: 0;
    font-weight: 400;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    margin: 0 0 0 10px;
    min-width: 120px;
}

body .borderButtonCancel {
    border-color: var(--cancel-color);
}

.titleSection .borderButton {
    float: right;
    font-family: "Montserrat-SemiBold";
    padding: 6px 10px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.titleSection h4 {
    float: left;
    margin: 7px 0 0;
    font-size: 14px;
}

.dashMenu {
    list-style: none;
    padding: 10px 30px;
    background: #fff;
}

.dashMenu li {
    display: block;
    padding-bottom: 20px;
    font-size: 14px;
}

.landingBodyWrap {
    padding: 20px 20px;
    margin: 15px 0 20px;
    background: #fff;
}

.landingBodyWrap span {
    color: #ababab;
    padding-left: 33px;
}

.landingHead {
    font-size: 25px;
    font-weight: 600;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    color: #333;
}

.landingMainContent {
    padding: 25px 0 0px;
}

.landingMainContent img {
    width: 100%;
    margin: 0 0 20px;
}

h3 {
    font-size: 20px;
    font-weight: 700;
    color: #000;
}

.landingMainContent p {
    color: #868686;
    font-weight: 400;
    padding: 10px 0;
}

.landingMainContent ul {
    margin: 20px 0 0;
}

.landingMainContent ul li {
    color: #868686;
    padding-bottom: 15px;
}

.lastSection {
    padding: 15px 0;
}

.final p {
    padding: 0;
    color: #656565;
    margin-bottom: 5px;
}

.final b {
    color: #807e7e;
}

.dashMenu li img {
    width: 28px;
    display: inline-block;
    margin-right: 20px;
    position: relative;
    top: -1px;
}

.dashMenu li a {
    text-decoration: none;
    color: #616161;
    display: block;
    line-height: 30px;
}

.gridList {
    width: 100%;
    border: 1px solid #d4d4d4;
    border-radius: 2px;
    padding: 5px;
    position: relative;
    background: white;
    margin-bottom: 10px;
    display: flex;
}

.Appointmentjsx .gridList {
    margin-bottom: 0px;
    border: none;
}

.gridImage {
    width: 70px;
    display: inline-block;
    vertical-align: top;
}

.gridImage img {
    width: 100%;
}

.gridContent {
    display: inline-block;
    width: 90%;
    padding: 0 10px 0 15px;
    vertical-align: top;
}

.gridListSection {
    clear: both;
    overflow: hidden;
    padding: 10px 0;
}

.dateSection {
    font-size: 11px;
    text-align: center;
    font-weight: bold;
    color: #ffffff;
    width: 100%;
    background: #3498db;
    min-height: 100%;
    padding: 5px;
    height: 75px;
}

.dateSection span {
    font-size: 26px;
    display: block;
    line-height: 45px;
}

.gridImage a {
    display: block;
    text-decoration: none;
}

.gridTop {
    font-size: 15px;
    color: #000;
    /* font-weight: 600; */
    margin: 0 0 2px;
    cursor: pointer;
}

.gridIconItem svg {
    margin: 2px 5px 0 0;
}

.gridIconItem .fa-edit {
    color: #777;
    margin: 0 0 0 8px;
    cursor: pointer;
}

.gridIconItem .fa-edit:focus {
    outline: none;
}

.gridDoctorName {
    color: #3498db;
    float: right;
}

.gridContent a {
    text-decoration: none;
}

.gridContent .pu_generalinfo .plmdlbtn a,
.gridContent .pu_generalinfo .plmdlbtn a:hover {
    text-decoration: none;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
    background-image: -moz-linear-gradient(top, #00afef, #005fef);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#00afef), to(#005fef));
    background-image: -webkit-linear-gradient(top, #00afef, #005fef);
    background-image: -o-linear-gradient(top, #00afef, #005fef);
    background-image: linear-gradient(to bottom, #00afef, #005fef);
    color: #fff;
    cursor: pointer;
}

.gridIconItem {
    width: 200px;
    display: inline-block;
    font-size: 13px;
    color: #777;
    vertical-align: top;
    margin: 0 0 5px;
}

.statusChangeDiv {
    display: none;
    padding: 20px 75px;
    clear: both;
    overflow: hidden;
}

.gridIconItemLarge {
    /* width: auto; */
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.gridEdit {
    position: absolute;
    font-size: 14px;
    /* font-weight: 600; */
    color: #000;
    cursor: pointer;
    top: 30px;
    right: 15px;
    font-family: "Montserrat-SemiBold";
}

.greenColor {
    color: var(--green-color);
}

.brownColor {
    color: var(--brown-color);
    font-weight: 600;
    cursor: pointer;
    padding-left: 3px;
}

h5 {
    margin: 0 0 10px;
    font-family: camphor, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #545454;
}

.clearfix {
    clear: both;
    overflow: hidden;
    width: 100%;
    margin: 0 0 10px;
}

body .labelText {
    font-size: 13px;
    font-family: camphor, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    display: block;
    margin: 10px 0;
}

.labelText span {
    font-family: "open_sansregular", sans-serif;
    display: inline-block;
    padding: 0 0 0 5px;
}

.buttonRightSection {
    text-align: right;
    margin: 20px 0 0;
}

body .borderCancelButton {
    border-color: var(--cancel-color);
}

.popupCoverWrap {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    overflow-y: auto;
    overflow-x: hidden;
    display: none;
}

.popupOverlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.popupDiv {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    width: 820px;
    margin: 30px auto;
    background: #fff;
    position: relative;
    border-radius: 5px;
}

.popUpTitle {
    padding: 15px;
    color: #3498db;
    font-size: 18px;
    position: relative;
}

body .popupClose {
    position: absolute;
    right: 10px;
    width: 30px;
    display: inline-block;
    text-align: center;
    height: 30px;
    color: #191c1f;
    font-size: 21px;
    font-weight: 600;
    line-height: 30px;
    top: 12px;
    cursor: pointer;
}

.popUpContent {
    padding: 10px 15px;
}

.newAppoinmentRightList {
    padding: 0 8px;
    width: 33.333%;
    display: inline-block;
    font-size: 12px;
    vertical-align: top;
    position: relative;
    margin: 0 0 8px 0;
    font-family: "Arial";
}

.appointmentForm {
    margin: 0 -5px 0 -10px;
}

body .formLabel {
    display: block;
    text-align: left;
    margin: 0 0 3px;
    width: 100%;
    font-weight: 500;
    font-family: "Arial";
}

.mobileCountrySelect {
    width: 20%;
    float: left;
    padding-right: 10px;
}

.mobileNumberField {
    width: 80%;
    float: left;
}

body .formInput,
body .formInputText {
    height: 30px;
    padding: 2px 5px;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    width: 100%;
    background: #fff;
    resize: none;
    outline: 0;
    font-family: "Arial";
    font-size: 12px;
}

body .formInputText {
    height: 50px;
}

.newAppoinmentButton {
    text-align: right;
    padding: 15px;
}

.formLabel sup {
    color: #ff0000;
    font-size: 113%;
    top: -1px;
    left: 3px;
}

.mobileCountrySelect .formInput {
    width: 35px;
}


/* .radioSection input[type="radio"] {
    display: none;
} */

.radioSection1 input {
    margin-right: 5px;
    vertical-align: middle;
    width: 17px;
    height: 17px;
}

.appointmentFormList1 {
    display: flex;
}

.radioSection {
    display: inline-block;
    margin: 0 10px 0 0;
}

.radioSection1 {
    margin-right: 10px;
}

.radioSection label {
    display: inline-block;
    margin: 0 5px 0 0;
    width: 17px;
    height: 17px;
    border: 1px solid #ccc;
    border-radius: 50%;
    vertical-align: middle;
    top: -2px;
    position: relative;
    cursor: pointer;
}

.radioSection label:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 2px;
    margin: auto;
    width: 11px;
    height: 11px;
    background: #428bca;
    border-radius: 50%;
    opacity: 0;
}

.radioSection input[type="radio"]:checked+label {
    border-color: #428bca;
}

.radioSection input[type="radio"]:checked+label:before {
    opacity: 1;
}

.breadcumbDiv nav {
    display: inline-block;
}

.breadcumbDivRight {
    float: right;
    padding: 8px 30px;
}

body .allPatientLink {
    font-size: 14px;
    color: var(--common-color);
    display: inline-block;
    text-decoration: underline;
    vertical-align: middle;
    cursor: pointer;
}

.breadcumbDivRight svg {
    vertical-align: middle;
    display: inline-block;
    margin: 0 0 0 15px;
    cursor: pointer;
}

.gridEdit svg {
    margin: 0 5px 0 0;
}

.footer {
    text-align: center;
    background: #bfb8b8;
    display: block;
    padding: 30px 15px 30px;
    clear: both;
    overflow: hidden;
}

.footerContent {
    display: block;
    text-align: right;
    font-size: 14px;
    color: #2d2d2d;
    width: 33.333%;
    float: right;
    font-family: "OpenSans-Regular";
}

.footerContent span {
    display: block;
}

.footerContent h6 {

    font-size: 22px;
    margin: 0 0 15px;
    /* font-family: "SourceSansPro-Regular"; */
    /* font-family: inherit; */
    font-weight: 500;
    font-family: "OpenSans-Regular";
}

.selectMenu {
    padding: 8px 0;
}

.selectMenu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
}

.selectMenu li {
    display: inline-block;
}

.cd-dot {
    padding: 3px 10px;
    background-color: #e6e6e6;
    display: block;
    font-size: 12px;
    text-decoration: none;
    border: 1px solid #3498db;
    color: #000;
    cursor: pointer;
    margin: 0 2px;
}

.selectMenu a {
    margin: 0 2px;
    display: block;
    text-decoration: none;
}

.listAct {
    background: #077075;
    color: #fff;
    border-color: #077075;
}

.selectMenu.topFixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: #cccccc;
}

.topFixed .cd-dot {
    background: #fff;
}

.topFixed .cd-dot.listAct {
    background: var(--common-color);
    color: #fff;
    border-color: var(--common-color);
}

body .gridListFullLink {
    clear: both;
    overflow: hidden;
    display: block;
}

.patientSearchitem {
    position: relative;
    width: 300px;
    display: inline-block;
    margin: 0 5px 0 0;
}

.patientSearchitem svg {
    position: absolute;
    right: 10px;
    top: 11px;
    color: #bdbdbd;
    pointer-events: none;
}

.patientCount {
    font-size: 15px;
    color: rgb(0, 143, 239);
    float: right;
    padding-top: 8px;
    display: block;
}

.headerScroll {
    position: relative;
}

.contentWraperScroll {
    padding-top: 0;
}

.profileDetailContent {
    width: 75%;
    display: inline-block;
    vertical-align: top;
}

.profileRightTag {
    width: 25%;
    display: inline-block;
    vertical-align: top;
}

.practiceHeadTitle {
    font-size: 13px;
    font-weight: 600;
    color: #545454;
    margin: 0 0 5px;
}

.profileRightInput .inputField {
    height: 30px;
    width: 97%;
}

.practiceManagementWrap {
    margin: 0 -15px;
    background: #fafafa;
}

.practiceManagementRow {
    margin: 0;
    padding: 0 0 0 10px;
    margin-bottom: 40px;
}

.profileDetail {
    margin: 0px 0px 15px 8px;
    background: #ececec;
    padding: 5px;
}


/* .practiceLeftSection {
    background: #ececec;
    padding: 0;
} */

.treatmentPlanHead {
    /* padding: 13px 10px 2px; */
    padding: 13px 0px 2px;
    font-size: 14px;
    border-bottom: 1px solid #afafaf;
    clear: both;
    min-height: 50px;
    margin-bottom: 6px;
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
}

.treatmentPlanName {
    float: left;
    text-transform: uppercase;
}

.treatmentPlanBtn {
    float: right;
}

body .practicementBtn {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    padding: 3px 12px;
    font-size: 13px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -ms-border-radius: 4px;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-image: linear-gradient(to bottom, #00afef, #005fef);
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 7px;
    border: 1px solid #005fef;
}

.treatmentPlanContent {
    padding: 10px;
}

.profileCover {
    background: #fff;
    padding: 10px;
}

.profileImg {
    width: 20%;
    display: inline-block;
    text-align: center;
    vertical-align: top;
}

.profileImg img {
    width: 75px;
    height: auto;
    margin-bottom: 15px;
    cursor: pointer;
}

.profileDetails {
    width: 80%;
    display: inline-block;
    padding-left: 10px;
    vertical-align: top;
}

.profileDetailList {
    clear: both;
    margin-bottom: 5px;
    color: #6f6f6f;
    font-size: 14px;
    line-height: 23px;
}

body .practiceLabel {
    width: 25%;
    text-align: right;
    display: inline-block;
    margin: 0;
    font-size: 13px;
    vertical-align: top;
    padding-right: 10px;
    position: relative;
}

.practiceContentText {
    display: inline-block;
    width: 75%;
    padding-left: 15px;
    font-size: 13px;
    position: relative;
    vertical-align: top;
    word-break: break-all;
}

.profileDetailList h4 {
    color: #608d15;
    font-size: 26px;
    padding: 20px 0 10px;
    font-weight: 400;
    font-family: "OpenSans-Regular";
}

.childDetailShow {
    width: 100%;
    padding: 0;
}

.childDetailTop {
    width: 100%;
    padding: 0;
    border-bottom: 1px solid #ddd;
    margin-bottom: 7px;
}

.childNameShow {
    width: 45%;
    padding: 3px 5px 1px 1px;
    display: inline-block;
}

.childDob {
    width: 25%;
    display: inline-block;
}

.childVaccination {
    width: 30%;
    display: inline-block;
}

body .clinicalCancel,
body .clinicalSave {
    display: inline-block;
    padding: 3px 12px;
    margin: 0 0 0 7px;
    font-size: 13px;
    text-align: center;
    vertical-align: top;
    cursor: pointer;
    color: #000;
    background-image: linear-gradient(to bottom, #fff, #e6e6e6);
    border: 1px solid #bbb;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -ms-border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
}

body .clinicalSave {
    border-color: #54a954;
    background-image: linear-gradient(to bottom, #62c462, #51a351);
    color: #fff;
}

.colspanLabel {
    position: absolute;
    right: 0px;
    top: 0;
}

.practiceLabel sup {
    color: #f00;
    font-size: 12px;
    top: -2px;
    margin-left: 5px;
}

.practiceContentText .inputField {
    width: 60%;
    margin-bottom: 5px;
    padding: 1px 5px;
    border: 1px solid #dcdcdc;
    height: 28px;
    color: inherit;
}

.practiceContentText .inputFieldXtraSmall {
    width: 50px;
    margin-right: 10px;
}

.mobileCountryCode {
    width: 10%;
    padding: 0;
    display: inline-block;
    vertical-align: top;
}

.mobileCountryCode .inputField,
.mobileNumberTxt .inputField {
    width: 100%;
}

.mobileNumberTxt {
    padding-left: 10px;
    width: 50%;
    display: inline-block;
    vertical-align: top;
}

.checkSection input[type="checkbox"] {
    display: none;
}

.checkSection label {
    display: inline-block;
    margin: 0 5px 0 0;
    width: 17px;
    height: 17px;
    border: 1px solid #ccc;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    border-radius: 0px;
}

.checkSection label:before {
    content: "";
    width: 5px;
    height: 11px;
    border: solid #b1b1b1;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    left: 5px;
    top: 1.3px;
    opacity: 0;
}

.checkSection input[type="checkbox"]:checked+label {
    border-color: #b1b1b1;
}

.checkSection input[type="checkbox"]:checked+label:before {
    opacity: 1;
}

.gynecologyContentSec {
    padding: 10px;
    width: 100%;
    margin-top: 10px;
}

.gynecologyContentDiv {
    width: 100%;
    margin-bottom: 10px;
    position: relative;
    color: #6f6f6f;
    font-size: 14px;
    line-height: 23px;
    float: left;
}

.gynecologyContentTxt {
    font-weight: 400 !important;
    font-size: 13px;
    margin: 0;
    float: left;
    padding: 5px 10px 0 0;
    color: #6f6f6f;
}

.gynecologyContentSelect {
    display: inline-block;
    margin-right: 15px;
    width: 160px;
}

.gynecologyContentSelect input[type="radio"] {
    float: left;
    margin: 9px 5px 0 0;
}

.gynecologyContentSelect label {
    float: left;
    font-weight: 400 !important;
    font-size: 14px;
    margin: 0 20px 0 0;
    color: #6f6f6f;
    padding: 6px 0 0 0;
}

.gynecologyContentDiv input[type="text"] {
    font-weight: 400 !important;
    font-size: 13px;
    width: 135px;
    margin: 0 10px 0 0;
    float: left;
    color: #6f6f6f;
    height: 30px;
    padding: 3px 10px;
    border: 1px solid #ddd;
}

.gynecologyContentSelect input[type="checkbox"] {
    float: left;
    margin: 12px 14px 0 -10px;
}

.gynecologyContentDiv .inputField {
    width: 135px;
    margin: 0 10px 0 0;
}

.gynecologyContentDiv .checkSection {
    margin: 0 15px 0 0;
}

.gynecologyContentDiv .checkSection {
    margin: 0 15px 0 0;
}

.gynecologyWrap {
    clear: both;
    float: left;
    padding: 10px 0 3px;
    background: #fff;
    margin: 5px 0;
}

.gynecologySplit {
    float: left;
    width: 100%;
}

.gynecologyCheckArea {
    clear: both;
    overflow: hidden;
}

.gynecologyCheckArea span {
    width: 33.333%;
    display: inline-block;
    margin-bottom: 10px;
    padding: 0;
    vertical-align: top;
}

.gynecologyCheckArea label,
.gynecologyLabel label {
    width: 85px;
    font-size: 13px;
    margin: 0;
    display: inline-block;
    padding: 5px 5px 0 0;
    color: #6f6f6f;
}

.gynecologyCheckArea span {
    width: 33.333%;
    display: inline-block;
    margin-bottom: 10px;
    padding: 0;
    vertical-align: top;
}

.gynecologyCheckArea input[type="text"] {
    font-weight: 400 !important;
    font-size: 13px;
    width: 52px;
    margin: 0;
    float: left;
    text-align: center;
    height: 30px;
    padding: 3px 5px;
    border: 1px solid #ddd;
}

.gynecologyCheckArea label {
    float: left;
}

.prgnancyDetailsWrap .gynecologyContentSelect input[type=checkbox]:checked+label:after {
    visibility: hidden;
}

.gynecologyCheckArea .inputField {
    width: 52px;
}

.gynecologyContentSec .inputField {
    height: 28px;
}

.gynecologyInputBox {
    clear: both;
    overflow: hidden;
    margin: 0 0 10px 0;
}

.gynecologyLabel {
    width: 50%;
    display: inline-block;
    padding: 0 0 10px;
    vertical-align: top;
}

.gynecologyLabel .profileSpan {
    padding: 5px 15px 0 0;
    display: block;
}

.gynecologyLabel label {
    width: 100%;
}

.gynecologyInputBox input[type="text"] {
    width: 95%;
    float: left;
    margin: 0;
    color: #6f6f6f;
    height: 30px;
    padding: 3px 10px;
    border: 1px solid #ddd;
    font-weight: 400 !important;
    font-size: 13px;
}

.childListDiv input[type="checkbox"] {
    margin: 8px 0 0 8px;
    width: 14px !important;
    height: 14px !important;
    float: left;
}

span.removeClose {
    display: inline-block;
    margin: 4px 0 0 20px;
    cursor: pointer;
    width: auto;
    padding: 0;
}

.trtmntPlanBtn.pregnInfoAppend {
    display: inline-block;
    padding: 2px 12px;
    margin-bottom: 0;
    font-size: 12px;
    margin-right: 5px;
    text-align: center;
    cursor: pointer;
    color: #fff;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -ms-border-radius: 4px;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
    background-color: #008fef;
    background-image: -moz-linear-gradient(top, #00afef, #005fef);
    display: block;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#00afef), to(#005fef));
    background-image: -webkit-linear-gradient(top, #00afef, #005fef);
    background-image: -o-linear-gradient(top, #00afef, #005fef);
    background-image: linear-gradient(to bottom, #00afef, #005fef);
    background-repeat: repeat-x;
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ff00afef', endColorstr='#ff005fef', GradientType=0);
    border-color: #005fef #005fef #0041a3;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid: DXImageTransform.Microsoft.gradient(enabled=false);
}

.gynecologyChildList .trtmntPlanBtn {
    float: left;
    width: auto;
    margin-left: 10px;
}

.childListCvrDiv label {
    font-size: 13px;
    width: auto;
    float: left;
}

.gynecologyChildList {
    float: left;
    width: 100%;
    padding-top: 10px;
}

.childListDiv {
    clear: both;
    padding-bottom: 10px;
    float: left;
    width: 100%;
}

span.childNameSec {
    padding-left: 0;
    width: 22%;
    float: left;
}

.gynecologyChildList input[type="text"] {
    font-weight: 400 !important;
    font-size: 13px;
    width: 100%;
    margin: 0;
    float: left;
    height: 30px;
    color: #6f6f6f;
    padding: 3px 10px;
    border: 1px solid #ddd;
}

span.childDobSec {
    width: 15%;
    padding-left: 7px;
    float: left;
}

.entrySubHeading {
    font-weight: 600;
    color: #808080;
    position: relative;
}

.entryClick {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    text-align: center;
    cursor: pointer;
    box-shadow: 1px 1px 5px #d2d2d2;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
}

.entryClick .fa-minus {
    display: none;
}

.clinicContentList {
    padding: 10px;
    color: #737373;
}

.clinicalLabel {
    width: 20%;
    display: inline-block;
    text-align: right;
    font-size: 15px;
    padding-right: 10px;
    vertical-align: top;
    font-family: "SourceSansPro-Regular";
    color: #737373;
    text-align: right;
}

.clinicalField {
    width: 65%;
    display: inline-block;
    padding-left: 10px;
    border-left: 1px solid #2980b9;
    position: relative;
    vertical-align: top;
}

.clinicalDate {
    width: 15%;
    padding-left: 10px;
    position: relative;
    display: inline-block;
    vertical-align: top;
}

.clinicalFieldSubTxt {
    font-size: 11px;
    display: block;
    margin: 0 0 6px;
}

.clinicalDate label {
    margin: 0;
    font-size: 14px;
}

.clinicalDesTxt {
    font-size: 13px;
    margin-bottom: 2px;
    display: block;
}

.entryContentDiv {
    margin: 15px 0 0;
}

.entryContentHide {
    display: none;
}

body .inputFieldBottom {
    margin-bottom: 10px;
}

body .inputFieldHalf {
    width: 50%;
}

.clinicFollowUp {
    border: 0;
    background: #fff;
}

.followUpTxt {
    cursor: pointer;
    font-size: 13px;
    color: #0494cc;
    padding-left: 15px;
}

.needFollowDiv {
    display: none;
}

.entryFormCover {
    margin: 0 0 10px;
}

.labelTextField {
    font-size: 13px;
    margin: 0 0 5px;
    display: block;
}

.practiceManagementList {
    color: #333;
    margin: 0 0 10px;
}

.treatmentContentAdd {
    width: 23px;
    height: 23px;
    display: block;
    font-size: 18px;
    right: 0;
    text-align: center;
    background: #00a700;
    line-height: 20px;
    z-index: 1;
    color: #fff;
    cursor: pointer;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    margin: 9px 0 0;
}

.practiceManagementList .checkSection {
    display: inline-block;
    margin: 5px 0 0;
}

body .inputFieldXs {
    width: 50px;
    margin-right: 6px;
    padding: 2px 7px;
}

.entryItemSecOpen .entryContentDiv {
    display: block;
}

.entryItemSecOpen .entryClick .fa-plus {
    display: none;
}

.entryItemSecOpen .entryClick .fa-minus {
    display: inline-block;
}

.treatmentNodata {
    position: relative;
    clear: both;
    overflow: hidden;
    height: 250px;
    background: #fff;
    padding: 10px;
}

.treatmentNodataText {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    padding: 15px 50px;
    display: inline-block;
    border: 1px solid #cbc5d4;
    color: #444;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    font-size: 16px;
    width: fit-content;
    margin: auto;
}

body .treatmentItemLabel {
    color: #737373;
    font-size: 15px;
    padding-right: 10px;
    width: 25%;
    display: inline-block;
    vertical-align: top;
    margin: 0;
}

.caseSheetItemList .inputField,
.caeSheetRadioWrap {
    width: 75%;
    vertical-align: top;
    display: inline-block;
}

.caseSheetItemList {
    margin-bottom: 10px;
}

.caseSheetItemListFull .treatmentItemLabel {
    width: 100%;
    margin-bottom: 5px;
}

.caeSheetRadioWrap .radioSection {
    width: 25%;
    margin: 0;
    vertical-align: top;
}

.gridListAppointment {
    position: relative;
}

.dateSectionSmall span {
    font-size: 32px;
    line-height: 36px;
}

.gridContentLarge {
    width: 90%;
    font-size: 13px;
    color: #5a5a5a;
}

.appoinmentReason {
    padding-bottom: 3px;
    font-weight: 600;
}

.appoinmentReason .elipsis {
    padding-left: 5px;
    font-weight: 400;
    display: inline-block;
    width: 90%;
    vertical-align: top;
}

.elipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.appoinmentSatusWrap {
    padding-bottom: 5px;
}

.appoinmentSTatus,
.appoinmentTYpe {
    width: 50%;
    display: inline-block;
}

.appoinmentSTatus {
    color: #710600;
}

.appoinmentHsptlName {
    font-size: 14px;
    color: #2f77c7;
}

.appointmentEdit {
    position: absolute;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
    padding: 3px 8px;
    background-image: linear-gradient(to bottom, #fff, #e6e6e6);
    border: 1px solid #ccc;
    right: -1px;
    top: -1px;
    cursor: pointer;
}

.keyLeft {
    width: 25%;
    display: inline-block;
    vertical-align: top;
}

.keyRight {
    width: 75%;
    display: inline-block;
    border-left: 1px solid #000;
    vertical-align: top;
}

.keyHead {
    color: #4a4a4a;
    font-size: 15px;
    font-weight: 600;
    display: inline-block;
    border-bottom: 2px solid #4a4a4a;
    margin-bottom: 10px;
    font-size: 11px;
}

.keyLeft ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.keyLeft li {
    padding-bottom: 5px;
    clear: both;
    overflow: hidden;
    font-size: 12px;
    color: #464646;
}

.keyLetter {
    padding-right: 5px;
    width: 10%;
    display: inline-block;
    vertical-align: top;
}

.keyDes {
    width: 85%;
    display: inline-block;
    vertical-align: top;
}

.teethWrap {
    width: 825px;
    margin: auto;
    border-bottom: 1px solid #afafaf;
}

.teethContent {
    margin-bottom: 0;
    font-size: 0;
    text-align: center;
}

.teethList {
    display: inline-block;
    vertical-align: top;
}

.teethList span {
    display: block;
    text-align: center;
    font-weight: 400;
    color: #444;
    font-size: 11px;
}

.teethImg {
    width: 33px;
    height: 45px;
    text-align: center;
    cursor: pointer;
    position: relative;
}

.teethImg img {
    height: 35px;
    width: auto;
    cursor: pointer;
}

.teethImg img {
    height: 45px;
    width: auto;
    cursor: pointer;
}

.teethActive img {
    -webkit-filter: drop-shadow(3px 0 1px rgba(192, 0, 255, 1));
    filter: drop-shadow(3px 0 1px rgba(192, 0, 255, 1));
    -moz-filter: drop-shadow(3px 0 1px rgba(192, 0, 255, 1));
    -ms-filter: drop-shadow(3px 0 1px rgba(192, 0, 255, 1));
    -o-filter: drop-shadow(3px 0 1px rgba(192, 0, 255, 1));
}

.teethArrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7.5px 10px 7.5px;
    border-color: transparent transparent #a9a9a9 transparent;
    display: block;
    position: absolute;
    top: 15px;
    z-index: 2;
    right: 8px;
}

.teethArrow:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6.5px 9px 6.5px;
    border-color: transparent transparent #fff transparent;
    display: block;
    position: absolute;
    top: 1px;
    right: -6px;
}

.teethImg select {
    font-size: 11px;
    position: absolute;
    z-index: 1;
    overflow: hidden;
    padding: 2px 0;
    top: 24px;
    right: 5px;
    height: 171px;
    color: #5a5a5a;
    overflow-y: scroll;
}

.teethImg select option {
    padding: 4px 5px;
    cursor: pointer;
    border-bottom: 1px solid #bdbdbd;
    font-weight: 300;
}

body .teethKey {
    position: absolute;
    left: 2px;
    right: 2px;
    top: 13px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
}

body .theethKeyClose {
    position: absolute;
    top: -8px;
    right: -2px;
    background: red;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    color: #fff;
    line-height: 9px;
}

.rowMargin {
    margin: 0;
}

.rowMargin .labelTextField {
    margin: 7px 0 0;
}

body .practiceInputField {
    width: 140px;
}

.slashDivide {
    font-size: 17px;
    color: #616161;
    margin: auto 10px;
}

.toggleOne {
    color: #3498db;
    cursor: pointer;
    display: inline-block;
    margin: 0 0 0 5px;
}

.practiceListSub {
    margin-left: 25px;
    display: inline-block;
    vertical-align: top;
}

.borderSeparate {
    clear: both;
    text-align: center;
    overflow: hidden;
    padding: 2px 15px 7px;
    width: 100%;
}

.borderSeparate span {
    border-bottom: 1px solid #cbc5d4;
    display: inline-block;
    width: 55%;
}

textarea.practiceInputField {
    height: 70px;
}

.treatmentPlanDate {
    color: #272727;
    font-weight: 300;
    font-size: 12px;
    padding: 8px 0px 8px;
    position: relative;
}

.planActive {
    position: relative;
}

.treatmentTable:last-child {
    margin-bottom: 0px;
}

.treatmentTable table {
    width: 100%;
    background: #fff;
    border: 1px solid #afafaf;
}

.treatmentTable table tr {
    border: 0px;
}

.treatmentTable table thead tr {
    border-bottom: 1px solid #afafaf;
}

.columnPadding {
    padding: 10px 5px;
}

.treatmentTable table td,
.treatmentTable table th {
    font-size: 11px;
    text-align: left;
}

.dentalProName,
.dentalProCost,
.dentalProDis,
.dentalProTotal,
.dentalProNote {
    position: relative;
    color: #000;
}

.dentalProTotal,
.dentalProCost {
    text-align: right;
}

.treatmentTable table .dentalProPlaned {
    border: 0;
    border-bottom: 1px solid #cbc5d4;
    background: #e0e0e085;
}

.treatmentTable table .groupBrdr,
.vitalSignsTable table .groupBrdr {
    border: 0;
    vertical-align: top;
}

.dentalProName .elipsis {
    max-width: 150px;
    display: inline-block;
    /* width: 85%; */
    width: 100%;
    vertical-align: top;
}

.procedureCodeText {
    font-size: 10px;
}

.dentalProNote .elipsis {
    max-width: 80px;
    display: inline-block;
}

.plannedBy span {
    padding-left: 5px;
    font-weight: 600;
}

.estimatedPrice {
    font-weight: 600;
}

.txtRight {
    text-align: right;
}

.treatmentTable .col-md-1 {
    width: 8.33333333%;
}

.treatmentTable .col-md-2 {
    width: 16.66666667%;
}

.treatmentTable .col-md-3 {
    width: 25%;
}

.treatmentTable .col-md-4 {
    width: 33.33333333%;
}

.treatmentTable .col-md-5 {
    width: 41.66666667%;
}

.treatmentTable .col-md-6 {
    width: 50%;
}

.treatmentTable .col-md-7 {
    width: 58.33333333%;
}

.treatmentTable .col-md-8 {
    width: 66.66666667%;
}

.treatmentTable .col-md-9 {
    width: 75%;
}

.treatmentTable .col-md-10 {
    width: 83.33333333%;
}

.treatmentTable .col-md-11 {
    width: 91.66666667%;
}

.treatmentTable .col-md-12 {
    width: 100%;
}

.dentalProName .checkSection {
    float: left;
    vertical-align: top;
    height: 30px;
    padding-right: 5px;
    width: 27px;
    margin-top: 0px;
}

.treatmentComplaintWrap {
    color: #6f6f6f;
    font-weight: 300;
    font-size: 12px;
    padding: 8px 10px;
    background: #fff;
    margin: 0 0 10px;
    position: relative;
    border: 1px solid #d4d4d4;
}

.treatmentComplaintRowWrap {
    width: 90%;
}

.treatmentComplaintRow {
    clear: both;
    overflow: hidden;
    padding-bottom: 10px;
    position: relative;
    z-index: 1;
}

.treatmentComplaintLeft {
    width: 25%;
    color: #737373;
    display: inline-block;
    text-align: left;
    font-size: 13px;
    padding-right: 10px;
    vertical-align: top;
}

.treatmentComplaintRight {
    width: 75%;
    vertical-align: top;
    padding-left: 10px;
    border-left: 1px solid #2980b9;
    position: relative;
    display: inline-block;
}

.treatmentComplaintRight:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    border-bottom: 1px solid #ccc;
}

.treatmentComplaintRight span {
    display: block;
    margin-bottom: 5px;
}

span.treatmentTAgName {
    display: inline-block;
    width: 80%;
}

span.treatmentTAgDate {
    width: 20%;
    display: inline-block;
    text-align: right;
}

.treatmentComplaintRight strong {
    font-weight: bold;
}

.treatmentComplaintNoted {
    padding: 10px 0px;
    width: 100%;
    border: 0;
    background: #fff;
}

.treatmentComplaintNoted span {
    font-weight: 600;
    color: #000;
    padding-left: 5px;
}

.treatmentProIcons {
    position: absolute;
    right: -1px;
    top: -1px;
}

.treatmentDropDiv {
    display: none;
    position: absolute;
    right: 0;
    padding: 2px 2px;
    top: 25px;
    width: 150px;
    font-size: 13px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
    z-index: 1;
}

.treatmentDrpClk {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
    padding: 0px 0px;
    background-image: linear-gradient(to bottom, #fff, #e6e6e6);
    border: 1px solid #ccc;
    cursor: pointer;
    font-size: 17px;
    display: block;
    line-height: 16px;
    height: 25px;
    width: 25px;
    text-align: center;
}

.treatmentDrpClk .fa-sort-down {
    vertical-align: top;
}

.treatmentDropList {
    padding: 5px 5px;
    color: #3c3c3c;
    font-weight: 300;
    font-size: 12px;
    cursor: pointer;
}

.treatmentDropList svg {
    padding-right: 5px;
    padding-top: 1px;
    width: 23px !important;
    display: inline-block;
}

.treatmentDropList:hover {
    color: #fff;
    background-color: #00a8e5;
    background-image: linear-gradient(to bottom, #00afef, #009cd6);
}

.dentalProNote .treatmentProIcons {
    top: -7px;
    right: -5px;
}

.treatmentListAddSection .row {
    margin: 0 -5px;
}

.treatmentHead {
    border-bottom: 1px solid #dadada;
    padding: 5px 5px 5px 5px;
    font-size: 12px;
    font-weight: 600;
    color: #868686;
}

.treatmentsList {
    border: 1px solid #dadada;
}

.treatmentListAddSection {
    background: #fff;
    margin: 0 -7px -10px;
}

.treatmentTxt {
    position: relative;
}

.treatmentPriceTxt {
    width: 30%;
    display: inline-block;
    vertical-align: top;
}

.treatmentPriceCross {
    width: 20%;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    padding: 5px;
}

.treatmentCostTxt {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    z-index: 9999;
}

.treatmentTotal {
    text-align: right;
    margin-left: -7px;
}

.treatmentsList .columnPadding {
    padding: 0 5px;
}

.treatmentCommonTxt {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    text-align: center;
}

.treatmentDivSecWrap {
    min-height: 160px;
    padding: 6px 0 10px;
    height: 290px;
    overflow: auto;
    overflow-x: hidden;
}

.treatmentCommonTxt span {
    padding: 15px 50px;
    display: inline-block;
    border: 1px solid #cbc5d4;
    color: #444;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    font-size: 16px;
}

.treatmentContentClose {
    width: 18px;
    height: 18px;
    position: absolute;
    top: -11px;
    font-size: 17px;
    right: -6px;
    text-align: center;
    border: 1px solid #ddd;
    line-height: 11px;
    z-index: 1;
    color: #ff4e4e;
    cursor: pointer;
    border-radius: 0 0 0 10px;
    -webkit-border-radius: 0 0 0 10px;
    -moz-border-radius: 0 0 0 10px;
    -o-border-radius: 0 0 0 10px;
}

.treatmentContent {
    padding: 5px 5px;
}

.practiceManagementRow ::-webkit-scrollbar {
    width: 8px;
}

.practiceManagementRow ::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.practiceManagementRow ::-webkit-scrollbar-thumb {
    background: #a4a4a4;
}

.treatmentContentList {
    position: relative;
    margin-bottom: 0;
    padding: 5px 0;
    border-bottom: 1px solid #cecece;
}

.treatmentTxt .treatmentTxtLabel {
    font-size: 14px;
    display: block;
    padding-left: 2px;
    margin: 0;
}

.treatmentNote,
.treatmentTeethShow {
    padding-top: 5px;
    margin-bottom: 5px;
}

.treatmentNote span {
    cursor: pointer;
    color: #00abff;
    font-weight: 400;
}

.treatmentNote input[type="text"] {
    width: 80%;
    border: 1px solid #989898;
    color: #828282;
    height: 25px;
    padding: 2px 5px;
    font-size: 13px;
}

.treatmentPriceTxt input[type="text"],
.treatmentCostTxt input[type="text"] {
    width: 100%;
    height: 25px;
    padding: 2px 5px;
    font-size: 13px;
}

.treatmentCostTxt input[type="text"] {
    width: 100px;
}

.treatmentTeethShow span {
    cursor: pointer;
    color: #00abff;
    font-weight: 400;
    word-break: break-all;
}

.treatmentDst span {
    cursor: pointer;
    color: #00abff;
    font-weight: 400;
}

.discountTxtWrap {
    width: 150px;
    clear: both;
    overflow: hidden;
    display: none;
}

.discountTxtWrap input[type="text"],
.discountTxtWrap select {
    width: 55px;
    float: left;
    margin-right: 5px;
    height: 25px;
    padding: 2px 5px;
    font-size: 13px;
}

.discountTxtWrap select {
    width: 50px;
}

.consentForm {
    padding-top: 16px;
}

.consentForm a {
    text-decoration: underline;
    color: #00abff;
}

.treatmentDst label {
    font-size: 14px;
    color: #008000;
}

.taxDetailSecHover {
    font-size: 10px;
    cursor: pointer;
    color: #00abff;
}

.taxDetailsSection {
    clear: both;
    overflow: visible;
    position: absolute;
    background: #fff;
    padding: 3px 5px;
    border: 1px solid #ddd;
    box-shadow: 1px 3px 11px #ddd;
    -webkit-box-shadow: 1px 3px 11px #ddd;
    -moz-box-shadow: 1px 3px 11px #ddd;
    -ms-box-shadow: 1px 3px 11px #ddd;
    width: 140px;
    color: #333;
    margin-top: 8px;
    display: none;
    z-index: 1;
}

.taxAmountList label {
    width: 70%;
    margin: 0;
    vertical-align: top;
}

.taxDetailsSection label:nth-child(2) {
    width: 30%;
}

.taxAmountList {
    padding: 0 0 2px;
}

.taxDetailsSection label {
    font-size: 10px;
    line-height: 15px;
}

.taxDetailsSection:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 10px 6px;
    border-color: transparent transparent #fff transparent;
    position: absolute;
    top: -10px;
    left: 8px;
    z-index: 1;
    filter: drop-shadow(0px 0 0 #868686);
    -webkit-filter: drop-shadow(0px 0 0 #868686);
    -moz-filter: drop-shadow(0px 0 0 #868686);
    -ms-filter: drop-shadow(0px 0 0 #868686);
}

.totalGreen {
    color: #008000;
}

.treatmentContentHover {
    position: absolute;
    right: 0;
    z-index: 1;
    background: #fff;
    top: 32px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    -webkit-transition: all .7s ease-in-out;
    -moz-transition: all .7s ease-in-out;
    -o-transition: all .7s ease-in-out;
    transition: all .7s ease-in-out;
    display: inline-block;
    border: 1px solid #008000;
    color: #008000;
    padding: 5px 15px;
    font-size: 11px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
    display: none;
}

.treatmentTeethWrap {
    display: none;
}

.teethImgHead {
    width: 800px;
    margin: 10px 15px 20px;
    padding: 5px 3px;
    clear: both;
    overflow: hidden;
    border-bottom: 1px solid #989898;
    position: relative;
}

.teethImgHead .checkSection {
    font-size: 12px;
    margin: 0 15px 0 0;
}

.treatmentGrandTotal {
    background: #e8e8e8;
    color: #585858;
    padding: 3px 0px 3px 5px;
    text-align: right;
    font-weight: 400;
    /* min-height: 60px; */
}

.treatmentGrandTotal span {
    margin: 0 10px;
    display: inline-block;
    font-size: 13px;
}

.treatmentGrandTotal .treatmentGrandByWrap {
    float: left;
    margin: 0;
}

.treatmentGrandTotal span span {
    display: block;
    text-align: right;
    margin: 0;
}

.treatmentGrandByWrap span.treatmentGrandBy {
    display: block;
    text-align: left;
}

.treatmentGrandByWrap .treatmentGrandBy span {
    text-align: left;
    display: inline-block;
    vertical-align: top;
}

.treatmentDrNameList {
    position: absolute;
    width: 215px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    top: 40px;
    padding: 2px 0;
    display: none;
    z-index: 1;
    right: 0px;
}

.treatmentGrandBy input[type="text"] {
    position: absolute;
    left: 40px;
    height: 20px;
    opacity: 0;
    width: 105px;
    padding: 2px 5px;
    border: 1px solid #bdbdbd;
    font-size: 13px;
}

.treatmentByName {
    color: #0095cc;
    padding-left: 5px;
    font-size: 13px;
}

.treatmentGrandByWrapBottom .treatmentDrNameList {
    bottom: 21px;
    top: auto;
}

.treatmentDrNameList ul {
    list-style: none;
    padding: 0;
    clear: both;
    margin: 0;
    max-height: 230px;
    overflow: auto;
}

.treatmentDrNameList ul li {
    clear: both;
    overflow: hidden;
    padding: 5px;
    cursor: pointer;
    color: #2d1152;
    outline: none !important;
    text-decoration: none;
}

.treatmentDrNameList ul li span {
    display: block;
}

.treatmentDrNameList ul li:hover {
    background: #cbc5d4;
    color: #000;
}

.treatmentProcedureList {
    /* margin: 0 -15px; */
    position: relative;
    z-index: 0;
}

.treatmentListtop {
    background: #EEE;
    padding: 10px;
    border: 1px solid #dadada;
    border-bottom: 0;
    float: left;
    width: 100%;
}

.treatmentListHead {
    clear: both;
    overflow: hidden;
    margin-bottom: 10px;
    padding-bottom: 15px;
}

.treatmentListHead span {
    float: left;
}

.treatmentSideBtn {
    float: right;
    display: inline-flex;
}

.practiceRightSection .trtmntPlanBtn {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
    padding: 2px 12px;
    font-size: 12px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -ms-border-radius: 4px;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-image: linear-gradient(to bottom, #00afef, #005fef);
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 7px;
    border: 1px solid #005fef;
}

.practiceRightSection {
    padding-left: 0;
}

.treatmentSideBtn img {
    width: 12px;
    cursor: pointer;
    margin-left: 5px;
}

.treatmentListSearch input[type="text"] {
    width: 80%;
    background-color: #fff;
    border: 1px solid #ccc;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    padding: 3px 5px;
    height: 29px;
    font-size: 12px;
}

.treatmentListNew {
    clear: both;
    padding-top: 15px;
}

.treatmentNewAdd {
    position: relative;
    padding-bottom: 5px;
}

.treatmentNewAdd input[type="text"] {
    width: 130px;
    background-color: #fff;
    border: 1px solid #ccc;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    padding: 3px 5px;
    display: inline-block;
    margin-right: 5px;
    vertical-align: top;
    height: 29px;
    font-size: 12px;
}

.treatmentNewAdd input[type="text"]:nth-child(2) {
    width: 60px;
}

.treatmentNewAdd input[type="text"]:nth-child(3) {
    width: 80px;
    margin-right: 0;
}

.treatmentNewAdd span {
    border-radius: 0 4px 4px 0;
    width: 33px;
    display: inline-block;
    min-width: 30px;
    margin-left: -1px;
    padding: 4px 2px 3px;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    text-align: center;
    text-shadow: 0 1px 0 #fff;
    background-color: #eee;
    border: 1px solid #ccc;
    vertical-align: top;
}

body .saVeBtn {
    display: inline-block;
    padding: 4px 12px;
    margin: 0 0 0 7px;
    font-size: 13px;
    text-align: center;
    vertical-align: top;
    cursor: pointer;
    border-color: #54a954;
    background-image: linear-gradient(to bottom, #62c462, #51a351);
    color: #fff;
    border: 0;
    border-radius: 4px;
    -ms-border-radius: 4px;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
}

.treatmentNewAddchck {
    clear: both;
    overflow: hidden;
    padding: 10px 0 0;
}

.treatmentNewAdd input[type="checkbox"] {
    display: inline-block;
    margin: 2px 5px 0 0;
}

.treatmentNewAdd label {
    font-size: 13px;
    padding: 0 10px 0 0;
    display: inline-block;
    vertical-align: top;
    font-weight: normal;
}

.taxDetailWrapper label {
    display: block;
    margin: 0 0 5px;
}

.taxMainContentWrap {
    clear: both;
    overflow: hidden;
}

.taxMainContentWrap {
    clear: both;
    overflow: hidden;
    margin-bottom: 5px;
    padding-bottom: 5px;
}

.taxMainWrap:last-child {
    margin-bottom: 0;
}

.treatmentNewAdd .taxMainWrap input[type="text"],
.treatmentNewAdd .taxMainWrap select {
    width: 125px;
    font-size: 12px;
    padding: 5px 4px;
    background-color: #fff;
    border: 1px solid #ccc;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    float: left;
    margin-right: 5px;
    height: 29px;
}

.treatmentNewAdd .taxMainWrap input.taxMainWrapInput {
    width: 55px;
}

.treatmentNewAdd .trtmntPlanBtn {
    border-radius: 4px;
    line-height: 24px;
    height: 29px;
    font-size: 18px;
    font-weight: 500;
    padding: 1px 0;
    text-align: center;
}

.taxMainWrap {
    clear: both;
    overflow: hidden;
    margin-bottom: 5px;
    padding-bottom: 5px;
}

.treatmentListSide {
    clear: both;
}

.treatmentNameList {
    width: 100%;
    border: 1px solid #dadada;
    color: #828282;
    max-height: 310px;
    overflow: auto;
    background: #fff;
    z-index: 1;
}

.treatmentNameListHeight {
    min-height: 323px;
}

.treatmentNameList ul {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 8px solid #ececec;
    border-bottom: none;
    border-top: none;
}

.treatmentNameList ul li {
    padding: 6px;
    border-bottom: 1px solid #dadada;
    font-size: 12px;
    font-weight: 300;
    cursor: pointer;
    color: #6f6f6f;
    clear: both;
    overflow: hidden;
}

.treatmentNameList ul li span {
    float: left;
}

.treatmentNameList ul li .elipsis {
    width: 60%;
    padding-right: 10px;
}

.tabProceduresId {
    font-size: 10px;
}

.treatmentNameListRight {
    width: 40%;
    text-align: right;
}

.treatmentNameList ul li .treatmentNameListAmt {
    float: right;
    margin-top: -15px;
}

b,
strong {
    font-weight: 600;
}

.treatmentSideListTxt {
    border: 1px dashed #2d1152;
    padding: 2px 5px;
    font-size: 11px;
    color: #2d1152;
    margin-top: -11px;
}

.treatmentGrandByWrap {
    font-size: 13px;
}

body .buttonDisable {
    opacity: 0.5;
    pointer-events: none;
}

.checkSection img {
    width: 20px;
    height: auto;
}

.planActive tbody:before {
    content: "";
    position: absolute;
    left: 0;
    top: 36px;
    bottom: 0;
    border-left: 3px solid #3498db;
}

.medicineDetailDiv .fa-eye {
    font-size: 12px;
}

.medicineDetailList {
    position: absolute;
    background: #fff;
    padding: 10px;
    z-index: 1;
    width: 450px;
    right: -10px;
    top: 23px;
    display: none;
    border-radius: 10px;
    box-shadow: 0 0 10px #c3c3c3;
    -webkit-box-shadow: 0 0 10px #c3c3c3;
    -moz-box-shadow: 0 0 10px #c3c3c3;
    -ms-box-shadow: 0 0 10px #c3c3c3;
    -o-box-shadow: 0 0 10px #c3c3c3;
}

.medicineDetailDiv {
    position: relative;
    margin-left: 15px;
    padding: 0 0 0px;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
}

.txtLeft {
    text-align: left;
}

.medicineDetailDiv:hover .medicineDetailList {
    display: block;
}

.medicineDetailList:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 12px 10px;
    border-color: transparent transparent #fff transparent;
    position: absolute;
    top: -9px;
    right: 7px;
    filter: drop-shadow(0px -3px 1px #eaeaea);
    -webkit-filter: drop-shadow(0px -3px 1px #eaeaea);
    -moz-filter: drop-shadow(0px -3px 1px #eaeaea);
    -ms-filter: drop-shadow(0px -3px 1px #eaeaea);
    -o-filter: drop-shadow(0px -3px 1px #eaeaea);
}

.medicineDetailListCvr {
    max-height: 310px;
    overflow-y: auto;
}

.medicineDetailItems {
    padding-bottom: 5px;
    color: #313131;
    padding-left: 10px;
    line-height: 15px;
    position: relative;
}

.medicineDetailItems:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 0 4px 5px;
    border-color: transparent transparent transparent #313131;
    position: absolute;
    left: 0;
    top: 4px;
}

.medicineDetailItems span {
    display: block;
    font-weight: 600;
    padding-bottom: 3px;
}

.practiceRightSection .trtmntPlanBtnCancel {
    background-image: linear-gradient(to bottom, #fff, #e6e6e6);
    color: #000;
    border: 1px solid #bbb;
}

.medicineListtop .treatmentListSearch input[type="text"] {
    width: 100%;
}

.treatmentNewAddBrdr {
    margin-bottom: 10px;
}

.treatmentNewAddBrdr:after {
    content: "";
    position: absolute;
    left: -10px;
    right: -10px;
    bottom: -3px;
    border-bottom: 1px solid #ccc;
}

.medicneNewAdd {
    clear: both;
    overflow: hidden;
    margin-bottom: 7px;
    font-size: 11px;
}

.medicneNewAdd label {
    width: 70px;
    margin: 0;
}

.treatmentNewAdd .medicneNewAdd input[type="text"],
.treatmentNewAdd .medicneNewAdd select {
    width: 105px;
    margin-right: 8px;
    background-color: #fff;
    border: 1px solid #ccc;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    padding: 1px 5px;
    display: inline-block;
    height: 29px;
}

.medicneNewAddNote input[type="text"] {
    width: 100%;
    height: 29px;
    font-size: 13px;
}

.treatmentNameList.medicineNameList {
    min-height: auto;
    /* max-height: 271px; */
    max-height: 153px;
}

body .ulLoadMore {
    display: block;
    text-align: center;
    text-decoration: none;
    background: #33739e;
    color: #fff;
    padding: 5px;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
}

.medicineHead {
    background: #eee;
    font-size: 13px;
    padding: 5px 10px;
    border: 1px solid #dadada;
    border-bottom: 0;
    border-top: 0;
    text-transform: uppercase;
}

.addGroupIcon {
    width: 40%;
    text-align: right;
}

.treatmentNameList ul li .addGroupIcon span {
    float: none;
    color: #6f6f6f;
    display: inline-block;
    padding: 0 2px;
    margin-left: 10px;
    cursor: pointer;
}

.treatmentNameList ul li:hover {
    background: #cbc5d4;
    color: #000;
}

.prescriptionRadio {
    display: inline-block;
    margin: 0 0 0 15px;
    font-size: 13px;
}

.prescriptionRadio .radioSection label {
    border-color: #888;
}

.skechpadWrap {
    clear: both;
    overflow: hidden;
    margin-bottom: 20px;
    width: 85%;
}

.skechpadChoose {
    padding-bottom: 10px;
}

.skechpadChoose label {
    width: 15%;
    display: inline-block;
    margin: 0;
}

.writePrePadCvr {
    padding: 20px 15px;
}

.sketchpadDiv {
    position: relative;
}

.palette-box {
    display: inline-block;
    padding: 2px 6px 2px 6px;
}

.palette {
    border: 2px solid transparent;
    width: 25px;
    height: 20px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin: 0 3px;
}

.skechpadChoose .red {
    background: #c22;
}

.skechpadChoose .blue {
    background: #22c;
}

.skechpadChoose .black {
    background: #000;
}

body .clearButton {
    padding: 0 10px;
    background: 0;
    border: 0;
    cursor: pointer;
    outline: 0;
}

canvas {
    border: 1px solid #a5a5a5;
    cursor: url(../images/icons/pen.ico), auto;
    background: #fff;
}

.caseSheetItemListFull .inputField {
    width: 100%;
}

body .practiceGreenButton {
    /* background: 0;
    border: 1px solid #046b29;
    padding: 6px 25px;
    color: #393939;
    font-size: 1em;
    border-radius: 5px;
    outline: 0;
    display: inline-block;
    cursor: pointer; 
    text-align: center;
    font-family: "montserratregular", sans-serif; */
    background: 0;
    border: 1px solid var(--save-color);
    padding: 5px 17px;
    color: #000 !important;
    text-align: center;
    font-family: "montserratregular", sans-serif;
    font-size: 14px;
    border-radius: 5px;
    outline: 0;
    font-weight: 400;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    margin: 0 0 0 10px;
    min-width: 120px;
}

.skechpadBottom {
    text-align: right;
}

.drawTumb {
    text-align: left;
    margin: 0 0 10px;
}

.drawTumbItem {
    width: 80px;
    border: 1px solid #ddd;
    padding: 5px;
    display: inline-block;
    margin: 0 10px 10px 0;
    position: relative;
    vertical-align: top;
}

.drawTumb img {
    width: 100%;
    height: auto;
}

body .drawClose {
    display: block;
    position: absolute;
    top: -7px;
    right: -8px;
    width: 20px;
    font-size: 13px;
    font-weight: 600 !important;
    height: 20px;
    padding: 3px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%;
    border: 1px solid #f00;
    text-align: center;
    line-height: 10px;
    background: #fff;
    cursor: pointer;
    color: #f00;
}

.skechpadBottom .caseSheetItemList {
    text-align: left;
    font-size: 12px;
}

.dentalProIcon a {
    font-size: 18px;
    color: #4e4e4e;
}

.red {
    color: #f00;
}

.green {
    color: #008000;
}

.dentalProIcon img {
    width: 20px;
}

.treatmentTable {
    margin: 0 0 10px;
}

.rowMargin .labelMarginBottom {
    margin: 0 0 5px;
    font-family: "SourceSansPro-Regular";
    color: #737272;
    font-size: 15px;
}

.rowMargin input::placeholder {
    color: #737272;
    font-size: 14px;
    font-family: "SourceSansPro-Regular";
}

.columnPaddingZero {
    padding: 0;
}

.dentalProNameTop span.elipsis {
    color: #0095cc;
    font-size: 13px;
    line-height: 23px;
    /* width: 73%; */
    width: 100%;
    max-width: 100px;
}

.dentalProNameTop {
    position: absolute;
    top: 10px;
    width: 100%;
    left: 3px;
}

.invoiceInnerTable table,
.invoiceInnerTable table tr {
    border: 0;
}

.invoiceInnerTableBr table tr {
    border-bottom: 1px solid #dedede;
}

.invoiceInnerTableBr .procedureInvoiceDiv {
    border-left: 2px solid #7dbf85;
}

.invoiceDivHeading {
    padding: 5px 7px;
    font-weight: 600;
    letter-spacing: 1px;
    background: #f3f3f3;
    color: #333;
}

.invoiceDivHeading,
.dentalInvoiceTotal {
    position: relative;
}

.dentalProName p {
    margin-bottom: 2px;
    max-width: 150px;
}

.invoiceWrapTable .invoiceDivHeading:after {
    content: "";
    background: #f3f3f3;
    width: 200px;
    right: -200px;
    position: absolute;
    top: -1px;
    bottom: -1px;
    border-bottom: 1px solid #dedede;
    border-top: 1px solid #dedede;
}

.procedureInvoiceDiv:first-child .invoiceDivHeading:after,
.prescriptionInvoiceDiv:first-child .invoiceDivHeading:after,
.labOrderInvoiceDiv:first-child .invoiceDivHeading:after {
    border-top: 1px solid #afafaf;
}

.invoiceWrapTable {
    overflow: hidden;
}

.invoiceTableCvrDiv .invoiceWrapTable {
    border: 0;
}

.invoiceTableCvrDiv {
    border: 1px solid #afafaf;
}

.invoiceTableCvrDiv .invoiceWrapTable .invoiceTableHeaderBrdr {
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.invoiceWrapTable .dentalProNote .treatmentProIcons {
    top: -12px;
}

.groupBg {
    background: #f1f1f1;
}

.dentalInvoiceAmount {
    clear: both;
    overflow: hidden;
    padding: 7px 0;
}

.inVoiceTotal,
.inVoicePaid,
.inVoiceBal {
    display: inline-block;
    width: 33.333%;
}

.inVoiceTotal span:first-child,
.inVoicePaid span:first-child,
.inVoiceBal span:first-child {
    font-weight: 700;
}

.treatmentTable table .dentalInvoiceAmount span {
    width: auto;
    padding-left: 5px;
    font-size: 12px;
}

.inVoiceBal span:last-child {
    color: #f00;
}

.treatmentTable table .groupBrdrTop,
.vitalSignsTable table .groupBrdrTop {
    border: 0;
    border-top: 1px solid #D8D8D8;
}

.paymentBy.paymentByDiv {
    width: 68%;
}

.paymentBy b {
    display: inline-block;
    padding-right: 7px;
    vertical-align: top;
}

.inVoiceTotal input[type="text"] {
    width: 75px;
    background-color: #fff;
    border: 1px solid #ccc;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    padding: 3px 5px;
    float: left;
    height: 28px;
}

.inVoiceTotal span {
    display: inline-block;
    vertical-align: top;
}

.inrSpan,
.dentalProName .inVoiceTotal .inrSpan {
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
    width: 33px;
    padding: 3px;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    text-align: center;
    text-shadow: 0 1px 0 #fff;
    background-color: #eee;
    border: 1px solid #ccc;
}

.inVoiceTotal .saVeBtn {
    padding: 5px 7px;
    font-size: 12px;
    margin: 0 0 0 5px;
}

.dentalProName .inVoiceTotal {
    width: 100%;
}

.treatmentContent.procedureAct:before {
    content: "";
    position: absolute;
    left: 0;
    top: -6px;
    bottom: 5px;
    border-left: 2px solid #7dbf85;
}

.treatmentContent.procedureAct,
.treatmentContent.prescriptionAct,
.treatmentContent.labAct,
.treatmentContent.roomsAct,
.treatmentContent.cafeteriaAct,
.treatmentContent.laundryAct,
.treatmentContent.disposableAct {
    position: relative;
    padding: 5px 5px 5px 10px;
}

.treatmentTags {
    display: inline-block;
    background: #a79ef3;
    padding: 2px 5px;
    font-size: 9px;
    position: relative;
    color: #FFF;
    font-weight: 600;
    width: 70px;
    letter-spacing: .5px;
    left: 2px;
    position: absolute;
    top: -4px;
}

.procedureAct .treatmentTags {
    background: #7dbf85;
}

.treatmentTags:after {
    background: #fff;
    border-bottom: 8px solid transparent;
    border-left: 10px solid #a79ef3;
    border-top: 8px solid transparent;
    content: '';
    position: absolute;
    right: -10px;
    top: 0;
}

.procedureAct .treatmentTags:after {
    border-left-color: #7dbf85;
}

.disposableAct .treatmentTags {
    background: #06d59c;
}

.disposableAct .treatmentTags:after {
    border-left-color: #06d59c;
}

.treatmentContent.prescriptionAct:before {
    content: "";
    position: absolute;
    left: 0;
    top: -6px;
    bottom: 5px;
    border-left: 2px solid #a79ef3;
}

.treatmentContent.disposableAct:before {
    content: "";
    position: absolute;
    left: 0;
    top: -6px;
    bottom: 5px;
    border-left: 2px solid #06d59c;
}

.treatmentGrandTotal input[type="text"] {
    width: 100px;
    padding: 2px 5px;
    border: 1px solid #bdbdbd;
    font-size: 13px;
}

.payNowBottom .clinicalSave {
    text-align: center;
}

.invoiceDivHeading,
.dentalInvoiceTotal {
    position: relative;
}

.dentalInvoiceTotal:after {
    content: "";
    background: #fff;
    width: 200px;
    right: -200px;
    position: absolute;
    /* top: -1px; */
    bottom: -1px;
    border-top: 1px solid #dedede;
}

.procedureInvoiceDiv:last-child .dentalInvoiceTotal:after,
.prescriptionInvoiceDiv:last-child .dentalInvoiceTotal:after,
.labOrderInvoiceDiv:last-child .dentalInvoiceTotal:after {
    bottom: -3px;
    /* border-bottom: 1px solid #dedede; */
}

.invoiceWrapTable .procedureInvoiceDiv .dentalInvoiceTotal:after {
    bottom: -21px;
}

.invoiceNoData {
    text-align: center;
    padding: 15px 0 0;
}

.treatmentTab {
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;
}

.treatmentTab li {
    width: 33.333%;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 5px 5px 0 0;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    -ms-border-radius: 5px 5px 0 0;
    -o-border-radius: 5px 5px 0 0;
    border-bottom: 5px solid transparent;
    display: inline-block;
    vertical-align: top;
    font-size: 15px;
    font-weight: 400;
    padding: 5px 2px;
    cursor: pointer;
}

.treatmentTab li.react-tabs__tab--selected {
    background: #eee;
    position: relative;
    z-index: 1;
    border-color: #b1b1b1;
}

.treatmentTab:after {
    border-bottom: 1px solid #b1b1b1;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    display: block;
}

.treatmentTab .prescriptionAct.react-tabs__tab--selected {
    border-bottom-color: #7dbf85;
}

.treatmentTab .procedureAct.react-tabs__tab--selected {
    border-bottom-color: #a79ef3;
}

.treatmentTab .disposableAct.react-tabs__tab--selected {
    border-bottom-color: #06d59c;
}

.treatmentTxtPayment p {
    font-size: 11px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.treatmentTxtPayment p:last-child {
    border: 0;
    padding: 0;
    margin: 0;
}

.dentalProNameDiv .dentalProNameTop {
    padding-right: 7px;
}

.txtCenter {
    text-align: center;
}

body .inVoiceTxtLabel {
    display: block;
    float: none;
    padding-top: 5px;
    font-size: 16px;
}

.inVoiceSideWraper {
    background: #ececec;
    padding: 10px;
}

.inVoiceSideBlck {
    background: #fff;
    padding: 10px;
    cursor: pointer;
}

.inVoiceTop {
    border-bottom: 1px solid #e4e4e4;
    padding: 10px 0;
    font-size: 13px;
    font-weight: 600;
}

.inVoiceSideLeft {
    width: 50%;
    text-align: left;
    display: inline-block;
    vertical-align: top;
}

.inVoiceSideRight {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    text-align: right;
}

.inVoiceAmount {
    padding: 0 10px;
    font-size: 12px;
}

.inVoiceTotalAmount {
    font-weight: 600;
    padding: 5px 0;
    font-size: 14px;
}

.inVoiceTotalAmount .inVoiceSideRight {
    color: #00abff;
}

.inVoiceSideBlck:hover {
    background: #cbc5d4;
    color: #000;
}

.treatmentTxt label {
    font-size: 14px;
    font-weight: 400;
    padding-left: 2px;
}

.treatmentTxt input[type="text"] {
    width: 55%;
    border: 1px solid #989898;
    color: #828282;
    height: 25px;
    padding: 2px 5px;
    font-size: 13px;
}

.txtRed {
    color: #f00;
}

.txtBlue {
    color: #00abff;
}

.treatmentDivSecWrapCvr {
    min-height: auto;
    height: auto;
}

.paymentSecTop {
    padding: 6px 5px;
    border: 1px dashed #8a8a8a;
    border-left: 0;
    border-right: 0;
    font-size: 12px;
}

.ContentLabel {
    padding-left: 0;
}

.ContentLabel span {
    padding-left: 10px;
    font-weight: 600;
}

.paymentSecContent.refundAmt {
    font-size: 11px;
    padding: 10px 15px;
    background: #efefef;
    border-bottom: 1px dashed #8a8a8a;
}

.paymentSecLst {
    margin-bottom: 6px;
}

.paymentSecLst label {
    padding-right: 10px;
    float: left;
    font-size: 12px;
    margin: 0;
}

.paymentSecLst span {
    display: inline-block;
    font-weight: 600;
}

.paymentSecLst input[type="text"],
.paymentSecLst select {
    width: 100px;
    padding: 3px 5px;
    border: 1px solid #9a9a9a;
    display: inline-block;
    height: 25px;
}

.paymentSecLst select {
    width: 130px;
    margin-left: 5px;
}

.paymentSecBtm {
    padding: 6px 10px;
    border-bottom: 1px dashed #8a8a8a;
    font-size: 12px;
    border-top: 1px dashed #8a8a8a;
}

.ContentBtmLabel {
    padding-right: 10px;
    padding-top: 5px;
    vertical-align: top;
}

.txtGreenColor {
    color: #008000;
}

.paymentSecBtm select {
    padding: 2px 0px;
    border: 1px solid #9a9a9a;
    display: inline-block;
    height: 23px;
    margin-left: 5px;
}

.paymentSecBtm textarea {
    width: 100%;
    min-width: auto;
    margin: 5px 0 0 0px;
    min-height: auto;
    height: 60px;
    padding: 5px 10px;
    border: 1px solid gainsboro;
    resize: none;
}

.innerContent {
    padding: 50px 0;
}

.whiteBg {
    background: #fff;
}

.titleHeading {
    padding: 0 0 30px;
    color: #3498db;
    font-size: 18px;
    position: relative;
    font-family: "Montserrat-SemiBold";
}

.treatmentTimelineType {
    font-size: 21px;
    color: #608d15;
    margin-bottom: 20px;
    clear: both;
    overflow: hidden;
}

.typeSelection {
    float: right;
}

body .timelineDeselect,
body .timelineSelect {
    font-size: 13px;
    color: #0095cc;
    cursor: pointer;
    padding: 7px 5px 0 0;
}

.treatmentTimelineCheck ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.treatmentTimelineCheck li {
    width: 25%;
    display: inline-block;
    margin-bottom: 5px;
    color: #565656;
    font-size: 13px;
    vertical-align: top;
}

.timelineTxt {
    margin: 0;
    display: inline-block;
    vertical-align: middle;
}

.treatmentTimelineCheck {
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
}

.timelineList {
    padding: 10px 0 5px 22px;
    border-left: 4px solid #add5e0;
}

.timelineListDate {
    margin-bottom: 15px;
    margin-top: 15px;
    display: inline-flex;
}

.timelineDate {
    font-size: 30px;
    color: #4599b1;
    font-weight: 600;
    float: left;
    line-height: 33px;
}

.timelineDateCvr {
    font-size: 11px;
    padding-left: 10px;
    display: inline-block;
}

.timelineMnthDate {
    color: #6f6f6f;
    margin-bottom: 2px;
}

.timelineDay {
    font-weight: 600;
    color: #000;
}

.timelineDateCvr span {
    display: block;
}

.timelineListContent {
    padding: 10px 10px 10px 25px;
    border: 1px solid #ddd;
    position: relative;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.timelineArrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 15px 10px 0;
    border-color: transparent #ddd transparent transparent;
    display: inline-block;
    position: absolute;
    left: -15px;
    top: 15px;
}

.timelineArrow:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 13px 9px 0;
    border-color: transparent #fff transparent transparent;
    left: 2px;
    top: -9px;
    position: absolute;
}

.timelineCircle {
    width: 15px;
    height: 15px;
    background: #4599b1;
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    position: absolute;
    left: -32px;
    top: 16px;
    border: 3px solid #fff;
}

.timelineListName {
    padding-bottom: 15px;
    font-size: 18px;
    color: #4a4a4a;
}

.timelineListName img {
    width: 23px;
    float: left;
    margin-right: 5px;
}

.timelineListName span {
    float: left;
    padding: 10px;
    padding-left: 0px;
}

.shareHospitalGroup .inputField {
    width: auto;
    padding: 2px 10px;
    font-size: 12px;
    height: 32px;
    margin-left: 10px;
}

.shareHospitalGroup {
    font-size: 13px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
}

.shareHospitalButtons {
    padding: 30px 0 20px;
    text-align: center;
}

.shareHospitalButtons ul {
    text-align: left;
}

.shareHospitalButtons ul li {
    width: 100%;
    padding-bottom: 15px;
}

body .printButton {
    border: 0;
    padding: 5px 20px;
    background: #2d1152;
    color: #fff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    display: inline-block;
    margin: 0 5px;
    cursor: pointer;
    font-size: 13px;
}

body .dataButton {
    border: 0;
    padding: 5px 20px;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#00afef), to(#005fef));
    background-image: -webkit-linear-gradient(top, #00afef, #005fef);
    background-image: -o-linear-gradient(top, #00afef, #005fef);
    background-image: linear-gradient(to bottom, #00afef, #005fef);
    color: #fff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    display: inline-block;
    margin: 0 5px;
    cursor: pointer;
    font-size: 13px;
}

.shareHistoryType {
    border: 0;
}

.leftmenu ::-webkit-scrollbar {
    width: 7px;
}

.leftmenu ::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.leftmenu ::-webkit-scrollbar-thumb {
    background: #8c8c8c;
}

.certificateCvrDiv h5 {
    text-align: center;
    font-size: 14px;
}

.certificateCvrDiv textarea {
    padding: 25px 15px;
    border: 1px solid gainsboro;
    font-size: 14px;
    color: #333;
    margin-bottom: 15px;
    transition: box-shadow .3s, border .3s;
    resize: none;
    width: 100%;
    min-height: 300px;
    outline: 0;
    border-top: none;
}

.certificateCvrDiv textarea.medCertificateSignature {
    width: 100%;
    height: auto;
    min-height: auto;
    margin: 0;
    padding: 15px 15px 0;
    border-bottom: none;
    margin-bottom: -10px;
}

#medicalCreate textarea:focus {
    outline: none;
}

.treatmentImageBtns {
    padding-bottom: 10px;
}

.treatmentImageBtns span {
    color: #fff;
    text-decoration: none;
    padding: 4px 15px;
    font-size: 12px;
    background-image: linear-gradient(to bottom, #906ebf, #2d1152);
    margin-right: 5px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -ms-border-radius: 4px;
    display: inline-block;
    cursor: pointer;
    vertical-align: top;
}

.galleryTitle {
    font-size: 14px;
    padding: 5px 0 5px 5px;
    color: #404040;
    font-weight: 600;
}

.sliderBreadCrumb {
    padding: 5px;
    display: flex;
    /* display: none; */
}

.sliderBreadCrumb span {
    padding-right: 5px;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
    display: inline-block;
}


/* .sliderBreadCrumb a {
    color: #404040;
    font-weight: 600;
    text-decoration: none;
} */

.sliderBreadCrumb span a {
    font-weight: 400;
}

.sliderBreadCrumb span:after {
    content: ' \003E';
    display: inline-block;
    /* font: normal normal normal 14px/1 FontAwesome; */
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 300;
    position: absolute;
    padding-left: 3px;
    top: 0px;
    right: -8px;
}

.sliderBreadCrumb span:last-child:after {
    visibility: hidden;
}

.sliderFullView {
    overflow-y: auto;
    max-height: 400px;
    float: left;
    width: 100%;
}

#folderList123 .sliderFullView {

    width: auto;
}

.gallerySliderView .item {
    margin: 5px;
    padding: 2px;
    background: #fff;
    width: 130px;
    display: inline-block;
    height: 130px;
    position: relative;
    vertical-align: top;
    border: 1px solid #ddd;
}

.uploadIcon {
    position: absolute;
    top: 5px;
    right: -13px;
    background: #fff;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    height: 25px;
    width: 25px;
    padding: 2px;
    line-height: 16px;
}

.gallerySliderView img {
    width: 100%;
    cursor: pointer;
    object-fit: cover;
    object-position: top;
}

.beforeAfterView {
    width: auto;
    display: inline-block;
    margin: auto;
    cursor: pointer;
    background: #FFF;
}

.beforeView {
    width: 215px;
    display: inline-block;
    padding: 10px;
    background: #fff;
    height: 240px;
    vertical-align: top;
    overflow: hidden;
    margin-bottom: 10px;
}

.galleryViewName {
    text-align: center;
    display: block;
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 8px;
}

.beforeView img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
}

.galleryOrtho {
    clear: both;
    overflow: hidden;
    width: 100%;
    margin: auto;
    cursor: pointer;
    display: inline-block;
    border: 1px solid #ddd;
    margin: 5px;
}

.orthoView {
    display: inline-block;
    width: 415px;
    padding: 5px 0 5px 5px;
    background: #fff;
    height: auto;
    vertical-align: top;
}

.xRayView {
    vertical-align: top;
    width: 210px;
    padding: 5px 5px 5px 0;
    background: #fff;
    height: 415px;
    display: inline-block;
}

.orthoView span {
    width: 135px;
    height: 135px;
    display: inline-block;
    padding: 5px;
    background: #fff;
    vertical-align: top;
}

.orthoView img,
.xRayView img {
    width: 100%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    overflow: hidden;
    height: 100%;
}

.xRayView span {
    width: 203px;
    height: 203px;
    display: inline-block;
    padding: 5px;
    background: #fff;
    vertical-align: top;
}

.imageDropdown .inputField {
    width: 150px;
}

.imageDropdown {
    display: inline-block;
    vertical-align: top;
    margin: 0 10px 0 0px;
}

.imageDropdownSmall .inputField {
    width: 80px;
}

.uploadImageThumb img {
    width: 100%;
}

.uploadImageThumb {
    width: 70px;
    display: none;
}

.imageDropdown .uploadButton,
.imageDropdown .mainButton {
    display: block;
}

.uploadButton input[type="file"] {
    display: none;
}

.uploadButton label,
body .mainButton {
    padding: 8px 15px;
    background: #2d1152;
    border-radius: 5px;
    line-height: 21px;
    display: inline-block;
    cursor: pointer;
    margin: 0;
    color: #fff;
    font-size: 11px;
    height: 35px;
}

body .addIcon {
    display: inline-block;
    width: 22px;
    text-align: center;
    margin: 7px 0 0 5px;
    cursor: pointer;
}

.addIcon img {
    width: 100%;
}

.treatmentImageAddList {
    position: relative;
    margin: 0 0 8px;
}

.leftmenu ul ul {
    margin: 0;
    padding: 0 0 0 45px;
    display: none;
}

.leftmenu li ul a {
    padding: 7px 10px 5px 25px;
}

.leftmenu ul ul img {
    width: 17px;
}

.leftmenu li ul span {
    font-size: 12px;
    padding-left: 15px;
}

.clinicalFieldSmall {
    width: 15.7%;
}

.clinicalFieldXs {
    width: 9%;
}

.tagListingDiv .react-tagsinput-input {
    width: 100%;
    border: 1px solid #dadada;
    font-size: 13px;
    height: 30px;
    padding: 2px 10px;
    border-radius: 3px;
    background: #fff;
}

.tagListingDiv .react-tagsinput {
    border: 0;
    background: transparent;
    padding: 0;
}

.rightTagTitle {
    margin: 0px 0 6px;
    font-size: 13px;
    font-weight: 600;
    color: #545454;
}

.patientright.pl_pro {
    padding: 10px 0;
    background: #ececec;
    border: 1px solid #dadada;
}


/* .tagListingDiv {
    padding: 0 0 0 15px;
} */

.tagListingDiv .react-tagsinput-tag {
    background: transparent;
    border: 0;
    color: #525252;
    margin: 0 10px 5px 0;
    padding: 2px 0px 0 0;
    position: relative;
    display: inline-block;
    vertical-align: top;
    font-size: 12px;
}

.tagListingDiv .react-tagsinput-remove {
    opacity: 0;
    width: 15px;
    display: inline-block;
    text-align: center;
    font-size: 17px;
    vertical-align: middle;
    font-weight: 400;
    line-height: 15px;
    position: relative;
    top: -1px;
    margin-left: 5px;
}

.tagListingDiv .react-tagsinput-tag:hover .react-tagsinput-remove {
    opacity: 1;
}

.rightTagList {
    margin: 0 0 10px;
}

.medicalHistoryData {
    display: none;
    padding: 8px 0;
    font-size: 12px;
}

.medicalHistoryHead {
    font-size: 12px;
    padding: 0 0 0 8px;
    position: relative;
    cursor: pointer;
}

.medicalHistoryHead svg {
    position: absolute;
    left: -10px;
    top: 3px;
    font-size: 11px;
}

.noRightData {
    text-align: center;
    color: red;
}

.medicalHistoryHead .fa-minus {
    display: none;
}

.innerHeader .headerBottom,
.innerCoverWraper .headerBottom {
    display: none;
}

.settingMenu {
    width: 17%;
    display: inline-block;
    vertical-align: top;
    background: #dedddd;
    height: 100%;
    /* min-height: 800px; */
    min-height: 100vw;
    padding: 15px 0 0;
    font-family: "Montserrat-Regular";
}

.settingsRight {
    width: 83%;
    display: inline-block;
    vertical-align: top;
    padding: 30px;
}

.settingLeftTitle {
    color: #191c1f;
    font-size: 21px;
    padding: 10px 10px 20px;
    font-weight: 600;
    letter-spacing: 1px;
}

.settingMenuList ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 13px;
}

.settingMenuList ul li a {
    padding: 15px 15px;
    display: block;
    color: #464646;
    text-decoration: none;
    font-weight: 600;
    font-family: "Montserrat-Regular";
    font-size: 12px;
    text-shadow: none;
}

.settingMenuList ul li {
    border-bottom: 1px solid white;
}

.dashboardTitle {
    font-size: 13px;
    font-weight: 600;
    color: #545454;
    margin: 0 0 25px;
    clear: both;
    overflow: hidden;
    /* font-family: "Montserrat-Regular"; */
}

.cardsettingLeft {
    width: 33.33%;
    display: inline-block;
    vertical-align: top;
}

.cardsettingRight {
    width: 66.66%;
    display: inline-block;
    vertical-align: top;
}

body .uploadButtonImage label {
    height: auto;
    padding: 5px 20px;
    display: inline-block;
}

.cardImageUpload {
    padding: 10px 0 0;
    display: none;
}

.cardsettingList {
    margin: 0 0 10px;
    font-size: 13px;
}

.cardsettingList .checkSection {
    display: inline-block;
    margin: 0 10px 0 0;
    vertical-align: middle;
}

.cardsettingList label {
    margin: 0 0 3px;
    display: block;
}

.cardsettingList .inputField {
    height: 30px;
}

.cardsettingList ul {
    padding: 0 0 0 15px;
    margin: 0;
}

.cardsettingList ul li {
    position: relative;
    padding: 0 23px 0 0;
    margin: 0 0 10px;
}

.cardsettingList ul li .fa-pen {
    position: absolute;
    right: 0;
    bottom: 7px;
    cursor: pointer;
    width: 12px;
    text-align: center;
    padding-top: 10px;
    height: 25px;
}

.dashButtonDiv {
    text-align: right;
    padding: 15px 0 0;
}

.exportList {
    width: 33.333%;
    display: inline-block;
    vertical-align: top;
    margin: 0 0 10px;
    font-size: 13px;
}

.exportList .checkSection {
    margin: 0 5px 0 0;
    position: relative;
    top: -1px;
}

.dashboardContentCenter {
    width: 75%;
    margin: auto;
}

.dashboardContentLarge {
    width: 85%;
    margin: auto;
}

.simplifiedSection {
    border: 1px solid #ddd;
    padding: 15px 20px;
    margin-bottom: 13px;
    background: #fff;
    color: #737272;
}

.simplifiedCheck {
    display: inline-block;
    margin: 0 30px 0 0;
}

.simplifiedSectionTop {
    margin: 0 0 15px;
}

.simplifiedSection .checkSection {
    display: inline-block;
    margin: 0 5px 0px;
    position: relative;
    top: -1px;
}

.simplifiedSectionItem {
    padding: 0 0 0 30px;
}

.simplifiedCheckList {
    margin: 0 0 15px;
}

.simpliFiedTxt {
    margin: 0 0 15px;
    color: #464646;
}

.simplifiedCheckSplit {
    width: 33.333%;
    display: inline-block;
    vertical-align: top;
}

.searchCount {
    text-align: right;
    margin: 10px -7px 0;
    font-size: 14px;
    font-weight: 600;
    color: purple;
}

.dashboardSearch {
    margin: 15px 0;
    background: #efecec;
    padding: 10px 20px;
}

.dashboardSearchList {
    padding: 0 12px;
}

body .dashButtonBorder {
    display: block;
    border: 1px solid var(--save-color);
    padding: 6px 20px;
    color: #393939;
    text-align: center;
    font-size: 14px;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
    height: 35px;
    font-family: "Montserrat-Regular";
}

body .dashButtonBorderGrey {
    border-color: #8e8e8e;
}

.dashboardtable table {
    width: 100%;
}

.dashboardtable table th,
.dashboardtable table td {
    padding: 8px 8px;
    font-size: 1.055556em;
    color: #333;
}

.dashboardtable table thead,
.dashboardtable table tbody {
    border-bottom: 1px solid #393939;
}

.dashboardtable table td {
    font-size: 13px;
    color: #333;
    vertical-align: top;
}

.dashboardtable {
    margin: 20px 0 40px;
}

.dashboardNoData {
    text-align: center;
    color: #ff0000;
    margin: 20px;
    font-size: 15px;
}

.homeUserDiv {
    background: #fff;
    padding: 20px 0 40px;
    text-align: center;
    border-bottom: 20px solid #f7f7f7;
}

.homeUserDiv img {
    margin: 0 auto 3px;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    object-fit: contain;
    object-position: center;
    background: #dddddd;
    border: 1px solid #ddd;
}

.homeUseName {
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
}

.homeUseName span {
    display: block;
    text-transform: capitalize;
    color: #333;
    font-weight: 800;
    font-size: 17px;
}

.homeLeftmenu {
    margin: 15px 0 0;
    background: #fff;
}

.QuickHeadTitle {
    padding: 20px 0px;
    border-bottom: #ccc solid 1px;
    color: #616161;
    font-size: 14px;
    margin: 0 0 15px;
    width: 80%;
    margin: 0 auto;
}

.profileSplit {
    margin: 20px 0;
}

body .borderButtonLarge {
    margin: 10px 0;
    padding: 8px 50px;
    font-size: 17px;
    font-weight: 400;
    color: #000000;
}

body .profileLink {
    color: #484848;
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
}

.landingSubHead {
    color: grey;
    text-align: left;
    margin: 15px 0;
    font-weight: 600;
    font-size: 16px;
}

.landingProfile .accordion * {
    outline: 0;
}

.landingProfile .accordion__item {
    margin: 0 0 7px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.landingProfile .accordion__button {
    padding: 13px 15px;
    font-weight: 600;
    color: #585858;
    background: #f1e7ff;
}

.landingProfile .accordion {
    border: 0;
}

.labelTextField sup {
    color: #f00;
    top: -3px;
    margin-left: 3px;
    font-size: 105%;
}

.landingProfile {
    margin: 15px 0 0;
}

.changePasswordDiv {
    margin: 5px 0 30px;
    border: 1px solid gainsboro;
    padding: 10px 15px;
}

.changePasswordDiv .inputField {
    width: 62%;
    font-family: "SourceSansPro-Regular";
    font-size: 14px;
    border-radius: 0px;
}

.borderButtonCancel.borderButtonLarge {
    margin: 10px 0 10px 30px;
    border: 1px solid #616161;
    color: #616161;
    box-shadow: 0 1px 2px rgb(0 0 0 / 40%);
    width: 245px;
    height: 45px;
    font-family: "Montserrat-Regular";
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landingProfile .practiceManagementList {
    position: relative;
}

.addOnSection {
    /* display: none; */
    padding: 15px 0;
}

.languageIcons {
    position: absolute;
    right: -35px;
    font-size: 23px;
    line-height: 36px;
    color: #0098f1;
    cursor: pointer;
}

.addOnContentList {
    width: 75%;
    position: relative;
    padding: 0 15px 10px 0;
}

.addOnSectionTitle {
    color: #3a3a3a;
    font-weight: 600;
    margin-bottom: 15px;
}

.addOnContent {
    border-bottom: 1px solid #8a8a8a;
    border-top: 1px solid #8a8a8a;
    padding: 15px 0 5px;
    margin-bottom: 10px;
}

.landingProfile .dashboardtable {
    margin: 0 0 20px;
}

.uploadThumbImage img {
    width: 50px;
}

.uploadThumbImage {
    display: inline-block;
    margin: 0 0 0 20px;
}

.uploadTextMessage {
    margin: 10px 0 0;
    font-style: italic;
    font-size: 14px;
    color: #3e3e3e;
}

.uploadButtonIarge {
    display: inline-block;
}

.uploadButtonIarge label {
    padding: 8px 35px;
    font-size: 16px;
    height: auto;
}

.uploadButtonIarge label:hover {
    background: lightgray;
}

.homeSpecialityTitle {
    margin: 0 0 15px;
    font-weight: 600;
    color: #6b6b6b;
}

.homeSpecialityList {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding: 0 15px 10px 0;
    font-size: 13px;
}

.homeSpecialityList .checkSection label {
    margin: 0 10px 0 0;
    top: -2px;
}

.headerRightDropList {
    position: absolute;
    width: 215px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    top: 35px;
    padding: 2px 0;
    /* display: none; */
    z-index: 1;
    right: 0;
}

.headerRightDropList ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 13px;
}

.headerRightDropList ul li a {
    display: block;
    padding: 7px 10px;
    color: #2d1152;
    text-decoration: none;
}

.headerRightDropList ul li a:hover {
    background: #cbc5d4;
    color: #000;
}

.calenderRow {
    margin: 0;
}

.calenderLeftSec {
    padding: 0;
    border-right: 1px solid #d3d3d3;
}

.calenderRightSec {
    padding-left: 0 !important;
}

.calenderBlockSec {
    padding: 8px 0 8px 15px;
    border-bottom: 1px solid #d3d3d3;
    font-size: 16px;
    font-family: "OpenSans-Regular";
    color: #000;
}

.calenderBlockList {
    color: #4e4e4e;
    text-decoration: none;
    display: block;
    outline: 0 !important;
    font-size: 12px;
    cursor: pointer;
    margin: 2px 0;
}

.calenderBlockList .fa-calendar {
    margin: 0 10px 0 0;
    position: relative;
    top: -1px;
}

.calenderTitle {
    font-size: 17px;
    padding: 6px 0;
    text-align: center;
    border-bottom: 1px solid #d3d3d3;
    line-height: 20px;
}

.doctorSearchFieldSec {
    display: block;
    position: relative;
    padding: 5px;
}

body .calenderList {
    margin: 0;
    list-style: none;
    padding: 0;
    cursor: pointer;
}

.doctorSearchFieldSec .doctorSearchIcon {
    position: absolute;
    right: 12px;
    top: 15px;
    color: #a5a5a5;
    pointer-events: none;
}

.calenderList li {
    border-bottom: #eaeaea 1px solid;
    padding: 8px 10px;
    box-shadow: none;
    font-weight: 300;
    font-size: 11px;
    color: #000;
    line-height: 15px;
    letter-spacing: .2px;
    position: relative;
    font-family: "OpenSans-Regular";
}

.calenderList li .fa-user-md {
    margin-right: 7px;
    position: relative;
}

body .doctorListLeftName {
    width: 88%;
    display: inline-block;
    vertical-align: middle;
}

.calenderWrap {
    background: #fff;
    position: relative;
    z-index: 0;
}

.doctorListArrow:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 8px;
    border-color: transparent transparent transparent #fff;
    position: absolute;
    left: -14px;
    top: -10px;
}

.doctorListArrow {
    width: 10px;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent #6b8e23;
    display: block;
    position: absolute;
    top: 6px;
    right: 3px;
}

.doctorClrFrst .doctorListArrow {
    border-color: transparent transparent transparent #6b8e23;
}

.doctorClrSnd .doctorListArrow {
    border-color: transparent transparent transparent #fd0260;
}

.doctorClrThrd .doctorListArrow {
    border-color: transparent transparent transparent #18e3a1;
}

.doctorClrFrth .doctorListArrow {
    border-color: transparent transparent transparent #4e62b3;
}

.doctorClrFfth .doctorListArrow {
    border-color: transparent transparent transparent #ccb21f;
}

.doctorClrSxth .doctorListArrow {
    border-color: transparent transparent transparent #b74e4f;
}

.doctorClrSvnth .doctorListArrow {
    border-color: transparent transparent transparent #f60;
}

.activeDr {
    background: #e0e0e0;
}

.activeDr .doctorListArrow:after {
    border-color: transparent transparent transparent #e0e0e0;
}

.calenderRightSec .react-tabs__tab-list {
    border-bottom: 1px solid #e4e4e4;
    margin: 0;
}

.calenderRightSec .react-tabs__tab {
    padding: 10px 0;
    font-size: 10px;
    width: 25%;
    text-align: center;
}

.circle {
    border-radius: 50%;
    display: block;
    margin: 10px auto 0 auto;
    width: 20px;
    height: 20px;
    color: #fff;
    font-size: 11px;
    text-align: center;
    line-height: 20px;
    background: #ddd;
    margin-bottom: 12px;
}

.circleGreen {
    background: #00a784;
}

.calenderRightSec .react-tabs__tab--selected {
    border-radius: 0;
    border: 0;
    border-bottom: 4px solid #2f77c7;
}

.circleRed {
    background: #cd143c;
}

.circleBlue {
    background: #4169e1;
}

.circleGrey {
    background: #4f4f4f;
}

.calenderTabContent {
    border: #ededed 1px solid;
    background: #fff;
}

.calenderNoData {
    text-align: center;
    font-size: 12px;
    color: #ff0000;
    padding: 5px 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

.calenderPopList {
    padding: 0 15px;
    width: 50%;
    display: inline-block;
    font-size: 11px;
    vertical-align: top;
    position: relative;
    margin: 0 0 8px 0;
}

.calenderPopupDiv {
    width: 600px;
}

.calenderPopListFull {
    width: 100%;
}

.calenderFormListSplit {
    width: 33.333%;
    display: inline-block;
    vertical-align: middle;
}

.calenderFormListSplitText {
    width: 10%;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}

.calenderFormListSmall {
    width: 23%;
    display: inline-block;
    vertical-align: middle;
    margin: 0 15px 0 0;
}

.calenderAddIcon {
    display: inline-block;
    padding: 5px 0 0;
    cursor: pointer;
}

.calenderAddIcon img {
    width: 20px;
}

.dashboardTitle .practicementBtn {
    float: right;
}

.chartImg img {
    width: 18px;
}

.chartImg {
    cursor: pointer;
    display: inline-block;
}

.dashboardTableText {
    max-width: 335px;
}

.smsNotify,
.smsNotifyError {
    background-color: #5a5a5a;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    color: #fff;
    font-size: 11px;
    padding: 5px 15px;
    letter-spacing: .4px;
    float: right;
}

.smsNotifyError {
    background-color: #e84c4c;
}

body .inputFieldMarginTop {
    margin: 10px 0 0;
}

.labelTextFieldTop {
    margin: 7px 0 0;
}

.dailysummary {
    border: 1px solid #ddd;
    padding: 30px 40px;
    font-size: 14px;
}

.SummaryDiv {
    padding-bottom: 10px;
    color: #737272;
}

.SummaryDiv label {
    padding-right: 10px;
    margin: 0;
    color: #737272;
    width: 25%;
    display: inline-block;
    vertical-align: top;
    line-height: 16px;
}

.SummaryDiv span {
    display: inline-block;
}

.SummaryDiv .checkSection label {
    width: 17px;
    padding: 0;
}

.summarySampleDiv h4 {
    clear: both;
    overflow: hidden;
    font-size: 15px;
    margin: 10px 0 15px;
    font-weight: 400;
    color: #000;
}

.sampleDiv .colan {
    width: 2%;
}

.sampleDiv {
    width: 100%;
    padding: 0 0 10px 15px;
    color: #737272;
}

.sampleDiv label {
    display: inline-block;
    padding-right: 10px;
    margin: 0;
    color: #737272;
    width: 22%;
    vertical-align: top;
}

.sampleDiv span {
    width: 78%;
    display: inline-block;
    color: #737272;
    vertical-align: top;
}

.SummaryDiv .inputField,
.SummaryDiv .textareaField {
    padding: 2px 5px;
    width: 200px;
}

.popupDivLarge {
    width: 900px;
}

.trackHistoryTableDiv {
    width: 60%;
    display: inline-block;
    vertical-align: top;
    padding: 0 7px;
    max-height: 400px;
    overflow-y: auto;
}

.trackHistorySec {
    margin: 0 -7px;
}

.trackHistoryDiv {
    width: 40%;
    display: inline-block;
    vertical-align: top;
    padding: 0 7px;
}

.trackHistoryTableDiv table {
    width: 100%;
    text-align: left;
}

.trackHistoryTableDiv table thead {
    background: #4ebab5;
    font-weight: 600;
    color: #fff;
}

.tableContentSec {
    padding: 10px;
    font-size: 12px;
}

.trackHistoryTableDiv th,
.trackHistoryTableDiv td {
    border: 1px solid #ddd;
}

.trackHistoryTableDiv td {
    color: #5d5d5d;
}

.sendSmsWrap {
    margin: -30px;
}

.sendSmsCountTop {
    background: #e8eff0;
    padding: 5px;
    display: flex;
}

.sendSmsCountDiv {
    width: 25%;
    display: inline-block;
    font-size: 13px;
    color: #464646;
    padding: 5px;
    font-weight: 300;
    position: relative;
}

.sendSmsCountDiv:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 85%;
    border-bottom: 1px solid #d9dee4;
}

.sendSmsCountDiv:after {
    content: "";
    border-right: 2px solid #b1b1b1;
    position: absolute;
    top: 10px;
    bottom: 10px;
    right: 15px;
}

.sendSmsCountDiv span {
    display: block;
    font-size: 15px;
    color: #2d7075;
    font-weight: 600;
    padding-top: 5px;
}

.sendSmsCountDiv:last-child:after {
    display: none;
}

.sendSmsCvrDiv {
    padding: 5px 5px;
}

.smsHeading {
    margin: 0 0 10px;
    display: block;
    padding: 10px 0;
    color: #2d7075;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 300;
    border-bottom: 1px solid #ddd;
}

.sendSmsContentDiv {
    clear: both;
    padding: 30px 0;
}

.sendSmsLeftSec {
    width: 15%;
    display: inline-block;
    text-align: center;
    vertical-align: top;
}

.smsLeftTab {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
}

.smsLeftTab li {
    height: 105px;
    font-size: 16px;
    position: relative;
    width: 35px;
    margin: auto;
}

.smsLeftTab li:first-child {
    height: auto;
}

.smsLeftTab span {
    background: #f1f1f1;
    width: 35px;
    display: block;
    margin: auto;
    height: 35px;
    color: #428bca;
    text-align: center;
    line-height: 35px;
    cursor: pointer;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    font-weight: 700;
    position: absolute;
    z-index: 1;
    bottom: 0;
}

.tabActive span {
    background: #428bca;
    color: #fff;
}

.smsLeftTab li:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
    width: 4px;
    background: #f2f2f2;
}

.smsLeftTab .tabActive:after {
    background: #42a196;
}

.sendSmsRightSec {
    width: 85%;
    display: inline-block;
    padding: 0 30px 30px;
    vertical-align: top;
}

.smsTypeSectionTop {
    padding: 0 0 30px;
}

.smsTypeSectionItem {
    display: inline-block;
    width: 17%;
    font-size: 16px;
    color: #6b6b6b;
}

.smsTypeSectionItem .radioSection label {
    margin: 0;
}

.trackHistoryTableDivFull {
    width: 100%;
    display: block;
    padding: 0;
}

.smsButtonDiv {
    text-align: right;
    padding: 20px 0 0;
    clear: both;
}

.smsButtonDiv .practicementBtn {
    padding: 3px 25px;
    font-size: 15px;
}

.smsLeftTab span:hover {
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
}

.smsTypeSectionBlock {
    padding: 10px 0;
}

.smsSubHeading {
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 5px;
}

.smsSubHeading span {
    font-size: 80%;
}

.smsTypeSectionTag .react-tagsinput {
    border: 1px solid #a9a9a9;
    height: 180px;
    overflow-y: auto;
    padding: 10px;
}

.smsTypeSectionTag .react-tagsinput-input {
    min-width: 170px;
    border: 0;
    border-bottom: 1px solid #a9a9a9;
    padding: 4px 0px;
    font-size: 12px;
    margin: 0;
    height: 29px;
}

.smsTypeSectionTag .react-tagsinput-tag {
    background-color: #5bc0de;
    color: #fff;
    border: 0;
    height: 29px;
    vertical-align: top;
    font-weight: 600;
}

.contentWhiteBg {
    background: #fff;
    padding-bottom: 0;
}

.smsTypeUpload {
    margin: 0 0 10px;
}

.tableSearchDiv {
    padding: 5px 0;
    text-align: right;
    color: #4a4a4a;
    font-size: 13px;
}

.tableSearchDiv .inputField {
    width: 175px;
    padding: 2px 5px;
    height: 30px;
    margin: 0 2px 0 5px;
    color: #4a4a4a;
}

body .practicementBtnSmall {
    padding: 3px 12px;
    font-size: 12px;
    margin: 2px 7px 0 0;
}

.smsBody {
    width: 50%;
    display: inline-block;
    padding: 10px 20px 10px 10px;
    vertical-align: top;
}

.scheduleSmsDiv {
    width: 50%;
    display: inline-block;
    padding: 10px 10px 10px 20px;
    vertical-align: top;
    position: relative;
}

.scheduleSmsDiv:after {
    position: absolute;
    content: "";
    height: 270px;
    background: #ddd;
    width: 1px;
    left: 0;
    top: 0;
}

.scheduleSmsDivHead {
    display: block;
    padding: 10px 0;
    color: #01a9a7;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 300;
}

.smsBody label {
    display: block;
    font-size: 12px;
    font-weight: 200;
    text-align: right;
    margin: 0;
}

.scheduleSMSRow {
    width: 100%;
    padding: 5px 0;
    clear: both;
}

.scheduleSMSRow label {
    min-width: 114px;
    font-size: 13px;
}

.scheduleSMSRow .inputField {
    width: auto;
    margin: 0 0 0 10px;
}

.practiceDetails {
    border: 1px solid #cbc5d4;
    padding: 25px 15px 20px;
}

.practiceDetailsLogo img {
    width: 50px;
}

.practiceDetailsLogo {
    text-align: right;
}

.practiceDetailsUpload {
    margin: 8px 0 0;
}

body .logoChange {
    text-decoration: none;
    cursor: pointer;
    color: #337ab7;
    font-weight: 600;
    font-size: 13px;
}

.practiceDetailsUpload .uploadButton {
    display: inline-block;
    margin: 0 0 0 10px;
}

.toggleHeader {
    display: none;
}

.toggleOverlay {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    display: none;
}

.treatmentListWriteDiv {
    height: 0;
    overflow: hidden;
    background: transparent;
    pointer-events: none;
}

.treatmentListWrite {
    height: 100%;
    pointer-events: auto;
    overflow: visible;
    background: #fff;
}

.skechpadMobilePicker {
    display: none;
}

body .prescriptionLink {
    display: none;
}

.teethWrap .teethSec {
    margin-bottom: 15px;
}

.keyRight .teethWrap {
    width: 100%;
}

body .borderButtonSmall {
    margin: 0 0 5px;
    font-size: 11px;
    padding: 5px 0px;
    font-weight: 400;
    width: 100px;
    text-align: center;
    min-width: 100px;
}

.uploadButtonSmall label {
    font-size: 11px;
    padding: 3px 0px;
    font-weight: 400;
    width: 100px;
    height: auto;
    color: #393939;
    background: #fff;
    font-family: "montserratregular", sans-serif;
    border: 1px solid var(--save-color);
}

.webCamSection {
    text-align: center;
}

.webCamSection video {
    width: 100% !important;
    height: auto !important;
}

.myCamera {
    width: 100% !important;
    height: auto !important;
}

.webCamButton {
    margin: 5px 0 0;
}

.settingListLiActive {
    background: #a7a6a6;
}

.settingMenuList ul .settingListLiActive a {
    color: #fff;
}

.settingMenuList .settingMenuSub {
    padding: 0 0 0 30px;
    display: none;
}

.settingMenuList ul ul li:last-child {
    border: 0;
}

.changeImage {
    display: block;
    color: #000;
    font-weight: 600;
    cursor: pointer;
    font-size: 9px;
    text-align: center;
    position: absolute;
    margin-top: 57px;
    display: none;
    margin-left: -2px;
}

.homeContentWraper {
    background: #fff;
}

.homeContentBanner {
    padding: 80px 0;
}

.contentHead {
    font-size: 44px;
    font-weight: 300;
    padding: 10px 0 70px;
    color: #191919;
    text-align: center;
}

.contentHead span {
    font-size: 24px;
    font-weight: 300;
    padding: 0px 0 0px;
    color: #2b2b2b;
    text-align: center;
    text-transform: uppercase;
    display: block;
}

.pulseLeftImg img {
    width: 90%;
}

.pulseLeftImg {
    text-align: center;
}

.loginForm {
    width: 80%;
    margin: auto;
    padding: 50px 20px 30px;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-size: 13px;
}

.loginList {
    margin: 0 0 25px;
}

body .loginButton {
    display: block;
    text-align: center;
    background: #0086ff;
    padding: 8px 10px;
    color: #fff;
    font-size: 13px;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    margin: 0 0 13px;
    font-weight: 400;
    font-family: "Montserrat-Regular";
}

body .commonTrialButton {
    color: black;
    background: #ffe20f;
    display: none;
}

body .commonTrialButton span {
    font-weight: 400;
    font-size: 75%;
}

body .forgotLink {
    cursor: pointer;
    color: #0086ff;
    text-decoration: none;
}

.loginList label {
    color: #565656;
    font-size: 14px;
    display: block;
    margin: 0;
}

.loginList .inputField {
    border: 0;
    border-bottom: 1px solid #ddd;
    border-radius: 0;
    outline: 0;
    transition: .2s ease all;
    -moz-transition: .2s ease all;
    -webkit-transition: .2s ease all;
    padding: 0;
    font-size: 14px;
    color: #8c8c8c;
}

.loginList .inputField:focus,
.loginList .inputField:active {
    transition: .2s ease all;
    -moz-transition: .2s ease all;
    -webkit-transition: .2s ease all;
    border-color: #8200ff;
}

.superchargeDiv {
    padding: 30px 0;
    text-align: center;
}

.headerText {
    font-size: 42px;
    font-weight: 400;
    margin: 0 0 20px;
}

.containerNew {
    width: 80%;
    margin: 0 auto;
    position: relative;
}

.topBlocks {
    width: 32.33%;
    display: inline-block;
    text-align: center;
    padding: 0 15px;
    margin: 25px 0;
}

.loginOuter .topBlocks {
    padding: 0 15px;
}

.imageDiv img {
    width: 100%;
}

.topBlocks h4 {
    font-size: 18px;
    margin: 0 0 7px;
}

.topBlocks h6 {
    font-size: 14px;
}

.imageDiv {
    padding: 10px 0 0;
}

.buttonDiv {
    width: 100%;
    text-align: center;
    padding: 30px 0 20px;
    clear: both;
}

body .topButton {
    background: #2d90e1;
    color: #fff;
    border: 0;
    padding: 12px 25px;
    font-size: 25px;
    font-weight: 400;
    border-radius: 4px;
    letter-spacing: 1px;
    text-decoration: none;
    cursor: pointer;
}

.doctorHomeSection {
    background: #f5f4f9;
    padding: 30px 0;
}

.iconTab {
    text-align: center;
    vertical-align: top;
    background: #fff;
    padding: 10px 20px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(179, 174, 174, 0.49);
    -moz-box-shadow: 0px 0px 20px 0px rgba(179, 174, 174, 0.49);
    box-shadow: 0px 0px 20px 0px rgba(179, 174, 174, 0.49);
    min-height: 370px;
    position: relative;
    color: #4e4e4e;
}

.iconTabList {
    width: 33.333%;
    display: inline-block;
    vertical-align: top;
    padding: 5px 5px 40px;
}

.iconTab img {
    width: 110px;
    border-radius: 50%;
    border: 1px solid #d0cfcf;
}

.iconQuotes {
    position: absolute;
    top: -15px;
    left: 14px;
}

.iconQuotes img {
    padding: 12px;
    width: 43px;
    border-radius: 0;
    border: 0;
    background: #8200ff;
}

.iconTab h4 {
    margin: 15px 0;
    padding: 0;
    font-weight: 400;
    font-size: 18px;
    color: inherit;
}

.iconTab h6 {
    margin: 15px 0;
    padding: 0;
    font-weight: 400;
    font-size: 13px;
    color: inherit;
}

.iconTab p {
    margin: 0;
    text-align: left;
    font-size: 13px;
}

.iconTabLong {
    min-height: 500px;
}


/*  */

.modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.pu_modal_content label {
    margin: 0;
    font-size: 11px;
}

.pu_modal_content .form-control {
    height: 28px;
    padding: 2px 5px;
    font-size: 11px !important;
    border-color: #e8e8e8;
    font-family: camphor, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    box-shadow: none;
}

.pu_modal_content .form-group {
    width: 50%;
    display: inline-block;
    padding: 0 10px;
    margin: 0 -3px 10px;
    vertical-align: top;
}

.modal-footer button {
    margin: 0 10px 10px 0;
    height: auto;
    padding: 5px 10px;
    min-width: 95px;
    font-family: camphor, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 600;
}

.pu_save,
.pu_save:hover,
.pu_save:focus {
    background: transparent !important;
    color: #000 !important;
}

.pu_cancel,
.pu_cancel:hover,
.pu_cancel:focus {
    border: 1px solid var(--cancel-color);
    background: transparent !important;
    color: #000 !important;
}

.calenderFull {
    margin-top: 15px;
    margin-bottom: 40px;
}

.fc .fc-button-primary {
    color: #000 !important;
    border: 1px solid #ddd !important;
    background-color: #fff !important;
    padding: 4px 20px !important;
}

.fc .fc-button-primary:focus {
    outline: none !important;
    box-shadow: none !important;
}

.fc .fc-button-group>.fc-button.fc-button-active {
    border-bottom: 3px solid #2f77c7 !important;
}

.fc-prev-button,
.fc-next-button,
.fc-today-button {
    margin: 0 2px !important;
}

.fc-today-button {
    border: 1px solid #c7c7c7 !important;
    border-radius: 0px;
    text-transform: capitalize !important;
    color: #000 !important;
}

.fc-button-group button {
    text-transform: capitalize !important;
}

.fc-icon-chevron-left:before {
    /* content: "\e900"; */
    content: ' \25C0' !important;
    font-size: 13px;
}

.fc-icon-chevron-right:before {
    content: ' \25B6' !important;
    font-size: 13px;
}

.fc .fc-button-primary {
    /* display: flex!important; */
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
}


/* Tooltip css begins */

.pu_user {
    width: 10%;
    float: left;
}

.pu_user img {
    width: 30px;
    object-fit: contain;
    object-position: center;
    height: 30px;
    border-radius: 50%;
    background: #dfdfdf;
    box-shadow: 0 0 6px #b7b7b7;
}

.pu_user_info {
    width: 90%;
    float: left;
    padding-left: 15px;
}

.pu_user_info h1 {
    font-size: 12px;
    color: #000;
    margin: 0px;
    font-weight: 600;
    margin-bottom: 2px;
}

.__react_component_tooltip {
    border: 1px solid #e5e5e5 !important;
}

.place-top::before {
    border-top: 8px solid #e5e5e5 !important;
}

.pu_generalinfo {
    width: 100%;
    float: left;
}

.pu_generalinfo p {
    margin-bottom: 0px;
    font-size: 12px;
    padding: 1px 0px;
    word-break: break-all;
}

.pu_generalinfo p img {
    width: 12px;
    margin-right: 8px;
}


/* Report Pharmacy stock detls */

.searchTxt {
    color: #048ac1;
    font-size: 13px;
    padding: 0 5px;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin: 10px -7px 0;
}

.dashboardtable tr td a {
    text-decoration: underline !important;
    color: blue !important;
    cursor: pointer;
}

.successMessageRed {
    text-align: center;
    color: red;
    width: 100%;
    display: block;
    font-size: 14px;
}

.successMessageRedBottom {
    margin: 30px 0px;
}

.elipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dashboardtable tr td span.refundReason {
    float: left;
    font-weight: normal;
    width: 70%;
    text-align: left;
}

.breadcrumbDoc {
    margin-bottom: 20px;
    width: 100%;
    float: left;
}

.breadcrumbDoc ul {
    padding: 0px;
}

.breadcrumbDoc ul li,
.breadcrumbDoc ul li a {
    list-style: none;
    float: left;
    color: #333;
    text-decoration: none;
    padding-right: 5px;
}

.arrow-right {
    display: block;
    margin: 7px auto;
    width: 8px;
    height: 8px;
    border-top: 1px solid #393939;
    border-left: 1px solid #393939;
    float: left;
    margin-right: 10px;
}

.arrow-right {
    transform: rotate(135deg);
}

.breadcrumbDoc ul li:last-child {
    color: #bcbcbc;
    /* font-weight: 600; */
}

.pl_calenderIcon {
    position: absolute;
    margin-left: -20px;
}

.pl_calenderIcon img {
    width: 20px;
}

.GSTtable th,
.GSTtable td {
    font-weight: normal;
    padding: 0 !important;
    vertical-align: top;
    border: none;
}

.dashboardtable table th,
.dashboardtable table td {
    border: none;
}

.dashboardtable a img {
    /* width: 20px; */
    margin: 0 3px;
}

.breadcrumbDocSect {
    padding: 25px 25px;
    border-bottom: 1px solid #ddd;
}

.gridList:hover {
    border: 1px solid #000;
}

.pl_vdo_dpdwn {
    position: absolute;
    right: 15px;
    text-align: right;
    margin-top: 20px;
}

.dashbrdMain .react-tabs__tab--selected {
    background: transparent;
    border-color: transparent;
    /* color: black; */
    border-radius: 0;
    border-bottom: 2px solid #000;
}

.dashbrdMain .react-tabs__tab--selected .tabHead {
    color: #2d1152;
}

.dashbrdMain .react-tabs__tab-list {
    border-bottom: none;
}

.tabHead {
    padding: 0px 5px;
    color: grey;
    font-size: 14px;
    margin: 0;
    font-weight: 600;
}

.dashbrdMain .react-tabs__tab:focus {
    box-shadow: none;
    border-color: transparent;
    outline: none;
}

.dashbrdMain .coverDiv {
    padding: 30px;
}

.registrationCardTop {
    background: #eb1e25;
    color: #fff;
    text-align: center;
    padding: 3px 0;
    margin-bottom: 10px;
    font-size: 10px;
}

.cardDetailRight {
    width: 60px;
    float: right;
    padding: 5px 5px 5px 5px;
    /* height: 132px; */
    height: 155px;
    /* position: relative; */
}

.cardDetailList {
    font-size: 10px;
}

.cardDetailList label {
    margin: 0;
    width: 20%;
    float: left;
}

.cardDetailListTxt {
    width: 73%;
    float: left;
}

.cardDetailListTxt span {
    float: left;
    width: 2%;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 23px;
}

.cardDetailListTxt .cardDetailListTxtCont {
    width: 95%;
    padding-left: 6px;
    word-break: break-word;
    font-weight: 600;
}

.registrationCardWrap {
    width: 80%;
    margin-left: 7%;
}

.registrationCardContent {
    padding: 0 8px;
    width: 100%;
    float: left;
    position: relative;
}

.registrationCardBtm {
    padding: 0 0px;
}

.registrationCardAddr {
    color: #fff;
    padding: 0px;
    margin: 20px 0 0;
    float: left;
    width: 100%;
    line-height: 10px;
    background: #008f73;
    border-bottom: 2px solid #a7cb37;
    clear: both;
    overflow: hidden;
    height: 26px;
    font-size: 10px;
}

.rgstrnAddrsIcon {
    float: left;
    text-align: center;
    color: #fff;
    background: #02795b;
    font-size: 12px;
    clear: both;
    overflow: hidden;
    height: auto;
    color: #fff;
    padding: 7px;
    line-height: 0px;
    width: 25px;
    margin-right: 2px;
}

.rgstrnAddrsIcon img {
    width: 10px;
}

.registrationCardContact {
    clear: both;
    overflow: hidden;
    padding: 2px 8px;
    font-size: 7px !important;
    background: #01609a;
}

.cardContactList {
    width: 50%;
    float: left;
    color: #fff;
    margin-bottom: 2px;
}

.cardContactList img {
    width: 7px;
    margin-right: 8px;
}

.cardInsructions {
    padding: 0 24px;
    margin-top: 8px;
    font-family: "SourceSansPro-Regular";
}

.pl_youtube {
    position: absolute;
    right: 30px;
}

.cardInsructions .elipsis {
    font-size: 12px;
}

.tabActive {
    background: #4bc108;
    color: #fff;
    cursor: default;
    /* padding: 2px 5px; */
    /* margin: 5px; */
    border-radius: 4px;
}

input::placeholder,
select::placeholder,
select option {
    color: #888;
    font-size: 13px;
}

.rightMedicalHistory {
    padding: 0 10px;
}


/* .pl_youtube.pl_ptnts {
    margin-top: -10px;
} */

.practiceManagementForm h3.practiceHead {
    font-size: 14px;
    font-weight: 600;
    color: #545454;
}

.practiceManagementForm .practiceManagementFormBlcok {
    display: block;
    margin-top: 10px;
}

.practiceManagementForm .practiceManagementFormBlcok ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.practiceManagementForm .practiceManagementFormBlcok ul li label {
    max-width: 100%;
    margin-bottom: 5px;
    margin-top: 10px;
    font-weight: 400;
    font-size: 13px;
    display: block;
}

.practiceManagementForm .practiceManagementFormBlcok ul li input {
    height: 35px;
    width: 100%;
    padding: 0 10px;
    margin-bottom: 15px;
    border: 1px solid #f2f2f2;
    background: white;
    font-size: 1em;
}

.prefixDateWrap {
    float: left;
    width: 59%;
    padding-left: 5px;
}

.prefixDateDiv {
    float: left;
    margin-right: 5px;
}

.checkBoxSquareDiv {
    width: 25px;
    position: relative;
    float: left;
}

.checkBoxSquareDiv label:after {
    /* -ms-filter: "alpha(opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0; */
    opacity: 1;
    content: '';
    position: absolute;
    width: 15px;
    height: 9px;
    background: transparent;
    top: 11px;
    left: 2px;
    border: 3px solid #5f5f5f;
    border-top: 0;
    border-right: 0;
    transform: rotate(-50deg);
    color: #000;
    /* opacity: 1;
    content: '';
    position: absolute;
    width: 12px;
    height: 7px;
    background: transparent;
    top: 12px;
    left: 6px;
    border: 3px solid #5f5f5f;
    border-top: 0;
    border-right: 0;
    -webkit-transform: rotate( -45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate( -45deg);
    color: #000; */
}

.checkBoxSquareDiv input[type=checkbox]:checked+label:after {
    -ms-filter: "alpha(opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
}

.prefixDateDiv span {
    width: 35px;
    text-align: center;
    padding: 0 5px;
    border: 1px solid #f2f2f2;
    height: 35px;
    float: left;
    line-height: 33px;
    background: #fff;
    margin-left: -1px;
}

.prefixDateYr span {
    width: 45px;
}

.practiceManagementForm .practiceManagementFormBlcok ul li select,
.pl_addTreatmnt select {
    padding: 3px;
    color: #888;
    border: 1px solid gainsboro;
    margin-bottom: 15px;
    width: 100%;
    font-size: 1em;
    height: 35px;
}

.autoIdGeneration {
    clear: both;
    overflow: hidden;
    font-weight: 500;
}

.autoIdGeneration span {
    float: left;
}

.autoIdGeneration label {
    float: left;
    margin: 1px 0 0 5px !important;
    font-weight: 600 !important;
}

li.borderLineLi {
    clear: both;
    overflow: hidden;
    padding: 10px 15px;
}

.borderLineDiv {
    border-bottom: 1px solid #c5c5c5;
    clear: both;
    overflow: hidden;
}

.practiceManagementForm .practiceManagementFormBlcok ul li input:focus-visible {
    outline: none;
}


/*  ======================      */

.practiceFootBtm {
    font-size: 10px;
    text-align: center;
    line-height: 17px;
}

.practicePrintlogoNarrow,
.practicePrintlogoWide {
    width: 22%;
    float: left;
}

.practicePrintlogoSquare {
    width: 100%;
    float: none;
}

.practicePrintName {
    width: 78%;
    float: left;
}

.practicePrintlogoNarrow img {
    width: 140px;
    height: auto;
}

.practicePrintlogoSquare img {
    width: 160px;
    height: auto;
    margin: auto;
}

.practicePrintlogoWide img {
    width: 150px;
    height: auto;
}

.practicePrintName.practiceNameSquare {
    width: 100%;
}


/*  */

.practiceManagementForm .practiceManagementFormBlcok ul li label,
.pl_addTreatmnt label {
    max-width: 100%;
    margin-bottom: 5px;
    margin-top: 10px;
    font-size: 13px;
    display: block;
    font-family: "Arial";
}

.practiceRadio {
    padding-left: 0px;
    display: inline-block;
    cursor: pointer;
    outline: 0;
}

.greenLink {
    color: green !important;
}

.practiceManagementForm .practiceManagementFormBlcok ul li input {
    height: 35px;
    width: 100%;
    padding: 0 10px;
    margin-bottom: 15px;
    /* border: 1px solid #f2f2f2; */
    background: white;
    font-size: 1em;
    border: 1px solid gainsboro;
}

.printBtm {
    position: absolute;
    right: 30px;
}

.practiceRadioSet input {
    width: 15px !important;
}


/* radio btn css */

.radio input[type=radio] {
    position: absolute;
    opacity: 0;
}

.radio input[type=radio]+.radio-label:before {
    content: "";
    background: #f4f4f4;
    border-radius: 100%;
    border: 1px solid #b4b4b4;
    display: inline-block;
    width: 15px;
    height: 15px;
    position: relative;
    top: 3px;
    margin-right: 1em;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
}

.radio input[type=radio]:checked+.radio-label:before {
    background-color: #3197EE;
    box-shadow: inset 0 0 0 4px #f4f4f4;
}

.radio input[type=radio]:focus+.radio-label:before {
    outline: none;
    border-color: #3197EE;
}

.radio input[type=radio]:disabled+.radio-label:before {
    box-shadow: inset 0 0 0 4px #f4f4f4;
    border-color: #b4b4b4;
    background: #b4b4b4;
}

.radio input[type=radio]+.radio-label:empty:before {
    margin-right: 0;
}

.practiceRadioSet label {
    font-weight: normal !important;
}

.practiceRadioSet input {
    height: auto !important;
}

.pl_chkbx input {
    width: 15px !important;
    height: 15px !important;
    float: left;
    margin-right: 8px;
}

.LimitScrollDiv2 {
    max-height: 735px;
    overflow-y: auto;
}

::-webkit-scrollbar-thumb {
    background: #a4a4a4;
    border: 52px none #fff;
    border-radius: 0;
}

::-webkit-scrollbar-track {
    background: #e6e6e6;
    border: 0 none #fff;
    border-radius: 1px;
}

::-webkit-scrollbar-corner {
    background: transparent;
}

::-webkit-scrollbar {
    width: 9px;
    height: 9px;
}

#imageThumb2 {
    width: 100px;
    margin: 8px 0px;
}

.practiceGreenButton {
    float: right;
    position: relative;
    right: 20px;
}

.LimitScrollDiv2 input {
    margin-bottom: 0px;
}


/*  */

.practiceFootBtm {
    font-size: 10px;
    text-align: center;
    line-height: 17px;
}

.practicePrintlogoNarrow,
.practicePrintlogoWide {
    width: 22%;
    float: left;
}

.practicePrintlogoSquare {
    width: 100%;
    float: none;
}

.practicePrintName {
    width: 78%;
    float: left;
}

.practicePrintlogoNarrow img {
    width: 140px;
    height: auto;
}

.practicePrintlogoSquare img {
    width: 160px;
    height: auto;
    margin: auto;
}

.practicePrintlogoWide img {
    width: 150px;
    height: auto;
}

.practicePrintName.practiceNameSquare {
    width: 100%;
}

.fc .fc-daygrid-day-number,
.fc .fc-col-header-cell-cushion {
    color: #000;
    text-decoration: none;
}

.fc .fc-toolbar-title {
    margin: 0;
    font-size: 18px !important;
    line-height: 40px;
    font-weight: normal;
    margin: -5px;
    color: black;
}


/* Add procedure */

.procedureCatType select {
    width: 200px;
    border: 1px solid #bfbfbf;
    height: 28px;
    padding: 2px 5px;
}

.procedureCatName {
    width: 18%;
    display: inline-block;
    font-size: 14px;
    vertical-align: middle;
}

.procedureCatType input[type="text"] {
    width: 200px;
    border: 1px solid #bfbfbf;
    height: 28px;
    padding: 2px 5px;
}

.rightTabTag h3.practiceHead,
.practiceManagementForm h3.practiceHead {
    margin: 20px 0 1px;
    font-size: 14px;
    font-weight: 600;
    color: #545454;
    font-family: "Arial";
    letter-spacing: .5px;
}

.procedureCatSec {
    padding: 30px 10px;
}

.procedureCatType {
    width: 82%;
    display: inline-block;
    font-size: 14px;
    vertical-align: middle;
}

.procedureCatSub {
    float: left;
    vertical-align: bottom;
}

.procedureCatSub input[type="text"] {
    width: 110px;
}

.procedureCatSub span {
    height: 28px;
    background: #ddd;
    display: inline-block;
    vertical-align: top;
    margin-left: -4px;
    border: 1px solid #bfbfbf;
    padding: 3px 3px;
}

.procedureCatSubMul {
    float: left;
    padding-left: 10px;
}

.procedureCatSubMul input[type="checkbox"] {
    margin: 10px 5px 0 0;
    float: left;
}

.procedureCatSubMul label {
    margin: 0;
    float: left;
    padding-top: 6px;
    font-weight: 400;
}

.procedureCatType textarea {
    resize: none;
    width: 200px;
    height: 90px;
    border: 1px solid #bfbfbf;
    padding: 2px 5px;
    min-width: 200px;
    min-height: 90px;
    margin: 0;
}

.procedureCatList {
    margin-bottom: 20px;
    clear: both;
    overflow: hidden;
}

.applicabletaxDiv {
    clear: both;
    width: 100%;
    float: left;
    padding: 10px;
    border: 3px solid #dedddd;
}

.procedureCatName {
    width: 18%;
    display: inline-block;
    font-size: 14px;
    vertical-align: middle;
}

.procedureCatType {
    width: 82%;
    display: inline-block;
    font-size: 14px;
    vertical-align: middle;
}

.borderSeparate {
    clear: both;
    text-align: center;
    overflow: hidden;
    padding: 2px 15px 7px;
}

.borderSeparate span {
    border-bottom: 1px solid #cecece;
    display: inline-block;
    width: 53%;
}

.taxDetailWrapperCvr {
    padding: 10px 0 20px;
}

.taxDetailWrapper label {
    float: none;
    display: block;
}

.taxMainContentWrap {
    clear: both;
    overflow: hidden;
}

.taxMainWrap {
    clear: both;
    overflow: hidden;
    margin-bottom: 5px;
    padding-bottom: 5px;
}

.taxDetailWrapperCvr select,
.taxDetailWrapperCvr input[type="text"] {
    padding: 7px 10px;
    border: 1px solid #c5c5c5;
    color: #585858;
    margin-right: 10px;
    font-size: 13px;
    width: 235px;
}

.procedureCatList {
    padding: 0px;
    margin-bottom: 20px;
    clear: both;
    overflow: hidden;
}

.practiceGreyButton,
.practiceGreyButton:hover {
    background: 0;
    border: 1px solid var(--cancel-color);
    padding: 5px 17px;
    height: 34px;
    color: #000 !important;
    min-width: 110px;
    float: right;
    margin-right: 40px;
    text-align: center;
    font-family: "montserratregular", sans-serif;
    font-size: 14px;
    border-radius: 5px;
    text-decoration: none;
    line-height: 22px;
    width: auto;
    min-width: 120px;

    /* background: 0;
    border-color: var(--cancel-color);
    padding: 5px 17px;
    color: #393939;
    text-align: center;
    font-family: "montserratregular", sans-serif;
    font-size: 14px;
    border-radius: 5px;
    outline: 0;
    font-weight: 400;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    margin: 0 0 0 10px;
    min-width: 120px; */
}


/* Treatment Plan css */

.treatmentTable table td,
.treatmentTable table th,
.treatmentTable table td span {
    font-size: 13px;
    text-align: left !important;
    padding: 0 1px;
}

.groupBrdr .dentalProName,
.groupBrdr .optionWrapper {
    /* display: inline; */
    display: -webkit-box;
    margin-top: 3px;
}

.plannedBy {
    padding: 0 5px;
}

.optionWrapper img {
    width: 20px;
    height: 20px;
    float: left;
}

.dentalProName,
.dentalProCost,
.dentalProDis,
.dentalProTotal,
.dentalProNote {
    padding: 10px 7px;
    float: left;
    width: 100%;
    position: relative;
    color: #000;
    font-size: 12px;
}

.dentalProNote.paddingFive img,
.webcamOpen img {
    cursor: pointer;
}

.conf-cursorpointer {
    width: 20px;
}

.plannedBy {
    padding: 7px 7px;
}


/* .treatmentPlanBtn {
    display: inline-block;
    padding: 2px 12px;
    margin-bottom: 0;
    font-size: 12px;
    margin-right: 5px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    color: #fff;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -ms-border-radius: 4px;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
    background-color: #008fef;
    background-image: -moz-linear-gradient(top, #00afef, #005fef);
    display: block;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#00afef), to(#005fef));
    background-image: -webkit-linear-gradient(top, #00afef, #005fef);
    background-image: -o-linear-gradient(top, #00afef, #005fef);
    background-image: linear-gradient(to bottom, #00afef, #005fef);
    background-repeat: repeat-x;
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ff00afef', endColorstr='#ff005fef', GradientType=0);
    border-color: #005fef #005fef #0041a3;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid: DXImageTransform.Microsoft.gradient(enabled=false);
} */

.treatmentPlanBtn a {
    color: #fff;
    text-decoration: none;
}


/*  */

.treatmentTable .dropdown {
    display: inline-block;
    position: absolute;
    right: 0px;
}

.dd-button {
    display: inline-block;
    border: 1px solid #ddd;
    border-radius: 0px;
    padding: 15px 9px 10px 20px;
    background-color: #ececec !important;
    cursor: pointer;
    white-space: nowrap;
    position: relative;
    z-index: 9;
}

.dd-button:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid black;
}

.dd-button:hover {
    background-color: #eeeeee;
}

.dd-input {
    display: none;
}

.dd-menu {
    position: absolute;
    top: 100%;
    border: 1px solid #ccc;
    border-radius: 0px;
    padding: 0;
    margin: -5px 0 0 0;
    background-color: #ffffff;
    list-style-type: none;
    right: 0;
    z-index: 9;
}

.dd-input+.dd-menu {
    display: none;
}

.dd-input:checked+.dd-menu {
    display: block;
    z-index: 999;
}

.dd-menu li {
    padding: 3px 45px 3px 8px;
    cursor: pointer;
    white-space: nowrap;
}

.dd-menu li:hover {
    background-color: #f6f6f6;
}

.dd-menu li a {
    display: block;
    margin: -10px -20px;
    padding: 10px 20px;
}

.dd-menu li.divider {
    padding: 0;
    border-bottom: 1px solid #cccccc;
}

.pl_print {
    border: 1px solid #ddd;
    position: absolute;
    right: 30px;
    padding: 4px 8px 4px 8px;
}

.pl_print img {
    width: 15px;
}

.groupBrdr .totalinr {
    text-align: right !important;
    float: left;
    width: 100%;
}

.close_row {
    width: 18px;
    height: 18px;
    position: absolute;
    font-size: 17px;
    right: 0;
    text-align: center;
    border: 1px solid #ddd;
    line-height: 11px;
    z-index: 1;
    color: #ff4e4e;
    cursor: pointer;
    border-radius: 0 0 0 10px;
    -webkit-border-radius: 0 0 0 10px;
    -moz-border-radius: 0 0 0 10px;
    -o-border-radius: 0 0 0 10px;
}


/* .close_row img {
    width: 15px;
} */

.pl_treatmentDiv tr.groupBrdr .dentalProDis,
.pl_treatmentDiv tr.groupBrdr .totalinr {
    text-align: right;
    color: #0c8c45;
    font-weight: 500;
    font-size: 14px;
}

.total_info p {
    text-align: right;
    margin: 0px;
}

.total_info {
    padding: 10px 10px;
}

.treatmentPlanList {
    min-height: 160px;
    padding: 0px 0 10px;
    height: 350px;
    overflow: auto;
    overflow-x: hidden;
    background: #fff;
}

.treatmentPlanList:last-child {
    padding-bottom: 0px;
}

.pl_blue_btn {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    padding: 3px 15px;
    font-size: 12px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -ms-border-radius: 4px;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
    background-color: #008fef;
    background-image: -moz-linear-gradient(top, #00afef, #005fef);
    display: block;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#00afef), to(#005fef));
    background-image: -webkit-linear-gradient(top, #00afef, #005fef);
    background-image: -o-linear-gradient(top, #00afef, #005fef);
    background-image: linear-gradient(to bottom, #00afef, #005fef);
    background-repeat: repeat-x;
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ff00afef', endColorstr='#ff005fef', GradientType=0);
    border-color: #005fef #005fef #0041a3;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid: DXImageTransform.Microsoft.gradient(enabled=false);
    margin-left: 5px;
}

.pl_treat.treatmentTable table .dentalProPlaned {
    border: 0;
    /* border-bottom: 1px solid #afafaf; */
    background: #fff;
}

.add_treatment_plan.aDDTreat .pl_treat.treatmentTable table .dentalProPlaned {
    background: transparent;
}

.appoinmentWrap {
    clear: both;
    overflow: hidden;
    padding: 3px 10px 3px 3px;
    border: 1px solid #cecece;
    background: #fff;
    margin: 3px;
    position: relative;
    display: flex;
}

.appoinmentDateDiv {
    width: 60px;
    height: 100%;
    background: #3498db;
    min-height: 100%;
    float: left;
    padding: 5px;
    text-align: center;
    color: #fff;
    font-size: 11px;
    display: block;
    justify-content: center;
    align-items: center;
}

.apnmentDate {
    font-size: 17px;
}

.appoinmentDateDiv span {
    display: block;
    margin-bottom: 5px;
}

.appoinmentDateDetail {
    float: left;
    width: 90%;
    font-size: 12px;
    color: #5a5a5a;
    padding-left: 10px;
}

.appoinmentLeftDiv {
    padding-bottom: 0px;
}

.appoinmentReason {
    display: block;
    font-weight: 600;
    clear: both;
    overflow: hidden;
}

.appoinmentReason span {
    float: left;
}

.appoinmentReason span.elipsis {
    float: left;
    padding-left: 5px;
    font-weight: 400;
    display: block;
    width: 90%;
}

.appoinmentSatusWrap {
    clear: both;
    overflow: hidden;
    padding-bottom: 5px;
}

.appoinmentSatusWrap span {
    width: 50%;
    float: left;
}

.appoinmentSatusWrap span {
    width: 50%;
    float: left;
}

.appoinmentRightDiv {
    clear: both;
    overflow: hidden;
    padding-bottom: 5px;
    font-size: 14px;
    color: #2f77c7;
}

.appoinmentRightDiv span {
    width: 50%;
}

.appointmentEdit {
    position: absolute;
    top: 0;
    right: 0;
}

.treatmentPlanWrap {
    background: #ececec;
    margin-right: -10px;
    padding: 0 10px 10px 10px;
    float: left;
    width: 100%;
}

.pl_blue_btn.pl_grey_btn {
    border: 1px solid #bbb;
    background: transparent;
    color: #000;
    text-shadow: none;
}

.clinicContentList {
    float: left;
    width: 100%;
    background: #fff;
    padding: 10px;
    border-bottom: 1px solid #eaeaea;
}

.clinicalField {
    width: 65%;
    /* float: left; */
    padding-left: 10px;
    border-left: 1px solid #2980b9;
    position: relative;
}

.clinicalField select,
.clinicalField input,
.clinicalField textarea {
    width: 100%;
    padding: 5px 10px;
    border: 1px solid #ccc;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border linear .2s, box-shadow linear .2s;
    -moz-transition: border linear .2s, box-shadow linear .2s;
    -o-transition: border linear .2s, box-shadow linear .2s;
    transition: border linear .2s, box-shadow linear .2s;
    line-height: 20px;
    color: #555;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    vertical-align: middle;
}

.printBtm {
    position: fixed;
    top: 20px;
    right: 10%;
    width: auto !important;
}

.dentalProPlaned .estimatedPrice.txtRight {
    padding-right: 6px;
}

.quantity::-webkit-inner-spin-button,
.quantity::-webkit-outer-spin-button {
    opacity: 1;
    margin: 0;
}

.treatmentPriceTxt input {
    width: 100%;
    height: 25px;
}

.pl_treatmentDiv .planActive tbody:before {
    border-left: none;
}

.pl_addTreatmnt .plannedBy {
    cursor: pointer;
    color: #00abff;
    font-weight: 400;
}

input:focus,
textarea:focus {
    outline: none;
}

.plannedBy input {
    height: 25px;
}

.patientright.pl_pro .rightTagList {
    margin: 0 0 10px;
    padding: 0 8px;
}

.pl_addTreatSection .treatmentPlanWrap {
    background: transparent;
    padding: 0;
}

.pl_addTreatSection .treatmentPlanWrap .sideMenuContent {
    background: transparent;
    border: 1px solid #cbc5d4;
}

.pl_addTreatSection .treatmentTable table tr {
    border-left: none;
    border-right: none;
    width: 100%;
    display: table;
}

.practiceRightSection .pl_pro .tagListingDiv input {
    width: 75%;
}

.treatmentPlanBtn.pl_blue_btn.pl_cancelbtn {
    background: #f5f5f5;
    color: #000;
    border: 1px solid #e0e0e0;
    background-image: linear-gradient(to bottom, #fff, #e6e6e6);
    border: 1px solid #bbb;
    border-radius: 4px;
    -ms-border-radius: 4px;
    box-shadow: inset 0 1px 0 rgb(255 255 255 / 20%), 0 1px 2px rgb(0 0 0 / 5%);
}

.treatmentPlanBtn.pl_blue_btn.pl_cancelbtn a {
    color: #000;
}


/* Popup css begins */

.ploverlay,
.ploverlayy {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(0 0 0 / 85%);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
    z-index: 9;
}

.ploverlayy {
    visibility: visible;
    opacity: 1;
    z-index: 999;
}

.ploverlayy:target {
    visibility: visible;
    opacity: 1;
    z-index: 10;
}

.ploverlay:target {
    visibility: visible;
    opacity: 1;
    z-index: 9999999;
}

.plpopup {
    transition: all 2s ease-in-out;
    width: 550px;
    padding: 15px 15px;
    border: 1px solid #ddd;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    background: #fff;
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    margin: auto;
}

.plpopup .close {
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
    cursor: pointer;
}

.plpopup.plpopupList h2 a {
    top: 0;
    right: 10px;
}

.plpopup .plcontent {
    max-height: 30%;
    overflow: auto;
}

.ploverlay h2 {
    font-size: 15px;
    font-weight: 600;
    color: #545454;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
    position: relative;
}

.addDataContent {
    padding: 30px 0 0;
    overflow-x: hidden;
}

.addDataDiv {
    padding-bottom: 10px;
    font-size: 0;
}

.addDataDiv label {
    width: 30%;
    display: inline-block;
    vertical-align: top;
    color: #4c4c4c;
    font-size: 14px;
}

.mandatory {
    color: red;
    font-size: 14px;
    vertical-align: top;
    padding-left: 2px;
}

.addDataDiv input[type="text"],
.addDataDiv textarea,
.addDataDiv select {
    width: 70%;
    min-width: auto;
    display: inline-block;
    min-height: auto;
    margin: 0;
    border: 1px solid #ccc;
    padding: 2px 7px;
    height: 33px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-size: 13px;
    color: #696969;
}

.summaryBtnDiv {
    text-align: right;
    clear: both;
    padding-top: 25px;
    position: relative;
}

.summaryBtnDiv .practiceGreenButton,
.summaryBtnDiv .practiceGreyButton {
    float: none;
    margin: 0 0 0 7px;
    font-size: 14px;
    display: inline-block;
    color: #393939;
    min-width: 110px;
    font-weight: 600;
    font-family: "montserratregular", sans-serif;
}


/* Popup css ends */

.pl_dr_info {
    padding: 0 10px 5px;
}

.pl_planned_status {
    border: 1px dashed;
    padding: 2px 8px;
    position: relative;
    top: 8px;
}


/*  */

.doc.practiceForm li label {
    color: #737272;
    font-size: 1.0555555556em;
    float: left;
    font-weight: normal;
    width: 100%;
    text-align: left;
}

.doc.practiceForm li input,
.doc.practiceForm li select,
.doc.practiceForm li textarea {
    border: 1px solid #b4b4b4;
    background: #fff;
}

.practiceCheckbox input[type="checkbox"] {
    opacity: 0;
    width: 0 !important;
    float: left !important;
    height: 0 !important;
    display: none;
}

.practiceCheckbox input[type="checkbox"]:disabled+label {
    opacity: .65;
}

.doc.practiceForm li label {
    color: #737272;
    font-size: 1.0555555556em;
    float: left;
    font-weight: normal;
    width: 100%;
    text-align: left;
}

.practiceCheckbox-primary input[type="checkbox"]:checked+label::before {
    background-color: #428bca;
    border-color: #428bca;
}

.practiceCheckbox label::before {
    content: "";
    outline: 0;
    display: inline-block;
    position: relative;
    width: 17px;
    height: 17px;
    left: 0;
    margin-left: -45px;
    margin-right: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: #fff;
    transition: border .15s ease-in-out, color .15s ease-in-out;
}

.practiceCheckbox input[type="checkbox"]:checked+label::after {
    font-family: 'FontAwesome';
    content: "\f00c";
    color: #fff;
}

.practiceCheckbox label::after {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    outline: 0;
    left: 0;
    top: 0;
    margin-left: -20px;
    padding-left: 3px;
    padding-top: 1px;
    font-size: 11px;
    color: #555;
}

.popMobViewBlck .popMobView {
    position: absolute;
    top: 50%;
    left: 200px;
    /* right: 0; */
    margin: 0 auto;
    width: 40%;
    border-radius: 12px;
    color: #c70000 !important;
    background-color: rgba(255, 253, 191, 0.94);
    line-height: 1.3em;
    padding: 10px;
    word-break: break-word;
    font-size: 11px;
}

select:focus {
    outline: none;
}


/* Add hospital expenses css begins */

.practiceManagementForm {
    display: inline-block;
    padding: 2px 50px;
    background: #fafafa;
    width: 100%;
    margin-top: 0;
    margin: 10px 0 40px;
}

.practiceManagementForm .practiceManagementFormBlcok {
    display: block;
    margin-top: 10px;
}

.taxMainContentWrap {
    clear: both;
    overflow: hidden;
}

.taxMainWrap {
    clear: both;
    overflow: hidden;
    margin-bottom: 5px;
    padding-bottom: 5px;
}

.practiceManagementForm .practiceManagementFormBlcok ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.practiceManagementForm.practiceManagementFormNew.pl_add_expenses {
    padding: 2px 10px;
}

.plpopup.plsmspopup {
    width: 960px;
}

.pl_sms_right {
    width: 75%;
    float: left;
    position: relative;
}

.pl_sms_left {
    width: 25%;
    float: left;
}

.plpopup.plsmspopup .plcontent {
    max-height: 80%;
    height: 490px;
    overflow-y: scroll;
    overflow-x: hidden;
    text-align: center;
}

.plpopup.plsmspopup textarea {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 90px;
    min-height: 111px;
    max-height: 111px;
    padding: 5px 10px;
    border: 1px solid gainsboro;
    font-size: 1em;
    margin-bottom: 15px;
    transition: box-shadow .3s, border .3s;
    resize: none;
    color: #c70000 !important;
}

.plpopup.plsmspopup .procedureCatList {
    margin-bottom: 10px;
}

.plpopup.plsmspopup .procedureCatName {
    font-size: 12px;
}


/* tabs css sms */

.tabset>input[type="radio"] {
    position: absolute;
    left: -200vw;
}

.tabset .tab-panel {
    display: none;
}

.tabset>input:first-child:checked~.tab-panels>.tab-panel:first-child,
.tabset>input:nth-child(3):checked~.tab-panels>.tab-panel:nth-child(2),
.tabset>input:nth-child(5):checked~.tab-panels>.tab-panel:nth-child(3),
.tabset>input:nth-child(7):checked~.tab-panels>.tab-panel:nth-child(4),
.tabset>input:nth-child(9):checked~.tab-panels>.tab-panel:nth-child(5),
.tabset>input:nth-child(11):checked~.tab-panels>.tab-panel:nth-child(6) {
    display: block;
}


/*
 Styling
*/

.tabset>label {
    position: relative;
    display: inline-block;
    padding: 5px 18px 5px;
    border: 1px solid transparent;
    border-bottom: 0;
    cursor: pointer;
    font-weight: 600;
    background: lightgray;
    color: #fff;
    border-radius: 10px 10px 0 0;
    margin: 0 10px;
}

.tabset>label:hover,
.tabset>input:focus+label {
    color: #06c;
}

.tabset>input:checked+label {
    border-color: #ccc;
    border-bottom: 1px solid #fff;
    margin-bottom: -1px;
    background: grey;
    color: #fff;
}

.tab-panel {
    padding: 30px 0;
    border-top: 1px solid #ccc;
}

.pl_email_txt {
    border: 1px solid #ccc;
    padding: 15px;
}

.selctFieldHolder.pl_communicationss {
    position: absolute;
    bottom: 20px;
    right: 0px;
    background: #fff;
    padding-top: 15px;
    width: 100%;
}

.plpopup.plsmspopup h2 a {
    display: inline-block;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: -15px;
    right: 0;
}

.treatmentHeadDue {
    color: #7b7b7b;
    display: inline-block;
    padding-left: 10px;
}

.plpopup h2 a {
    display: inline-block;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: -15px;
    right: 0;
}

.bootstrap-tagsinput {
    background-color: #fff;
    display: inline-block;
    padding: 4px 2px;
    color: #555;
    vertical-align: middle;
    border-radius: 4px;
    max-width: 100%;
    line-height: 22px;
    cursor: text;
    width: 100%;
}

.smsTypeSectionDiv .bootstrap-tagsinput input {
    min-width: 170px;
    border: 0;
    border-bottom: 1px solid #a9a9a9;
    padding: 4px 5px;
}

.bootstrap-tagsinput input {
    border: 0;
    box-shadow: none;
    outline: 0;
    background-color: transparent;
    padding: 0 6px;
    margin: 0;
    width: auto;
    max-width: inherit;
}

.smsTypeSectionDiv .bootstrap-tagsinput {
    border: 1px solid #a9a9a9 !important;
    height: 180px;
    overflow-y: auto;
    padding: 10px;
}

.smsButtonDiv .commonButtonCancel,
.smsButtonDiv .commonButton {
    display: inline-block;
    padding: 6px 20px;
}

.smsTypeSectionDiv .bootstrap-tagsinput .tag {
    margin-bottom: 3px;
    display: inline-block;
    padding: 6px 5px;
}

.bootstrap-tagsinput .tag {
    margin-right: 2px;
    color: white;
    font-size: .9em;
    padding: 5px;
}

.sendSmsWrap .label-info {
    background-color: #5bc0de;
}

.sendSmsWrap .label {
    display: inline;
    padding: 0.2em 0.6em 0.3em;
    font-size: 75%;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
}

.bootstrap-tagsinput .tag [data-role="remove"] {
    margin-left: 8px;
    cursor: pointer;
}

.smsTypeSectionDiv .bootstrap-tagsinput .tag [data-role="remove"] {
    display: inline-block;
    padding: 0;
}

.bootstrap-tagsinput .tag [data-role="remove"]:after {
    content: "x";
    padding: 0 2px;
}

.fileUpload_upload.btn_upload {
    border: 1px solid #222;
    background: none !important;
    color: #222 !important;
    border-radius: 3px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    padding: 7px 20px;
    width: auto;
    position: relative;
    font-size: 12px;
    display: inline-block;
    margin-bottom: 5px;
    cursor: pointer;
}

.smsTypeSectionBlock .btn_upload {
    float: none;
    width: auto;
}

.fileUpload_upload input.upload_upload {
    cursor: pointer;
    font-size: 20px;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

.fileUpload_upload.btn_upload:hover,
.blogUploadBtn:hover {
    color: #fff !important;
    background: #000 !important;
    transition: .2s ease all;
    -moz-transition: .2s ease all;
    -webkit-transition: .2s ease all;
}

.pl_add_group {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    padding: 3px 15px;
    font-size: 12px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -ms-border-radius: 4px;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
    background-color: #008fef;
    background-image: -moz-linear-gradient(top, #00afef, #005fef);
    /* display: block; */
    background-image: linear-gradient(to bottom, #00afef, #005fef);
    background-repeat: repeat-x;
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ff00afef', endColorstr='#ff005fef', GradientType=0);
    border-color: #005fef #005fef #0041a3;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid: DXImageTransform.Microsoft.gradient(enabled=false);
    margin-left: 10px;
}

.pl_add_group:hover {
    color: #fff;
}

.tableSearchDiv span {
    padding: 0 5px;
}

.smsTypeSectionTable .table-bordered th {
    background: #4ebab5;
    font-weight: 600;
    color: #fff;
}

.sendSmsWrap .tabActive {
    background: #fff;
}

.sendSmsWrap .tabActive span {
    background: #42a196;
    color: #fff !important;
    padding: 0px;
}

.sendSmsWrap .smsLeftTab span {
    color: #42a196;
}

.radioButtonDiv input[type="radio"]+label:before {
    content: " ";
    background-color: #fefefe;
    border-radius: 50px;
    display: inline-block;
    position: absolute;
    left: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #42a196;
}

.sendSmsWrap .tabset>input:checked+label {
    border-color: transparent;
    border: none;
    margin-bottom: -1px;
    background: transparent;
    color: #6b6b6b;
}

.sendSmsWrap .tabset>label {
    position: relative;
    display: inline-block;
    border-bottom: 0;
    cursor: pointer;
    background: transparent;
    color: #6b6b6b;
    margin: 0 25px 0 0;
    padding: 0px;
}

.sendSmsWrap .tab-panel {
    border: none;
}

.tabset label input {
    margin-right: 5px;
}

.contentSectionCover {
    padding: 30px 50px;
}

.mainTitle {
    font-weight: 600;
    color: #545454;
    margin-bottom: 15px;
}

.formItemDiv {
    padding-bottom: 10px;
    font-size: 13px;
}

.formItemHalfDiv {
    width: 40%;
    display: inline-block;
    vertical-align: top;
}

body .formCommonTxt {
    width: 23%;
    min-width: 90px;
    vertical-align: middle;
    font-size: inherit;
}

body .formFieldSec {
    display: inline-block;
    vertical-align: middle;
    width: 69%;
}

body .colonSpan {
    vertical-align: middle;
    display: inline-block;
    width: 8px;
    text-align: center;
}

body .formCommonField {
    display: inline-block;
    vertical-align: middle;
    height: 33px;
    border: 1px solid #c7c7c7;
    padding: 2px10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    width: 90%;
    font-size: inherit;
    margin: 0;
}

.groupPatientCvr {
    padding: 10px 5px 3px;
    border: 1px solid #DDE;
}

.patientDetailDivSec {
    padding: 0 5px;
    max-height: 500px;
    overflow-y: auto;
}

.commonSubTitle {
    padding-bottom: 5px;
    font-weight: 600;
    font-size: 13px;
    color: #5f5f5f;
}

.patientDetailDiv {
    position: relative;
    border: 1px solid #ddd;
    margin-bottom: 5px;
    padding: 5px;
    width: 33%;
    display: inline-block;
    vertical-align: top;
    display: inline-flex;
}

.imageLeftDiv {
    width: 8%;
    display: inline-block;
    vertical-align: top;
}

.imageLeftDiv img {
    width: 100%;
}

.detailRightDiv {
    width: 89%;
    display: inline-block;
    vertical-align: top;
    font-size: 11px;
}

.grpPatientName {
    font-weight: 600;
}

patientDetailDiv .optionWrapper,
.selectButtons {
    position: absolute;
    top: 5px;
    right: 0;
}

.optionWrapper {
    float: left;
    padding-right: 5px;
    line-height: 13px;
    width: 25px;
}

.optionWrapper input[type="checkbox"] {
    display: none;
}

.optionWrapper input[type="checkbox"]+label {
    display: inline;
    position: relative;
    margin: 0;
}

.optionWrapper input[type="checkbox"]+label::before {
    display: inline-block;
    width: 16px;
    height: 16px;
    content: ' ';
    border: 1px solid #777;
    vertical-align: middle;
    cursor: pointer;
}

.optionWrapper input[type="checkbox"]:checked+label::after {
    width: 4px;
    height: 9px;
    border: 2px solid #777;
    border-left: none;
    border-top: 0;
    content: ' ';
    position: absolute;
    left: 6px;
    bottom: 4px;
    transform: rotatez(45deg);
    -webkit-transform: rotatez(45deg);
    -moz-transform: rotatez(45deg);
    -ms-transform: rotatez(45deg);
    -o-transform: rotatez(45deg);
    cursor: pointer;
}

.sendSmsWrap .tabset label input {
    margin-top: 5px;
}


/* Dashboard css */

.dashboardWrap {
    clear: both;
    overflow: hidden;
    margin: 30px 0;
    min-height: 355px;
}

.dashboardWrap .col-md-6 {
    padding: 0;
}

.dashboardLeft {
    padding: 10px;
}

.dashHead {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 600;
    color: #4a4a4a;
}

.appoinmentWrapCvr {
    overflow: auto;
    height: 522px;
}

.dashboardWrap .appoinmentWrap {
    margin: 0 0 13px;
    cursor: pointer;
}

.appoinmentDateDiv span {
    display: block;
    margin-bottom: 2px;
}

.apnmentDayTime {
    font-size: 12px;
}

.dashboardLeft .appoinmentDateDetail {
    width: 88%;
}

.appoinmentDateDetail {
    float: left;
    width: 90%;
    font-size: 12px;
    color: #5a5a5a;
    padding-left: 10px;
}

.dashBoardTopDetail {
    clear: both;
    overflow: hidden;
    padding-bottom: 5px;
    font-weight: 600;
}

.dashBoardPatient,
.dashBoardHosName {
    width: 50%;
    padding-right: 10px;
    float: left;
}

.appoinmentReason {
    display: block;
    font-weight: 600;
    clear: both;
    overflow: hidden;
}

.dashBoardContentDes {
    clear: both;
    overflow: hidden;
    padding-bottom: 5px;
}

.dashBoardContent {
    width: 33.333%;
    float: left;
    padding-right: 5px;
    font-weight: 600;
}

.dashboardLeft .appoinmentSatusWrap span {
    width: 33.333%;
}

.dashboardBoxRight {
    overflow: auto;
    padding: 10px;
}

.dashboardBoxTop {
    padding-bottom: 15px;
}

.dashboardBoxRight table {
    width: 100%;
    color: #464646;
    font-size: 12px;
    border: 1px solid #ddd;
    border-right: 0;
    max-height: 195px;
    overflow: auto;
    display: block;
}

.dashbrdMain thead,
.dashbrdMain tbody,
.dashbrdMain tfoot,
.dashbrdMain tr,
.dashbrdMain td,
.dashbrdMain th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}

.dashBrdDate,
.dashBrdPatient,
.dashBrdTest,
.dashBrdSpecimen,
.dashBrdStatus {
    padding: 10px 5px;
}

.dashboardBoxRight table td,
.dashboardBoxRight table th {
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
}

.dashboardBoxRight table th {
    font-weight: 400;
}


/* calendar modal css */

.pu_modal_content select {
    word-wrap: normal;
    height: 28px;
    padding: 2px 5px;
    font-size: 11px !important;
    border-color: #e8e8e8;
    width: 100%;
}

.smsTypeSectionBlock {
    padding: 10px 0;
}

.smsBody {
    width: 50%;
    float: left;
    padding: 10px;
}

.scheduleSmsDivHead {
    display: block;
    padding: 10px 0;
    color: #01a9a7;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 300;
}

.smsTypeSectionBlock .smsBody textarea {
    width: 100%;
    padding: 5px 10px;
    resize: none;
    border: 1px solid #a9a9a9;
    height: 105px;
}

.smsButtonDiv {
    text-align: right;
    padding: 20px 0 0;
    clear: both;
}

.smsButtonDiv .commonButtonCancel,
.smsButtonDiv .commonButton {
    display: inline-block;
    padding: 6px 20px;
}

.scheduleSMSRow input,
.scheduleSMSRow select {
    border: 1px solid #a9a9a9;
    padding: 4px 5px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    height: 31px;
    vertical-align: middle;
    margin: 0 2px;
}

.modal .modal-header button {
    background: transparent;
    border: none;
}

.modal-content button span {
    font-size: 20px;
}

.fc-scrollgrid-sync-table a {
    color: #fff;
}

.todyTimeSlot {
    display: block;
    width: 45px;
    height: 45px;
    background: #3498db;
    min-height: 100%;
    float: left;
    padding: 2px 5px;
    color: #fff;
    font-size: 13px;
    text-align: center;
}

.todyDetails {
    width: 75%;
    padding: 4px 0 0 5px;
    float: left;
    text-align: left;
}

.patntname {
    font-size: 11px;
    padding-bottom: 3px;
    color: #710600;
    text-transform: uppercase;
}

.dctrname {
    font-size: 11px;
    text-transform: uppercase;
    color: #333;
}

.clndrAppmntRightBlock {
    padding: 3px;
    border-bottom: 1px solid #eaeaea;
    float: left;
    width: 100%;
    clear: both;
    margin-top: 0;
    position: relative;
    background-color: #fff;
}

.patientPayment .planActive tbody:before {
    border-left: none;
}

.redTxt {
    color: #f00;
}

.calenderRightSec input,
.calenderRightSec button {
    border: none;
    text-align: center;
    background: transparent;
}

.inventoryStockDiv .col-lg-4 {
    padding-left: 0;
}

.addLinkTxt,
span.addLinkTxt {
    font-size: 12px;
    color: #0093cc;
    cursor: pointer;
}

.pl_editInventory.practiceManagementForm .practiceManagementFormBlcok ul li input {
    margin-bottom: 0px;
}

.pl_add_item_details {
    float: left;
    margin-left: 20px;
}

.CardPrint {
    text-align: right;
    margin-right: 8px;
    padding-bottom: 10px;
    background: #ececec;
}

.CardPrint a {
    padding: 5px 30px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background: #2d1152;
    color: #fff;
    border: 0;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
}

.patientHealthcrd .registrationCard {
    width: 320px;
    height: auto;
    font-size: 10px;
    line-height: 14px;
    margin: 0;
    background: #fff;
    position: relative;
    margin-top: 15px;
    margin-left: 15px;
    font-family: "SourceSansPro-Regular";
}

.patientHealthcrd .cardDetailList label {
    margin: 0;
    width: 27%;
}

.settingsHealthCard .cardDetailList label,
.settingsHealthCard .cardDetailListTxt .cardDetailListTxtCont,
.settingsHealthCard .cardDetailList.cardDetailListBold {
    font-family: "OpenSans-Bold";
}

.settingsHealthCard .cardDetailListTxt {
    width: 82%;
    float: right;
}

.patientHealthcrd .treatmentPlanList,
.patientHealthcrd .treatmentPlanHead {
    background: #ececec;
}

.patientHealthcrd .treatmentPlanHead {
    margin-bottom: 0px;
}

.patientHealthcrd .rgstrnAddrs {
    font-size: 7px;
    color: #fff;
    float: left;
    padding: 3px 0 2px 5px;
    padding: 1px 0 2px 5px;
    width: 90%;
    line-height: 9px;
}

.patientHealthcrd .registrationCardContact {
    font-size: 9px;
}

.patientHealthcrd .rgstrnAddrsIcon {
    width: 20px;
    margin-right: 5px;
}

.patientHealthcrd .rgstrnAddrsIcon img {
    width: 10px;
}

.patientHealthcrd .cardContactList img {
    width: 10px;
    margin-right: 5px;
}


/* History css */

.treatmentTimeline {
    margin: 10px;
    background: #fff;
    padding: 10px;
}


/*  */

.edtinv .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.edtinv .form-group label {
    position: relative;
    cursor: pointer;
}

.edtinv .form-group label:before {
    content: '';
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 1px solid #333;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
}

.edtinv .form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #333;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.treatmentTimelineCheck ul li input[type="checkbox"] {
    margin: 6px 6px 0 0px;
    float: left;
}

.pl_history .pl_addTreatmnt .plannedBy {
    color: #000;
}

.pl_invoice .dentalProName {
    display: block;
}

.pl_history .pl_treatmentDiv tr.groupBrdr .dentalProDis {
    color: #000;
    font-size: 11px;
}

.edtinv .checkbox-inline {
    padding: 0 15px;
}

.edtinv .checkbox-inline input {
    margin-right: 2px;
}

.edtinv .taxType {
    height: auto;
    min-height: 35px;
    width: 100%;
    padding: 10px 10px;
    margin-bottom: 15px;
    background: white;
    font-size: 1em;
    border: 1px solid gainsboro;
}

.edtinv .taxType p {
    margin: 0;
    padding-top: 5px;
}

.edtinv .subTitileNAme {
    padding: 0px;
}

.patientGroupTop select {
    width: 60%;
    margin-bottom: 8px;
    padding: 3px 5px;
    border: 1px solid #dcdcdc;
    color: #5a5a5a;
    font-size: 14px;
    height: 32px;
}

span.GroupAddButton {
    width: auto;
    float: none;
    padding-left: 5px;
}

.GroupAddButton .commonButton {
    height: 32px;
    border: none;
    color: #fff;
    border: 1px solid #005fef;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#00afef), to(#005fef));
    background-image: -webkit-linear-gradient(top, #00afef, #005fef);
    background-image: -o-linear-gradient(top, #00afef, #005fef);
    background-image: linear-gradient(to bottom, #00afef, #005fef);
}

.GroupAddButton .commonButtonCancel {
    height: 33px;
    background-image: linear-gradient(to bottom, #fff, #e6e6e6);
    border: 1px solid #c7c7c7;
    border-radius: 4px;
}

.patientGroupItem {
    padding: 5px 10px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    display: inline-block;
    margin-right: 10px;
    width: 48%;
    position: relative;
}

span.patientGroupName {
    display: inline-block;
    vertical-align: top;
    padding-right: 10px;
}

span.patientClose {
    position: absolute;
    right: 5px;
    top: 4px;
    cursor: pointer;
}

span.patientClose img {
    width: 16px;
    cursor: pointer;
}

.tableTopHoldButton .selctFieldHolder {
    display: inline-block;
    width: 20%;
    padding-left: 20px;
    /* float: left; */
}

.tableTopHold .selctFieldHolder select,
.tableTopHold .selctFieldHolder input[type="text"] {
    width: 100%;
    height: 35px;
    padding: 0 10px;
}

.tableTopHoldButton select {
    font-size: 13px;
    background: white;
    width: 20%;
    height: 35px;
    padding: 0 10px;
    float: left;
}

.pl_search input {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    padding: 3px 12px;
    font-size: 13px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -ms-border-radius: 4px;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
    background-image: linear-gradient(to bottom, #00afef, #005fef);
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 7px;
    border: 1px solid #005fef;
    height: 35px;
    padding: 0 20px;
    /* position: relative;
    top: -8px; */
}

.pl_staffList table {
    border: 0px solid transparent;
}

.pl_staffList table a img {
    width: 20px;
    cursor: pointer;
}

.serviceInfoWrap {
    clear: both;
    overflow: hidden;
    padding: 35px;
    border: 1px solid #cbc5d4;
    margin: 20px 0;
}

.serviceListMain {
    width: 50%;
    float: left;
    margin-bottom: 10px;
    padding: 0 10px;
    min-height: 71px;
}

.serviceListMain label {
    width: 100%;
}

.serviceListMain input[type="text"],
.serviceListMain input[type="password"],
.serviceListMain select {
    width: 100%;
    height: 35px;
    padding: 0 10px;
    border: 1px solid gainsboro;
    height: 40px;
}

.spotlightArea {
    width: 100%;
    float: left;
}

.pl_drAdd .nextLevelBlock {
    display: inline-flex;
    justify-content: left;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
}

.pl_drAdd .nextLevelBlock .leftSplitDate {
    padding-left: 0px;
    padding-right: 35px;
    margin-bottom: 20px;
}

.pl_drAdd .nextLevelBlock select {
    width: 80px;
    font-size: 12px;
    margin: 0 1px 3px;
    border: 1px solid lightgray;
    color: #8f8f8f;
    height: 32px;
    margin-bottom: 20px;
}

.subLevelMAin .divMultipleBlockHold {
    width: 100%;
    overflow: hidden;
    margin-bottom: 5px;
    padding: 10px;
    margin-top: 20px;
    background: #f2f2f2;
}

.spotlightAreaMain {
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
    /* padding: 5px; */
    width: 100%;
}

.storehead {
    background-color: #f2f2f2;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-table;
    padding: 5px;
    width: 92%;
}

.doc.blogVisitPage .blogVisitBlock {
    display: block;
    background: white;
    border-bottom: 1px solid gainsboro;
    margin-bottom: 30px;
    width: 85%;
}

.doc.blogVisitPage .blogVisitBlock .blogVistAvtarBlock {
    display: table-cell;
    border: 3px solid white;
    padding-top: 12px;
}

.doc.blogVisitPage .blogVisitBlock .blogVistAvtarBlock a {
    vertical-align: middle;
    border-radius: 100%;
    width: 35px;
    height: 35px;
}

.doc.blogVisitPage .blogVisitBlock .blogVistAvtarBlock a img {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
}

.doc.blogVisitPage .blogVisitBlock .blogVisitAvatarInfo {
    display: table-cell;
    vertical-align: middle;
    font-size: 14px;
    line-height: 1.4;
    padding-left: 10px;
    text-rendering: auto;
}

.doc.blogVisitPage .blogVisitBlock .blogVisitAvatarInfo span.avtarname {
    display: inline-block;
}

.doc.blogVisitPage .blogVisitBlock .blogVisitAvatarInfo span.avtarname a {
    font-family: "OpenSans-Bold";
    font-size: 1em;
    color: #393939;
}

.DashboardWelcome .doc.blogVisitPage .blogVisitBlock .blogVisitAvatarInfo span.datetime {
    display: block;
    color: #333;
}

.DashboardWelcome .landingHead {
    font-size: 32px;
}

.doc a,
.doc a:focus,
.dd-menu a {
    color: #2d1152;
    outline: none !important;
    text-decoration: none;
}

.smlFollow {
    border: 1px solid green;
    padding: 5px;
    display: inline-block;
    width: 90px !important;
    line-height: 14px;
    border-radius: 5px;
    text-align: center;
    font-size: 13px;
    margin-left: 7px;
    height: 25px !important;
    margin-top: 10px;
    cursor: pointer;
    background: white;
    color: green;
}

.doc.blogVisitPage .blogVisitBlock .blogVisitAvatarInfo span.datetime {
    display: block;
}

.doc.blogVisitPage .blogVisitBlock .blogVisitHead {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: "OpenSans-Bold";
}

.doc.blogVisitPage .blogVisitbannerBlock {
    margin-top: 15px;
}

.doc.blogVisitPage .blogVisitBlock .blogVisitHead a {
    clear: both;
    font-size: 1.9em;
    line-height: 36px;
    color: #393939 !important;
    width: 100%;
    cursor: pointer;
}

.doc.blogVisitPage .blogVisitBlock .blogVisitPara {
    display: block;
    clear: both;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    line-height: 30px;
    color: #393939;
    width: 98%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: -10px;
    cursor: pointer;
    opacity: .7;
}

.doc.blogVisitPage .blogVisitBlock .blogVisiIconsSet {
    display: block;
    width: 100%;
    position: relative;
    height: 50px;
    margin-bottom: 10px;
    clear: both;
}

.doc.blogVisitPage .blogVisitBlock .blogVisiIconsSet span.blogVisitLike {
    position: absolute;
    top: 10px;
    cursor: pointer;
    font-size: .8888888889em;
    text-align: center;
}

.doc.blogVisitPage .blogVisitBlock .blogVisiIconsSet span.blogVisitLike .iconTwo {
    width: 25px;
    height: 25px;
    clear: both;
    float: left;
}

.nocursorPointer {
    cursor: default !important;
}

.pl_drAddEdu .nextLevelBlock label {
    width: 10%;
}

.pl_drAddEdu .nextLevelBlock select {
    width: 175px;
}

.storeheadAlgnRgt {
    margin-bottom: 0;
    float: right;
    margin-right: 8%;
    background-color: #f2f2f2;
}

.addbtnStick {
    width: 32px;
    height: 32px;
    background-position: center;
    text-align: center;
    float: right;
    margin-bottom: 5px;
    margin-top: 5px;
}

.storehead2 {
    background-color: #f2f2f2;
    margin-top: 0;
    margin-bottom: 2px;
    display: inline-table;
    padding: 5px;
    width: 92%;
}

.selctFieldHolder {
    float: left !important;
    margin-right: 1% !important;
}

.selctFieldHolder label {
    float: left;
    text-align: right;
    margin-right: 15px;
    padding-top: 5px;
    font-size: 14px;
    color: #393939;
}

.storehead2 input.tinyField {
    padding: 1px;
    width: 55px;
    font-size: 14px;
    margin: 0 0 5px;
    border: 1px solid lightgray;
    height: 32px;
    text-indent: 5px;
    color: #8f8f8f;
}

select.plstatus {
    font-size: 12px;
    margin: 0 1px 3px;
    border: 1px solid lightgray;
    color: #8f8f8f;
    height: 32px;
    width: 100px;
}

.bigScrolldiv {
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    height: 250px;
    height: 150px;
    border: 1px solid gainsboro;
    margin: 10px 0 0 0;
    padding: 10px 0 0 10px;
}

.table-bordered {
    border: 1px solid #ddd;
    border-collapse: separate;
    border-left: 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 0;
}

.pl_drAdd .table-bordered>tbody>tr>td {
    border: 1px solid #ddd !important;
}

.pl_drAdd .table td span {
    width: auto;
    padding-left: 5px;
}

.pl_drAdd .spanLeft {
    float: left;
    margin-right: 5px;
}

textarea.mgalrgTxtareaNew {
    padding: 1px;
    min-width: 100%;
    font-size: 14px;
    margin: 0 0 5px;
    border: 1px solid lightgray;
    text-indent: 5px;
    color: #8f8f8f;
    min-height: 80px;
    max-height: 90px;
}

.clinicinpt {
    padding: 0 10px;
    border: 1px solid gainsboro;
    height: 40px;
}

.backPage {
    padding-bottom: 15px;
}

.backPage a {
    padding: 7px 15px;
    color: #005fef;
    border: 1px solid #005fef;
    font-size: 13px;
    display: inline-block;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.blogSampleHead {
    position: relative;
}

.doc.blogDetailPage .blogVistAvtarBlock {
    display: table-cell;
    border: 3px solid white;
    padding-top: 12px;
}

.doc.blogDetailPage .blogVistAvtarBlock a {
    vertical-align: middle;
    border-radius: 100%;
    width: 35px;
    height: 35px;
}

.doc.blogDetailPage .blogVistAvtarBlock a img {
    max-width: 35px;
    max-height: 35px;
    border-radius: 100%;
}

.doc.blogDetailPage .blogVisitAvatarInfo {
    display: table-cell;
    vertical-align: middle;
    font-size: 14px;
    line-height: 1.4;
    padding-left: 10px;
    text-rendering: auto;
}

.doc.blogDetailPage .blogVisitAvatarInfo span.datetime {
    display: block;
}

.doc.blogDetailPage .blogVisitAvatarInfo span.datetime a {
    font-size: .7777777778em;
    color: grey;
}

.doc.blogDetailPage .blogDetailHead,
.blogDetailpara h2 {
    clear: both;
    font-size: 1.9em;
    line-height: 36px;
    color: #393939 !important;
    letter-spacing: .5px;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 25px;
    font-family: "OpenSans-Bold";
}

.doc.blogDetailPage .blogDetailpara p {
    display: block;
    clear: both;
    padding-top: 25px;
    padding-bottom: 10px;
    font-size: 16px;
    line-height: 26px;
    color: #393939;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: -10px;
}

.doc.blogDetailPage .blogDetailpara li {
    color: #393939;
    font-size: 16px;
    line-height: 26px;
    opacity: .7;
}

.batchIcons2 {
    padding: 20px 0;
}

.defaulttags a {
    display: inline-block;
    font-size: .8888888889em;
    text-align: center;
    background: rgba(0, 0, 0, .05);
    color: #7a7a7a !important;
    border-radius: 5px;
    padding: 5px;
    margin: 2px;
    margin-bottom: 15px;
}

.doc .divider {
    height: 1px;
    background: gainsboro;
    width: 100%;
    clear: both;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
}

.commentBox .commentheadBlock {
    display: block;
    text-decoration: underline;
    font-size: 1.3333333333em;
    color: #000008;
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
}

.commentBox .commentTextarea {
    height: 100px;
    min-height: 100px;
    max-height: 100px;
    border: 1px solid #ccc;
    width: 100%;
    font-size: 1em;
    border-radius: 5px;
    text-indent: 10px;
}

.commentBox input[type="submit"].commentSubmit {
    padding: 10px;
    width: 100px !important;
    text-align: center;
    font-size: 1em !important;
    margin-top: 10px;
    background: white;
    float: right;
    border: 1px solid #616161;
    color: #616161;
    border-radius: 5px;
}

.commentBox {
    margin-bottom: 20px;
}

.treatmentListHead .treatmentHeadAdvance,
.treatmentListHead .treatmentHeadDue {
    float: none;
    display: inline-block;
    margin: 0 10px;
}

.treatmentHeadAdvance span {
    color: #008000;
    padding-left: 5px;
}

.treatmentHeadAdvance span,
.treatmentHeadDue span {
    float: none;
}

.treatmentHeadDue span {
    color: #f00;
    padding-left: 5px;
}

.plInvoice .dentalProName p {
    margin-bottom: 2px;
    max-width: 150px;
    float: none;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.plInvoice .invoiceDivHeading::after,
.plInvoice .dentalInvoiceTotal:after {
    border-top: none !important;
}

.plInvoice .groupBrdr .dentalProName,
.plInvoice .groupBrdr .optionWrapper {
    display: block;
    font-size: 12px;
    padding-left: 0px;
}

.plInvoice span.paymentBy.paymentRefundDiv {
    width: 32%;
    text-align: right !important;
    color: #f00;
    font-weight: 600;
    float: right;
}

.plInvoice span.paymentBy.paymentRefundDiv b {
    color: #000;
}

.plInvoice span.paymentBy b {
    display: inline-block;
    padding-right: 7px;
    vertical-align: top;
}


/* Select dpdwn */

.treatmentDrNameList ul a {
    text-decoration: none;
}

.plmdlbtn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.pledit,
.plpaynow {
    background-color: #007fff;
    border: none;
    color: #fff;
    padding: 3px 8px;
    margin-left: 3px;
    border-radius: 4px;
}

.plpaynow {
    background-color: #2d1152;
}

.tooltip1:hover .tooltiptext1,
.fc-daygrid-event:hover .__react_component_tooltip {
    visibility: visible;
    opacity: 1;
    margin-top: -5px;
    display: block;
}

.fc .fc-daygrid-event {
    z-index: unset !important;
}

.fc .fc-daygrid-event:hover {
    color: #fff;
}

.__react_component_tooltip {
    pointer-events: all !important;
}

.edtinv .procedureCatType input {
    margin-right: 5px;
}

.pl_applicableTax_scroll {
    max-height: 210px;
    overflow-y: scroll;
    overflow-x: hidden;
    height: auto;
}

.plrmv {
    position: relative;
    display: flex;
    justify-content: flex-end;
    position: relative;
    right: 0px;
    top: 1px;
}

.plrmv span {
    width: 18px;
    height: 18px;
    font-size: 17px;
    text-align: center;
    border: 1px solid #ddd;
    line-height: 11px;
    z-index: 1;
    color: #ff4e4e;
    cursor: pointer;
    border-radius: 0 0 0 10px;
}

.pl_treatmentSelect {
    display: flex;
    justify-content: space-between;
}

.prescriptionTrtmntListItem {
    border-bottom: 1px solid #ececec;
}

.precsriptionStore {
    padding: 10px;
}

.pl_addTreatmnt .treatmentTags {
    left: 0;
    position: relative;
    top: 0;
}

.plRoom {
    background: #29bee0;
}

.treatmentTags.plRoom:after {
    border-left: 10px solid #a79ef3;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
}

.mgbtm_15 {
    margin-bottom: 15px;
}

.ptpri .practiceManagementForm .practiceManagementFormBlcok ul li.liFrequency {
    padding: 0;
}

.ptpri .practiceManagementForm .practiceManagementFormBlcok ul li.liFrequency input {
    width: 45px !important;
    float: left;
    margin-right: 6px;
    padding: 0 5px;
}

.ptpri .treatmentSelect {
    padding: 20px 10px 10px;
}

.ptpri .radioAreaType {
    padding: 0px 30px;
    float: left;
}

.ptpri .radiolabel {
    margin: 0 10px 0 2px;
}

.react-tabs__tab--selected {
    border-bottom: 2px solid #2d1152 !important;
    border-radius: 0px !important;
}


/* .react-tabs__tab {
    border: none!important;
} */

.blogVisitPage .blogVisitBlock .blogVisitbannerBlock img {
    width: 100%;
}

.ptadd .prescriptionTrtmntListItem {
    border-bottom: 1px solid #cbcaca;
}

.practiceRightSection .trtmntPlanBtn.pl_save_btn {
    border-color: #54a954;
    background-image: linear-gradient(to bottom, #62c462, #51a351);
}

.practiceRightSection .trtmntPlanBtn.pl_cancell_btn {
    background-image: linear-gradient(to bottom, #fff, #e6e6e6);
    color: #393939;
    border: 1px solid #ccc;
}

.treatmentTeethShow a {
    cursor: pointer;
    color: #00abff;
    font-weight: 400;
    text-decoration: none;
}

.pl_tbodyy {
    width: 100%;
    float: left;
}

.profileForm .homeSpecialitycheck {
    overflow-y: scroll;
    max-height: 235px;
    min-height: auto;
}

.plcasesheet {
    position: absolute;
    right: 0px;
    z-index: 999;
    top: 0;
}

.pl_casesheetWrap .treatmentComplaintRight {
    width: 70%;
    padding-top: 7px;
}

.pl_casesheetWrap .treatmentComplaintRowWrap {
    width: 100%;
}

.pl_casesheetWrap .treatmentComplaintRow {
    overflow: inherit;
    z-index: auto;
}

.pl_casesheetWrap .inputField {
    border: 1px solid #ccc;
    height: 30px;
}

.pl_casesheetWrap .clinicalDate input {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 30px;
}

.pl_casesheetWrap .caseSheetItemList label {
    color: #737373;
    font-size: 15px;
    padding-right: 10px;
    width: 25%;
    float: left;
}

.pl_casesheetWrap .caseSheetItemList input[type="text"] {
    width: 75%;
    padding: 5px 10px;
    border: 1px solid #ccc;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border linear .2s, box-shadow linear .2s;
    -moz-transition: border linear .2s, box-shadow linear .2s;
    -o-transition: border linear .2s, box-shadow linear .2s;
    transition: border linear .2s, box-shadow linear .2s;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
    color: #555;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    vertical-align: middle;
    float: left;
}

.pl_casesheetWrap .caeSheetRadioWrap {
    width: 75%;
    float: left;
}

.pl_casesheetWrap .caeSheetRadio {
    width: 25%;
    float: left;
}

.pl_casesheetWrap input[type="radio"] {
    outline: none !important;
    width: auto !important;
    display: inline-block;
    height: auto !important;
    margin: 4px 4px 0px 0px;
}

.pl_casesheetWrap .caseSheetItemList {
    clear: both;
    overflow: hidden;
}

.pl_casesheetWrap .caseSheetRadioText textarea {
    width: 100%;
    padding: 5px 10px;
    border: 1px solid #ccc;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border linear .2s, box-shadow linear .2s;
    -moz-transition: border linear .2s, box-shadow linear .2s;
    -o-transition: border linear .2s, box-shadow linear .2s;
    transition: border linear .2s, box-shadow linear .2s;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
    color: #555;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    vertical-align: middle;
    height: 111px;
    min-height: 111px;
    max-height: 111px;
    resize: none;
}

.medicalWrap {
    clear: both;
    overflow: hidden;
    padding: 10px;
    background: #fff;
}

.medicalHeading {
    font-size: 16px;
    margin: 5px 0 10px 0;
    display: block;
}

.medicalCheck {
    text-transform: uppercase;
    margin-bottom: 20px;
}

.medicalGeneral {
    display: block;
    clear: both;
    overflow: hidden;
    margin-bottom: 10px;
    font-size: 16px;
    color: #737373;
}

.medicalGeneral input[type="checkbox"] {
    margin: 5px 0 0 0;
    float: left;
}

.medicalGeneral span {
    float: left;
    padding-left: 6px;
}

.medicalCheck ul {
    list-style: none;
    padding: 10px;
    margin: 0;
    clear: both;
    overflow: hidden;
    border: 1px solid #ddd;
}

.medicalCheck ul li {
    float: left;
    width: 50%;
    color: #737373;
    font-size: 13px;
    margin-bottom: 5px;
}

.medicalCheck ul li input[type="checkbox"] {
    margin: 3px 0 0 0;
    float: left;
    cursor: pointer;
}

.medicalCheck ul li span {
    float: left;
    padding-left: 6px;
}

.medicalPartListMain span {
    display: inline-block;
    vertical-align: middle;
    padding-left: 5px;
}

.medicalPartListMain img {
    width: 35px;
}

.medicalPartList {
    width: 12.5%;
    float: left;
    padding: 0 10px;
    margin-bottom: 15px;
}

.medicalListDrpcontent {
    clear: both;
    overflow: hidden;
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
}

.medicalListTitle {
    margin-bottom: 12px;
    font-size: 15px;
    color: #737373;
}

.medicalPartListSub ul,
.medicalListcontent ul {
    list-style: none;
    padding: 0;
    clear: both;
    overflow: hidden;
    margin: 0;
}

.medicalPartListSub ul li,
.medicalListcontent ul li {
    color: #737373;
    font-size: 13px;
    margin-bottom: 5px;
    float: left;
    width: 50%;
}

.medicalPartListSub ul li input[type="checkbox"],
.medicalListcontent ul li input[type="checkbox"] {
    margin: 3px 0 0 0;
    float: left;
    cursor: pointer;
    width: 4% !important;
}

.medicalPartListSub ul li span,
.medicalListcontent ul li span {
    float: left;
    padding-left: 6px;
    max-width: 95%;
}

.pl_drList {
    display: block;
    position: relative;
    min-height: auto;
    overflow-y: scroll;
    top: 0;
    left: 0;
    max-height: 135px;
}

.add_treatment_plan .react-datepicker-wrapper {
    width: auto;
}

.add_treatment_plan .react-datepicker-wrapper input,
.add_treatment_plan .react-datepicker-wrapper input::placeholder {
    color: rgb(27, 153, 213);
    border: none;
    background: transparent;
    font-size: 13px;
}


/* Casesheet Dental print css */

.caseSheetPrintWrap {
    width: 700px;
    margin: auto;
}

.caseSheetTop {
    clear: both;
    overflow: hidden;
    border-bottom: 2px solid #000;
    font-family: "Arial";
    text-shadow: none;
    float: left;
}

.caseLogo {
    width: 15%;
    float: left;
}

.caseLogo img {
    width: 100%;
}

.caseNAme {
    width: 80%;
    float: left;
    text-align: center;
    padding: 5px 0 0 0;
}

.caseSheetPrintWrap h2 {
    font-size: 25px;
    margin: 0 0 4px;
}

.caseNAme span {
    display: block;
    font-size: 14px;
}

.caseDoctor {
    width: 100%;
    float: left;
    font-size: 16px;
    font-weight: 600;
}

.caseDoctor p {
    margin: 0 0 3px 0;
    font-size: 14px;
}

.patientDetails {
    padding-top: 5px;
}

.patientDetailItem {
    padding-top: 6px;
    clear: both;
    overflow: hidden;
}

.patientNAme {
    width: 60%;
    float: left;
}

.patientNAmeLeft {
    width: 30%;
    float: left;
}

.patientNAmeRight {
    width: 70%;
    float: left;
}

.patientAge,
.patientSex {
    width: 20%;
    float: left;
}

.patientAgeLeft,
.patientSexLeft {
    /* width: 27%; */
    width: auto;
    float: left;
}

.patientAgeRight,
.patientSexRight {
    /* width: 73%; */
    width: auto;
    float: left;
}

.patientAddressLeft {
    width: 11%;
    float: left;
}

.patientAddressRight {
    width: 89%;
    float: left;
}

.patientComplaint {
    margin: 5px 0;
    padding: 5px 5px 0;
    border: 1px solid #000;
}

.patientComplaintItem {
    clear: both;
    overflow: hidden;
    height: auto;
    margin-bottom: 5px;
    display: flex;
    font-size: 13px;
}

.patientComplaintLabel {
    float: none;
    width: 27%;
}

.patientComplainttXT span {
    display: block;
    word-break: break-all;
}

.patientComplainttXT {
    width: 73%;
    float: left;
}

.medicalHeding {
    font-weight: 600;
}

.patientHistorySec {
    margin: 5px 0;
    padding: 0 5px 5px;
    border: 1px solid #000;
    height: auto;
}

.patientMedical {
    width: 27%;
    float: left;
}

.patientMedicalTxt {
    width: 70%;
    float: left;
}

.patientMedicalTxt .colan {
    width: 2%;
    float: left;
}

.patientMedicalTxt span {
    width: 98%;
    float: left;
}

.keySelectionWrap {
    margin: 10px 0 0 0;
    border: 1px solid #000;
}

.keySelectTop {
    border-bottom: 1px solid #000;
    clear: both;
    overflow: hidden;
}

.keySelectLeft {
    width: 25%;
    float: left;
    padding: 2px 5px;
    font-size: 11px;
    height: 157px;
}

.keyHead {
    color: #4a4a4a;
    font-weight: 600;
    border-bottom: 1px solid #4a4a4a;
    margin-bottom: 5px;
    display: inline-block;
}

.keySelectLeft ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.keySelectLeft ul li {
    padding-bottom: 0px;
    clear: both;
    overflow: hidden;
    color: #000000;
}

.keyLetter {
    padding-right: 3px;
    width: 9%;
    float: left;
}

.keyDes {
    width: 91%;
    float: left;
}

.keySelectRight {
    width: 75%;
    float: left;
    padding: 2px 5px;
    border-left: 1px solid #000;
    height: 157px;
}

.keySelectDiv {
    width: 50%;
    float: left;
}

.keySelectDivTopOne {
    float: right;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    /* padding: 5px; */
}

.keySelectDivTopLeft {
    text-align: right;
    padding-bottom: 5px;
}

.keyItem {
    display: inline-block;
    width: 24px;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    vertical-align: top;
}

.keySelector {
    font-size: 9px;
    width: 25px;
    height: 12px;
    font-weight: 400;
}

.keyItem span {
    display: block;
    color: #000;
}

.keySelectDivTopTwo {
    float: left;
    border-bottom: 1px solid #000;
    /* padding: 5px; */
}

.keySelectDivTopRight,
.keySelectDivBottomRight {
    padding-bottom: 5px;
}

.keySelectDivBottomLeft {
    text-align: right;
    padding-bottom: 5px;
}

.clinicContentWrap {
    clear: both;
    overflow: hidden;
}

.clinicContentLeft,
.clinicContentRight {
    width: 50%;
    float: left;
    border-right: 1px solid #000;
    padding: 5px;
    color: #000;
}

.caseSheetPrintWrap .caseSheetItemList span span.colan {
    width: 100%;
}

.caseSheetPrintWrap .caseSheetItemList {
    clear: both;
    /* overflow: hidden; */
    margin-bottom: 3px;
    font-size: 12px;
    height: auto;
}

.caseSheetPrintWrap .caseSheetItemList label {
    width: 25%;
    float: left;
}

.caseSheetPrintWrap .caseSheetItemList span {
    width: 74%;
    float: left;
}

.caseSheetPrintWrap .caseSheetItemList .caseSheetItemListTxt {
    width: 95%;
    float: left;
}

.caseSheetPrintWrap .clinicRadioText {
    height: auto;
    border-bottom: 1px solid #000;
    padding: 5px;
}

.caseSheetPrintWrap .clinicRadioText label {
    display: block;
    text-align: center;
    margin-bottom: 5px;
    font-size: 14px;
}

.caseSheetPrintWrap .clinicRadioText span {
    display: block;
    font-size: 12px;
}

.caseSheetPrintWrap .clinicRadioText:last-child {
    border: 0;
}

.caseSheetPrintWrap .clinicContentRight {
    border: 0 !important;
    padding: 0;
}

.caseSheetPrintWrap .specialisationWrap {
    margin-top: 30px;
    font-size: 13px;
}

.caseSheetPrintWrap .specialisationOne {
    clear: both;
    overflow: hidden;
    height: auto;
    margin-bottom: 8px;
}

.specialisationOne .medicalTitle {
    float: left;
    width: 30%;
}

.specialisationOne span,
.specialisationTwo span {
    line-height: 16px;
    display: block;
}

.specialisationTwo {
    clear: both;
    overflow: hidden;
    height: auto;
    margin-bottom: 8px;
}

.specialisationTwo .medicalTitle {
    width: 56%;
    float: left;
}

.specialisationOne span,
.specialisationTwo span {
    line-height: 16px;
    display: block;
}

.specialisationConsent {
    clear: both;
    overflow: hidden;
    margin-bottom: 8px;
}

.specialisationConsent .medicalTitle {
    width: 13%;
    float: left;
}

.specialisationConsent span {
    width: 87%;
    float: left;
    line-height: 18px;
    text-align: justify;
}

.specialisationNames {
    clear: both;
    overflow: hidden;
    padding-top: 20px;
}

.specialisationNames span {
    width: 40%;
    float: right;
    margin-bottom: 5px;
    clear: both;
}

.printBtm {
    position: fixed;
    top: 20px;
    right: 10%;
    width: auto !important;
}


/* Set permission css */

.siteSection {
    padding: 20px 0;
}

.siteSelectList {
    padding-bottom: 20px;
}

.siteSelectList label {
    padding-right: 15px;
    font-weight: 600 !important;
    float: none;
    font-size: 14px;
}

.siteSelectionField {
    width: 160px;
    display: inline-block;
    margin-right: 30px;
}

.siteSelectionField select {
    width: 100%;
    padding: 2px 10px;
    height: 35px;
    border: 1px solid #c3c3c3;
    border-radius: 5px;
    background: #fff;
    font-size: 14px;
}

body .blueButton {
    background: 0;
    border: 1px solid #0014ff;
    padding: 4px 30px;
    color: #393939;
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
    text-align: center;
    font-family: "montserratregular", sans-serif;
    font-size: 13px;
    border-radius: 5px;
    outline: 0;
    width: auto;
    font-weight: 400;
    line-height: 22px;
}

.setPermission .react-tabs__tab-list {
    border: none;
}

.setPermission .react-tabs__tab {
    border-bottom: 2px solid transparent !important;
    color: #393939 !important;
    background: none !important;
    font-weight: 700 !important;
    width: 125px;
    text-align: center;
}

.setPermission .react-tabs__tab--selected {
    border-bottom: 2px solid #393939 !important;
    color: #393939 !important;
    background: none !important;
    font-weight: 700 !important;
    border-top: none;
    border-left: none;
    border-right: none;
}


/* .setPermission .react-tabs__tab--selected {
    border: none!important;
} */

.setPermission .fade:not(.show) {
    opacity: 1;
}

.setPermission .menuSelectSection {
    padding: 30px 10px;
    width: 500px;
}

.setPermission .tabButton {
    padding: 20px 0;
}

body .saveBtn,
body .cancelBtn {
    background: 0;
    border: 1px solid #046b29;
    padding: 4px 30px;
    color: #393939;
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
    text-align: center;
    font-family: "montserratregular", sans-serif;
    font-size: 13px;
    border-radius: 5px;
    outline: 0;
    width: auto;
    font-weight: 400;
    line-height: 22px;
}

body .cancelBtn {
    border: 1px solid #b22222;
}

.setPermission .menuMainSelect {
    max-height: 305px;
    overflow-y: auto;
}

.setPermission .menuSelectSection ul {
    padding: 0;
    list-style: none;
    margin: 0;
}

.setPermission .menuSelectSection li {
    clear: both;
    overflow: hidden;
    padding-bottom: 10px;
}

.setPermission .optionWrapper {
    float: left;
    padding-right: 5px;
    line-height: 13px;
    width: 25px;
}

.setPermission .optionWrapper input[type="checkbox"] {
    display: none;
}

.setPermission .optionWrapper input[type="checkbox"]+label {
    display: inline;
    position: relative;
    margin: 0;
}

.setPermission .optionWrapper input[type="checkbox"]:checked+label::before {
    border: 2px solid #777;
}

.setPermission .optionWrapper input[type="checkbox"]+label::before {
    display: inline-block;
    width: 16px;
    height: 16px;
    content: ' ';
    border: 1px solid #777;
    vertical-align: middle;
    cursor: pointer;
}

.setPermission .menuSelectSub {
    padding: 7px 0 5px 30px;
    clear: both;
}

.menuSelectSub li {
    padding-bottom: 10px;
}

.menuSelectSub .menuLabelTxt {
    width: 175px;
}

.subList {
    width: 100%;
    display: block;
    clear: both;
    padding: 8px 0 15px 30px;
    border-bottom: 1px solid #ddd;
}

.subListBlk {
    width: 19%;
    display: inline-block;
    vertical-align: top;
}

.plcanvasclose {
    position: relative;
    width: 15%;
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
}

.plcanvasclose .close_row {
    position: absolute;
    right: -6px;
    top: -7px;
    border: 1px solid red;
    padding: 2px 4px;
    border-radius: 50%;
    font-size: 13px;
    background: #fff;
}

.addmoreImgs {
    width: 100%;
    float: left;
    padding: 15px 0;
    background: #ececec;
}

span.visitDate {
    width: auto;
    font-size: 12px;
}

.patientDetailCover .rightTagTxt {
    padding: 0 10px 0 10px;
}

#appendNotifyDefault .rightTagTxt label {
    width: 100%;
}

.rightTagTxt .colorRed {
    color: #f00;
}

.rightTagTxt label {
    margin-bottom: 0;
    text-align: left;
    color: #525252;
    font-weight: 300 !important;
    font-size: 13px;
    padding: 0;
    cursor: pointer;
    display: block;
}

.tagCLose {
    padding-left: 5px;
    display: none;
    vertical-align: top;
    line-height: 15px;
    position: absolute;
    visibility: visible !important;
}

#appendNotifyDefault li:hover span {
    display: inline-block;
    visibility: visible !important;
}

.rightTagListWrap .rightTagTxt {
    display: block;
    position: relative;
}

.rightTagTxt label li:hover .tagCLose {
    display: inline-block;
    visibility: visible !important;
}


/* Accordion css begins here */

.rightTagList.pl_accordion label.accrdnLabel::before {
    content: '+';
    margin-right: 5px;
    color: #000;
    font-weight: 700;
    font-size: 17px;
}

.rightTagList.pl_accordion input:checked+label.accrdnLabel::before {
    content: '-';
    margin-right: 8px;
    color: #000;
    font-weight: 700;
    font-size: 17px;
}

.rightTagList.pl_accordion input {
    display: none;
}

.rightTagList.pl_accordion label.accrdnLabel {
    display: block;
    padding: 0px;
    margin: 0 0 1px 0;
    cursor: pointer;
    border-radius: 3px;
    color: #000;
    transition: ease .5s;
    font-size: 13px;
}

.rightTagList.pl_accordion .content {
    padding: 10px 15px;
    margin: 0 0 1px 0;
}

.rightTagList.pl_accordion input+label.accrdnLabel+.content {
    display: none;
}

.rightTagList.pl_accordion input:checked+label.accrdnLabel+.content {
    display: block;
}

.mrgTop .table tr td label,
.mrgTop .table tr th {
    font-size: 11px;
    color: #000;
}

th.thBg {
    border: 1px solid #ddd !important;
    background-color: #f2f2f2;
}

.tdBg .drawTumbItems img {
    width: 80px;
    height: 80px;
    border: 1px solid #ddd;
}

.mrgTop {
    font-size: 11px;
}

.mrgTop ul {
    padding: 0px;
}

.mrgTop .table> :not(caption)>*>* {
    border: 1px solid #dddddd;
}

.presptn .__react_component_tooltip {
    color: #fff;
    background: #FFF !important;
    border: 1px solid transparent;
    width: 430px;
    overflow-y: scroll;
}

.priscriptionThumbnail {
    width: 100px;
    height: 100px;
    border: 1px solid #ccc;
    margin: 5px;
}

.addTreatmentTr {
    width: 100%;
    border: 1px solid #cbc5d4;
    float: left;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    padding: 0 30px;
}

.treatmentCommonTxt1 {
    float: left;
    position: relative;
    margin: 50px auto;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
}

.treatmentCommonTxt1 span {
    padding: 15px 50px;
    display: inline-block;
    border: 1px solid #cbc5d4;
    color: #444;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    font-size: 16px;
}

.pl_success {
    color: #008000;
}

.pl_pending {
    color: #f00;
}

#showLab ul li {
    list-style: none;
}

#showLab {
    display: flex;
}

.pl_testdpdn {
    position: absolute;
    right: 0;
    top: 42px;
}

.testResultList {
    clear: both;
    overflow: hidden;
    border-bottom: 1px solid #ececec;
    position: relative;
    padding-left: 10px;
}

.testResultRight {
    clear: both;
    overflow: hidden;
}

.testResultList .col-md-3,
.testResultList .col-md-8,
.testResultList .col-md-1,
.testResultList .col-md-4 {
    padding: 0 10px;
}

.testResultListHead label {
    font-weight: 600 !important;
    color: #252525;
    padding: 3px 0;
}

.testResultList label {
    width: 100%;
    margin-bottom: 5px;
    margin-top: 10px;
    font-size: 13px;
    display: block;
}

.pltestResult .treatmentContentClose {
    top: 0px;
    right: 2px;
}


/* .pltestResult .testResultRight .closeSuperAdmin {
    position: absolute;
    right: 50px;
    margin: 0;
    top: 100px;
    padding: 0px;
} */

.pltestResult .closeSuperAdmin {
    position: absolute;
    right: 50px;
    width: 18px;
    height: 18px;
    font-size: 15px;
    margin-left: 15px;
    text-align: center;
    border: 1px solid #ddd !important;
    line-height: 13px;
    z-index: 1;
    display: inline-block;
    color: #ff4e4e;
    cursor: pointer;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
}

.testResultDateSpan {
    display: block;
    padding: 10px 0;
    font-size: 12px;
    color: #3c3c3c;
}

.treatmentImageAdd {
    padding: 10px;
    clear: both;
    overflow: hidden;
    background: #fff;
    margin: 0 0px;
}

.treatmentImageAdd select,
.treatmentImageAdd input[type="text"] {
    width: 150px;
    border: 1px solid #969696;
    padding: 7px 6px;
    font-size: 13px;
    color: #464646;
    float: left;
    cursor: pointer;
    margin-right: 10px;
    background: transparent;
}

.treatmentImageAdd select,
.treatmentImageAdd input[type="text"] {
    width: 150px;
    border: 1px solid #969696;
    padding: 7px 6px;
    font-size: 13px;
    color: #464646;
    float: left;
    cursor: pointer;
    margin-right: 10px;
    background: transparent;
}

.treatmentUploadSpltCvr {
    float: left;
}

.webCamImageWrap {
    clear: both;
}

.treatmentUploadSpltCvr .btnFileUpload {
    float: left;
    margin: 0 10px 0 0;
    background: #2d1152;
    border: 1px solid #2d1152;
    color: #fff;
}

.webCamImageWrap .practiceGreenButton {
    width: 110px;
    font-size: 11px;
    float: none;
    position: relative;
    cursor: pointer;
    padding: 0;
    display: inline-block;
}

.webCamImageWrap .practiceGreenButton label {
    padding: 8px 0;
    display: block;
    margin: 0;
    cursor: pointer;
    text-align: center;
    width: auto;
    background: #2d1152;
    border: 1px solid #2d1152;
    color: #fff;
}

.webCamImageWrap .practiceGreenButton input[type="file"] {
    display: none;
}

.treatmentUploadWeb {
    float: left;
    margin-right: 7px;
    max-width: 110px;
}

.treatmentUploadWeb .webCamWrap {
    min-height: auto;
}

.treatmentUploadWeb .webCamImg {
    width: 100px;
    display: flex;
    flex-direction: column;
    position: relative;
    top: -5px;
}

.webCamImg {
    position: relative;
}

.treatmentUploadWeb img {
    width: 110px;
    margin-bottom: 5px;
}

.webCamImg img {
    cursor: pointer;
    width: 100%;
    height: auto;
}

.webCamImageMain .practiceGreenButton {
    min-width: 130px;
    width: auto;
    margin: 0 5px 0;
    background: #2d1152;
    border: 1px solid #2d1152;
    color: #fff !important;
    padding: 9px 5px;
}

.uploadIcon img {
    width: 20px;
    height: auto;
    cursor: pointer;
}

.fileRecordThumb {
    width: 75px;
    height: 75px;
    float: left;
    margin: 10px;
    border: 1px solid #ddd;
    padding: 5px;
    position: relative;
    cursor: pointer;
}

.fileRecordThumbWarp .fileRecordThumb {
    margin: 15px;
}

.fileRecordThumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.fileRecordThumb .thumbClose {
    position: absolute;
    top: -8px;
    right: -10px;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background: #fff;
    cursor: pointer;
}

.treatmentListtop {
    background: #EEE;
    padding: 10px;
    border: 1px solid #dadada;
    border-bottom: 0;
    float: left;
    width: 100%;
}

.friendlyNoteDiv {
    color: #000;
    position: relative;
    font-family: "OpenSans-Regular";
    top: -3px;
}

.friendlyNoteDiv:hover .friendlyNoteText {
    display: block;
}

.friendlyNoteText {
    width: 300px;
    display: block;
    position: absolute;
    background: #fff;
    font-size: 12px;
    color: #313131;
    z-index: 9999;
    padding: 10px;
    right: 0;
    top: 25px;
    box-shadow: 0 0 7px #b7b7b7;
    -webkit-box-shadow: 0 0 7px #b7b7b7;
    -moz-box-shadow: 0 0 7px #b7b7b7;
    -ms-box-shadow: 0 0 7px #b7b7b7;
    -o-box-shadow: 0 0 7px #b7b7b7;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -ms-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    display: none;
}

.friendlyNoteText textarea {
    min-height: auto;
    height: 110px;
    margin: 0;
    font-size: 13px;
    line-height: 18px;
    color: #313131;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -ms-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    padding: 5px;
    border: 1px solid gainsboro;
    width: 100%;
    resize: none;
}

.friendlyNoteDiv .saveButton {
    text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
    background-image: linear-gradient(to bottom, #00afef, #005fef);
    display: inline-block;
    padding: 3px 8px;
    border: 0;
    border-radius: 5px;
    color: #fff;
}

.friendlyBtns {
    display: block;
    text-align: right;
    padding-top: 10px;
}

.friendlyNoteText:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 12px 10px;
    border-color: transparent transparent #fff transparent;
    position: absolute;
    top: -10px;
    right: 7px;
    filter: drop-shadow(0px -2px 2px #b7b7b7);
    -webkit-filter: drop-shadow(0px -2px 2px #b7b7b7);
    -moz-filter: drop-shadow(0px -2px 2px #b7b7b7);
    -ms-filter: drop-shadow(0px -2px 2px #b7b7b7);
    -o-filter: drop-shadow(0px -2px 2px #b7b7b7);
}

.imageRightHotSpot .treatmentListHead {
    font-weight: 600;
}

.inVoiceTxtLabel {
    float: none !important;
    text-align: center;
    padding-top: 5px;
    font-size: 16px;
    display: block;
}

.imageRightHotSpot .treatmentNameList {
    max-height: 455px;
    min-height: 455px;
}

.treatmentNameList .rightMainSubHeading {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    margin: auto;
    left: 0;
    right: 0;
    padding: 10px 10px;
    width: 75%;
    border-radius: 5px;
    text-align: center;
    border: 1px solid #ddd;
}

.rightMainSubHeading {
    display: block;
    font-size: 12px;
}

.noDataFound {
    display: inline-block;
    border: 1px solid #cbc5d4;
    color: #444;
    border-radius: 7px;
    font-size: 16px;
    width: 50%;
    margin: 100px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: red; */
    padding: 15px;
}

.noDataFound p {
    margin: 0px;
    color: red;
}

.DrNameView {
    position: absolute;
    right: 13px;
    margin-top: 30px;
    top: 95px;
    z-index: 0;
}

.drNameViewArrow {
    position: relative;
    right: 0;
    margin-top: -35px;
    text-align: right;
    cursor: pointer;
}

.drNameViewArrow img {
    width: 8px;
    margin-left: 8px;
}

.drNameViewArrow small {
    margin-right: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
    display: block;
}


/*  */

.date-picker-icon {
    float: right;
    margin-right: 6px;
    margin-top: -30px;
    position: relative;
    z-index: 2;
}

.icon.iconCalendar.calendar.plcalendar {
    font-size: 16px;
}

.icon.iconCalendar.calendar.plcalendar svg {
    position: absolute;
    margin-left: 9.5%;
    margin-top: -46px;
}

.icon.iconCalendar.calendar.plcalendar2 svg {
    position: absolute;
    margin-left: 11%;
    margin-top: -15px;
    z-index: 999;
}

#friendlyNote {
    margin-top: 0px;
}

.icon.iconCalendar.calendar.plReportsCalendar {
    font-size: 16px;
    position: relative;
    margin-top: -25px;
    margin-left: 4px;
    top: -30px;
    left: 100%;
}

.icon.iconCalendar.calendar.plReportsCalendar svg {
    position: absolute;
    margin-top: -16px;
    margin-left: -25px;
}

.plautoId .checkBoxSquareDiv label:after {
    opacity: 1;
    content: unset;
}

.plAlerts {
    padding-right: 15px;
}

.borderButton.borderButtonLarge.plAddFam {
    border: none;
    font-weight: 600;
    margin: 0px;
    padding: 5px;
    color: #fff;
    font-size: 13px;
    background: #2d1152;
}

.formInput::placeholder {
    font-size: 11px;
    color: #ccc;
}

.plPriceInfo {
    margin: 0;
    height: 40px;
    width: 100%;
    padding: 3px 10px;
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 1em;
}

.class-red {
    color: #ff0000 !important;
}


/* Tooltip css begins */

.toolTipWrap {
    position: absolute;
    z-index: 9999999;
    background: #fff;
    width: 450px;
    overflow-x: hidden;
    right: 0;
    padding: 10px;
    margin-top: 0px;
    -webkit-user-select: all;
    user-select: all;
    display: block;
    border-radius: 10px;
    box-shadow: 0 0 10px #c3c3c3;
    -webkit-box-shadow: 0 0 10px #c3c3c3;
}

.drawTumbItemVisit {
    float: left;
}

.dosageInput input {
    width: 40px !important;
    float: left;
    margin-right: 5px;
    padding: 0 5px;
    border: 1px solid #f2f2f2;
    height: 35px;
    margin-bottom: 15px;
}

.plstartDate,
.plstartDate::placeholder {
    color: #393939 !important;
    border: 1px solid #e6e6e6 !important;
    padding: 10px 0px;
    height: 35px;
    border-radius: 4px;
    margin-top: -3px;
}

.completedPlanCalendar {
    color: rgb(27, 153, 213);
    border: none;
}

.appointmentEdit span {
    top: 55px !important;
    border: 1px solid #ddd;
}

.procedureCatType #chkShowPharmacy {
    height: 20px;
    width: 20px;
}

.procedureCatName.plTaxes {
    width: 100%;
}

.plCheckBx label {
    margin-left: 5px;
}

.LborderdentalPrint {
    border: none;
    border-bottom: 1px solid #393939;
    width: 97%;
}

.mrg_rgt.inputField.plCollege {
    border: 1px solid lightgray;
    border-radius: 0;
    color: #8f8f8f;
    height: 32px;
}

.plAutoList {
    width: 100%;
    float: left;
    height: 190px;
    overflow: hidden;
    overflow-y: scroll;
    padding: 2px 10px;
    display: none;
    border: 1px solid #c5c5c5;
}

.plAddPatnt {
    width: 75px;
    height: 75px;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
}

.plLabTestAppend td {
    padding: 0px;
    border: none;
}

.plLabTestAppend td input {
    width: 147px;
    margin-right: 25px;
}

.plLabTestAppend td input.plQuantity {
    width: 100px;
    margin-right: 20px;
}

.addDataHead {
    font-size: 18px;
    font-weight: 600;
    color: #545454;
    border-bottom: 1px solid #ddd;
    position: relative;
    font-family: "Montserrat-Regular";
}

span.popUpClose {
    right: 0;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    top: 10px;
    width: 18px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
}

.plpopup.plpopupList .popBack {
    margin-top: 10px;
}

.imageRightHotSpot .inVoiceSideBlck {
    background: #fff;
    color: #000;
    clear: both;
    overflow: hidden;
}

.inVoiceSideBlck {
    padding: 10px;
    cursor: pointer;
}

.cropBoxItem {
    float: left;
    width: 100%;
    position: relative;
    margin-bottom: 0px;
}

.cropBoxContent {
    width: 100%;
    float: left;
    padding: 0 0 0 20px;
    font-weight: 400;
}

.cropBoxTop {
    float: left;
    width: 100%;
    overflow: visible;
    margin-bottom: 5px;
    padding-right: 40px;
    position: relative;
}

.cropBoxRightImage {
    float: left;
    color: #fff;
    position: absolute;
    left: -25px;
    top: -1px;
}

.marker-text {
    position: absolute;
    top: 10%;
    width: 100%;
    margin: 0;
    z-index: 1;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: #fff;
}

.cropBoxRightImage p {
    font-size: 10px;
}

.cropBoxRightImage img {
    width: 20px;
    height: 25px;
}

.cropBoxName {
    float: left;
    font-weight: 600;
    width: 60%;
}

.cropBoxName .elipsis {
    color: #5d5d5d;
}

.cropBoxName .elipsis {
    width: 100%;
    display: block;
}

.cropBoxDate {
    float: right;
    font-size: 11px;
}

.cropContentSec {
    font-size: 12px;
    clear: both;
}

.cropBoxContent textarea {
    padding: 5px;
    min-height: auto;
    font-weight: 400;
    margin: 0;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 70px;
    min-height: 70px;
    max-height: 70px;
    padding: 5px 10px;
    border: 1px solid gainsboro;
    font-family: "source_sans_proregular", sans-serif;
    font-size: 1em;
    margin-bottom: 15px;
    transition: box-shadow .3s, border .3s;
    resize: none;
}

.cropContentSec span {
    display: flex;
    justify-content: flex-end;
    word-break: break-all;
    margin-bottom: 5px;
}

.cropContentSecBtn {
    display: block;
    text-align: right;
}

.cropContentSecBtn a {
    margin-left: 10px;
    text-decoration: none;
}

.cropBoxBtns {
    position: absolute;
    top: -2px;
    right: -3px;
}

.cropBoxBtns span {
    display: inline-block;
    cursor: pointer;
}

.itemFolder {
    margin: 5px;
    padding: 25px 2px 8px;
    border: 1px solid #d4d4d4;
    width: 130px;
    float: left;
    height: auto;
    position: relative;
    cursor: pointer;
}

.itemFolder span {
    font-size: 12px;
    text-align: center;
    display: block;
    word-break: break-word;
}


/* uploadImg */

.uploadImg {
    width: 70% !important;
    margin: 0 auto;
    display: block;
    text-align: center;
    justify-content: center;
}

.sliderFullView .item span {
    text-align: center;
    display: block;
}

.cropBoxRightImage.cropBoxRightImageNew {
    position: relative;
    left: 100%;
}

.autoSearch {
    height: auto;
    overflow-y: scroll;
    background: #fff;
    border: 1px solid #ddd;
    padding: 0 5px !important;
    font-size: 13px;
    max-height: 250px;
}

.autoSearch li:hover {
    cursor: pointer;
}

.LabOrderAddLIst {
    height: auto;
    min-height: auto;
    max-height: unset;
    padding: 0px 0 0px;
    border-bottom: none;
}

.searchSection.plAppInfo {
    margin: 0px;
    padding: 0px;
}

.appInfoWrap {
    width: 84%;
    margin: 0 auto;
    padding: 15px 0;
}

.plAppointmenPopup {
    width: 820px;
}

.innerContent.plAppContent {
    padding: 15px 0px;
}

.plSubMenu {
    padding: 0px 20px;
}

.headerRightList span {
    color: #fff;
}

.successFullLogin {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.successFullLogin span {
    font-size: 26px;
    display: block;
    color: green;
    margin-bottom: 20px;
    text-align: center;
    font-family: "Montserrat-Regular";
}

.successFullLogin a {
    display: inline-block;
    color: #ececec;
    background: #2d1152;
    padding: 10px 15px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: 600;
    font-family: "Montserrat-Regular";
}

.autoSearch1 {
    padding: 1px;
    border: 1px solid #e8e8e8;
    margin: 0;
    margin-top: 1px;
    border-radius: 4px;
}

.autoSearch1 ul {
    padding: 0px;
}

.autoSearch1 li {
    list-style: none;
    font-size: 12px;
    padding: 2px 5px;
}

.autoSearch1 li::marker {
    display: none !important;
}

.appoinmentShowMoreBtn {
    clear: both;
    font-size: 12px;
    overflow: hidden;
    padding: 0 15px 0px;
    text-align: right;
    cursor: pointer;
    margin-bottom: -7px;
}

.appointmentForm .appoinmentShowMoreBtn a,
.appointmentForm .appoinmentShowMoreBtn a:hover {
    color: #3a9edb;
    font-family: "Arial";
}

input[type="checkbox"],
select,
.childTeethShow {
    cursor: pointer;
}

#gridnew_docedit_achtrow {
    margin-top: 10px;
    margin-left: 0px;
}

#gridnew_docedit_achtrow input {
    margin: 0;
    width: 99%;
    background-color: transparent;
    border: 1px solid #dcdcdc;
}

.dalignCenter {
    display: flex;
    justify-content: left;
    align-items: center;
}


/* LOADER CSS */


/* .loaderWrap {
    position: absolute;
    top: 50%;
    left: 50%;
} */

.loaderPurple {
    font-size: 10px;
    margin: 0px auto;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #2d1152;
    background: linear-gradient(to right, #2d1152 10%, rgba(45, 17, 82, 0) 42%);
    position: relative;
    animation: load3 1.4s infinite linear;
    transform: translateZ(0);
}

.loaderPurple:before {
    width: 50%;
    height: 50%;
    background: #2d1152;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}

.loaderPurple:after {
    background: #fff;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.loaderWrap img {
    position: absolute;
    top: 25px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;
}

@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}


/* ======================= */


/* START LOADER CSS */

.fullpage-loader {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.6);
    z-index: 9999;
    opacity: 1;
    transition: opacity 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fullpage-loader .fullpage-loader__logo {
    position: relative;
}

.fullpage-loader .fullpage-loader__logo:after {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /* -webkit-animation: shine 2.5s infinite cubic-bezier(0.42, 0, 0.58, 1);
    animation: shine 2.5s infinite cubic-bezier(0.42, 0, 0.58, 1); */
    /* background: rgba(255, 255, 255, 0.8); */
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 50%, rgba(255, 255, 255, 0) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#00ffffff", endColorstr="#00ffffff", GradientType=1);
    background: transparent;
}

@-webkit-keyframes shine {
    0% {
        transform: translateX(-100%) skew(-30deg);
    }

    100% {
        transform: translateX(200%) skew(-30deg);
    }
}

@keyframes shine {
    0% {
        transform: translateX(-100%) skew(-30deg);
    }

    100% {
        transform: translateX(200%) skew(-30deg);
    }
}

.fullpage-loader--invisible {
    opacity: 0;
}


/* END LOADER CSS */

.formSection {
    padding: 10px;
}

.listDiv {
    clear: both;
    overflow: hidden;
    padding-bottom: 10px;
}

.labelTxt {
    float: left;
    width: 105px;
}

.contentDiv {
    float: left;
    width: 470px;
    padding-left: 10px;
    position: relative;
}

.semiColan {
    position: absolute;
    left: 0;
    top: 0;
}

.contentTxt {
    display: block;
    border-bottom: 1px solid;
    min-height: 20px;
}

.applying {
    font-weight: bold;
    margin-bottom: 3px;
}

.checkFieldDiv {
    padding: 12px 15px 0;
    clear: both;
    overflow: hidden;
}

.ckeckList,
.ckeckListGrid {
    width: 33.333%;
    float: left;
    padding: 0 15px 10px;
    text-align: right;
}

.ckeckList.ckeckListLarge {
    float: right;
    width: 50%;
}

.signatureField {
    padding: 30px 65px;
    text-align: right;
}

.inputfield {
    border: 0px;
    outline: 0px;
    width: 100%;
}

.mediumField {
    float: left;
    width: 285px;
}

.smallLabelTxt {
    float: left;
    width: 65px;
}

.smallField {
    float: left;
    width: 110px;
    padding-left: 10px;
    position: relative;
}

.upper {
    float: left;
    width: 77%;
}

.addressField {
    float: left;
    width: 360px;
    padding-right: 5px;
}

.extraDiv {
    border: 1px solid;
    width: 100px;
    text-align: center;
    padding: 2px;
    float: left;
}

.ckeckList input[type="checkbox"],
.ckeckListGrid input[type="checkbox"] {
    margin: 3px 0 0 8px;
    vertical-align: top;
}

.colourList {
    width: 16.666%;
    float: left;
    padding: 8px;
    border: 1px solid #000;
    border-right: 0;
    border-bottom: 0;
    position: relative;
}

.colourList input[type="checkbox"] {
    position: absolute;
    top: 1px;
    right: 1px;
    margin: 0;
    width: 15px;
    height: 15px;
}

.borderBottom {
    border-bottom: 1px solid #000;
}

.borderRight {
    border-right: 1px solid #000;
}

.viewport {
    height: auto;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.viewportSub {
    position: relative;
}

.viewportImg {
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    transform: translate(0px, 0px) scale(1, 1);
    touch-action: pan-y;
    user-select: none;
}

.annotationAreaDiv {
    position: absolute;
    justify-content: left;
    align-items: center;
    display: none;
}

.annotationAreaDiv-hide {
    position: absolute;
    justify-content: left;
    align-items: center;
    display: none;
}

.annotationAreaDiv-show {
    position: absolute;
    justify-content: left;
    align-items: center;
    display: block;
    width: 200px;
    z-index: 2;
}

.annotationWrapper {
    border: 1px solid rgb(221, 221, 221);
    border-radius: 4px;
    padding: 8px;
    background-color: #fff;
    /* margin-left: 25px; */
    margin-left: 0px;
}

.annotationSubDiv {
    width: 100%;
}

.annotationSubDiv .annotationInput {
    border: 1px solid #ddd;
    resize: none;
    width: 100%;
    font-size: 13px;
    padding: 0 5px;
}

.annotationSubDiv input[type="text"].annotationInput {
    border: 0;
    border-bottom: 1px solid #ddd;
}

.annotationLocImg {
    color: #fff;
    position: absolute;
    /* left: 0;
    top: -1px; */
    left: -12px;
    top: -38px;
}

.annotation-marker-text {
    position: absolute;
    top: 10%;
    width: 100%;
    margin: 0;
    z-index: 1;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: #fff;
}

.plLabOrderRightSec {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
}

.specializationSelection select {
    background: transparent;
    color: #fff;
    text-align: right;
    border: none;
    appearance: none;
    padding-right: 27px;
    /* font-family: "Montserrat-SemiBold"; */
}

.specializationSelection select#selectFildtype {
    background-image: linear-gradient(45deg, transparent 50%, #fff 50%), linear-gradient(135deg, #fff 50%, transparent 50%), linear-gradient(to right, transparent, transparent);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    background-repeat: no-repeat;
    margin-top: 5px;
}

.specializationSelection select option {
    color: #545454;
    text-align: left;
    padding: 8px 0x;
}

.specializationClk {
    width: 120px;
    float: left;
}

.headerBottomIcon a.active {
    background: white;
    padding: 8px 15px 5px;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    -webkit-box-shadow: 1px -2px 5px -1px rgb(97 97 97);
    -moz-box-shadow: 1px -2px 5px -1px rgba(97, 97, 97, 1);
    box-shadow: 1px -2px 5px -1px rgb(97 97 97);
}

.serviceListMain .smyLargstFieldMulti {
    height: 85px;
}

.headerTopGreen {
    background-color: var(--header-top-green);
}

.headerBottomGreen {
    background-color: var(--header-bottom-green);
}

.fc .fc-button .fc-icon {
    /* vertical-align: bottom; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.healthRecordsImgUpload {
    border: 1px solid #cbc5d4;
    padding: 15px;
    text-align: center;
    position: relative;
    margin: 10px;
    position: relative;
    background-color: #fff;
    margin-right: 0px;
}

.healthRecordsClose {
    position: absolute;
    top: -8px;
    right: -8px;
    cursor: pointer;
}

.healthRecordsClose img {
    width: 20px;
}

.healthRecordsImgUpload button {
    border: 1px solid #cbc5d4;
    padding: 15px;
}

.healthRecordsImgUpload h2 {
    font-size: 18px;
    margin-bottom: 10px;
}

.testResultClose {
    position: absolute;
    margin-top: 10px;
}

#testResult .treatmentNameList {
    border: 0px solid #dadada;
    border-left: 1px solid #dadada;
    border-right: 1px solid #dadada;
}

.treatmentNameList {
    border: none;
}

.testResultLink.testResultCustomRead_PTour label a {
    cursor: pointer;
}

.headerBottomIconNew {
    margin: 0px 2px 0 !important;
}

.dentalProName,
.dentalProCost,
.dentalProDis,
.dentalProTotal,
.dentalProNote {
    padding: 5px 3px;
    font-size: 11px;
    font-family: "OpenSans-Regular";
}

.dentalProDis.treatmentTxtPayment {
    text-align: right !important;
}

.treatmentTable table td .dentalProName.dentalProNameTop.txtRight {
    text-align: right;
    display: flex;
    justify-content: flex-end;
}

.invoiceInnerTableBr table tr:last-child {
    border: none;
}

.treatmentPlanList.pl_treatmentDiv.pl_addTreatmnt .close_row {
    padding: 0px 9px;
    position: relative;
    left: 97%;
    top: 2px;
}

.profileRightTag .profileRightInput li {
    color: #f00;
}

.profileRightTag .profileRightInput li span {
    color: #525252 !important;
    position: relative;
    top: -5px;
}

.rightTagTxt li span {
    position: relative;
    top: -5px;
}

.tagsCloseIcon {
    padding-left: 4px;
    padding-right: 4px;
    position: relative;
    top: -5px;
}

.pl_actions {
    position: absolute;
    top: 10px;
    right: 0px;
}

.itemFolderIcon {
    width: 80px;
    background: #ecd27e;
    height: 55px;
    position: relative;
    margin: 0 auto 10px;
}

.itemFolderIcon:after {
    content: "";
    border-bottom: 10px solid #ecd27e;
    width: 45px;
    top: -9px;
    left: 0;
    height: 0;
    position: absolute;
    border-right: 10px solid transparent;
}

.treatmentTable table .itemFolder span {
    width: 100%;
    text-align: center !important;
}

.treatmentTable table .sliderBreadCrumb span {
    width: auto;
    font-size: 13px;
}

.sliderBreadCrumb span {
    color: #404040;
    font-weight: 600;
    text-decoration: none;
}

.treatmentTable.pl_patientFiles .col-md-12 {
    width: 100%;
    display: flex;
    flex-flow: wrap;
    align-items: center;
}

.plfolderTxt {
    color: #404040;
}

#imageList {
    /* display: flex; */
    flex-wrap: wrap;
    width: 100%;
}

.cardDetailLogo canvas {
    cursor: default;
}

.cardDetailLogo.cardDetailLogo-preview {
    top: -10px;
}

.cardDetailImg-preview {
    position: absolute;
    top: 55px;
    right: 10px;
}

.webcam-btn {
    background: 0;
    border: 1px solid var(--save-color);
    padding: 6px 16px;
    color: #393939;
    text-align: center;
    font-family: "montserratregular", sans-serif;
    font-size: 11px;
    border-radius: 5px;
    outline: 0;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    margin: 5px 0 5px 0px;
}

.itemFolderImg {
    padding: 14px 2px 14px;
    margin: 5px !important;
}

.imgSec {
    width: 75px !important;
    height: 75px !important;
    float: left;
    margin: 4px 25px;
    border: 1px solid #ddd;
    padding: 5px;
    position: relative;
    cursor: pointer;
}

.thumbClose.imgSecthumbClose {
    position: absolute;
    right: 20px;
    display: flex;
    justify-content: flex-end;
    z-index: 999999;
    top: 10px;
    width: auto !important;
}

.uploadImgDental {
    width: 100% !important;
    margin: 0px auto;
    display: block;
    text-align: center;
    justify-content: center;
}


/* Popup css */

.popup-link {
    display: flex;
    flex-wrap: wrap;
}

.popup-link a {
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
}

.popup-container {
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform: scale(1.3);
    position: fixed;
    z-index: 999999999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(21, 17, 17, 0.61);
    display: flex;
    align-items: center;
}

.popup-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 40%;
    border-radius: 6px;
}

.popup-content p {
    font-size: 17px;
    padding: 10px;
    line-height: 20px;
}

.popup-content a.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    background: none;
    padding: 0;
    margin: 0;
    text-decoration: none;
}

.popup-content a.close:hover {
    color: #333;
}

.popup-content span:hover,
.popup-content span:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.popup-container:target {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.popup-container h3 {
    margin: 10px;
}

.priscriptionThumbnailPopup {
    width: 500px;
    height: 500px;
}

.thumbPopup {
    float: left;
    width: 100%;
    display: flex;
}


/*End popup styles*/

.cardDetailLeft {
    display: inline-block;
    width: 79%;
    padding-top: 25px;
}

body .form-control {
    display: block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
    height: 35px;
    background: #ffffff;
    border: 1px solid #ced4da;
    font-size: 12px;
    padding: 2px 10px;
    border-radius: 5px;
    outline: 0;
    margin-bottom: 5px;
}

.table> :not(:first-child) {
    border-top: 1px solid #f2f2f2;
}

.coverWraper .treatmentTable .invoiceClose .close_row {
    position: absolute;
    top: auto;
    right: 0px;
    left: auto;
}

.dashboardCover a {
    text-decoration: none;
}

.appointmentNumberSearch {
    position: absolute;
    background: #fff;
    z-index: 99;
    max-height: 360px;
    min-width: 298px;
    overflow-y: auto;
    height: auto;
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
}

.appointmentNumberSearch li,
.itemNameSearch li {
    border-bottom: 1px solid #ddd !important;
    background: transparent !important;
    position: relative;
    padding: 5px 5px;
    font-size: 11px;
    text-align: left;
    cursor: pointer;
}

.appointmentNumberSearch li:hover {
    background: #cbc5d4 !important;
    color: #000;
}

#editiconId {
    position: absolute;
    right: 15px;
    top: 22px;
    width: 15px;
    cursor: pointer;
}

#editiconIdPr {
    position: absolute;
    right: 15px;
    top: 22px;
    width: 15px;
    cursor: pointer;
}

#newUsertextId {
    position: absolute;
    left: 80px;
    top: 0;
    font-weight: bold;
}

#appoinmentDateTime.appmntDateTime {
    height: 28px;
    border: 1px solid #e8e8e8;
}

.treatmentSelectBtns.caseSheetMAdd input {
    width: auto;
    float: left;
}

.treatmentDrNameList.pl_drList ul {
    max-height: unset;
}

.treatmentDrNameList ul li span option {
    width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.vitalSignsItems {
    width: 75px;
    float: left;
    height: 75px;
    clear: none;
    border: 1px solid #ddd;
    margin: 5px;
    padding: 5px;
    overflow: hidden;
}

.filesPrint {
    width: 100%;
    float: left;
}

.treatmentCommonTxt1.labTestMessage {
    margin: 100px auto;
}

.pl_casesheetWrap.simplifiedForm .inputField {
    width: 100%;
}

.clinicContentList h2 {
    font-weight: 600;
    color: #808080;
    font-size: 14px;
    margin: 0;
}


/*  */

.content.plAccordion {
    width: 100%;
    padding: 0;
    float: left;
    margin-bottom: 8px;
}

.content.plAccordion:last-child {
    margin-bottom: 0px;
}

summary {
    font-size: 0;
    font-weight: 600;
    background-color: #fff;
    color: #333;
    margin-bottom: 1rem;
    outline: none;
    border-radius: 0.25rem;
    text-align: left;
    cursor: pointer;
    position: relative;
}


/* details>summary::after {
    position: absolute;
    content: "+";
    right: 20px;
    font-size: 30px;
    top: -8px;
}

details[open]>summary::after {
    position: absolute;
    content: "-";
    right: 20px;
    font-size: 30px;
    top: -8px;
} */


/* details>summary::-webkit-details-marker {
    display: none;
} */


/* details[open] summary~* {
    animation: sweep .5s ease-in-out;
} */


/* @keyframes sweep {
    0% {
        opacity: 0;
        margin-top: -10px
    }
    100% {
        opacity: 1;
        margin-top: 0px
    }
} */


/* summary::marker {
    display: none;
} */

.prescriptionTrtmntListItemDiv .prescriptionTrtmntListItem:last-child {
    border: 0;
}

.prescriptionTrtmntListItem {
    border-bottom: 1px solid #cecece;
    position: relative;
}

.precscriptionTrtmntList ul {
    padding-bottom: 10px !important;
}

.prescriptionTrtmntListItem ul li {
    list-style: none;
}

.practiceManagementForm .practiceManagementFormBlcok ul li.liFrequency input {
    width: 50px !important;
    float: left;
    margin-right: 6px;
    padding: 0 5px;
}

.liFrequency input {
    width: 50px !important;
    margin-right: 5px;
}

.needFollow {
    background: #fff;
    padding: 5px 10px;
    width: 100%;
    float: left;
    color: #0494cc;
}

.liFrequency {
    display: flex;
    justify-content: left;
    align-items: end;
}

.dAlignCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.labOrderAddSec {
    float: left;
    background: #fff;
    width: 100%;
}

.treatmentContentClose.LabOrderCloseIcon {
    top: -12px;
    right: 0px;
}

.pulseHeader {
    padding: 15px 0;
    background: var(--common-color);
    height: 74px;
}

.pulseLogo {
    float: left;
    /* width: 100px; */
    object-fit: contain;
    overflow: hidden;
    object-position: center;
    position: relative;
    top: -5px;
}

.pulseLogo img {
    width: 160px;
    object-fit: contain;
    object-position: center;
    height: 54px;
}

.pulseLogin,
.pulseRegister {
    float: right;
    padding-top: 6px;
}

.pulseLogin a,
.pulseRegister a {
    display: block;
    padding: 5px 20px;
    border: 1px solid #dedede;
    color: #dedede !important;
    text-decoration: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.pulseLogin {
    display: none;
}

.contentHead.pulseLoginHead {
    padding-top: 50px !important;
    font-family: "Montserrat-Regular";
    padding: 10px 0 45px;
}

.userCoverWrap {
    float: right;
    position: relative;
    margin-top: 15px;
    font-family: "OpenSans-Regular";
}

.userClicks {
    cursor: pointer;
    color: #dedede;
}

.userSubDetail {
    position: absolute;
    background: #321a52;
    width: 180px;
    right: 0;
    padding: 15px 15px 10px;
    top: 23px;
    font-size: 12px;
    display: none;
}

.userSubDetail a {
    color: #dedede;
    text-decoration: none;
    display: block;
    padding-bottom: 6px;
    border-bottom: 1px solid #636363;
    margin-bottom: 8px;
}

.userSubDetail a:last-child {
    margin: 0;
    border: 0;
}

.loginList.txtRight .forgotLink a:hover {
    color: #23527c;
    text-decoration: underline !important;
}

.contentWraper .form-control:focus {
    border-color: #ced4da;
    box-shadow: none;
}

body .patientSearchitem #patientFirstNameListing {
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    width: 100%;
    height: 35px;
    background: #ffffff;
    border: 1px solid #ced4da;
    font-size: 12px;
    padding: 2px 10px;
    border-radius: 5px;
    outline: 0;
    margin-bottom: 5px;
    position: relative;
    top: 1px;
}

.ui-widget.ui-widget-content {
    border: 1px solid #c5c5c5;
    padding: 0px;
    font-family: "Arial";
}

.ui-menu .ui-menu-item {
    border-bottom: 1px solid #ddd !important;
    background: transparent !important;
}

.ui-menu .ui-menu-item-wrapper {
    position: relative;
    padding: 3px 1em 3px 0.4em;
}

.memberNUmber {
    float: right;
    color: #525252;
    font-size: 11px;
    line-height: 16px;
}

.memberNAme {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #525252;
    max-width: 70%;
    float: left;
    text-transform: uppercase;
}

.memberEMail {
    font-size: 11px;
    line-height: 16px;
    color: #525252;
    width: 100%;
}

.memberMObile {
    color: #525252;
    font-size: 11px;
    line-height: 16px;
}

.ui-menu .ui-menu-item:hover,
.ui-menu .ui-menu-item:focus {
    background: #e5dfef !important;
    margin: 0 !important;
    font-weight: initial !important;
    border: 0 !important;
    border-bottom: 1px solid #ddd !important;
}

.contentWrap.members_receive {
    background: #f3f1f2;
    width: 100%;
    clear: both;
    overflow: hidden;
    text-align: center;
    padding: 30px 0;
}

.contentWrap.members_receive .contentHead {
    padding-bottom: 40px;
}


/* .contentList {
    padding: 0 0 0 50px;
} */

.contentList ul {
    padding-left: 50px;
}

.contentList ul li {
    list-style-type: none;
    text-align: left;
    font-size: 19px;
    color: #8e8d8d;
    padding-bottom: 15px;
    font-weight: 300;
}

.contentList ul li img {
    width: 35px;
    margin-right: 20px;
}

.contentImg {
    width: 100%;
    clear: both;
    overflow: hidden;
    text-align: center;
}

.contentImg img {
    width: 100%;
}

.contentWrap.members_receive h2 {
    text-align: center;
    font-weight: 300;
    color: #6f6f6f;
    font-size: 26px;
    font-family: "OpenSans-Bold";
    margin-top: 20px;
    margin-bottom: 10px;
}

.contentPlus img {
    padding: 50px 0;
}

.landingHead.changePswd {
    color: grey;
    height: 50px;
    padding: 10px 15px;
    margin: 0 auto;
    border: 1px solid gainsboro;
    background: white;
    font-size: 20px;
    font-family: "Montserrat-Regular";
}

.landingHead.oldPswd {
    font-size: 19px;
    color: grey;
    font-family: "Montserrat-Regular";
    padding: 4px 0 15px;
    border: none;
}

.borderButton.borderButtonLarge.legoButton_green {
    background: white;
    color: #8300ff;
    border: 1px solid #8300ff !important;
    font-size: 1.1111111111em;
    padding: 10px 10px;
    border-radius: 5px;
    text-align: center;
    font-family: "Montserrat-Regular";
    transition: background .4s ease;
    height: 45px !important;
    box-shadow: 0 1px 2px rgb(0 0 0 / 40%);
    width: 245px;
    display: inline-block;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
}

.actionButtons {
    display: flex;
}

.pulseContentCoverWraper {
    min-height: 650px;
    background-color: #fff;
}

.pulseContentCoverWraper .homeUserDiv {
    border-bottom: none;
}

.addPatientTab.tabActive,
.addPatientTab {
    padding: 1px 6px;
    font-size: 13px;
    font-family: "SourceSansPro-Regular";
}

.filUploadInline:hover span {
    display: block;
}

.dAlignCenterAddPatient {
    display: flex;
    justify-content: center;
}

.uploadButton.borderButton.uploadButtonSmall1 {
    margin: 0;
    min-width: 100px;
    padding: 3px 0px;
    color: #393939
}

#file1 {
    text-align: center;
    padding: 0;
    margin: 0;
    height: auto;
    background: #fff;
    color: #393939;
}

.uploadButtonSmall1 .uploadBtn {
    margin: 0;
    min-width: 100px;
    padding: 0px 0px;
    color: #393939;
    height: auto;
    background-color: #fff;
}

.addLanguage {
    font-size: 12px;
    font-family: "Arial";
    color: #333;
}

.addFamilyMem {
    width: 30%;
    float: left;
}

.pl_vdo_dpdwnAddPatient {
    position: absolute;
    right: 10px;
    text-align: right;
    margin-top: -45px;
}

.selectLanguage {
    display: block;
    width: 150px;
    position: absolute;
    top: 27px;
    padding: 5px;
    font-size: 12px;
    right: 0;
    border-radius: 5px;
    background: #494949;
    border: 1px solid #ccc;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border linear .2s, box-shadow linear .2s;
    transition: border linear .2s, box-shadow linear .2s;
    font-family: "OpenSans-Regular";
    z-index: 9;
}

.selectLanguage ul {
    margin: 0px;
    padding: 0;
}

.selectLanguage ul li {
    list-style: none;
    color: #fff;
    padding: 3px;
    border-bottom: 1px solid #fff;
    text-align: left;
    cursor: pointer;
}

.selectLanguage ul li:last-child {
    border-bottom: none;
}

.selectLanguage ul li:hover {
    background: rgba(0, 0, 0, 0.3);
}

.cardDetailImg {
    text-align: right;
    height: 50px;
    float: right;
    overflow: hidden;
    width: 50px;
    margin-bottom: 5px;
    object-fit: contain;
}

.cardDetailLogo {
    text-align: right;
    width: 45px;
    float: right;
    overflow: hidden;
    margin-bottom: 5px;
    position: absolute;
    right: 5px;
    top: 50px;
}

.instructionHealthCard {
    padding: 20px 30px;
}

.appointmentNumberSearchW {
    width: 350px;
}

.addPatientTab {
    cursor: pointer;
}

.addPatientTab.tabActive {
    cursor: default;
}

#dobId {
    margin: 0px;
}

.cardInstrnTitle {
    display: block;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 10px;
}

.signatureDivSec {
    font-weight: 600;
    position: absolute;
    bottom: 10px;
    right: 15px;
}

.registrationCardWrap.settingsBg {
    background: #fff;
    padding-bottom: 15px;
}

.cardDetailLogo.QRcodeLogo {
    /* top: 80px; */
    top: auto;
    bottom: -15px;
}

.rgstrnAddrs {
    font-size: 13px;
}

.IstrSec {
    padding-bottom: 40px;
}

td {
    font-family: "OpenSans-Regular";
}

th {
    font-family: "Montserrat-Regular";
    color: #333;
}

.settingsRight select,
.settingsRight input {
    font-family: "SourceSansPro-Regular";
    color: #888;
    font-size: 14px;
}

.practiceGreyButton.closeTodo,
.practiceGreyButton.closeTodo:hover {
    font-family: "SourceSansPro-Regular";
    font-size: 14px;
}

.icon.iconCalendar svg {
    color: #cecece;
    cursor: pointer;
}

#divTaxDetails .procedureCatType {
    width: 99%;
}

.procedureCatType1 label {
    font-size: 13px;
    color: #333;
}

.procedureCatType1 {
    padding-bottom: 11px;
}

.addDataContent .summaryBtnDiv .practiceGreenButton {
    margin: 0 0 0 30px;
    font-family: "Montserrat-SemiBold";
}

.ploverlay .plpopup {
    padding: 20px 30px;
    font-family: "SourceSansPro-Regular";
}

.saveBtnModal {
    position: relative;
    right: -20px;
}

table,
.pharmacyManufactureWrap.pharmacyManuf table {
    table-layout: fixed;
}

.reportWrap table.prescriptionTb,
table.staffListTble,
table.treatmentProcedureTble,
.supplierInvoiceWrap table,
.printOutWrap table,
.pharmacyManufactureWrap table,
.receiptPrint table {
    table-layout: auto;
}

.supplierInvoiceWrap .dashboardtable table th,
.supplierInvoiceWrap .dashboardtable table td {
    font-size: 13px;
}

body #additemDetailsButton.practiceGreenButton {
    color: #393939 !important;
    font-family: "Montserrat-Regular";
}

.icon.iconCalendar.calendar.plcalendar.plcalendarItem svg {
    margin-left: 8.5%;
}

.changeImage.changeImgPro {
    color: rgb(51, 122, 183);
    font-size: 14px;
    text-align: center;
    justify-content: left;
    align-items: center;
    margin: 0px 33px;
    font-weight: normal;
}

.uploadButton label.profileEditImg {
    background: #fff;
    color: #393939;
    border: 1px solid var(--save-color);
    font-family: "Montserrat-SemiBold";
    padding: 3px 0px;
}

.paddingFive img {
    cursor: pointer;
}

.labOdrDpdn .dentalProNote.paddingFive .dentalProNote {
    position: absolute;
    top: -27px;
    right: 0;
}

.patientright .rightTagList .tagListingDiv input::placeholder {
    font-size: 13px !important;
}

.printOutSettingContent th,
.printOutSettingContent td {
    font-family: "SourceSansPro-Regular";
    font-size: 12px;
}

.drNameViewArrow .appointmentNumberSearch {
    right: 0px;
}

.webcam-img {
    width: 75px;
    margin: 0 auto;
}

table.PrescriptionAmnt,
table.purchaseInvoiceTble,
table.addSpcmnTble,
table.doctrListTble,
table.incomeExpnsTbl {
    table-layout: auto;
}

table.doctrListTble tr:last-child {
    border-bottom: 1px solid;
}

.apnmentDay.apnmentDayTime {
    font-size: 17px;
    text-transform: uppercase;
}

.treatmentSectionDisable {
    opacity: .4;
    height: auto;
    /* padding: 25px 0 0; */
    pointer-events: none;
    user-select: none;
    cursor: not-allowed;
}

.treatmentPlanList.pl_treatmentDiv.pl_addTreatmnt .close_row {
    top: -1px;
    font-size: 13px;
}

.hospitalName {
    position: absolute;
    top: 6px;
    right: 15px;
    color: #2f77c7;
    font-size: 14px;
}

.gridList .gridContent {
    width: 65%;
}

.gridList .gridEdit {
    width: 25%;
    text-align: right;
}

.plpopup.plpopupList .popBack {
    margin-top: 0px;
}

.appointmentPopUp .addDataHead {
    border: none;
}

.appointmentPopUp .innerContent.plAppContent {
    padding: 5px 0px 0;
}

.rightTagList.pl_accordion table.table {
    table-layout: auto;
}

.landingBodyWrap h2 {
    font-size: 20px;
    font-family: "OpenSans-Bold";
    color: #000;
}

.landingBodyWrap h3 {
    font-size: 16px;
    font-weight: 700;
    color: #000;
    padding-bottom: 10px;
}

.landingBodyWrap p,
.landingBodyWrap ul li {
    color: #868686;
    font-weight: 400;
    padding: 10px 0;
}

.dd-menu li {
    padding: 3px 15px 3px 8px;
    width: 145px;
}

.dd-menu li:hover img,
.printImg:hover img {
    filter: invert(1);
}


.dd-menu li a:hover {
    color: #fff;
}

.dd-menu li:hover {
    background-color: #008fef;
    background-image: -moz-linear-gradient(top, #00afef, #005fef);
    display: block;
    background-image: linear-gradient(to bottom, #00afef, #005fef);
    background-repeat: repeat-x;
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ff00afef', endColorstr='#ff005fef', GradientType=0);
    border-color: #005fef #005fef #0041a3;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    color: #fff;
}

.ptMedicalHistory .noDataFound {
    margin: 0 auto;
    width: 100%;
    border: none;
    font-size: 14px;
    padding: 4px;
}

#appendNotifyDefault .rightTagTxt li,
#appendNotifyDefault .rightTagTxt li:hover {
    width: auto;
    float: left;
    padding-right: 10px;
}

#patientsInGroups {
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.treatmentProfile {
    background-color: #ececec;
}

.ptntHlthCrd .cardDetailLogo {
    width: 40px;
    /* top: 50px; */
    /* top: -5px; */
    top: 2px;
}

.gridImage.profilegridImage img {
    width: 60px;
    height: 60px;
    max-width: 60px;
    max-height: 60px;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
}

#appendNotifyDefault {
    width: 100%;
    float: left;
    min-height: auto;
    overflow: hidden;
    overflow-y: scroll;
    padding: 0px 5px;
    /* position: relative;
    z-index: 9999; */
    border-radius: 6px;
    margin-bottom: 5px;
    max-height: 60px;
}

.ptntHlthCrd .cardDetailImg {
    height: 40px;
    width: 40px;
    object-fit: contain;
    object-position: center;
    display: flex;
    align-items: center;
    position: absolute;
    top: 45px;
    right: 5px;
}

.ptntHlthCrd .cardDetailLogo.cardDetailbarCode {
    bottom: -10px;
    right: 5px;
    top: unset;
}

.ptntHlthCrd .cardDetailRight {
    height: auto;
}

.ptMedicalHistory label.elipsis {
    color: #000 !important;
    font-weight: 600;
    display: block;
}

/* @media print {
    html, body {
      height: 100%;
      margin: 0 !important;
      padding: 0 !important;
      overflow: hidden;
    }
  } */

.ptntHlthCrd .registrationCardContent .cardDetailList label {
    font-size: 13px;
    line-height: 23px;
    font-weight: 600;
}

.doc.breadcrumbDoc.breadCrumbPrctmngt.pladdpurchase ul li:last-child {
    color: #bcbcbc;
}

.taxMainContentWrap ul li:last-child,
.sellingDetaillsWrap ul li:last-child,
.sellingDetaillsWrap li:last-child,
.sellingDetailSec {
    color: #333 !important;
}

#supplierList li {
    width: 100%;
}


/* HEALTH CARD PRINT CSS */

@media print {
    /* @page {
        size: portrait;
    } */

    #parent-selector .instructionFormWrap,
    .instructionFormWrap1 .instructionFormWrap {
        width: 100%;
        transform: scale(.7);
        transform-origin: 0 0;
    }

    body {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
        margin: 0px;
    }

    #HealthCardHeader,
    #HealthCardLeftMenu,
    #HealthCardProfiledetail,
    #cardPrintButton,
    #HealthCardPatientright,
    #healthCardFooter,
    #healthCardHead {
        display: none;
    }

    #healthCardPrint,
    #divPatientContent,
    #hr,
    .pl_addTreatSection,
    .practiceManagementRow,
    #healthCardPrint,
    #cardPrint,
    #cardPrintButton {
        background-color: #fff;
        border: none
    }

    .registrationCard.card_id_1 {
        margin: 0px;
    }

    .pdfView {
        padding-bottom: 6px !important;
    }

    .patientInvoiceAddTable {
        width: 100%;
        font-family: sans-serif;
    }

    #labDetails table,
    .patientInvoiceAddTable {
        width: 100%;
    }

    .dentalProName,
    .dentalProCost,
    .dentalProDis,
    .dentalProTotal {
        padding: 8px 7px;
        text-align: left;
        font-family: sans-serif;
        font-size: 12px;
        border-bottom: 1px solid #ddd;
    }

    th {
        text-align: left !important;
        display: table;
        font-family: sans-serif !important;
        font-size: 12px !important;
    }

    thead {
        border-bottom: 1px solid rgb(203, 197, 212);
        float: left;
        width: 102%;
        display: table;
    }

    .historyPRINT .table_print td,
    .historyPRINT .tr_head td {
        text-transform: capitalize;
    }

    .historyPRINT .timelineList {
        padding: 3px 0 5px 20px;
    }

    .historyPRINT .timelineListContent {
        padding: 10px 2px 10px 2px;
    }

    .historyPRINT .treatmentTable table tr {
        border: none;
        background: #ddd;
        width: 100%;
        border-spacing: unset;
    }

    .historyPRINT .optionWrapper {
        display: none;
    }

    /*  */
    .historyPRINT table {
        page-break-inside: auto
    }

    .historyPRINT tr {
        page-break-inside: avoid;
        page-break-after: auto
    }

    .historyPRINT thead {
        display: table-header-group
    }

    .historyPRINT tfoot {
        display: table-footer-group
    }

    .treatmentComplaintRowWrap .treatmentComplaintRight.pul_HDLPrint {
        margin-left: 30% !important;
    }
}


/* ===================== */

.ptFilesMedical .planActive tbody:before {
    top: 28px;
}

.ptFilesMedical .treatmentPlanDate {
    padding: 5px 10px 5px;
}

.ptFilesMedical .plfolderTxt {
    font-size: 10px;
    position: relative;
    top: 4px;
}

.ptFilesMedical .fileRecordThumbWarp .fileRecordThumb {
    margin: 20px 15px;
}

.imageRightHotSpot #txtHealthName,
.imageRightHotSpot #txtFolderName {
    padding-top: 8px;
}

.taxDataOuter {
    width: 100%;
    float: left;
}

.taxDataOuter .taxDataSelected {
    padding-right: 20px;
    width: 185px;
    float: left;
}


/*  */


/* .prefixDateWrap .checkBoxSquareDiv label:after {
    opacity: 1;
    content: '';
    position: unset;
    width: 12px;
    height: 7px;
    background: transparent;
    top: 12px;
    left: 6px;
    border: 3px solid #5f5f5f;
    border-top: 0;
    border-right: 0;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    color: #000;
} */

.prefixDateWrap .checkBoxSquareDiv label {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 35px;
    padding: 0;
    margin: 0 !important;
    left: 0;
    top: 0;
    float: none;
    border: 1px solid #f2f2f2;
    background: #fff;
}

.prefixDateWrap .checkBoxSquareDiv input[type=checkbox]:checked+label:after {
    -ms-filter: "alpha(opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
    background-color: red;
    content: '';
    position: absolute;
    width: 12px;
    height: 7px;
    background: transparent;
    top: 12px;
    left: 6px;
    border: 3px solid #5f5f5f;
    border-top: 0;
    border-right: 0;
    transform: rotate(-45deg);
    color: #000;
}

.prefixDateWrap .checkBoxSquareDiv label:after {
    /* -ms-filter: "alpha(opacity=0)";
    filter: alpha(opacity=0); */
    /* opacity: 0; */
    content: '';
    position: absolute;
    width: 12px;
    height: 7px;
    background: transparent;
    top: 12px;
    left: 6px;
    border: 3px solid #5f5f5f;
    border-top: 0;
    border-right: 0;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    color: #000;
}

.prefixDateWrap .checkBoxSquareDiv label::after {
    opacity: 0;
}

.prefixDateWrap .checkBoxSquareDiv label:hover::after {
    -ms-filter: "alpha(opacity=30)";
    filter: alpha(opacity=30);
    opacity: .3;
}

table.invoicePrint {
    table-layout: auto;
}

.invoicePrint tr td {
    font-family: "SourceSansPro-Regular";
    line-height: 15px !important;
    color: #000 !important;
}

.invoicePrint .treatmentComplaintWrap {
    border: none
}

.invoicePrint .treatmentComplaintLeft,
.invoicePrint .treatmentComplaintWrap {
    font-size: 14px;
}

.invoicePrint .treatmentComplaintLeft {
    color: #6f6f6f;
}

.dentalProNote.caseSheetMdclDpdwn {
    position: absolute;
    right: 0px;
}

.pl_casesheetWrap .popup-container h3 {
    margin: 10px;
    font-family: "Montserrat-Regular";
}

.pl_casesheetWrap .popup-content a.close {
    background: none !important;
    color: #191c1f !important;
    font-size: 21px;
}

.sketchpadP,
#sketchpad {
    border: 1px solid #a5a5a5;
    cursor: url(/src/images/icons/pen.ico), auto;
    background: #fff;
}

.dashboardProfile .landingHead {
    font-family: "Montserrat-SemiBold";
    font-size: 1.1111111111em;
    color: grey;
    text-align: left;
    margin-bottom: 15px;
    text-transform: uppercase;
    padding: 40px 15px 15px;
    background: #f2f2f2;
    margin: 0;
    border: none;
}

.landingBodyWrap.dashboardProfile {
    border: 1px solid gainsboro;
    padding: 15px 15px;
}

.dashboardProfile .landingProfile {
    margin: 0;
}

.dashboardProfile .landingProfile .accordion__button {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
    padding: 20px 15px;
    font-family: "Montserrat-SemiBold";
}

.dashboardProfile .landingProfile .accordion__item {
    margin: 0 0 0px;
    border: none;
    padding: 3px 10px;
    background: #f2f2f2;
}

.dashboardProfile .accordion__button:before {
    display: none;
}

.dashboardProfile .accordion__panel {
    border-top: 1px solid #ddd;
}

.dashboardProfile .uploadTextMessage {
    font-family: "SourceSansPro-Regular";
    font-size: 1em;
    color: #393939;
    float: left;
    margin-bottom: 20px;
    margin-top: 20px;
    font-style: italic;
    width: 60%;
    text-align: center;
    float: left;
    position: absolute;
}

.practiceManagementList .legoButton_green.AddButton {
    border: 1px solid #f2f2f2 !important;
}

.languageIcons .fa-plus {
    background: #8300ff;
    color: #fff;
    padding: 3px;
    border-radius: 4px;
    width: 25px;
}

#selectFildtype {
    font-size: 14px;
}

#formId .addDataHead {
    border-bottom: none;
}

#formId .popUpClose {
    background: none !important;
    color: #191c1f !important;
    font-size: 20px;
    top: 0px;
    font-weight: 700;
    line-height: 15px;
}

.addAppoPlus,
.plpopup.plpopupList.selectDr .close {
    font-size: 22px;
}

.patientList .plpopup {
    padding: 5px 15px;
}

.plpopup.plpopupList.selectDr h2 a {
    top: 5px;
}

.invoicePrint .treatmentComplaintRight:after {
    display: none;
}

.tramntPlanDpdwnWrap .planActive tbody:before {
    top: 35px;
}

.completedPlansWrap .planActive tbody:before {
    top: 39px;
}

.completedPlansWrap input[type=checkbox]:checked+label:after {
    top: 4px;
}

.tramntPlanDpdwnWrap .treatmentTable table td,
.tramntPlanDpdwnWrap .treatmentTable table th,
.tramntPlanDpdwnWrap .treatmentTable table td span {
    font-size: 11px;
}

.tramntPlanDpdwnWrap .dentalProName,
.tramntPlanDpdwnWrap .dentalProCost,
.tramntPlanDpdwnWrap .dentalProDis,
.tramntPlanDpdwnWrap .dentalProTotal,
.tramntPlanDpdwnWrap .dentalProNote {
    padding: 5px 7px;
}

.add_treatment_plan .dentalProName,
.add_treatment_plan .dentalProCost,
.add_treatment_plan .dentalProDis,
.add_treatment_plan .dentalProTotal,
.add_treatment_plan .dentalProNote {
    color: #868686;
}

/* .add_treatment_plan .treatmentCommonTxt1 {
    margin: 90px auto;
} */

.completedPlansWrap .dentalProName,
.completedPlansWrap .dentalProCost,
.completedPlansWrap .dentalProDis,
.completedPlansWrap .dentalProTotal,
.completedPlansWrap .dentalProNote {
    padding: 7px 4px;
}

.completedPlansWrap .treatmentTeethShow span {
    font-size: 14px !important;
}

.taxDetailWrapper input::placeholder {
    font-size: 11px;
}

.practiceLeftSection.pl_addTreatSection {
    padding-right: 2px;
}

.patientPayment.plInvoice .invoiceTableCvrDiv table {
    table-layout: auto;
}

.plInvoice #treatmentPlanListDiv .invoiceTableCvrDiv {
    border: none;
}

.labTestDtls .addDataHead {
    margin-bottom: 25px;
    font-size: 15px;
    padding-top: 15px;
}

.labTestDtls.labTestDtlsSettings .addDataHead {
    padding-top: 0px;
}

.labTestDtls .plpopup {
    padding: 0px 20px 20px;
}

.labTestDtls .labelField {
    font-size: 13px;
}

.labTestDtls #gridnew_docedit_achtrow input {
    width: 92%;
    margin: 0px 25px;
}

.labTestDtls #gridnew_docedit_achtrow input.plQuantity {
    width: 65%;
    margin: 0px 18px 0 36px;
}

.dashButtonBorder.AddBtn {
    background: #22a2fd;
    border: none;
    color: #fff;
}

table.table.staffListTble th,
table.table.staffListTble tbody tr:last-child {
    border-bottom: 1px solid #000;
}

table.staffListTble {
    margin-top: 15px;
}

.add_treatment_plan.aDDTreat .treatmntName {
    position: relative;
    top: -15px;
}

.add_treatment_plan.aDDTreat .dentalProPlaned {
    position: relative;
    top: -8px;
}

.add_treatment_plan.aDDTreat .dentalProName,
.add_treatment_plan.aDDTreat .dentalProCost,
.add_treatment_plan.aDDTreat .dentalProDis,
.add_treatment_plan.aDDTreat .dentalProTotal,
.add_treatment_plan.aDDTreat .dentalProNote {
    padding: 5px 7px;
}

.add_treatment_plan.aDDTreat .close_row {
    font-size: 16px !important;
    padding: 0px 7px;
    top: 0px !important;
    left: 97%;
    margin: 0;
}

.add_treatment_plan.aDDTreat .total_info {
    background: #ececec;
    width: 100%;
    margin: 0 auto;
}

.aDDTreat #lblMessage {
    font-size: 12px;
    position: absolute;
    left: 360px;
    margin-top: 5px;
}

.DRList .StaffList-MainDiv h3 {
    font-family: "OpenSans-Bold";
}

#DescriptionEng {
    font-size: 13px;
}

.addProcedureSectn .taxDetailWrapperCvr input::placeholder {
    font-size: 13px;
}

.pl_drAdd h4 {
    font-family: 'OpenSans-Regular';
    font-size: 19px !important;
    color: #020202;
}

.pl_drAdd .serviceListMain label,
.pl_drAdd .serviceListMain input,
.pl_drAdd .serviceListMain input::placeholder,
.pl_drAdd .serviceListMain select {
    font-family: "SourceSansPro-Regular";
}

.pl_drAdd .officeHours {
    font-family: "OpenSans-Regular";
    font-weight: 500;
    background-color: #f2f2f2;
    padding: 8px;
}

.instructionFormWrap textAreaBox.textAreaBox:focus,
.instructionFormWrap textAreaBox.textAreaBox:focus-visible {
    border: 2px solid #000 !important;
    outline: solid;
}

.SettingsConsent .cardsettingList label {
    font-size: 15px;
    font-family: "SourceSansPro-Regular";
}

.PurchaseInvoiceDetails .searchTxt {
    font-size: 14px;
}

.PurchaseInvoiceDetails .dashboardtable table th,
.PurchaseInvoiceDetails .dashboardtable table td {
    font-size: 13px;
}

.add_treatment_plan.aDDTreat .treatmentPrice,
.add_treatment_plan.aDDTreat .taxAddTreat {
    padding: 0 7px;
    position: relative;
    top: -8px;
}

.add_treatment_plan.aDDTreat .totalinr.taxAddTreat {
    padding-right: 25px;
}

.plpopup.plpopupList.selectDr {
    padding: 35px 15px;
    top: 24%;
    width: 345px !important;
}

.followUpTxtDate {
    font-size: 13px;
    color: #757575;
    padding-right: 10px;
    padding-left: 15px;
}

.caseshtMedAdd .clinicalField select,
.caseshtMedAdd .clinicalField select::placeholder,
.caseshtMedAdd .clinicalField input,
.caseshtMedAdd .clinicalField input::placeholder,
.caseshtMedAdd .clinicalField textarea,
.caseshtMedAdd .clinicalField textarea::placeholder {
    color: #737373;
    font-size: 13px;
}

.AmountInfo {
    padding-left: 110px;
    position: relative;
    top: -1px;
}

.add_treatment_plan.aDDTreat .treatmentPriceTxt.addCompltdtreatmentPriceTxt {
    width: 38%;
}

.add_treatment_plan.aDDTreat .treatmentCostTxt.addCompltdtreatmentCostTxt {
    width: 40%;
    padding-left: 14px;
}

.plLabOrderRightSec.labOrderAddRightScroll {
    min-height: auto;
    max-height: 227px;
}

.caseSheetDentalPrintWraper .patientDetailItem {
    font-size: 13px;
    color: #000;
    padding-top: 0px;
}

.caseSheetDentalPrintWraper .patientCase {
    width: 46%;
    float: left;
}

.caseSheetDentalPrintWraper .patientCaseLeft {
    width: 41%;
    float: left;
}

.caseSheetDentalPrintWraper .patientCaseRight {
    width: 60%;
    float: left;
}

.caseSheetDentalPrintWraper .patientRef,
.caseSheetDentalPrintWraper .patientDate {
    width: 27%;
    float: left;
}

.caseSheetDentalPrintWraper .patientHistoryTxt span {
    display: block;
    word-break: break-all;
}

.caseSheetDentalPrintWraper .medicalHeding {
    color: #000;
}

.caseSheetDentalPrintWraper {
    font-family: "Arial";
    padding: 15px 0;
}

.caseSheetDentalPrintWraper .medicalTitle {
    font-weight: 600;
}

.labOrderAddWrap .treatmentPlanWrap {
    padding: 0;
}

.labOrderAddWrap .treatmentPlanHead {
    border: 1px solid #dadada;
    background: #fff;
    margin: 0;
    /* border-bottom: none; */
}

.labOrderAddWrap .treatmentsList {
    border-top: none;
}

.labOrderAddWrap .plrmv {
    top: -1px;
}

.labOrderAddWrap .practiceManagementForm .practiceManagementFormBlcok ul li label {
    margin: 0px;
    margin-bottom: 4px;
}

.labOrderAddWrap .precscriptionTrtmntList ul {
    padding-bottom: 0px !important;
}

.labOrderAddWrap .practiceManagementForm .practiceManagementFormBlcok ul li input {
    border: 1px solid #f2f2f2;
    margin: 0;
}

.labOrderAddWrap .practiceManagementForm .practiceManagementFormBlcok ul li select {
    background: #fff;
    border: none;
    margin: 0;
    border: 1px solid #f2f2f2;
}

.labOrderAddWrap #precsriptionStores input {
    margin-bottom: 15px;
}

.labOrderAddWrap .practiceManagementForm {
    background: #fff;
}

.labOrderAddWrap .treatmentPlanHead {
    padding-left: 15px;
}

.labOrderAddWrap #txtComments {
    border: 1px solid gainsboro;
    border-radius: 0px;
}

#savePro {
    font-weight: normal;
}

.labOrderAddWrap .autoSearch1 li:hover {
    background-color: #007fff;
    color: #fff;
}

.labOrderAddWrap .autoSearch1 li {
    font-size: 11px;
    color: #000;
}

.labOrderAddWrap .autoSearch1 {
    border: 1px solid #cecece !important;
}

.pharmacyManufactureWrap .pagination {
    margin: 40px 0;
    width: 100%;
    display: flex;
    justify-content: end;
}

.conf-cursorpointer,
.addSpcmnTble td img {
    cursor: pointer;
}

.labOrderFormsWrap .LborderdentalPrint {
    border-bottom: none;
}

.labTestDtls #gridnew_docedit_achtrow input.lbTestDetlsQuantity {
    width: 60% !important;
    margin: 0px 32px 0 48px;
}

.labTestDtls #gridnew_docedit_achtrow input.lbTestDetlsItemName {
    width: 100% !important;
    margin: 0px 25px;
}

.patientAppontmnt.icon.iconCalendar.calendar.plcalendar svg {
    position: absolute;
    margin-left: 0;
    margin-top: 0;
    left: 95%;
    top: 10px;
    opacity: .6;
}

.ptFiles .uploadIcon {
    top: -5px;
    right: -5px;
}

.clinicContentBtn.ptFilesAddButton {
    background: #fff;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    padding-bottom: 10px;
}

.ptFiles .itemFolderIcon:after {
    border-bottom: none;
}

.ptFiles .itemFolderIcon {
    background: transparent;
}

.settingsHealthCard .rgstrnAddrs,
.settingsHealthCard .registrationCardContact,
.settingsHealthCard .cardInsructions .elipsis,
.settingsHealthCard .signatureDiv {
    font-size: 10px;
}

.settingsHealthCard .registrationCardWrap {
    width: 320px;
}

.settingsHealthCard .cardInsructions {
    margin-top: 20px;
}

.plAddPatient .formLabel sup,
.plAddPatient .plAppContent .formLabel sup {
    top: -1px;
    left: 1px;
    color: #333;
}

.patientList .patientSearchitem select,
.patientList .patientSearchitem input,
.patientList .patientSearchitem input::placeholder {
    font-size: 13px;
}

.lbOrderPrintMain .LborderdentalPrint {
    border: none;
}

.parameterTestName {
    text-align: left;
    width: 100%;
    float: left;
    margin-top: 15px;
}

.ptInvoiceAddMsg .treatmentCommonTxt1 {
    position: absolute;
}

.ptInvoiceAddDtpkr .react-datepicker-wrapper,
.ptInvoiceAddDtpkr .react-datepicker__input-container input {
    width: 100%;
}

.ptMedicalHistory .practiceManagementForm {
    margin: 4px 0 4px;
    border: none !important;
}

.lbOrderPrintMain .LborderdentalPrint.LborderdentalPrintBorderbtm {
    border-bottom: 1px solid #000;
}

#treatList .inVoiceAmount .inVoiceTotalAmount {
    font-weight: normal;
    font-size: 12px;
}

.treatmentPlanList.pl_treatmentDiv.pl_addTreatmnt .close_row.ptPaymentListRefundClose {
    position: relative;
    right: 0px;
    top: 0px;
    left: auto;
    float: right;
}

.ptpaymntRfundWrapper .treatmentPlanList.pl_treatmentDiv.pl_addTreatmnt table th:last-child,
.ptpaymntRfundWrapper .treatmentPlanList.pl_treatmentDiv.pl_addTreatmnt table td:last-child {
    padding-right: 15px;
}

.ptpaymntRfundWrapper .treatmentPlanList.pl_treatmentDiv.pl_addTreatmnt table .totalBalanceAmountField {
    padding-right: 20px;
}

.ptpaymntRfundWrapper .treatmentPlanList.pl_treatmentDiv.pl_addTreatmnt table tr {
    border-bottom: 1px solid #cbc5d4;
}

.ptpaymntRfundWrapper .treatmentPlanList.pl_treatmentDiv.pl_addTreatmnt table tr:last-child {
    border-bottom: none;
}


/* .webcamOpen img:hover span,
.webcamOpen:hover span {
    display: block!important;
    opacity: 1;
} */


/*  */

#scroll {
    position: fixed;
    right: 10px;
    bottom: 50px;
    background: rgba(0, 0, 0, 0.4);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    text-align: center;
    color: #fff;
    line-height: 37px;
    cursor: pointer;
    z-index: 10;
    display: none;
}

#scroll span {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -8px;
    margin-top: -12px;
    height: 0;
    width: 0;
    border: 8px solid transparent;
    border-bottom-color: #ffffff;
}

#scroll:hover {
    opacity: 1;
    filter: "alpha(opacity=100)";
    -ms-filter: "alpha(opacity=100)";
}

.footerfaAngleUp {
    font-size: 20px;
    margin: -2px 0;
}

.titleSection .borderButton {
    font-family: "Montserrat-SemiBold";
}

.plAddPatient .newAppoinmentRightList input::placeholder,
.plAddPatient .addFamilyMem input::placeholder,
.plAddPatient .profileForm input {
    font-size: 12px;
}

body .plAddPatient .borderButton,
body .plAddPatient .borderButtonCancel {
    font-family: "Montserrat-SemiBold";
}

.patientList .breadcrumbDoc ul li:last-child {
    font-weight: normal;
}

.plAppointmenPopup#formId .addDataHead {
    line-height: 15px;
}

.dflexend {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
}

.settingsRight .dashboardContent .dashboardSearch .dashboardSearchList select,
.settingsRight .dashboardContent .dashboardSearch .dashboardSearchList select option,
.settingsRight .dashboardContent .dashboardSearch .dashboardSearchList input::placeholder {
    font-size: 15px;
}

body .purchaselistWrapper .dashboardtable tr td a {
    color: #337ab7 !important;
    text-decoration: none !important;
}

.tramntPlanDpdwnWrap .groupBrdr .dentalProName,
.tramntPlanDpdwnWrap .groupBrdr .optionWrapper {
    display: inherit;
}

.tramntPlanDpdwnWrap .pl_actions {
    top: 0px;
}

.calenderRightSec .calenderTabTitle {
    float: left;
    padding: 10px 0px 0;
    font-size: 9px !important;
    text-align: center;
    font-family: "Montserrat-Regular";
    font-weight: 700;
    color: grey;
    width: 25%;
    margin-bottom: 3px;
    cursor: pointer;
}

.calenderRightSec .calenderTabTitle.active {
    color: #2d1152;
    border-bottom: 3px solid #3498db;
}

.userInfo {
    color: #000;
    background: #fff;
}

.DrInfo {
    background: #f0f0f5;
    padding: 4px
}

.DrInfo p {
    margin-bottom: 0px;
}

.pu_user_info>p {
    font-size: 12px;
    color: #393939;
    margin-bottom: 0px;
    word-break: break-all;
}

.calendarTooltip:after {
    border-style: solid;
    border-width: 11px 9.5px 0 9.5px;
    border-color: red transparent transparent transparent;
    top: auto;
    bottom: -11px;
    filter: drop-shadow(0px 0 0 rgba(0, 0, 0, 0.3));
    -webkit-filter: drop-shadow(0px 0 0 rgba(0, 0, 0, 0.3));
    -moz-filter: drop-shadow(0px 0 0 rgba(0, 0, 0, 0.3));
    -ms-filter: drop-shadow(0px 0 0 rgba(0, 0, 0, 0.3));
    -o-filter: drop-shadow(0px 0 0 rgba(0, 0, 0, 0.3));
}

.calendarTooltip {
    background: #fff;
    padding: 10px;
    max-width: 275px;
    /* display: none; */
    font-size: 14px;
    text-align: left;
    cursor: default;
    float: left;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    -ms-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    white-space: normal;
    color: #000;
    font-size: 12px;
    margin-bottom: -8px;
    height: auto;
    max-height: 280px;
    overflow: hidden;
    overflow-y: auto;
    /* position: absolute;
    right: 0;
    z-index: 9; */
}

.calendarTooltipOuter:after {
    content: "";
    margin: 2px 1.5em;
    display: inline-block;
    border: 9px solid transparent;
    border-top: 9px solid #fff;
    border-bottom: 0 none;
}

.calendarTooltipOuter {
    position: absolute;
    right: -12px;
    z-index: 9;
    bottom: 40px;
    display: none;
    /* width: 230px; */
}

.clndrAppmntRightBlock:hover .calendarTooltipOuter {
    display: block;
}

.priceLbl p {
    padding-top: 10px;
}

.procedureCatListWrap .proCatChechk {
    width: 32.222%;
    display: inline-block;
    vertical-align: top;
    float: none;
}

.practiceCheckbox-primary input[type="checkbox"]:checked+label::after {
    color: #fff;
}

input[type=checkbox]:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 11px;
    left: 16px;
    width: 4px;
    height: 10px;
    border: 1px solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.practiceManagementFormNew h3 {
    color: #077075 !important;
    font-size: 14px !important;
}

.caseshtMedAdd .clinicContentList .clinicalField input {
    width: 44%;
}

.caseshtMedAdd .clinicContentList .appointmentNumberSearchCSMed li {
    padding: 1px 5px;
}

.caseshtMedAdd .clinicalFieldSubTxt span li>span {
    position: relative;
    top: -5px;
    left: -5px;
}

.caseshtMedAdd .appointmentNumberSearchCSMed {
    min-width: 220px;
}

.caseshtMedAdd .clinicalLabel {
    font-family: "OpenSans-Regular";
}

.caseshtMedAdd small {
    font-family: "SourceSansPro-Regular";
}

.caseshtMedAdd .clinicalField select {
    width: 240px;
}

#appoinmentDateTime {
    width: 100%;
}

.breadcrumb-item:last-child {
    color: #bcbcbc;
}

.Appointmentjsx .appointmentPopupClose .popUpClose {
    top: -5px;
    font-size: 25px;
}

.Appointmentjsx .addDataHead.appointmentPopupClose {
    border-bottom: none;
    line-height: 10px;
    margin-bottom: 15px;
}


/* .Appointmentjsx .customizationPop .appointmentForm label {
    color: #737272;
} */


/* .Appointmentjsx .ploverlayy {
    background: #101419;
} */

.Appointmentjsx .customizationPop .newAppoinmentButton {
    padding-bottom: 0px;
}

.Appointmentjsx .formLabel sup {
    font-size: 100%;
    top: 0px;
    left: 0px;
    color: #333;
}


/* .receiptPrint table tbody {
    border-bottom: 1px solid #ddd;
} */

.printOutSettingContent table tbody tr:last-child,
.printOutFooterSec table tbody {
    border-bottom: 1px solid #ffffff;
}

.receiptprintInnerTable tbody {
    border-bottom: 1.1px solid #ddd;
}

.receiptprintInnerTable tbody tr td tr:last-child {
    border-bottom: 0px solid #fff !important;
}

.patientAppointmentWrapper .appoinmentDateDiv {
    width: 70px;
}

.tramntPlanDpdwnWrap .treatmentTable table thead tr th,
.completedPlansWrap .treatmentTable table thead tr th {
    padding: 4px 7px;
}

.tramntPlanDpdwnWrap .optionWrapper input[type="checkbox"]:checked+label::after,
.completedPlansWrap .tramntPlanDpdwnWrap .optionWrapper input[type="checkbox"]:checked+label::after {
    left: 7px;
    top: 4px;
}

.aDDTreat .plannedBy input {
    width: 215px;
}

.addCmpltdln .treatmentPrice.addCompltedPlnQty {
    margin-left: 47px;
}

.addCmpltdln .treatmentTeethShow {
    margin-left: 54px;
    margin-top: -10px;
    margin-bottom: 5px;
}

.addCmpltdln .pl_drList.inventortDetailsCompltdplan {
    height: auto;
    position: absolute;
    min-height: auto;
    max-height: 60px;
    overflow-y: scroll;
    top: 18px;
    left: 90px;
    right: auto;
    width: auto;
    padding: 5px;
    min-width: 130px;
}

.inventryOuter {
    position: relative;
    display: flex;
    justify-content: flex-end;
}

.inventoryInfoWrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.patientPayment .dentalProName,
.patientPayment .dentalProCost,
.patientPayment .dentalProDis,
.patientPayment .dentalProTotal,
.patientPayment .dentalProNote {
    padding: 7px 7px;
    font-size: 11px;
    font-family: "Arial";
    padding-bottom: 0px;
    position: relative;
    top: -4px;
}

.patientPayment .dentalProNameTop span.elipsis {
    font-family: "OpenSans-Regular";
    font-size: 13px;
}


/* Login css */

#loginSection {
    width: 33%;
    float: right;
    position: absolute;
    right: auto;
    top: 48%;
    padding: 40px 20px 0px;
    border: 1px solid #e8e8e8;
    /* height: 320px; */
    height: auto;
    font-size: 13px;
    border-radius: 5px;
}

.loginSiteID section {
    position: relative;
    top: 330px;
}

.loginSiteID .loginForm {
    border: none;
}

body .loginSiteID {
    font-family: "OpenSans-Regular";
    width: 100%;
    /* height: 100vh; */
    min-height: 100vh;
    height: auto;
}

.loginOuter,
.loginSiteID .contentHead,
.loginSiteID .contentSubHead {
    /* font-family: "Montserrat-Regular"; */
    font-family: "Montserrat-Light";
}

.loginSiteID .contentSubHead {
    font-size: 24px;
    font-weight: 300;
    padding: 0px 0 0px;
    color: #2b2b2b;
    text-align: center;
    text-transform: uppercase;
    display: block;
}

.loginOuter .contentHead span {
    padding-top: 50px;
}

.loginOuter .contentHead {
    padding-top: 80px;
    padding-bottom: 20px;
}

.loginOuter .coverWrap {
    width: 100%;
    clear: both;
    overflow: hidden;
    position: relative;
    top: 330px;
    margin-bottom: 330px;
}

.loginOuter .headerText h2 {
    font-weight: normal !important;
    font-family: "Montserrat-Regular";
}

.loginOuter .loginList label {
    font-family: "SourceSansPro-Regular";
}


/* .loginOuter .containerNew {
    width: 100%;
} */

.loginOuter .buttonDiv a {
    font-family: "Montserrat-Regular";
}

.firstLeft ul li {
    color: #000000;
    font-size: 20px !important;
    margin: 8px 0;
    position: relative;
    font-weight: 500;
    line-height: 45px;
    font-family: "Montserrat-Regular" !important;
}

.loginOuter #loginDiv {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0;
}

.loginOuter .fourth .secondRight {
    width: 100%;
    padding: 0;
}


/*  */


/* .blockCalendarPopupSec{
    padding: 20px;
} */

.blockCalendarPopupSec span.popUpClose {
    top: 0px;
    font-size: 27px;
    color: #393939;
}

.blockCalendarPopupSec .newAppoinmentRightList {
    width: 47%;
    margin-bottom: 15px;
    padding-left: 0px;
}

.blockCalendarPopupSec .newAppoinmentRightList .appointmentFormList input::placeholder {
    font-size: 12px;
}

.blockCalendarPopupSec .addDataHead {
    border-bottom: none
}

.blockCalendarPopupSec .newAppoinmentRightList.dateSelect {
    width: 32%;
    margin: 0px;
}

.blockCalendarPopupSec .newAppoinmentRightList.timeSelect {
    width: 27%;
    padding: 0;
    margin: 0px;
}

.blockCalendarPopupSec .newAppoinmentRightList.toTxt {
    width: 30px;
    padding-left: 8px;
    margin-bottom: 0;
    vertical-align: unset;
    padding-top: 5px;
}

.blockCalendarPopupWrapper .modal {
    overflow-x: unset;
    overflow-y: unset;
}

.calAction {
    position: relative;
    top: -5px;
}

.calAction img {
    cursor: pointer;
}

body.modal-open {
    overflow-y: hidden;
}


/* Custom radio buttons */

.blockCalendarPopupSec input[type="radio"]+label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    margin-right: 15px;
    font-size: 12px;
    font-family: "Arial";
}

.blockCalendarPopupSec input[type="radio"]+label:before {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    margin-right: 14px;
    position: absolute;
    top: -3px;
    left: 0;
    border: 1px solid #aaa;
    background-color: #fff;
    border-radius: 50%;
}

.blockCalendarPopupSec input[type="radio"] {
    display: none !important;
    /* *display: inline; */
}

.blockCalendarPopupSec input[type="radio"]:checked+label:after {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #428bca;
}

.blockCalendarPopupSec .modalWrapper {
    padding: 10px 0;
}


/* popup Component css */

body.ReactModal__Body--open .ReactModalPortal .ReactModal__Overlay {
    background-color: #0000007a !important;
    z-index: 9999999 !important;
}

body.ReactModal__Body--open .ReactModal__Content {
    width: 100%;
    overflow: unset !important;
}

.loginSiteID .successFullLogin {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    position: relative;
    top: 430px;
}

.loginOuter .iconTab {
    width: 32.33%;
    margin: 0px 3px 45px;
}

.blockCalformInput {
    border: 1px solid #b4b4b4;
    background: #fff;
    height: 35px;
    padding: 0 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    font-size: 14px;
}

.plCalendarModal #newUsertextId {
    left: 110px;
    top: 4px;
}

.patientInfoList {
    position: absolute;
    background: #fff;
    top: auto;
    top: 65px;
    font-size: 10px;
    max-height: 175px;
    overflow: hidden;
    overflow-y: scroll;
    overflow: auto;
    border: 1px solid #c5c5c5;
    z-index: 999;
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    min-width: 260px;
}

.patientInfoList.patientInfoListName {
    top: 50px;
}

.patientInfoList div {
    border-bottom: 1px solid #e8e8e8;
    padding: 2px 8px;
    cursor: pointer;
    line-height: 18px;
}

.patientInfoList div:hover {
    background-color: #e8e8e8;
}


/*  */

.floating-label {
    position: relative;
}

.floating-input {
    padding-left: 10px !important;
    font-family: "SourceSansPro-Regular";
}

.floating-input:focus {
    outline: none;
    border-bottom: 2px solid #8200ff;
}

.floating-label label {
    color: #8c8c8c;
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.floating-input:focus~label,
.floating-input:not(:placeholder-shown)~label {
    top: -20px;
    font-size: 14px;
}

.forgotLink a {
    font-family: "OpenSans-Regular";
}

#Errmessagebloc {
    margin-bottom: 30px;
    font-family: "OpenSans-Regular";
}

.pu_modal_content.plCalendarModal .form-group {
    margin: 0 -3px 5px;
}

.fc-timegrid-event-harness>.fc-timegrid-event {
    color: #fff;
    font-size: 11px;
    padding: 1px;
    font-family: "Arial";
}

.patientHealthcrd.ptntHlthCrd .pl_addTreatSection .treatmentPlanWrap {
    padding-right: 15px;
}

.specializationSelection {
    right: 110px;
    top: 37px;
    z-index: 1;
    /* z-index: -1; */
}

.specializationSelection {
    position: absolute;
    /* top: 50%; */
    top: auto;
    right: 10px;
    transform: translateY(-30%);
    -webkit-transform: translateY(-30%);
    -moz-transform: translateY(-30%);
    -ms-transform: translateY(-30%);
    -o-transform: translateY(-30%);
    cursor: pointer;
}

.specializationSelection .specializationClk {
    color: #fff;
}

.specializationSelection .treatmentDrNameList {
    position: absolute;
    width: 300px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    top: 60px;
    padding: 2px 0;
    display: none;
    z-index: 1;
    right: 0px;
    font-weight: normal;
}

.specializationSelection .treatmentDrNameList ul li span {
    display: block;
    color: #2d1152;
}

.specializationSelection .cartClk {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    border-color: #2d1152 transparent transparent transparent;
    display: inline-block;
    margin-left: 9px;
    vertical-align: top;
    margin-top: 9px;
    border-color: #fff transparent transparent transparent;
}

.specializationSelection .specializationClk {
    color: #fff;
    text-align: right;
    /* padding-top: 10px; */
    font-weight: normal;
    font-size: 14px;
    position: relative;
    top: -6px;
}

.specializationSelection .headerRightList span {
    font-weight: normal;
}

.specializationSelection .treatmentDrNameList ul li {
    display: block;
    padding: 9px;
    margin: 0;
    height: auto;
}

.specializationSelection .treatmentDrNameList ul a {
    font-size: 14px;
}

.adminLoginHospital {
    color: #fff;
    display: inline-block;
    font-size: 12px;
    vertical-align: middle;
    padding-top: 14px;
    padding-right: 10px;
    font-weight: 600;
    pointer-events: none;
}

.adminLoginHospital {
    color: #fff;
    display: inline-block;
    font-size: 12px;
    vertical-align: middle;
    padding-top: 14px;
    padding-right: 10px;
    font-weight: 600;
    pointer-events: none;
}

.adminbackHospital {
    pointer-events: auto;
    position: absolute;
    font-size: 11px;
    bottom: -20px;
    padding: 0;
}

.adminbackHospital a {
    color: #fff
}

.adminbackHospital a {
    color: #fff;
    border-bottom: 1px solid #f1f1f1;
    text-decoration: none;
}

.rgstrnAddrs {
    font-size: 7px !important;
    color: #fff;
    float: left;
    padding: 1px 0 2px 5px;
    width: 90%;
    line-height: 11px;
}

.cardInsructions ul {
    min-height: 115px;
    max-height: 100%;
}

.calendarListHeading {
    background: #eee;
    display: flex;
    padding: 8px 14px;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    color: #222;
    font-weight: 600;
}

.calendarListWrap {
    border: 1px solid #ddd;
}

.calendarListRow {
    display: flex;
    border-bottom: 1px solid #ddd;
    color: #222;
    justify-content: left;
    align-items: center;
}

.calendarTime {
    padding: 8px 14px;
    cursor: default;
    background: #3498db;
    color: #fff;
    padding: 8px !important;
    /* min-width: 145px; */
    width: 100%;
}

.calendarContent {
    padding: 8px 0px;
    width: 100%;
    cursor: default;
    font-size: 13px;
}

.calendarTag {
    padding: 8px 0px 8px 2px;
    cursor: default;
}

.calendarTagSpan {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    cursor: default;
}

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
    padding-right: 63px;
}

.fc-list-button {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    font-size: 1em;
    line-height: 1.5;
    border-radius: 0 0.25em 0.25em 0;
    color: #000 !important;
    border: 1px solid #ddd !important;
    background-color: #fff !important;
    padding: 4px 20px !important;
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 0;
    cursor: pointer;
    height: 33px !important;
}

.fc .fc-toolbar.fc-header-toolbar {
    height: 42px;
}

.calenderSectionWrap {
    position: relative;
}

.fc-list-button.fc-button-active {
    border-bottom: 3px solid #2f77c7 !important;
    top: 6px;
    height: 31px;
}

.calenderFull .react-datepicker-popper[data-placement^=bottom] {
    z-index: 999;
}

.searchDataSection {
    position: absolute;
    left: 0;
    top: 5px;
    width: 95%;
}

.pl_casesheetWrap .plcasesheet {
    top: 3px;
    right: -1px;
}

.patientPayment.pl_casesheetWrap .dentalProNote {
    padding: 7px 7px;
    font-size: 11px;
    font-family: "Arial";
    padding-bottom: 0px;
    position: relative;
    top: -1px;
}

.pl_casesheetWrap .dentalProNote.caseSheetMdclDpdwn {
    right: -2px;
}

.pl_casesheetWrap.plMdcl .plcasesheet {
    top: 0px;
    right: -1px;
}


/* Accordion css begins here */


/* .simplifiedAccordion .accordion__button:before {
     display: inline-block;
    content: '+';
    border: none;
    transform: none;
    font-size: 23px;
    font-weight: bolder;
    color: #000;
    box-shadow: 1px 1px 5px #d2d2d2;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    height: 25px;
    width: 30px;
} */

.simplifiedAccordion button {
    display: inline-block;
    border: none;
    transform: none;
    font-size: 23px;
    font-weight: bolder;
    color: #000;
    box-shadow: 1px 1px 5px #d2d2d2;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    height: 25px;
    width: 30px;
    position: absolute;
    margin-right: 10px;
    background-color: #fff;
}

.simplifiedAccordion .accordion__button {
    margin-bottom: 10px;
}

.simplifiedAccordion .accordion__button[aria-expanded='true']::before,
.simplifiedAccordion .accordion__button[aria-selected='true']::before {
    content: '-';
    transform: rotate(0deg);
    font-size: 30px;
}

.simplifiedAccordion .accordion__button {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    background: #fff;
    padding: 3px 0px;
    font-weight: 600;
    color: #808080;
}

.simplifiedAccordion .accordion__button:hover {
    background-color: #fff;
}

.simplifiedAccordion .accordion__panel {
    padding: 0px;
    background: #ffff;
}

.simplifiedAccordion .accordion__item+.accordion__item {
    border-top: 10px solid #ececec;
}

.clinicContentList .clinicalDesField textarea,
#txtFollowNote {
    height: 100px;
    min-height: 100px;
}

.simplifiedAccordion .accordion__button .clinicContentList {
    border-bottom: none;
}

.simplifiedAccordion .clinicalField select {
    width: 50%;
}

.simplifiedAccordion .clinicFollowUp .clinicContentList:last-child {
    margin-bottom: 10px;
}

.simplifiedAccordion .clinicFollowUp {
    padding: 10px 0;
}

.simplifiedAccordion .followUpTxt {
    position: relative;
    top: 5px;
}

.simplifiedAccordion .prescriptionTrtmntListItem ul {
    padding: 0px;
}

.simplifiedAccordion label {
    display: inline-block;
    font-family: "Arial";
    font-size: 13px;
    color: #000;
}

.simplifiedAccordion .liFrequency input {
    position: relative;
    top: -3px;
}

.simplifiedAccordion .inputField {
    border: 1px solid #f2f2f2;
    height: 35px;
    border-radius: 0px;
}

.simplifiedAccordion .dAlignCenter {
    display: flex;
    align-items: end;
    justify-content: center;
    position: relative;
    top: -8px;
}

.simplyCommentSec {
    width: 100%;
    padding: 8px 10px;
    border-top: 2px solid #ccc;
    float: left;
    background: #fff;
}

.newAppoinmentRightList.ListWrap {
    width: 50%;
    padding-right: 0;
}

.ListWrap .newAppoinmentButton {
    padding: 20px 0;
}

.caseSheetMdclDpdwn.caseSheetMdclHistoryDpdwn {
    padding: 0px;
    position: relative;
    top: 0px;
    right: -1px;
}


/* .prefixDateWrap input[type=checkbox]:checked+label:hover:after {

    opacity: .3;
       content: '';
    position: absolute;
    width: 12px;
    height: 7px;
    background: transparent;
    top: 12px;
    left: 6px;
    border: 3px solid #5f5f5f;
    border-top: 0;
    border-right: 0;
    transform: rotate(-45deg);
    color: #000;

} */

.practiceContentText .checkSection input[type="checkbox"]:checked+label {
    border-color: #fff;
    background: #0075ff;
    color: #fff;
    width: 15px;
    height: 15px;
    border-radius: 3px;
}

.practiceContentText .checkSection label:before {
    content: "";
    width: 5px;
    height: 9px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(38deg);
    position: absolute;
    left: 4px;
    top: 1.3px;
}

.practiceContentText .checkSection label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    background: transparent;
    border-color: #000;
    color: #fff;
    width: 14px;
    height: 14px;
    border-radius: 3px;
    border: 1px solid #767676;
}

.consultpatient {
    clear: both;
    text-align: right;
    color: #fff;
}

body .consultpatient a {
    background: #5b791c;
    margin: 0;
    padding: 1px 12px;
    /* font-family: "Arial"; */
    font-weight: 500;
    cursor: pointer;
    font-family: "OpenSans-Bold";
}

.item-meta ul .consultpatient a,
.consultpatient a {
    background: rgba(113, 6, 0, 1);
    padding: 4px 13px;
    color: #fff;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    margin-top: 4px;
    font-weight: 600;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    margin-left: 5px;
}

.printOutHeaderSec table td,
.printOutSettingContent th,
.printOutSettingContent td {
    font-family: "Arial";
    color: #000 !important;
    /* vertical-align: text-top;  */
    vertical-align: middle;
    word-break: break-word;
}

.casesheetMedicalPrintSec .treatmentComplaintRowWrap {
    width: 100%;
}

.casesheetMedicalPrintSec span.treatmentTAgDate {
    text-align: left;
}

.ptInvoiiceAddPlus {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;
}

.plLabOrderChbx .optionWrapper input[type="checkbox"]:checked+label::after {
    margin-top: -8px;
}

.ptInvoiiceAddWrap .total_info p {
    font-size: 12px;
}

.plbottomBlock {
    padding-right: 25px;
}

.paymentSecContent.invoicePaymntSecContent_PTour {
    font-size: 11px;
    padding: 10px 15px;
    background: #efefef;
}

.paymentSec.payableInfo {
    width: 100%;
    float: left;
    background: #efefef;
}

.paymentSec.payableInfo .paymentSecBtm {
    padding: 6px 5px;
    border: 1px dashed #8a8a8a;
    border-left: 0;
    border-right: 0;
    font-size: 12px;
    clear: both;
    overflow: hidden;
}

.paymentSec.payableInfo .ContentBtmLabel {
    padding-left: 10px;
    padding-top: 5px;
    float: left;
}

.paymentSec.payableInfo .paymentSecBtnBtm {
    float: right;
}

.paymentSec.payableInfo .paymentSecLst {
    margin-bottom: 6px;
    clear: both;
    overflow: hidden;
}

.ptInvoiiceAddWrap .treatmentPrice {
    float: left;
    width: 140px;
    margin-left: 70px;
}

.ptInvoiiceAddWrap .dentalProCost {
    width: 80px;
}

.ptInvoiiceAddWrap .dentalProDis {
    padding-left: 25px;
}

.calendarListButton {
    width: 115px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 12px;
}

.calendarListButton button {
    border: 2px solid #2d1152;
}

.calendarListButton .plpaynow {
    border-radius: 0px;
    cursor: pointer;
}

.listStatus {
    min-width: 135px;
    float: right;
    text-align: left;
    color: #4e4e4e;
    text-decoration: none;
    display: block;
    outline: 0 !important;
    font-size: 12px;
    cursor: pointer;
    margin: 2px 0;
    cursor: default;
}

.profileDetailList.pregnancyInfo span.childrenDetails {
    float: right;
    width: 75%;
    padding-left: 15px;
    font-size: 13px;
    position: relative;
}

.profileDetailList.pregnancyInfo span.childDetailShow {
    width: 100%;
    padding: 0;
}

.profileDetailList.pregnancyInfo span.childDetailTop {
    width: 100%;
    padding: 0;
    border-bottom: 1px solid #ddd;
    margin-bottom: 7px;
}

.profileDetailList.pregnancyInfo span.childNameShow {
    width: 45%;
    padding: 3px 5px 1px 1px;
}

.profileDetailList.pregnancyInfo span.childDob {
    width: 25%;
    padding: 0;
}

.profileDetailList.pregnancyInfo span.childVaccination {
    width: 30%;
    padding: 0;
}

.gynecologyContentDiv {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    position: relative;
    display: inline;
}

.prgnancyDetailsWrap {
    position: relative;
    left: -26%;
    width: 785px;
    float: left;
}

.prgnancyDetailsWrap .react-datepicker-wrapper {
    width: auto;
    float: left;
}


/*  */

.profileAccordion .accordion__button:before {
    display: inline-block;
    content: '+';
    border: none;
    transform: none;
    font-size: 23px;
    font-weight: bolder;
    color: #000;
    display: flex;
    justify-content: left;
    align-items: center;
    position: absolute;
    right: 5px;
}

.profileAccordion .accordion__button[aria-expanded='true']::before,
.profileAccordion .accordion__button[aria-selected='true']::before {
    content: '-';
    transform: rotate(0deg);
    font-size: 30px;
}

.profileAccordion .accordion__button {
    display: flex;
    align-items: center;
    justify-content: left;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    background: #fff;
    padding: 3px 0px;
    font-weight: 600;
    color: #808080;
    position: relative;
}

.homeSpecialitycheck .checkSection label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    background: transparent;
    border-color: #000;
    color: #fff;
    width: 14px;
    height: 14px;
    border-radius: 3px;
    border: 1px solid #767676;
}

.homeSpecialitycheck .checkSection label:before {
    content: "";
    width: 5px;
    height: 9px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(38deg);
    position: absolute;
    left: 4px;
    top: 0.3px;
}

.practiceManagementList .homeSpecialitycheck .checkSection {
    display: inline-block;
    margin: 2px 0 0;
}

.homeSpecialitycheck .checkSection input[type="checkbox"]:checked+label {
    background: #0075ff;
}

.practiceManagementList .homeSpecialitycheck .checkSection {
    float: left;
}


/* c css 2 */

.calendarListButton {
    width: 370px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 12px;
}

.calendarTime {
    padding: 8px 0px;
    float: left;
}

.calendarListButton {
    width: 265px;
}


/* c css2  */

.calendarListButton button {
    margin-left: 5px;
    color: #3498db;
    border: none;
    cursor: pointer;
    background: none;
}

.listStatus img {
    width: 15px;
    margin-right: 5px;
    margin-top: -5px;
}

.__react_component_tooltip {
    margin-top: 2px !important;
    cursor: default;
}

.plAlerts input[type=checkbox]:checked+label:after {
    visibility: hidden;
}

.todaysAppointment {
    float: left;
    cursor: pointer;
    font-family: "Montserrat-SemiBold";
    position: relative;
    z-index: 999;
    margin-left: 0px;
    margin-right: 10px;
}

.appointmentTypeStatus {
    padding-left: 15px;
    width: 100%
}

.plpaynow.appointmntListViewayNow {
    font-size: 13px;
    font-family: "Montserrat-SemiBold";
}

.__react_component_tooltip {
    z-index: 9999 !important;
}

.healthRecordWrapper {
    height: auto;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 15px;
}

.healthRecordWrapper .HealthRecordThumb img {
    width: 100%;
    height: 100%;
}

.healthRecordWrapper .HealthRecordThumb {
    width: 75px;
    height: 75px;
    float: left;
    margin: 10px;
    border: 1px solid #ddd;
    padding: 5px;
    position: relative;
    cursor: pointer;
    margin-left: 0px;
}

.printDescription {
    padding-left: 6px;
}

.noDataFound.noMedication {
    margin: 0px auto;
    width: 100%;
    font-size: 14px;
    border: none;
}

.practiceManagementForm.noMedicationWrap {
    margin: 0;
}

.newAppoinmentRightList.ListWrap {
    width: 91%;
    position: absolute;
    margin-top: 15px;
}

.timingList {
    height: auto;
    max-height: 300px;
    overflow-y: auto;
    display: block;
    text-align: left;
    width: 100%;
    font-weight: 500;
    font-family: "Arial";
    overflow-x: hidden;
    position: relative;
    top: 50px;
    margin-bottom: 60px;
}

.borderButton.createTimeslot {
    position: relative;
    top: -20px;
}

.calAction.closeTime {
    position: relative;
    top: -6px;
    left: -15px;
}

.timingList input {
    width: 100%;
    height: auto;
    background: #ffffff;
    border: none;
    font-size: 12px;
    padding: 2px 0px;
    border-radius: 5px;
    outline: 0;
    margin-bottom: 0px;
    pointer-events: none;
    -webkit-user-select: none;
    user-select: none;
}

.timingList .timingListItem {
    border-bottom: 1px solid #ddd;
    width: 99%;
}

.timeStatus {
    min-width: 90px;
    font-weight: 600;
    font-family: "SourceSansPro-Regular";
}

.timeStatusLabel {
    font-size: 12px;
    font-weight: 600;
    font-family: "SourceSansPro-Regular";
}

.newAppoinmentRightList.ListWrap.createSect {
    width: 91%;
    position: absolute;
    margin-top: 15px;
    /* background: #ffffff; */
    /* height: 30px;
    top: 30px; */
}

.slotInfoListAdded {
    width: 100%;
    height: auto;
    max-height: 200px;
    overflow: auto;
}

.incomeexpenseTble table.incomeExpnsTbl {
    table-layout: fixed;
}

.incomeexpenseTble table.incomeExpnsTbl td {
    font-size: 14px;
    vertical-align: middle;
}

.add_treatment_plan .plannedBy input,
.add_treatment_plan .plannedBy input::placeholder {
    font-size: 13px;
    font-family: "SourceSansPro-Regular";
    color: #828282;
    padding-left: 3px;
}

.add_treatment_plan .plannedBy input[type="text"] {
    border: 1px solid #989898;
}

#doctorListSection {
    left: auto;
    margin-left: -5px;
}

.taskDate {
    margin-left: -5px;
}

.teethImgHead span.teethErrMsg {
    position: absolute;
    top: 7px;
    right: 41%;
    font-size: 12px;
}

.langAddOption .proAddLangInput input {
    width: 97% !important;
    margin-left: 12px;
    margin-bottom: 8px;
}

.form-group.proAddLanguage {
    position: relative;
    left: -20px;
    top: -3px;
}

.proAddLangInput input {
    width: 90% !important;
}

.proRemoveLanguage {
    position: relative;
    left: 11px;
    top: -5px;
}

.doctorSearchFieldSec.drnameCalendar label {
    display: block;
    text-align: left;
    margin: 0 0 3px;
    width: 100%;
    font-weight: 500;
    font-family: "Arial";
    font-size: 12px;
}

.doctorSearchFieldSec.drnameCalendar {
    position: relative;
    top: 10px;
    width: 92%;
}

.doctorSearchFieldSec.drnameCalendar .doctorSearchIcon {
    top: 30px;
}

.patientInfoList.appointmentNumberSearch {
    top: 58px;
    min-width: 260px;
}

.patientInfoList.appointmentNumberSearch div {
    border-bottom: 1px solid #e8e8e8;
    padding: 5px;
    font-size: 12px;
    text-transform: capitalize;
}

.newAppoinmentButton.viewAllBtnClandr .borderButton {
    position: absolute;
    right: 0;
    top: -15px;
    z-index: 999;
}

.noDataMsg {
    color: red;
    text-transform: capitalize;
    font-size: 12px;
    margin-top: 8px;
}

.reasonForBlock {
    background: #ffffff;
    border: none;
    font-size: 12px;
    padding: 2px 0px;
    outline: 0;
    margin-bottom: 0px;
    pointer-events: none;
    user-select: none;
    width: 142px;
}

.doctorSearchFieldSec.drnameCalendar input,
.doctorSearchFieldSec.drnameCalendar input::placeholder {
    font-weight: 500;
    font-family: "Arial";
    font-size: 12px;
}

.calenderGoogleForm {
    width: 45%;
    margin: auto;
    padding: 50px 0 20px;
}

.calenderGooglehead {
    clear: both;
    overflow: hidden;
    margin-bottom: 30px;
    font-size: 18px;
    color: #393939;
    font-family: "Montserrat-SemiBold";
}

.calenderGoogleList {
    clear: both;
    overflow: hidden;
    margin-bottom: 10px;
    font-size: 13px;
}

.calenderGoogleList label {
    width: 25%;
    float: left;
}

.calenderGoogleList select {
    width: 75%;
    float: left;
    padding: 0 10px;
    border: 1px solid gainsboro;
    height: 35px;
}

.calenderGoogleList .checkbox {
    margin-bottom: 0;
    display: flex;
}

.calenderGoogleList input[type="checkbox"] {
    margin-right: 10px;
}

.calenderGoogleList .checkbox label {
    width: 100%;
}

.checkbox-warning input[type="checkbox"]:checked+label::before {
    background-color: #0d9fff;
    border-color: #0d9fff;
}

.calenderGoogleList .checkbox label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    left: 0;
    margin-left: -20px;
    border: 1px solid #000;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    background-color: #fff;
    -webkit-transition: border .15s ease-in-out, color .15s ease-in-out;
    -o-transition: border .15s ease-in-out, color .15s ease-in-out;
    transition: border .15s ease-in-out, color .15s ease-in-out;
}

.calenderGoogleList .checkbox input[type="checkbox"]:checked+label::after {
    font-family: 'FontAwesome';
    content: "\f00c";
    top: 2px;
}

.checkbox-warning input[type="checkbox"]:checked+label::after {
    color: #fff;
}

.calenderGoogleBtn {
    clear: both;
    overflow: hidden;
}

.calenderGoogleBtn input {
    float: right;
}

.checkerButton {
    display: block;
    background: 0;
    border: 1px solid green;
    color: #393939;
    border-radius: 5px;
    padding: 8px;
    cursor: pointer;
    outline: 0;
    margin: auto;
    margin-bottom: 10px;
}


/*  */

.exportCalndrSec .modalDialog {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 99999;
    opacity: 0;
    -webkit-transition: opacity 100ms ease-in;
    -moz-transition: opacity 100ms ease-in;
    transition: opacity 100ms ease-in;
    pointer-events: none;
}

.exportCalndrSec .modalDialog:target {
    opacity: 1;
    pointer-events: auto;
    overflow-y: scroll;
}

.exportCalndrSec .modalDialog>div {
    max-width: 600px;
    width: 90%;
    position: relative;
    margin: 10% auto;
    padding: 20px;
    border-radius: 3px;
    background: #fff;
}

.exportCalndrSec .close.exportClose {
    position: absolute;
    right: 20px;
    color: #000;
    text-decoration: none;
}

.exportCalndrSec ul {
    list-style: none;
    padding-left: 0px;
}

.exportCalndrSec .modal-header {
    padding-top: 0px;
}


/* Patient listing data not found */

.patientList .practiceManagementForm {
    border: none !important;
    padding: 0px 0px;
}

.patientList .practiceManagementForm .noDataFound {
    display: inline-block;
    border: none;
    color: #444;
    border-radius: 7px;
    font-size: 14px;
    width: 50%;
    margin: 0px;
    text-align: left;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0;
}

.__react_component_tooltip {
    /* padding: 0px!important; */
    width: 300px !important;
    -webkit-box-shadow: 0 0 7px #b7b7b7;
    box-shadow: 0 0 7px #b7b7b7;
}

.__react_component_tooltip.place-bottom::before {
    margin-left: 18px;
}


/* =============== HOSPITAL LAB CSS BEGINS =============== */

.headerHospitalLab {
    background: #0096d6;
    width: 100%;
    height: 80px
}

.userDropDiv.userDropDivLab {
    min-width: 160px;
    background: #0096d6;
    margin-top: 5px;
}

.userDropDiv.userDropDivBilling {
    min-width: 160px;
    background: #e87070;
    margin-top: 5px;
}

.userDropDiv.userDropDivPharmacy {
    min-width: 160px;
    background: #177f40;
    margin-top: 5px;
}

.userDropDiv li a:hover,
.userDropDiv li a:focus,
.userDropDiv span li a:hover,
.userDropDiv li span:hover,
.userDropDiv li span:focus {
    background: none !important;
    color: #cbcbcb !important;
}

.lablogginedUser {
    font-size: 12px;
    padding: 0px 15px;
    color: #464646;
    font-weight: 600;
    font-family: "Montserrat-Regular";
}

.hospitalLabWraper .dashButtonBorder.labButton {
    color: rgb(255, 255, 255);
    border: none;
    background: #10bae6;
    padding: 15px 15px;
    font-size: 13px;
    height: auto;
    border-radius: 0;
}

.hospitalLabWraper .dashboardTitle {
    /* font-family: "SourceSansPro-Regular"; */
    font-size: 13px;
    font-weight: 600;
    text-shadow: none;
    letter-spacing: .2px;
}

.hospitalLabWraper .inputField,
.hospitalLabWraper .dashButtonBorder.labButton {
    border-radius: 0px;
}

.hospitalLabWraper .dashboardContent {
    background: #ffff;
    float: left;
    width: 100%;
    padding: 15px;
    margin-top: 10px;
    padding-top: 0;
}

.hospitalLabWraper .clrBlack img {
    width: 18px;
    cursor: pointer;
}

.hospitalLabWraper .treatmentPlanDate {
    font-weight: 600;
}

.hospitalLabWraper .planActive tbody:before {
    top: 28px;
}

.hospitalLabWraper.exportCalndrSec .modalDialog>div {
    max-width: 70%;
    margin: 1% auto;
}

.hospitalLabWraper input[type=checkbox]:checked+label:after {
    top: 4px;
}

.hospitalLabWraper .labTitle {
    font-size: 15px;
    font-weight: 600;
    color: #545454;
}

.hospitalLabWraper .close.exportClose {
    right: 33px;
    padding: 6px;
    position: absolute;
    z-index: 999;
}

.hospitalLabWraper .patientName label {
    font-size: 13px;
    padding-right: 8px;
}

.hospitalLabWraper .patientName span {
    font-size: 13px;
}

.hospitalLabWraper .patientName {
    font-size: 13px;
    display: inline-block;
    color: #272727;
}

.hospitalLabWraper .patientNameDiv {
    margin-bottom: 10px;
}

.hospitalLabWraper .treatmentTable table td span {
    font-size: 12px;
    text-transform: uppercase;
}

.hospitalLabWraper .addDataContent .serviceProviderBtns {
    text-align: right;
    padding: 20px 0 0;
}

.hospitalLabWraper .serviceProviderBtns a,
.hospitalLabWraper .serviceProviderBtns input[type="submit"] {
    display: inline-block;
    padding: 7px 30px;
    background: #2d1152;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
    border: none;
    margin-left: 8px;
    font-size: 14px;
}

.hospitalLabWraper .treatmentTable table td {
    vertical-align: middle;
}

.hospitalLabWraper .treatmentTable table td .plannedBy span {
    text-transform: none;
    font-size: 11px;
}

.hospitalLabWraper .dentalProName {
    padding: 5px 5px;
}

.serviceProviderVerfied {
    padding-top: 15px;
}

.serviceProviderVerfied span {
    font-weight: 600;
    font-size: 12px;
    display: inline-block;
    padding-right: 5px;
    color: #6a6a6a;
}

.serviceProviderVerfied input[type="text"] {
    border: 1px solid #cbcbcb;
    font-size: 13px;
    padding: 3px 5px;
    width: 175px;
    color: #525252;
    margin-right: 5px;
}

.hospitalLabWraper.labTestBillWrapper .dentalProName span.viewDetailLink a {
    text-transform: none !important;
}

.hospitalLabWraper .treatmentPlanList {
    height: auto;
    min-height: auto;
}

#friendlyNoteView,
#friendlyNoteSave {
    width: 95%;
    margin: 8px auto;
    /* display: flex; */
    min-height: auto;
    display: inline-table;
    border: none;
}

.profrndlynte .gridContent .pu_generalinfo .plmdlbtn a,
.profrndlynte .gridContent .pu_generalinfo .plmdlbtn a:hover {
    margin-right: 8px;
    margin-bottom: 8px;
}

.__react_component_tooltip.show {
    opacity: 1 !important;
}

.plSubMenu.plLabLeftSubMenu {
    padding-right: 0;
    border-top: 1px solid #fff;
}

.displayFlxEnd {
    display: flex;
    justify-content: flex-end;
}

.hospitalLabOrder .searchTxt {
    font-size: 14px;
}

.hospitalLabOrder .paginationSection,
.hospitalLabOrder .pharmacyManufactureWrap .pagination,
.hospitalLabOrderView .pharmacyManufactureWrap .pagination {
    margin: 0px;
}

.hospitalLabOrderView .breadcumbDiv .breadcrumb {
    padding-left: 0;
}

.ptInvTble .invoiceInnerTableBr tr.procedureInvoiceDiv {
    display: flex;
    width: 100%;
}

.ptInvTble .invoiceInnerTableBr table tr:last-child {
    border-left: 2px solid #7dbf85;
}

.ptInvTble .invoiceWrapTable .dentalProName.dentalProNameTop img {
    width: 20px;
}

.ptInvTble .dentalProName.dentalProNameTop {
    display: inline-flex;
    justify-content: left;
    align-items: center;
}

.ptInvTble .invoiceWrapTable {
    border: 1px solid #afafaf;
}

.ptInvTble .inVoiceTotal input::placeholder {
    font-size: 11px;
    font-family: "SourceSansPro-Regular";
    color: #5a5a5a;
    font-weight: 600;
}

.ptInvTble .procedureInvoiceDiv.plInvFirstTblRw {
    width: 100%;
    padding: 0;
    background: #f3f3f3;
}

.ptInvTble span.ptInvTbleSpan {
    width: 89%;
    float: left;
    display: flex;
    align-items: center;
}

.ptInvTble .inVoiceTotal input[type="text"] {
    border-right: 0;
}

.ptInvTble .dentalProNote {
    padding: 8px 7px;
}

.ptInvTble .pl_print {
    padding: 3px 8px 4px 8px;
}

.ptInvTble .invoiceDivHeading.invoiceDivHeadName {
    font-size: 11px;
}

.ptInvTble .dentalProName .elipsis {
    font-family: "OpenSans-Regular";
}

.ptInvTble .inrSpan,
.ptInvTble .dentalProName .inVoiceTotal .inrSpan {
    font-family: "OpenSans-Regular";
}

.ptInvTble .treatmentTable table .dentalInvoiceAmount span {
    color: #5a5a5a;
}

.ptInvTble .inVoicePaid,
.ptInvTble .inVoiceBal {
    width: 32.333%;
}

.addPaymentWrap #treatList .inVoiceAmount .inVoiceTotalAmount {
    font-weight: 600;
    font-size: 14px;
}

.addPaymentWrap .inVoiceAmountList {
    padding: 5px 0px;
}

.addPaymentWrap .paymentSecContent select,
.addPaymentWrap .paymentSecContent input {
    height: 25px;
    font-size: 12px;
    font-weight: 600;
    width: 130px !important;
    padding: 2px 5px;
    border: 1px solid #9a9a9a !important;
    margin-left: 5px;
    color: #525252;
}

.addPaymentWrap .paymentSecContent input[type="text"] {
    width: 100px !important;
}

.treatmentPlanWrap.patientPaymentList {
    border: 1px solid #dadada;
    padding: 0;
    background: #fff;
}

.treatmentPlanWrap.patientPaymentList .dentalProNote,
.treatmentPlanWrap.patientPaymentList .dentalProName {
    font-family: "OpenSans-Regular";
    font-size: 12px;
    font-weight: 600;
    color: #868686;
}

.treatmentPlanWrap.patientPaymentList table td span {
    font-size: 11px;
}

.treatmentPlanWrap.patientPaymentList td {
    vertical-align: middle;
    padding: 5px 0px;
}

.treatmentPlanWrap.patientPaymentList .treatmentPlanList.pl_treatmentDiv.pl_addTreatmnt .close_row {
    left: 97.5%;
}

.treatmentPlanWrap.patientPaymentList .pl_treatmentDiv tr.groupBrdr .dentalProDis,
.treatmentPlanWrap.patientPaymentList .pl_treatmentDiv tr.groupBrdr .totalinr {
    color: #333;
}

.ptPymntCloseIcon {
    position: absolute;
    right: 19px;
}

.treatmentPlanWrap.patientPaymentList .pl_treatmentDiv tr.groupBrdr .dentalProDis {
    font-size: 12px;
}

.addPaymentWrap .pl_addTreatmnt .pl_treat tr {
    border-bottom: 1px solid #cecece;
}

.totalinr.balanceAmountTblList,
.BalanceAmntTh {
    padding-right: 20px;
    font-size: 12px !important;
}

.headerHospitalharmacy {
    background-color: #177f40;
}

.hosPharmacy .pharmacyLgnPrint a {
    color: #337ab7;
    cursor: pointer;
    font-size: 12px;
}

.hosPharmacy .pharmacyLgnPrint a:hover {
    text-decoration: underline;
    color: #337ab7;
}

.hospitalLabWraper.hosPharmacy .dentalProName {
    padding: 5px 5px;
    font-family: "OpenSans-Regular";
    font-size: 12px;
}

.hospitalLabWraper.hosPharmacy .searchTxt {
    margin: 0px -7px 0;
    font-size: 14px;
}

.hospitalLabOrder .searchTxt {
    font-size: 14px;
}

.hospitalLabWraper.hosPharmacy .dashboardtable table th {
    border: none;
    color: #333;
    font-size: 13px;
    font-family: "Montserrat-Regular";
}

.hospitalLabWraper.hosPharmacy .dashboardtable tr td span.refundReason {
    width: 90%;
}

.hosPharmacy .TextRed {
    color: red;
}

.medReOrder .dashButtonBorder {
    padding: 6px;
    font-size: 13px;
}

.headerHospitalLab.headerHospitalBill {
    background-color: #e87070;
}

.itemsReturnWrap {
    padding-top: 15px;
}

.setPermission .menuSelectSection .optionWrapper input[type="checkbox"]:checked+label::after {
    left: 6px;
    top: 6px;
}

.hospitalLabWraper.exportCalndrSec.medReOrder .modalDialog>div {
    max-width: 700px;
}

.hospitalLabWraper.medReOrder .close.exportClose {
    right: 15px;
}

.hospitalLabWraper.medReOrder .inputField,
.hospitalLabWraper.medReOrder .dashButtonBorder.labButton {
    border-radius: 5px;
}

.form-group.medCheckBox label:before {
    content: '';
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 2px solid #777;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 6px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
}

.form-group.medCheckBox input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 6px;
    width: 5px;
    height: 9px;
    border: solid #777;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.form-group.medCheckBox {
    display: block;
    margin-bottom: 15px;
}

.form-group.medCheckBox input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.form-group.medCheckBox label {
    position: relative;
    cursor: pointer;
}

.dashButtonBorder.MedReorderSubmit {
    border: 1px solid rgb(4, 107, 41);
    width: auto;
    position: relative;
    right: 15px;
    padding: 8px 15px;
    background: #046b29;
    color: #fff;
    font-weight: normal;
}


/* ============== */

.patientFilesDentalPopup span.popUpClose {
    font-size: 45px;
    background: #fff;
    color: #000;
    position: absolute;
    right: -220px;
    text-align: center;
    width: 40px;
    height: 40px;
    line-height: 40px;
    top: -15px;
    cursor: pointer;
}

.plpopup.plpopupList.patientFilesDentalPopup {
    width: 900px;
    padding: 5px;
    top: 20px;
}

.patientFilesDentalPopup .orthoView span {
    width: 198px;
    height: 198px;
}

.patientFilesDentalPopup .orthoView {
    width: 600px;
    height: 603px;
}

.patientFilesDentalPopup .beforeView {
    width: 440px;
    height: 300px;
    margin-bottom: 100px;
}

.plpopup.plpopupList.patientFilesDentalPopupGallery {
    background: transparent;
    border: none;
    transform: translate(0, 15%);
    top: auto;
}

.ploverlayy.patientFilesDentalPopupGalleryploverlayy {
    background: #000;
    z-index: 9999999;
    top: 0;
}

.ploverlayy.patientFilesDentalPopupGalleryploverlayy span.popUpClose {
    top: -30px;
    color: grey;
    font-size: 30px;
}

.ploverlayy.patientFilesDentalPopupOVERLAY {
    background: #333;
    z-index: 99999999;
    border-radius: 0px;
}

.historyInfoTbles table {
    table-layout: auto;
}

.InvoiceTr {
    border: none !important;
    position: relative;
    top: 10px;
}

.vitalSignsTable.vitalSignsItemsCvr.treatmentpadZero {
    width: 100%;
    float: left;
}

.ImageGalleryWrap {
    width: 100%;
    height: auto;
    float: left;
    background: #fff;
    text-align: center;
    padding: 25px;
}

.ImageGalleryWrap h3 {
    font-size: 15px;
    font-family: "OpenSans-Regular";
    font-weight: normal;
}


/*  */

.patientFilesDentalPopupGalleryploverlayy .triangle-left,
.patientFilesDentalPopupGalleryploverlayy .triangle-right {
    width: 0;
    height: 0;
    /* margin: 30px auto; */
}

.patientFilesDentalPopupGalleryploverlayy .triangle-left,
.patientFilesDentalPopupGalleryploverlayy .triangle-right {
    border-top: 15px solid #000;
    border-bottom: 15px solid #000;
}

.patientFilesDentalPopupGalleryploverlayy .triangle-right {
    border-left: 25px solid #fff;
    padding: 0;
    border-right: none;
    position: absolute;
    right: -310px;
    top: 30%;
    opacity: .65;
}

.patientFilesDentalPopupGalleryploverlayy .triangle-left {
    border-right: 25px solid #fff;
    padding: 0;
    border-left: none;
    position: absolute;
    left: -310px;
    top: 30%;
    opacity: .65;
}

.patientFilesDentalPopupGalleryploverlayy .triangle-left:hover,
.patientFilesDentalPopupGalleryploverlayy .triangle-right:hover {
    opacity: 1;
}

.patientFilesDentalPopupGalleryploverlayy .PopupGalleryIndicator {
    color: #fff;
    opacity: .65;
    text-align: right;
}

.patientFilesDentalPopupGalleryploverlayy .slick-slide img {
    display: block;
    width: 100%;
    max-height: 480px;
}

.InvoiceAddSection .plannedBy span {
    font-size: 14px;
    font-weight: 400;
}

.InvoiceAddSection .plannedBy input {
    width: 210px;
    margin-left: 5px;
}

.treatmentTable table td span.invoiceTretmentName {
    font-size: 14px;
    color: #333;
    font-weight: 600;
    font-family: "SourceSansPro-Regular";
}

.dentalProName.invoiceTowardsName {
    padding-left: 10px;
}

.generatedInfo {
    background: #e8e8e8;
    margin: -0.1%;
}

.treatmentCostTxt.totalDiscountINRInput {
    display: flex;
    justify-content: flex-end;
    float: right;
}

.treatmentCostTxt.totalDiscountINRInput input[type="text"] {
    width: 100px;
    padding: 2px 5px;
    border: 1px solid #bdbdbd;
    font-size: 13px;
}

.totalGreen {
    color: #008000;
}

.InvoiceAddSection .treatmentTags {
    padding: 1.3px 5px;
}

.InvoiceAddSection .pl_treatmentDiv .planActive tbody:before {
    border-left: 2px solid #a79ef3;
}

.InvoiceAddSection .pl_treatmentDiv .planActive tbody.LabTestTbody:before {
    border-left: 2px solid #ffa0a0;
}

.treatmentTags.procedureTreatmentTags {
    background: #7dbf85;
}

.treatmentTags.disposableTreatmentTags {
    background: #06d59c;
}

.InvoiceAddSection .pl_treatmentDiv .planActive tbody.procedureTbody:before {
    border-left: 2px solid #7dbf85;
}

.InvoiceAddSection .pl_treatmentDiv .planActive tbody.disposableTbody:before {
    border-left: 2px solid #06d59c;
}

.treatmentTags.procedureTreatmentTags:after {
    border-left: 10px solid #7dbf85;
}

.treatmentTags.disposableTreatmentTags:after {
    border-left: 10px solid #06d59c;
}

.react-tabs__tab--selected .procedureTab {
    border-bottom: 2px solid #7dbf85 !important;
    padding: 0px 21px;
}

.ptInvoiiceAddWrap .procedureTbody .treatmentPrice {
    margin-left: 70px;
}

.react-tabs__tab--selected .prescriptionsTab {
    border-bottom: 2px solid #a79ef3 !important;
    padding: 0px 16px;
}

.react-tabs__tab--selected .labOrdersTab {
    border-bottom: 2px solid #ffa0a0 !important;
    padding: 0px 21px;
}

.react-tabs__tab--selected .disposablesTab {
    border-bottom: 2px solid #06d59c !important;
    padding: 0px 19px;
}


/* .InvoiceAddSection .react-tabs__tab{
      padding: 6px 12px;
} */

.InvoiceAddSection .react-tabs__tab--selected {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 0px 0px;
    cursor: pointer;
    padding-top: 6px;
}

.InvoiceAddSection .react-tabs__tab--selected {
    border-color: #b1b1b1 !important;
    border-radius: 6px 6px 0 0 !important;
}

.InvoiceAddSection .react-tabs__tab:focus {
    box-shadow: none !important;
}

.InvoiceAddSection .react-tabs__tab {
    /* width:30%; */
    width: auto;
    text-align: center;
    color: grey;
    font-family: "OpenSans-Regular";
}

.changeImage.changeImgPro.AddPatientImgUpload {
    font-size: 13px;
    margin: 5px 40px;
    cursor: pointer;
}

.treatmentTable table tbody.MedicineTbody td {
    vertical-align: top;
}

.row.dentalProPlaned1.secondRow,
.row.dentalProPlaned.thirdRow {
    display: flex;
    flex-direction: row;
    flex-wrap: inherit;
    align-items: center;
    border-bottom: none !important;
}

.row.dentalProPlaned.thirdRow {
    border-radius: 0px;
    border: 1px solid gainsboro;
    height: 29px;
    font-size: 12px;
    margin-bottom: 30px;
}

.fulWidthSelect.frequencyUsage {
    height: 29px;
    font-size: 12px;
    background: #f2f2f2;
    border-color: #f2f2f2;
}

.totalinr.totalinrAmountView {
    padding-top: 5px;
    padding-right: 15px;
}

.InvoiceAddSection .liFrequency input {
    width: 35px !important;
    margin-right: 5px;
    border: 1px solid gainsboro;
    height: 29px;
    margin-top: 20px;
    padding: 7px;
}

.InvoiceAddSection tbody {
    border-bottom: 1px solid gainsboro;
}

body .InvoiceAddSection .row.dentalProPlaned.thirdRow .inputField {
    height: 29px;
    border: 1px solid gainsboro;
    border-radius: 0px;
}

body .InvoiceAddSection .plstartDate,
body .InvoiceAddSection .plstartDate::placeholder {
    padding: 10px 10px;
    height: 29px;
    border-radius: 0;
    border: 1px solid gainsboro;
}

.InvoiceAddSection .treatmentPlanList.pl_treatmentDiv.pl_addTreatmnt .close_row {
    top: 0px;
}

.InvoiceAddSection .treatmentNameList ul li .elipsis {
    width: 50%;
    padding-right: 10px;
}

.InvoiceAddSection .treatmentNameListRight {
    width: 50%;
}

.treatmentTags.laborderTreatmentTags {
    background: #ffa0a0;
}

.treatmentTags.laborderTreatmentTags:after {
    background: #fff;
    border-bottom: 8px solid transparent;
    border-left: 10px solid #ffa0a0;
    border-top: 8px solid transparent;
    content: '';
    position: absolute;
    right: -10px;
    top: 0;
}

.appointmentNumberSearch.bgBlueAutosearch li:hover {
    background: #0096d6 !important;
    color: #fff;
}

.appointmentNumberSearch.bgBlueAutosearch li {
    border: none !important;
}

.appointmentNumberSearch.bgBlueAutosearch {
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    border: 1px solid #c5c5c5;
}

#hospitalNameId {
    cursor: default;
}

.customDatePicker .react-datepicker__time-container {
    position: absolute;
    right: -85px;
    top: -1px;
    border-top: 1px solid #aeaeae;
    border-radius: 0 0.3rem 0.3rem 0;
    overflow: hidden;
    height: 241px;
}

.customDatePicker .react-datepicker {
    border-radius: 0.3rem 0 0 0.3rem;
}

.customDatePicker .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 0px;
}

.customDatePicker .react-datepicker__header react-datepicker__header--time {
    padding: 10px;
}

.customDatePicker .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    padding: 20px;
}

.plLabOrderChbx .treatmentTable .col-md-4 {
    padding: 5px 2px;
}

.plLabOrderChbx .invoiceTableCvrDiv tbody {
    border-left: 3px solid #3498db;
}

.plLabOrderChbx .dentalProNote.paddingFive {
    margin-top: 10px;
}

.row.dentalProPlaned.labTestBottom {
    display: flex;
    flex-wrap: inherit;
    border-bottom: none !important;
}

.treatmentNameList ul.labOrderUl li {
    display: flex;
}

.patientright.pl_pro.tabsRightMain .rightTagList {
    padding: 0 0px;
}

.patientright.pl_pro.tabsRightMain .treatmentNameList ul {
    border: 0px solid #ececec;
}

body .patientright.pl_pro.tabsRightMain .inputField {
    height: 29px;
    width: 95%;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    border-radius: 0px;
}

.patientright.pl_pro.tabsRightMain .rightTagList .react-tabs__tab-list {
    margin: 0 10px;
}

.noDataFoundCommon.treatmentCommonTxtWrap {
    position: relative;
    clear: both;
    overflow: hidden;
    height: 250px;
    background: #fff;
    margin: 10px 0 10px 10px;
}

.noDataFoundCommon .treatmentCommonTxt {
    display: block;
    text-align: center;
    font-size: 12px;
    color: #2d1152;
}

.noDataFoundCommon .treatmentCommonTxt span {
    padding: 15px 50px;
    display: inline-block;
    border: 1px solid #cbc5d4;
    color: #444;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    font-size: 16px;
}

#divClone span.childNameSec {
    float: none;
}

.childListDivAppendTable span.childNameSec {
    width: 96%;
}

.vaccineTxtWrap {
    position: relative;
    left: -70px;
}

.vaccineTxtWrap span.removeClose {
    position: relative;
    top: -4px;
}

.settingsHealthCard .registrationCardWrap {
    width: 320px;
    height: auto;
    float: left;
}

.settingsHealthCard .registrationCardContent {
    padding: 8px 8px;
}

.settingsHealthCard .cardDetailImg {
    height: 40px;
    width: 40px;
}

.labOrderMainWrap .labOdrDpdn .dentalProNote.paddingFive .dentalProNote {
    position: absolute;
    top: -5px;
    width: 70px;
}

.labOrderMainWrap .treatmentTable table tr td.col-md-3.lbOrderNotes {
    position: absolute;
    width: 17%;
    float: left;
}

.groupBrdr.trLists {
    position: relative;
    top: -8px;
}

.medReOrder .dashboardtable table {
    table-layout: fixed;
}

.closeRow img {
    display: flex;
    justify-content: flex-end;
    float: right;
}

.simplifiedForm .simplifiedAccordion .faq__content {
    border-top: 1px solid #cecece;
    float: left;
    width: 100%;
}

.simplifiedForm .simplifiedAccordion .faq__content .treatmentContentClose {
    top: -11px;
    right: -10px;
}

.simplifiedFormRightSec {
    position: relative;
    top: -53px;
    /* top: -106px; */
}

.simplifiedFormPrescriptionRightSec {
    position: relative;
    /* top: -116px; pharmcy lgn */
    top: -55px;
}

.simplifiedFormLabOrderRightSec {
    position: relative;
    top: -177px;
}

.simplifiedForm .rightSection.simplifiedLabrightSection {
    padding: 0px;
}

.treatmentPlanHead.EntryFormsSec {
    background: #ececec;
    width: 99%;
    margin: 0 auto;
}

.simWraper .treatmentPlanWrap {
    padding: 5px 10px 10px 10px;
}

.DisabledField {
    background-color: transparent;
}

.settingsHealthCard .cardDetailList {
    font-size: 10px;
    clear: both;
    overflow: hidden;
}

.settingsHealthCard .cardDetailList label {
    width: 18%;
    padding-top: 4px;
}

.labOrderHeadDiv {
    background: #ffffff !important;
    width: 99%;
    border: 10px solid #ececec;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-bottom: none;
    margin-bottom: 15px;
    border-bottom: 10px solid #ececec;
}

.labOrderHeadDivPlus {
    display: inline-block;
    border: none;
    transform: none;
    font-size: 23px;
    font-weight: bolder;
    color: #000;
    box-shadow: 1px 1px 5px #d2d2d2;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    height: 25px;
    width: 30px;
    margin-right: 0px;
    background-color: #fff;
}

.labOrderHeadDivPlusName {
    font-weight: 600;
    color: #808080;
    font-size: 14px;
    margin: 0;
    text-transform: none;
    width: 96%;
}

.simplifiedForm .treatmentPlanWrap {
    width: 99.5%;
}

#PrescriptionContentDiv {
    margin: 0px;
}

.simplifiedAccordion .casesheetInput button {
    background-color: transparent;
    box-shadow: none;
    height: auto;
}

#CasesheetContentDiv {
    padding-bottom: 0;
}

#CasesheetContentDiv .row.practiceManagementRow {
    margin-bottom: 15px;
}

.prescriptionMedList {
    height: 90px;
    overflow-y: scroll;
}

.treatmentNameList.treatmentPlanList.simplifiedDrugList {
    min-height: 125px;
    height: 125px;
}

.rightTagTitle.simplifiedRightHead {
    width: 100%;
    float: left;
    padding: 0px 5px;
}

.simplPrescriptionRightBg {
    background-color: red;
}

.procedureCatName.plTaxes.AddProcedureApplicableTax {
    float: left;
    width: 20%;
}

.specialityDetails .homeSpecialityList {
    width: 100%;
    padding: 0 15px 9px 0;
}

.pl_testdpdn1 {
    position: relative;
    right: 7px;
    top: -28px;
}

.InvoiceAddSection .treatmentTeethShow {
    position: absolute;
    margin-top: 25px;
}


/* .calenderWrap .__react_component_tooltip {
    padding: 10px!important;
} */

.settingMenuList ul li a.active {
    background-color: #a7a6a6;
    color: #fff;
    font-weight: normal;
}

.settingMenuList.labLeftMenuList ul li a.active {
    background: #d0cfcb !important;
    color: #464646;
    font-weight: 600;
}

.borderButtonLarge:focus-visible,
.borderButtonLarge:focus {
    outline: -webkit-focus-ring-color auto 1px;
}

#appointmentPatientReason {
    height: 50px;
}

.fc-scrollgrid-section-liquid .pu_generalinfo p {
    margin-bottom: 2px;
    font-size: 12px;
    padding: 2px 0px;
}

.plEdit button,
span.plpaynow {
    background-color: #007fff;
    border-color: #007fff;
    padding: 4px 11px;
    font-size: 10px;
    margin-left: 5px;
    color: #fff;
    border: 0;
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}

span.plpaynow {
    background-color: #2d1152;
    border-color: #2d1152;
    cursor: pointer;
}

.fc-scrollgrid-section-liquid .pu_generalinfo p.drNameInfo {
    background: #f0f0f5;
    padding: 5px 10px;
}

.addLabTest .pharmacyManufactureWrap .pagination {
    margin: 5px 0px;
}

#labTestDetails .AddLabtestMdl,
#addParameter .AddLabtestMdl {
    width: 550px;
}

.customizationPop label {
    font-size: 13px;
}

disabled,
select:disabled {
    background: #e9ecef;
    border-radius: 5px;
}

.fc .fc-today-button.fc-button:disabled {
    opacity: 1;
    background: #f1f1f1 !important;
}

.fc .fc-button {
    border-radius: 0px;
}

.CalendarImg {
    margin: -3px 10px 0 0;
    width: 16px;
}

.eventDelete {
    position: absolute;
    right: 0;
    /* background: rgb(107 142 35); */
    background: transparent;
    width: 15px;
    text-align: center;
    cursor: pointer;
    z-index: 9;
    top: 0;
}

.fc-timegrid-event-harness>.fc-timegrid-event {
    height: auto;
    width: 100%;
    /* white-space: nowrap; */
    white-space: break-spaces;
    text-overflow: ellipsis;
    overflow: hidden;
}

.popover-body .pu_generalinfo {
    padding-bottom: 15px;
}

.calenderRightSec {
    padding-left: 0px;
}

.calenderRightSec .calenderRightSecInput {
    width: 65%;
    margin: 0 auto;
}

.modal-title {
    color: #545454;
    font-size: 14px;
}

.pu_cancel {
    border: 1px solid var(--cancel-color) !important;
}

.fc .fc-timegrid-slot-label-cushion {
    font-size: 11px;
}

.fc .fc-col-header-cell-cushion {
    color: #585858;
    font-size: 14px;
}

.registrationCard.card_id_1.patientRegistrationCard {
    min-height: 208px;
}




/* a:hover{
    color: #fff !important;
} */

.registrationCardWrapNodataFound {
    width: 100%;
    height: auto;
    background: #ececec;
}

.registrationCardWrapNodataFound .healthCardNodata {
    width: 320px;
    height: auto;
    background: #fff;
    display: block;
    padding: 10px;
    font-size: 12px;
    border-bottom: 10px solid #ececec;
    margin: 0 auto;
}

.healthCardNodata p {
    margin: 0px;
}

.presptn input[type=checkbox]:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
}

.presptn .planActive tbody:before {
    top: 28px;
}

.dentalProNote.PrescriptionActions {
    position: absolute;
    right: 1px;
    top: 23px;
    width: 30px;
}

.DateMonthView {
    width: 75%;
    margin: 0 auto;
    padding: 15px 0px;
}

.changeStatusWrap {
    background: #fff;
    /* border: 1px solid #d4d4d4; */
    padding: 10px 90px;
    margin-bottom: 15px;
    border-top: none;
}

.changeStatusWrap p {
    font-size: 13px;
    color: #000;
    vertical-align: top;
    margin: 0 0 8px;
    font-family: "Arial";
}

.textAreaAppointment {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 111px;
    min-height: 111px;
    max-height: 111px;
    padding: 5px 10px;
    border: 1px solid gainsboro;
    font-family: "SourceSansPro-Regular";
    font-size: 1em;
    margin-bottom: 15px;
    transition: box-shadow .3s, border .3s;
    resize: none;
}

select.formInput.selectOptionAppointmnt {
    width: 50%;
    background: #f2f2f2;
    margin-bottom: 10px;
}

.__react_component_tooltip.show {
    visibility: hidden !important;
}

.eventZindex .__react_component_tooltip.show {
    visibility: visible !important;
}


/*  */

/* .fc-timegrid.fc-timeGridWeek-view.fc-view .fc-timegrid-event-harness.fc-timegrid-event-harness-inset.eventZindex,
.fc-daygrid.fc-dayGridMonth-view.fc-view .fc-timegrid-event-harness.fc-timegrid-event-harness-inset.eventZindex {
    z-index: 999 !important;
} */

.fc-timegrid-event-harness.fc-timegrid-event-harness-inset.eventZindex {
    z-index: 999 !important;
}



.fc .fc-timegrid-col-events {
    z-index: initial !important;
}

.DashboardWelcome .landingBodyWrap span {
    padding-left: 0px;
}

.DashboardWelcome .doc.blogVisitPage .blogVisitBlock .blogVisitPara {
    color: grey;
    font-size: 1.2em;
    line-height: 30px;
    font-family: "OpenSans-Regular";
}

.DashboardWelcome .doc.blogVisitPage .blogVisitBlock .blogVisitPara a {
    color: grey;
}

.profileAccordion .landingBodyWrap span {
    padding-left: 0px;
}

.profileAccordion .homeSpecialityList span input[type="checkbox"] {
    margin-right: 5px;
}

.profileAccordion .homeSpecialityList span {
    padding-left: 0px;
    color: #939393;
}

#spanAddSupplier {
    font-size: 16px;
}

body #gridnew_docedit_achtrow .inputField.lbTestDetlsItemName.dataAppend {
    width: 76% !important;
    margin-left: 25px;
    margin-right: 45px;
}

body .disabledField {
    width: 100%;
    height: 35px;
    border: 1px solid #ced4da;
    font-size: 14px;
    padding: 2px 10px;
    border-radius: 5px;
    outline: 0;
    margin-bottom: 5px;
}

.printOutHeaderSec.PrescPrint tr hr {
    height: 2px;
    color: #000;
    opacity: 1;
}

.labTestPrintWrap table {
    table-layout: auto;
}

.teethContentHead {
    clear: both;
    overflow: hidden;
    border: 1px solid #000;
    background: #ececec;
    font-size: 13px;
}

.teethHeadItem {
    width: 25%;
    float: left;
    font-weight: 600;
    padding: 5px 3px;
    font-family: "Arial";
    text-shadow: none;
}

.testNameHead {
    width: 52%;
}

.patientReadingHead {
    width: 14%;
}

.normalValueHead {
    width: 20%;
}

.teethContentDesItem {
    float: left;
    padding: 1px 3px 0;
}

.patientTestResultPrint .testNameHead {
    width: 66%;
}

.patientTestResultPrint .teethContentDesItem span {
    font-size: 12px;
}


/* .gridIconItem .__react_component_tooltip:hover{
       opacity: 1;
       visibility: visible;
} */

.gridIconItem .__react_component_tooltip.show {
    visibility: visible !important;
}

.gridIconItem .__react_component_tooltip {
    padding: 0px;
}

.enterYourOtp {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    color: #333;
}

.otpForgot {
    display: flex;
    justify-content: center;
}

.loginForm.forgotPasswordForm {
    background: #f0f3f4;
    padding: 15px 20px 10px;
    width: 320px;
}

.enterOTPTxt {
    border: 0;
    border-bottom: 1px solid #ddd;
    outline: 0;
    transition: .2s ease all;
    -moz-transition: .2s ease all;
    -webkit-transition: .2s ease all;
    font-size: 16px;
    color: #8c8c8c;
    display: block;
    text-align: center;
    width: 100%;
    height: 35px;
    background: #ffffff;
    padding: 5px 10px;
    margin-bottom: 5px;
    cursor: pointer;
    color: #000;
}

.forgotPasswordForm .loginList .inputField {
    padding-left: 15px;
    color: #000;
}


/* .forgotPasswordForm .loginList .inputField::placeholder{
       font-family: "Montserrat-Regular" !important;
} */

.forgotPasswordForm .loginList {
    margin: 0 0 20px;
}

.forgotPasswordFormMailWrap .loginButton {
    margin-top: 35px;
}

body .inputFieldBottom,
body .inputFieldBottom::placeholder {
    margin-bottom: 10px;
    font-family: "SourceSansPro-Regular";
    color: #888;
    font-size: 1.111111em;
}

.inputFieldBottom::placeholder,
.inputFieldBottom option {
    color: #888;
    font-size: 1.111111em;
    font-family: "SourceSansPro-Regular";
}

.fileUpload_upload.btn_upload:hover span {
    color: #fff !important;
}

.dashBoardContent.emailIdDashBoardContent {
    width: 38%;
}

.dashBoardContent.mobNumDashBoardContent {
    width: 28%;
}

.appoinmentWrap.appoinmentWrapMainDiv {
    padding: 3px 2px 3px 3px;
    justify-content: space-between;
}

.calendarListRow .listStatus {
    font-family: "OpenSans-Regular";
    font-size: 13px;
    color: #222;
}

.appoinmentWrap.appoinmentWrapMainDiv .appoinmentDateDetail {
    padding-left: 0;
}

.fc-scrollgrid-liquid .fc-daygrid-event {
    white-space: break-spaces;
}


/* .calenderSectionWrap .fc-timegrid-event-harness > .fc-timegrid-event {
    position: relative;
} */

.fc-timeGridWeek-view.fc-view .eventDetails {
    position: absolute;
}

.presptn .treatmentTable.planActive.prescriptionTableIMages tbody:before {
    top: 22px;
}

.presptn .position-relative {
    position: absolute !important;
    top: 34px !important;
    right: 2px !important;
}

.medicineStockUpdateWrap .dashboardtable tr td span.refundReason {
    width: 90%;
}

.dashboardtable table td img {
    cursor: pointer;
}

.patientPrescriptionWrapper hr {
    height: 2px;
    color: #000;
    opacity: 1;
}

.showHidePassword {
    position: relative;
    left: -25px;
    width: 15px;
}

.pl_editInventory.edtinv input[type=checkbox]:checked+label:after,
.addProcedureSectn input[type=checkbox]:checked+label:after {
    visibility: hidden;
}

.pl_editInventory.edtinv .procedureCatType #chkShowPharmacy {
    height: 13px;
    width: 13px;
}

.borderButton.borderButtonLarge.legoButton_green.drImageUpload {
    position: relative;
    top: 50px;
    margin-bottom: 30px;
}

.homeProfileDetails .uploadButton.uploadButtonIarge label:hover {
    background: lightgray !important
}

#treatmentPlanListDiv {
    width: 100%;
    float: left;
    background: #ececec;
    border: 1px solid #cbc5d4;
    /* border-top: none; */
}

#setFlag span {
    width: 320px;
    background: #fff;
    float: left;
    margin: 0 auto;
    padding: 10px;
    margin-bottom: 15px;
}

#setFlag {
    margin: 0 auto;
    display: flex;
}

.presptn #treatmentPlanListDiv {
    border: none;
}

.priscriptionPrintTr {
    display: inline-block;
    width: 20%;
    vertical-align: top;
    text-align: left;
    border: 1px solid #ccc;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-left: 8px;
}

.priscriptionPrintTr img {
    width: 100%;
    min-height: 80px;
}

.treatmentNameList.treatmentPlanList.treatmentPlanListGenericMed {
    min-height: auto;
    padding: 0px 0 10px;
    height: auto;
    overflow: auto;
    overflow-x: hidden;
    max-height: 153px;
}

.treatmentNameList.treatmentPlanList.allDrugsList {
    min-height: auto;
    max-height: 153px;
}

.appointmentNumberSearch.drugRightAutoSearch {
    margin-top: -16px !important;
}

.gridList.patentListingg .gridContent {
    width: 78%;
}

.ptList .gridImage {
    vertical-align: middle;
    margin-top: 8px;
}

.ptList .gridImage img {
    width: 100%;
    width: 60px;
    height: 60px;
    max-width: 60px;
    max-height: 60px;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
}

.gridIconItem.mrNumberView {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.mrNumberEdit {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 173px;
}

.calendarContent.drNameDisplay svg {
    margin-right: 5px;
}

.pl_casesheetWrap.plMdcl #treatmentPlanListDiv,
.patientPayment #treatmentPlanListDiv {
    border: 1px solid transparent;
}

.patientFilesDentalPopupGalleryploverlayy .triangle-left:hover {
    content: "Previous (Left arrow key)";
}

.popup-container.popup-containercaseSheet h3 {
    margin: 0;
    font-size: 16px;
}

.popup-container.popup-containercaseSheet .popup-content a.close {
    font-size: 20px;
    line-height: 15px;
}

.test {
    user-select: none;
    cursor: not-allowed;
    pointer-events: none;
    filter: alpha(opacity=40);
    background: #8dc3ed;
    color: #fff;
    text-shadow: none;
}

.ptpri.ptadd .practiceManagementForm {
    margin: 0px 0 0px;
}

.patientPayment.ptpri.ptadd .treatmentsList {
    border: none;
}

.newAppoinmentRightList.labnameListWrap {
    width: 55%;
}

.newAppoinmentRightList.labnameListWrap input {
    height: 35px;
    width: 100%;
    padding: 0 10px;
    margin-bottom: 15px;
    border: 1px solid #f2f2f2;
    background: white;
    font-size: 1em;
}

body .labnameListWrap .inputField {
    width: 100%;
    height: 35px;
    background: #ffffff;
    border: none;
    font-size: 12px;
    border-radius: 3px !important;
}

.lbOrderSelect select::placeholder,
.lbOrderSelect select {
    color: #737272;
    border-radius: 0;
    font-size: 14px;
}

.appointmentNumberSearch.labOrderDentalAddAutoSearch {
    margin-top: -15px;
    min-width: 430px;
}

.appointmentNumberSearch.labOrderDentalAddAutoSearch li:hover,
.itemNameSearch li:hover {
    background-color: #1da0f1 !important;
    color: #fff;
}

.mrgTop.ptMedicalHistory.allergyList li {
    font-size: 13px;
}

.frindlynoteEdtImg {
    margin-left: 7px;
    cursor: pointer;
}

.pharmacyManufactureWrap.pharmacyManuf .pagination {
    margin: 15px 0;
}

.testResultWrappp .pl_testdpdn1 {
    top: 0px;
    z-index: 9;
}

.SettingsConsent .cardsettingList sup {
    font-size: 18px;
    top: 0;
    color: red;
}

.appointmentNumberSearch.labOrderDentalAddAutoSearch.addPurcharseAutoSearch {
    min-width: 320px;
}

.appointmentNumberSearch.labOrderDentalAddAutoSearch.addPurcharseAutoSearch li:hover {
    color: #fff !important;
}

.itemNameSearch {
    position: absolute;
    width: 310px;
    height: auto;
}

.itemNameSearch li {
    width: 100%;
}

.autoSearch.manufactureListHeight {
    height: auto;
}

.autoSearch li:last-child {
    color: #000 !important;
}

.autoSearch li:last-child:hover {
    color: #000 !important;
}

.hospitalSelectDropdown {
    cursor: default;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    width: 100%;
    height: 35px;
    background: #ffffff;
    border: 1px solid #ced4da;
    font-size: 12px;
    padding: 2px 10px;
    border-radius: 5px;
    outline: 0;
    margin-bottom: 5px;
    position: relative;
    top: 1px;
}

.calanderActions button {
    background: #cbc5d470;
    padding: 8px 10px;
    border: 1px solid #cbc5d4;
    margin-right: 10px;
    min-width: 100px;
    border-radius: 6px 6px 0 0;
    font-size: 13px;
    font-weight: 500;
    border-bottom: none;
    position: relative;
    bottom: -1px;
}

.calanderActions button.active {
    background: #2d1152;
    border: 1px solid #2d1152;
    color: #fff;
}

.calanderActions {
    border-bottom: 1px solid #ddd;
    display: flex;
}

.calenderSectionWrap .fc-timegrid-event-harness>.fc-timegrid-event.blockCalanderView {
    position: absolute;
}

.patientFilesDentalPopup .xRayView {
    width: 302px;
    height: 603px;
}

.patientFilesDentalPopup .xRayView span {
    width: 292px;
    height: 296px;
}

.patientFilesDentalPopup .xRayView {
    width: 32%;
}

.slick-track {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
}

.gallerySliderView .xRayView img,
.gallerySliderView .beforeView img {
    max-height: none;
}

.autoSearch .itemNameSearch li:last-child {
    color: #fff !important;
}

.todayVisitView .noDataFound {
    margin: 0 auto;
    width: 100%;
    border: none;
    font-size: 14px;
    padding: 4px;
}

.todayVisitView .practiceManagementForm {
    margin: 10px 0 0px;
    border: none !important;
}

.pharmacyWrapper table {
    table-layout: fixed;
}


/* select:focus,textarea:focus{
    outline: 1px solid #000 !important;
} */

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    background: #bfe5e6;
    z-index: 9;
}

.add_treatment_plan.aDDTreat input[type=checkbox]:checked+label:after {
    display: none;
}

.aDDTreat.addCmpltdln .treatmentDrNameList.pl_drList ul li span,
.aDDTreat.addCmpltdln .treatmentTable table td span {
    font-size: 13px;
    text-transform: capitalize;
}

.aDDTreat.addCmpltdln .pl_drList {
    max-height: 157px;
}

.dentalProName.paddingFiveLabOrder .elipsis {
    width: 100%;
    max-width: 250px;
}

.Appointmentjsx .appointmentGridList {
    border: 1px solid #d4d4d4;
    width: 100%;
    display: block;
    margin-bottom: 15px;
}

.Appointmentjsx .appointmentGridList .gridList:hover {
    border: none;
}

.tramntPlanDpdwnWrap .treatmentCommonTxt1,
.completedPlansWrap .treatmentCommonTxt1 {
    min-height: 250px;
    background: #fff;
    margin: 0 auto;
}

.tramntPlanDpdwnWrap .treatmentCommonTxt1>span,
.completedPlansWrap .treatmentCommonTxt1>span {
    margin: 90px auto;
}

.Appointmentjsx #appoinmentDateTime.appmntDateTime,
.Appointmentjsx select.formInput.selectOptionAppointmnt,
body .changeStatusWrap .inputField {
    /* height: 35px;border: 1px solid gainsboro; */
    height: 30px;
    border: 1px solid #e8e8e8;
}

.appointmentGridList .changeStatusWrap label {
    font-size: 13px;
    color: #000;
    vertical-align: top;
    margin: 0 0 5px;
    font-family: "Arial";
}

body #hospitalNameIdAppNew {
    height: 35px;
    background: #f2f2f2 !important;
    border: 1px solid #ced4da;
}

.Appointmentjsx .appointmentGridList .changeStatusWrap .col-md-6 {
    margin-bottom: 10px;
}

.elipsisNotes {
    display: -webkit-box;
    max-width: 145px;
    float: left;
    height: auto;
    line-height: 14px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: inner-spin-button !important;
    width: 40px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
}

.patientDetailsTable table td {
    word-break: break-all;
}

.clearCloseCalander {
    background: #fff;
    position: absolute;
    border: 1px solid #ddd;
    width: 100%;
    display: table;
    top: -28px;
}

.closeCalander {
    position: relative;
    left: 50%;
}

.clearCloseCalander a {
    cursor: pointer;
    padding: 5px 13px;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    background: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}

.patientPrescriptionWrapper .medicineDetailItems:before {
    visibility: hidden;
}

.patientPrescriptionWrapper .medicineDetailItems p {
    font-size: 12px !important;
    margin: 0;
    color: #6a6a6a;
}

.patientPrescriptionWrapper .medicineDetailItems span {
    font-size: 13px;
    margin-bottom: 6px;
    display: block;
}

.patientPrescriptionWrapper .medicineDetailItems span {
    display: block;
    font-weight: 500;
    padding-bottom: 3px;
    color: #000;
}

.patientPrescriptionWrapper .medicineDetailName {
    font-size: 13px;
    font-weight: 600;
}

.medicineDetailDiv:hover .toolTipWrap {
    display: block !important;
}

.medicineDetailDiv {
    position: relative;
    margin-left: 15px;
    padding: 0 0 5px;
    cursor: pointer;
}

.medicineDetailList {
    position: absolute;
    background: #fff;
    padding: 10px;
    z-index: 1;
    width: 450px;
    right: -10px;
    top: 23px;
    display: none;
    border-radius: 10px;
    box-shadow: 0 0 10px #c3c3c3;
    -webkit-box-shadow: 0 0 10px #c3c3c3;
    -moz-box-shadow: 0 0 10px #c3c3c3;
    -ms-box-shadow: 0 0 10px #c3c3c3;
    -o-box-shadow: 0 0 10px #c3c3c3;
}

.presptn .position-relative1 {
    position: absolute;
    right: 0;
    font-size: 12px;
}

.mrNumberSection #editiconId {
    position: absolute;
    right: 10px;
    top: 28px;
    width: 15px;
    cursor: pointer;
}

.loginForm.loginErrMsgPos p {
    position: relative;
    top: -18px;
}


/*  */

input[type="date"],
input[type="month"] {
    position: relative;
}

input[type="date"]:after {
    font-family: 'Material Icons';
    content: "\ebcc";
    color: #cecece;
    padding: 0 5px;
    font-size: 20px;
}

input[type="month"]:before {
    content: "Expiry Date : ";
    color: #888;
    padding: 0 5px;
    font-size: 13px;
}

input[type="month"]:after {
    font-family: 'Material Icons';
    content: "\ebcc";
    color: #888;
    padding: 0 5px;
    font-size: 20px;
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="month"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="month"]::-webkit-inner-spin-button {
    z-index: 1;
}

input[type="date"]::-webkit-clear-button,
input[type="month"]::-webkit-clear-button {
    z-index: 1;
}


/*  */

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('../css/materialicons.woff2') format('woff2');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

.aDDTreat .pl_addTreatSection .pl_drList {
    width: 160px;
    padding: 5px;
}

.inventryOuter.addTreatTaxDetails {
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-top: 15px;
}

.aDDTreat .treatmentTable table td span.inventoryInfoWrap span {
    font-size: 10px;
}

.inventryOuter.addCompltedPlaninventryOuter {
    flex-direction: column;
    display: block;
    margin-top: 20px;
}

.inventryOuter.addCompltedPlaninventryOuter .treatmentDrNameList.pl_drList.inventortDetailsCompltdplan {
    left: auto;
}

.pharmacyManufactureWrap .dashboardtable table td {
    border: none;
    word-break: break-word;
}

.close_row.close_span {
    position: absolute !important;
    left: 2px !important;
    display: flex;
    align-items: center;
    font-size: 18px !important;
    justify-content: center;
    width: 20px;
    height: 20px;
}

.close_row.close_span span {
    font-size: 18px;
    padding-bottom: 3px;
    padding-left: 3px;
}

.labTestDtlsSettings .AddLabtestMdl .close.exportClose {
    font-size: 14px;
    right: 30px;
    top: 8px;
}

.labTestDtlsSettings .summaryBtnDiv {
    justify-content: flex-end;
    display: flex;
    right: -20px;
}

.Appointmentjsx .paginationSection {
    width: unset;
    float: none;
}

.appointmentFormList select,
.plAppInfo select {
    color: #888;
    font-size: 12px;
}

.Appointmentjsx .gridList .gridContent {
    width: 72%;
}

.pl_addTreatSection #lblMessage {
    text-align: center;
    display: flex;
    width: 40%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: absolute;
    left: 15%;
}

.treatmentPlanList.pl_treatmentDiv.pl_addTreatmnt .disposableTbody span.close_row {
    left: 92%;
    padding-bottom: 3px;
    padding-left: 3px;
}

.disposableTbody span.close_row span {
    font-size: 18px;
}

.patientPayment.plInvoice .noDataFound p {
    color: #444;
}

#leftmenuRes ul li:hover,
#leftmenuRes ul li a.active {
    background: rgba(151, 140, 169, 0.4);
}

.labTestDtlsSettings .paginationSection {
    width: 100%;
    float: left;
}

.clinicalField .react-datepicker-wrapper {
    position: absolute;
    width: 98%;
}

.inputField.friendlyNoteViewSaveTxtArea {
    height: auto;
    resize: none;
    min-height: 100px !important;
    border: 1px solid gainsboro !important;
    width: 100% !important;
    display: block !important;
    font-size: 12px;
}

.addPaymentWrap .paymentSecContent {
    font-size: 11px;
    padding: 10px 15px;
    background: #efefef;
}

.paymentSec br {
    display: none;
}

.paymentSec select {
    margin-bottom: 0px;
}

#loginSection p {
    position: relative;
    top: -20px
}

.addCmpltdln .react-datepicker-popper {
    z-index: 9999 !important;
}

[aria-label="Go to last page"],
[aria-label="Go to first page"] {
    font-size: 22px;
    line-height: 15px !important;
}

[aria-label="Go to next page"],
[aria-label="Go to previous page"] {
    font-size: 14px;
    line-height: 17px !important;
    font-weight: 600;
}

.calendarCustomTooltip {
    position: relative;
}

.calendarCustomTooltip:hover .calendarCustomTooltipContent {
    display: block;
    position: absolute;
    left: 0;
    z-index: 9999;
    background: #fff;
    /* height: 200px; */
}

.calendarCustomTooltipContent {
    width: 300px;
    display: block;
    position: absolute;
    font-size: 12px;
    color: #313131;
    z-index: 3;
    padding: 10px;
    right: 0;
    top: 25px;
    box-shadow: 0 0 7px #b7b7b7;
    -webkit-box-shadow: 0 0 7px #b7b7b7;
    -moz-box-shadow: 0 0 7px #b7b7b7;
    -ms-box-shadow: 0 0 7px #b7b7b7;
    -o-box-shadow: 0 0 7px #b7b7b7;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -ms-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    display: none;
}

.fc-timegrid-event-harness-inset.eventZindex .fc-timegrid-event {
    overflow: visible;
}


/* Mdcl Histry */


/* JUST COMMON TABLE STYLES... */

.patientrightWrapper table {
    border-collapse: collapse;
    width: 100%;
}

.patientrightWrapper th,
.patientrightWrapper td {
    background: #fff;
    padding: 8px 16px;
}

.patientrightWrapper .tableFixHead {
    overflow: auto;
    height: auto;
    max-height: 250px;
}

.patientrightWrapper .tableFixHead thead th {
    position: sticky;
    top: -1px;
    font-size: 12px;
}

.patientrightWrapper thead,
.patientrightWrapper tbody,
.patientrightWrapper tr,
.patientrightWrapper td,
.patientrightWrapper th {
    border: 1px solid #ddd;
}


/* Profile details */

.gridIconItem.gridIconItemLarge.prNumberGridItem {
    width: 44.5%;
    max-width: 100%;
}


/* .profrndlynte .gridIconItem span{
    font-weight: 600;
} */

.profrndlynte .gridIconItem {
    color: #000;
    font-family: "OpenSans-Regular";
    width: 226px;
}

.gridIconItem.gridSmallWidth {
    width: 129px;
}

.gridIconItem.gridIconItemLarge.prNumberGridItem.PrGridOnly {
    text-align: left;
}

.profrndlynte .gridPatientName {
    width: 90%;
    float: left;
    display: block;
}

.profrndlynte .gridPatientName span {
    float: right;
}

.profrndlynte span.gridPatientName .gridIconItem {
    float: right;
    display: flex;
    justify-content: flex-end;
    margin-right: 8px;
}


/************************* alert  *************************/

.popuptext {
    position: fixed;
    min-width: 300px;
    bottom: 10px;
    right: 10px;
    background: #2d1152;
    padding: 16px 20px;
    color: #fff;
    border-radius: 5px;
    transform: translateX(100%);
    opacity: 0;
    visibility: hidden;
    transition: .5s
}

.popuptext.show {
    transform: translateX(0%);
    opacity: 1;
    visibility: visible;
}


/************************* alert  *************************/

.labTestDetailsList {
    overflow: auto;
    height: auto;
    max-height: 50px;
}

.labTestDetailsList .procedureCatType1 {
    padding-bottom: 5px;
}

body #gridnew_docedit_achtrow .inputField.lbTestDetlsItemName.dataAppend.labTestDetailsField {
    width: 100% !important;
    margin-right: 70px;
}

.disposableWraperList {
    overflow: auto;
    height: auto;
    max-height: 155px;
}

.ptMedicalHistory table {
    table-layout: auto;
}

.notInHospital {
    color: red;
    border-bottom: 1px solid red;
    padding-bottom: 2px;
    font-size: 11px !important;
    width: 79px;
}

.fc-timegrid.fc-timeGridDay-view.fc-view .__react_component_tooltip {
    left: auto !important;
}

.addpaymentRefundWrap .inVoiceSideLeft,
.addpaymentRefundWrap .inVoiceSideRight {
    font-size: 14px;
    font-weight: 600;
}

.addpaymentRefundWrap span.ContentLabel {
    font-weight: 600;
    font-size: 12px;
    padding-left: 10px;
}

select#paymentTypeId option {
    color: #000;
}

.simplifiedFormCasesheetSection {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-top: 1px solid #ececec;
}

.simplifiedFormCasesheetSection .treatmentCommonTxt {
    position: relative;
    left: auto;
    right: auto;
    transform: none;
    top: auto;
}

.tagListingDiv.drugsSearch .iconSearchDrug {
    position: relative;
    right: 22px;
    opacity: .5;
    cursor: pointer;
}

.serviceProviderBtns.invoiceAddPrintbtn {
    text-align: right;
    padding: 20px 0 0;
}

.serviceProviderBtns.invoiceAddPrintbtn a {
    display: inline-block;
    padding: 7px 30px;
    background: #2d1152;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
}

.ptInvoiiceAddWrap.InvoiceAddSection.exportCalndrSec .modalDialog>div {
    max-width: 750px;
}

.ptInvoiiceAddWrap.InvoiceAddSection.exportCalndrSec .modalDialog {
    z-index: 9999999;
}

.ptInvoiiceAddWrap.InvoiceAddSection.exportCalndrSec .addDataHead span {
    font-size: 15px;
}

.add_treatment_plan.exportCalndrSec .dentalProName,
.add_treatment_plan.exportCalndrSec .dentalProCost,
.add_treatment_plan.exportCalndrSec .dentalProDis,
.add_treatment_plan.exportCalndrSec .dentalProTotal {
    color: #000 !important;
}

.InvoiceAddSection.exportCalndrSec .planActive tbody:before {
    content: "";
    position: absolute;
    left: 0;
    top: 29px;
}

.treatmentTable table td.treatQty {
    text-align: center !important;
}

.dentalProNote.compltdPlanDpdwn {
    top: 0px;
    position: relative;
}

.testResultRight.row {
    margin-left: -4px;
    margin-top: 5px;
}

.treatmentGrandBy .treatmentByName,
.react-datepicker-wrapper input {
    cursor: pointer;
}

.labOrderMainWrap .pl_print {
    z-index: 9;
}

.ptInvoiiceAddWrap.InvoiceAddSection .react-datepicker__time-container {
    position: absolute;
    right: -85px;
    border: 1px solid #aeaeae;
}

.ptInvoiiceAddWrap.InvoiceAddSection .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 0px;
}

.tagListingDiv.DrugsRightSearch svg {
    position: absolute;
    margin-left: -20px;
    margin-top: 10px;
    opacity: .5;
}

#btnCancel {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 15px;
    top: 15px;
    outline: 0;
}

.Appointmentjsx #btnCancel,
.plAppointmenPopup #btnCancel {
    right: 0;
    top: 0;
}

#btnCancel:focus {
    border: 1px solid #030303;
}

span.popUpClose {
    position: unset;
}

.mrNumberEdit {
    outline: 0;
}

.newAppoinmentButton button:focus {
    box-shadow: 0px -1px 5px 1px rgb(0 0 0 / 40%);
}

.radioSection1 input:focus,
.appointmentFormList select:focus,
.appointmentFormList input:focus,
.appointmentFormList textarea:focus,
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: 1px solid rgb(0, 0, 0, .2);
}

.btn_mrno {
    position: absolute;
    right: 0;
    border: 0;
    outline: 0;
}

.btn_mrno img {
    top: 50% !important;
    transform: translate(-50%, -50%);
    left: 50%;
}

.btn_mrno:focus img {
    transform: translate(-50%, -50%) scale(1.2);
    filter: brightness(0.5);
}

.ptInvoiceAddMsg.INVOICE {
    display: flex;
    justify-content: center;
}

.patientPrescriptionWrapper table {
    table-layout: fixed;
}

.plInvoice.ptInvTble .dentalProName .elipsis {
    max-width: 200px;
    display: inline-block;
    width: 100%;
}

.plInvoice.ptInvTble .dentalProName p {
    max-width: 200px;
}

.dashboardMainThead {
    position: sticky;
    top: 0;
    background: #fff;
}

.plInvoice.labOrderDentalTbles .groupBrdr .dentalProName {
    padding-left: 5px;
}

.invoiceErrrMsg {
    padding: 0 20px;
    width: 38%;
}


/* ================== LIGHT BOX CSS BEGINS HERE ================= */

.styles_column__2r45X {
    width: 130px;
    height: 130px;
    overflow: hidden;
    object-fit: contain;
    object-position: center;
    background: #fff;
    border: 1px solid #ddd;
    padding: 3px;
    display: block !important;
}

.styles_lightroom__1X2qE {
    z-index: 99999999 !important;
    position: fixed !important;
}

.styles_lightroomdesc__rNPED {
    text-align: right !important;
    width: 20vw !important;
}

.styles_topmenu__oGT_u img:first-child {
    visibility: hidden;
}

.styles_topmenu__oGT_u img {
    cursor: pointer;
}


/*  */

.pl_staffList.pl_drAdd.drListAdd table {
    margin: 0;
    width: 50%;
    float: left;
    background: #fff;
}

.pl_staffList.pl_drAdd.drListAdd .table-bordered>tbody>tr>td {
    display: flex;
    justify-content: space-between;
}

.appointmentPopupClose #btnCancel:focus {
    border: none;
}

.ril__caption {
    display: flex;
    justify-content: center !important;
}

.treatmentComplaintLeft1::after {
    content: '';
    position: absolute;
    border-right: 1px solid blue;
    width: 40px;
    height: 20px;
    margin-top: 0px;
}

.treatmentComplaintRight1 {
    padding-left: 55px;
    margin-top: -2px;
}

.tablePrintRaw1 {
    padding-top: 5px;
}

.treatmentComplaintRow1 {
    margin-bottom: 5px;
}

.tramntPlanDpdwnWrap .elipsisNotes {
    max-width: 110px;
}

.tramntPlanDpdwnWrap th.col-md-3:last-child {
    padding: 4px 0;
}


/* #prescriptionDivMain.prescriptionDivMainHeight{
    height:731px;
    background: pink;
} */

.gridIconsList.gridIconsListBottomInfo {
    display: flex;
    justify-content: space-between;
    /* width: 725px; */
}

.fc-timegrid.fc-timeGridWeek-view.fc-view .place-bottom::after {
    border-bottom-style: solid;
    border-bottom-width: 13px;
    top: -10px;
}

.fc-timegrid.fc-timeGridWeek-view.fc-view .__react_component_tooltip {
    margin-top: 17px !important;
}

.homeSpecialityList input[type=checkbox]:checked+label:after {
    visibility: hidden;
}

.ptInAddPopup.InvoiceAddSection.exportCalndrSec .planActive tbody:before {
    content: "";
    position: absolute;
    left: 0;
    top: 39px;
}

#clear {
    cursor: pointer;
}

.plpopup.plpopupList.patientFilesDentalPopup .galleryOrtho {
    display: flex;
    /* flex-direction: row-reverse; */
    justify-content: left;
}

.item.itemFolder {
    margin: 5px;
    padding: 2px;
    background: #fff;
    width: 130px;
    float: left;
    height: 130px;
    position: relative;
}

#oneObject .breadcrumb-ul {
    font-size: 14px;
    padding: 5px 0 5px 5px;
    color: #404040;
    font-weight: 600;
    float: left;
}

#oneObject span.breadcrumb-ul::after {
    content: "\f105 ";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 300;
    position: absolute;
    padding-left: 3px;
    top: 3px;
    right: -6px;
}

.arr {
    background: #000;
    display: block;
    float: left;
}

.arr-prev {
    width: 8px;
    height: 8px;
    margin: 14px 5px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    transform: rotate(135deg);
    -o-transform: rotate(135deg);
}

.arr-prev:after {
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    width: 8px;
    height: 8px;
    background: #ececec;
}

#printBtnPatientInvoiceAdd {
    display: inline-block;
    padding: 7px 30px;
    background: #2d1152;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
    margin-top: 20px;
}

.ptIN.ptInAddPopup.InvoiceAddSection.exportCalndrSec .planActive tbody:before {
    content: "";
    position: absolute;
    left: 0;
    top: 28px;
}

.dashButtonBorder.searchcaseSettings {
    width: 15%;
    float: right;
    margin-top: -5px;
}


/*  */

input[type=date][placeholder]:invalid::-webkit-datetime-edit,
input[type=month][placeholder]:invalid::-webkit-datetime-edit,
input[type=week][placeholder]:invalid::-webkit-datetime-edit {
    display: none;
}

input[type=date][placeholder]:invalid::before,
input[type=month][placeholder]:invalid::before,
input[type=week][placeholder]:invalid::before {
    content: attr(placeholder);
}

.SummaryDivCover {
    border: 1px solid #ddd;
    padding: 30px 40px;
}

.moduleCvrDiv {
    clear: both;
    padding: 10px 0 0;
}

.moduleDiv {
    clear: both;
    border: 1px solid #ddd;
    padding: 15px 20px;
    margin-bottom: 13px;
}

.moduleDivItem {
    clear: both;
    overflow: hidden;
    padding: 5px 10px;
    display: block;
}

.moduleDivItem span {
    float: left;
    padding-right: 10px;
    color: #737272;
    font-size: 13px;
}

.moduleSelect {
    font-weight: 600;
}

.moduleDivItem input[type=checkbox]:checked+label:after {
    top: 6px;
}

.moduleDivItem.moduleDivItemCvrSec {
    width: 33%;
    display: inline-block;
    clear: none;
    vertical-align: top;
}

.moduleDivItemCvrSec .moduleAddPortion {
    width: 100%;
    display: block;
    clear: both;
    overflow: hidden;
}

.moduleDivItemCvrSec span {
    width: 85%;
}

.EditAppontmntWraper {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    width: 97%;
    margin: 0px auto 10px;
    padding: 5px 0;
    background: #e8e8e885;
}

.yearOfBirthInfo {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 85px;
}

.bulletinBoardHead .bltHeading {
    font-size: 32px;
    font-weight: 600;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    color: #333;
}

.dashboard-right .blogVisitBlock .blogVistAvtarBlock {
    display: table-cell;
    border: 3px solid white;
    padding-top: 12px;
}

.dashboard-right .blogVisitBlock .blogVistAvtarBlock img {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
}

.dashboard-right .blogVisitAvatarInfo {
    display: table-cell;
    vertical-align: middle;
    font-size: 14px;
    line-height: 1.4;
    padding-left: 10px;
    text-rendering: auto;
}

.dashboard-right .avtarname a {
    font-weight: 700;
    font-family: "OpenSans-Bold";
    font-size: 1em;
    color: #393939;
    text-decoration: none;
}

.dashboard-right .blogVisitHead {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: "OpenSans-Bold";
}

.dashboard-right .blogVisitHead a {
    clear: both;
    font-size: 1.9em;
    line-height: 36px;
    color: #393939 !important;
    width: 100%;
    cursor: pointer;
    text-decoration: none;
}

.dashboard-right .blogVisitPara>a {
    color: grey;
    font-size: 16px;
    font-family: "OpenSans-Regular";
    display: block;
    clear: both;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 30px;
    width: 98%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: -10px;
    cursor: pointer;
    text-decoration: none;
}

.dashboard-right .blogVisitPara ul li {
    color: grey;
    font-size: 16px;
    font-family: "OpenSans-Regular";
    clear: both;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 98%;
    text-overflow: ellipsis;
    margin-top: -10px;
    cursor: pointer;
    text-decoration: none;
}

.dashboard-right .blogVisitPara {
    color: grey;
    font-size: 1.2em;
    font-family: "OpenSans-Regular";
    display: block;
    clear: both;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 30px;
    width: 98%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: -10px;
    cursor: pointer;
    opacity: .7;
    text-decoration: none;
}

.printOutHeaderSec {
    width: 540px;
}


/* HELP SECTION CSS BEGINS HERE */

.FAQBodyWrap {
    padding: 50px 30px;
    width: 80%;
    margin: auto;
}

.faqQuestionDiv {
    padding-bottom: 10px;
}

.FAQBodyWrap h4 {
    font-size: 18px;
    font-weight: 700;
    color: #676565;
    margin-top: 10px;
    margin-bottom: 10px;
}

.FAQBodyWrap p {
    padding: 0 0 15px;
    color: #717171;
}

.clickSeeVideo {
    color: #717171;
}

.faqQuestionDiv .videoHelp {
    float: none;
}

.videoHelp {
    top: -4px;
}

.videoHelp {
    text-align: right;
    float: right;
    padding: 5px 0 5px 5px;
    position: relative;
    z-index: 1;
}

.videoHelp img {
    width: 31px;
    cursor: pointer;
}

.DashboardWelcome.helpWrapper .FAQBodyWrap .landingHead,
.FAQWrap h2 {
    font-size: 25px;
    font-weight: 600;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    color: #000;
}

.FAQBodyWrap .landingHead {
    margin-bottom: 30px;
}

.patientPaymntListItems p {
    font-size: 11px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

#gridnew_docedit_achtrow.LangList {
    margin-top: 0px;
}

.webcamOpen img {
    cursor: pointer;
}

.profileImg img {
    height: 75px;
    object-fit: cover;
    object-position: top;
}

.webOpenImage {
    height: auto !important;
}

.itemFolderIcon1.folderViewMoreImg>img {
    width: 105px;
    display: inline-block;
    height: 125px;
    object-fit: cover;
    object-position: top;
}

.appointmentFormList1.prnumappointmentFormList1 input {
    outline: none;
}

.invAddRighthr {
    width: 95%;
    margin: 0px auto 15px;
}

.settingMenuPharmacy {
    width: 100%;
}

.hospitalLabWraper .pharmacyManufactureWrap .pagination {
    margin: 15px 0 10px;
}

.supplierInvoiceWrap .searchTxt {
    margin: 0px;
    padding: 0 0px;
}

.supplierInvoiceWrap .searchCount {
    margin: 10px 0px 0;
}

.rightSection.settingsRight.labloginMAin .treatmentPlanWrap.patientConsultInnerPgeLoadDiv_PTour {
    padding: 0px;
}

.rightSection.settingsRight.labloginMAin .treatmentPlanWrap.patientConsultInnerPgeLoadDiv_PTour .treatmentPlanHead {
    padding: 13px 10px 2px;
}

.rightSection.billingLeftMenu {
    padding-left: 15px;
}

.headerHospitalBill .headerBottom,
.headerHospitalLab .headerBottom {
    background: transparent;
}

.rightSection.rightSection.settingsRight.BillingloginMAin {
    width: 100%;
    padding: 15px;
}

.pharmacyModal .leftmenu {
    opacity: 0;
}

.PtListDpdwn {
    width: 60px;
    float: left;
}

.pltestResult .testResultRight .closeSuperAdmin {
    position: absolute;
    right: 30px;
}

.testResultWrappp .planActive tbody:before {
    top: 39px;
}

.rightSection.settingsRight.labloginMAin {
    width: 100%;
    padding: 15px;
    padding-left: 10px;
}

.exportCalndrSec.addLabTest #btnCancel {
    right: 0;
    top: 0px;
}

.exportCalndrSec.addLabTest #btnCancel:focus {
    border: none;
}

#pharmacyModal #treatmentPlanListDiv {
    border-top: none;
}

.gridItemLab {
    width: auto;
    float: left;
}

.gridItemLab .searchTxt {
    width: auto;
}

.practiceLeftSection.simpliFormLeft {
    width: 66.66666667%;
}

.practiceRightSection.simplifiedFormRightSec.simpliFormRight {
    padding-left: 10px;
    background: rgb(255, 255, 255);
    width: 31%;
    position: absolute;
    right: 5px;
    top: 257px;
}

.codevalue_txt {
    font-size: 12px;
}

.receipt_amt {
    display: inline-block !important;
    text-align: right;
}

.invoice_no {
    left: 6px;
}

.towards {
    text-align: right;
}

.textRight {
    text-align: right;
}

.paid_amt {
    text-align: center;
    width: 25%;
}

.sincedate {
    float: right;
    width: auto !important;
}

.invoiceInnerborder {
    border-left: 2px solid #5cb85c;
    padding: 0px;
}

.complet_plan {
    border: 0;
}

.complet_plan thead tr {
    border: 1px solid #afafaf;
}

.table_style .dentalProDis {
    padding: 10px 7px;
    width: 100%;
    position: relative;
    color: #000;
    font-size: 12px;
}

table table thead tr {
    border-bottom: 0 !important;
}

.hr_style {
    border-bottom: 2px solid #000;
    opacity: 1;
}

.vitalSignsItems img {
    max-width: 100%;
}

.vitalSignsItems {
    display: flex;
    align-items: center;
    justify-content: center;
}

.printOutHeaderSec table td {
    padding: 2px 0px;
}

.tr_head td {
    font-weight: 600;
    font-size: 11px !important;
    font-family: "OpenSans-Regular";
}

.tr_body td {
    font-weight: 500;
    font-size: 11px !important;
    font-family: "OpenSans-Regular";
}

.table_print td {
    padding: 8px 3px !important;
}

.table_subhead {
    font-weight: 600;
    background: #f3f3f3;
    color: #333;
}

.text_amoutn {
    width: auto;
    padding-left: 5px;
    font-size: 12px;

}

.text_amout {
    font-weight: 700;
    color: #5a5a5a;
    font-size: 12px !important;
}

.tr_footer {
    background: #f1f1f1;
}

.invoic_text {
    color: #0095cc !important;
    font-size: 13px !important;
}

.group_amount {
    font-size: 11px !important;
    font-family: "OpenSans-Regular" !important;
    vertical-align: bottom !important;
}

.primary_span {
    color: #0095cc;
    font-size: 13px;
}

@media print {
    body {
        overflow: visible;
    }

    #printBtm {
        display: none;
    }
}

.Appointmentjsx #btnCancel:focus,
.plAppointmenPopup #btnCancel:focus {
    border: 1px solid #030303;
}

a:-webkit-any-link:focus-visible {
    outline-offset: 1px;
    outline: 1px solid #bdbaba;
}

:focus-visible {
    outline: 1px solid #bdbaba;

}

.historyPRINT .group_amount {
    vertical-align: top !important;
}

.historyPRINT .group_amount:last-child .primary_span {
    position: relative;
    top: -10px;
}

.historyPRINT td.group_amount .primary_span {
    position: relative;
    top: 0px;
}

.historyPRINT .treatmentTable:last-child {
    border-bottom: 1px solid #afafaf;
}

.labTestDetailsList.taxInfoListSection {
    max-height: 100px;
}

.addpaymentRefundWrap .refundAmounttableList {
    margin-top: 5px;
    border: 1px solid #989898;
    color: #828282;
    height: 25px;
    padding: 2px 5px;
    font-size: 13px;
    border-radius: 0px;
}

/* .addpaymentRefundWrap .paymentSecTop,.addpaymentRefundWrap .paymentSecContent {
       padding: 6px 10px;
} */
.addpaymentRefundWrap .refundAmoutINR span input {

    position: relative;
    top: -2px;
}

.paymentSecContent.paymentSecContent_PTour.refundAmoutINR .paymentSecLst {
    background: #ececec;
    padding: 4px 10px 8px;
    border-bottom: 1px dashed #8a8a8a;
    border-top: 1px dashed #8a8a8a;
}

.table_print td img {
    width: 20px;
}

td.invoic_text {
    color: #0095cc !important;
}

.contentWraper.contentWraperScroll.patientPayment.pl_casesheetWrap.simplifiedForm {
    min-height: 166px;
}

.practiceLeftSection.simWraper {
    min-height: 115vh;
}

.LeftMenuTitle {
    padding: 15px 15px;
    display: block;
    color: #000;
    font-weight: 600;
    font-family: "Montserrat-Regular";
    font-size: 12px;
    text-shadow: none;
    background: #c3bebe;
    text-transform: capitalize;
    border-bottom: 1px solid #fff;
    text-align: center;
}

.reciptTaxDetailsWrap .reportTableCvr {
    overflow-x: scroll;
    clear: both;
}

.reciptTaxDetailsWrap .receiptTable thead th,
.reciptTaxDetailsWrap .receiptTable td {
    border: 1px solid #bababa;
}

.reciptTaxDetailsWrap .receiptTable th,
.reciptTaxDetailsWrap .receiptTable td {
    padding: 7px 5px;
    font-size: 12px;
    font-weight: 400;
    min-width: 90px;
    color: #383838;
}

.reciptTaxDetailsWrap .tablecontentCntr {
    position: relative;
    top: 15px;
    text-align: center;
}

.reciptTaxDetailsWrap .receiptTable thead .tableBorderBtm {
    border-bottom: 0;
}

.reciptTaxDetailsWrap .receiptTable .receiptTaxBox.textCenter,
.reciptTaxDetailsWrap .receiptTable .tablecontentCntr {
    font-weight: 600;
}

.reciptTaxDetailsWrap .reportTableCvr.receiptTableDiv table {
    table-layout: auto;
    margin-bottom: 35px;
}

.reciptTaxDetailsWrap .dashboardtable table td {
    border: 1px solid #ccc;
}

.reciptTaxDetailsWrap .dashboardtable table td {
    font-size: 12px;
}

.reciptTaxDetailsWrap .receiptTable th {
    font-weight: 600;
}

.reciptTaxDetailsWrap .receiptTable td {
    font-weight: 400;

}

.genericMedicineWrap .react-tabs__tab--selected {
    border: none;
    background: transparent;
}

.genericMedicineWrap .react-tabs__tab-list {
    border-bottom: 0px solid #aaa;
}

.genericMedicineWrap .react-tabs__tab {

    border: none;
}

.genericMedicineWrap .react-tabs__tab:focus {
    box-shadow: none;
    border-color: none;
    outline: none;
}

.practiceDetailsWraper .serviceListMain .smyLargstFieldMulti {
    height: 40px;
}

.practiceDetailsWraper .profileImg img {
    height: 50px;
    object-fit: contain;
    object-position: center;
    width: 85px;
}

.ptIN.ptInAddPopup.add_treatment_plan.ptInvoiiceAddWrap.InvoiceAddSection.exportCalndrSec .inventryOuter.addCompltedPlaninventryOuter {
    position: absolute;
    margin-top: 6px;
}

.casesheetMedPrintWrapr .printOutHeaderSec {
    width: 100%;
}

.casesheetMedPrintWrapr .treatmentComplaintWrap {
    padding: 0px 10px;
    margin: 0 0 0px;
    border: none;
}

.casesheetMedPrintWrapr .treatmentComplaintRight:after {
    border-bottom: none;
}

.casesheetMedPrintWrapr .treatmentTAgDate {
    position: absolute;
    bottom: 0;
    text-align: right;
    right: 0;
}

.loginOuter .pulseLeftImg img {
    margin-top: 40px;
}

/*  */
.billingAdminWrap .rightSection {
    padding: 15px 15px 0px 15px;
}

.billadminChangePassword .container {
    max-width: 100%;
}

.historyPRINT .treatmentTable table {
    display: flex;
}

.historyPRINT .table_print td img {
    width: 15px;
}

.historyInfoTbles .treatmentTimelineCheck ul li input[type="checkbox"] {
    margin: 3px 6px 0 0px;
}

.plSubMenu div:last-child li {
    border: none;
}

.clearCloseCalander.ToDateClearOpen {
    margin-left: -90px;
}

.patientDetails.caseSheetpatientDetails {
    width: 540px;
}

.casesheetpatientComplaint,
.FooterPrintCasesheet .printOutFooterSec {
    width: 540px;
}

.caseSheetPrintWrap.caseSheetDentalPrintWraper .caseSheetDentalPrintTble thead {
    display: contents;
}

#testResultAdd.testResultAdd,
#appointmentAdd.testResultAdd,
#caseSheetAdd.testResultAdd,
#addTreatmentPlans.testResultAdd,
#addCompletedPlan.testResultAdd,
#priscriptionAdd.testResultAdd,
#labOrderAdd.testResultAdd,
#invoiceAdd.testResultAdd,
#paymentAdd.testResultAdd,
#filesAdd.testResultAdd {
    background: rgba(151, 140, 169, 0.4);
}

/*  */
.pladdpurchase .dashboardSearchList {
    padding: 0px 3px;
}

/*  */
.printLogoIimg,
.printOutHeaderSec img {
    width: 100%;
}

.popupCloseReact {
    position: absolute;
    right: 30px;
    border: none;
}

.headerHospitalBill .userDropDiv {
    background: #e87070;
}

.headerHospitalharmacy .userDropDiv {
    background: #177f40;
}

.headerHospitalLab.headerHospitalBill .userDropDiv {
    background-color: #e87070;
}

.headerHospitalLab .userDropDiv {
    background: #0096d6;
}

.headerHospitalLab.headerHospitalharmacy .userDropDiv {
    background-color: #177f40;
}

.headerHospitalLab.headerHospitalBill .userDropDiv {
    background-color: #e87070;
}

.header.headerScroll .headerTop.headerTopGreen .userDropDiv {
    background-color: var(--header-top-green);
}

.PrescriptionPrintImgs .priscriptionPrintTr {
    width: 45%;
}

.PrescriptionPrintImgs .priscriptionPrintTr img {
    width: 100%;
    height: 230px;
    object-fit: contain;
    object-position: center;
}

.uploadCondtion {
    position: absolute;
    margin-top: 40px;
    font-size: 12px;
    font-weight: 600;
    color: #2d1152;
    margin-left: -140px;
    color: red;
}

.leftSplitDate input[type=checkbox]:checked+label:after {
    display: none;
}

.uploadCondtionMedical {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px auto;
    font-size: 12px;
    color: red;
    font-weight: 600;
}

/* sudheesh edit */
.calendarDateOfBirth {

    /* padding: 0 8px; */
    width: 85.333%;
    /* display: inline-block; */
    /* font-size: 12px; */
    /* vertical-align: top; */
    position: relative;
    margin: 0 0 8px 0;
    /* font-family: "Arial"; */

}

/*  */
.toggleItems {
    width: 90%;
    padding: 5px 120px 5px 23px;
}

.treatmentComplaintRightValue:after {
    display: none;
}

.printImg a {
    color: #000;
}

.printImg:hover a {
    color: #fff;
}

.vitalSignsPrint .treatmentComplaintRight:after {

    border-bottom: 0px solid #ccc;
}

.vitalSignsPrint .vitalPrintColon .treatmentComplaintRight::before {
    display: none;
}

.vitalSignDrpDwn {
    position: absolute;
    right: 0px;
    top: 0px;
}

.pul_shareData {
    padding-left: 0;
}

.pul_shareData li {
    list-style: none;
    display: inline-block;
    padding-right: 8px;
    min-width: 36%;
}

.pul_shareAction {
    display: flex;
    justify-content: center;
}

.treatmentTimelineCheck.shareHistoryType ul li input[type="checkbox"] {
    margin: 3px 6px 0 0px;
    float: left;
}

.close_popup {
    position: absolute;
    top: 5px;
    right: 13px;
    font-size: 18px;
    font-family: 'OpenSans-Bold';
}

.popup_btnDiv {
    text-align: center;
    margin-top: 7px;
    padding: 0 15px;
}

.popupbtn_submit {
    border: none;
    padding: 4px 20px;
    font-size: 14px;
    border-radius: 7px;
    background-color: var(--common-color);
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
}

.popupup_headtext {
    font-size: 17px;
    font-family: "OpenSans-Bold";
    padding-bottom: 10px;
    padding-bottom: 10px;
    background-color: #8a72a924;
    padding: 12px 10px;
    text-align: center;
    border-radius: 15px 15px 0 0;
    border-bottom: 1px solid #91949144;
    color: var(--common-color);
}

.assoc_popupList {
    width: 400px;
    padding-top: 0;
    padding-bottom: 15px;
    border-radius: 15px;
    padding-left: 0;
    padding-right: 0;
    top: 30%;
    /* height: 300px; */
}

.labl_association {
    font-size: 16px;
    padding-left: 10px;
}

.input_radioassoc:focus {
    outline: none;
}

.input_radioassoc {
    cursor: pointer;
    margin-right: 10px;
}

.plpopup.assoc_popupList .plcontent {
    height: 195px;
    overflow: auto;
    padding: 10px 25px;
    margin-right: 2px;
    margin-top: 4px;
}

.assoc_popupList .assc_popupdiv {
    margin: 10px 0;
}

.fullpage-loaderr {
    position: absolute;
    top: 70px;
    right: 0;
    height: 80%;
    width: 17vw;
    overflow: hidden;
    background: rgb(255 255 255 / 98%);
    z-index: 9999;
    transition: opacity 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fullpage-loaderr .fullpage-loader__logo {
    position: relative;
}

.txtarea_treatments {
    width: 65%;
    padding: 5px 10px;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    transition: border linear .2s, box-shadow linear .2s;
    line-height: 20px;
    color: #555;
    border-radius: 4px;
    vertical-align: middle;
    min-height: 100px;
}

.notification_block {
    position: absolute;
    right: 20%;
    top: 42px;
    cursor: pointer;
}

.notification_block img {
    width: 100%;
}

.notification_block :before {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: transparent;
}

.notification_block .video-icon,
.notifi-icon {
    display: inline-block;
    width: 35px;
    height: 35px;
    z-index: 3;
    text-align: center;
    padding-top: 5px;
    font-size: 18px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    background-color: var(--greenColor);
    border-radius: 100%;
    color: #fff;
}

.notification_block .video-icon:hover svg {
    color: #fff;
}

.notification_block .video-icon ::after,
.notification_block .video-icon::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    bottom: 0;
    left: 0;
    border-radius: 100%;
    border: 1px solid #00ffd0;
}

.notification_block .video-icon::before {
    animation: ripple 2s linear infinite;
}

.notification_block .video-icon::after {
    animation: ripple 2s linear 1s infinite;
}

.notification_block .video-icon:hover {
    background-color: var(--header-bottom-bg-color);
}

.notification_block:before {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(7, 41, 77, 0.4);
}

@keyframes ripple {
    0% {
        transform: scale(1);
    }

    75% {
        transform: scale(1.75);
        opacity: 1;
    }

    100% {
        transform: scale(2);
        opacity: 0;
    }
}

.plpopup.plpopupList.plpopupNotification {
    padding: 20px 20px;
    top: 24%;
    width: 500px !important;
}

.notif_ul {
    background-color: #bfe5e6;
    border-radius: 6px;
    padding: 15px 36px !important;
    margin-bottom: 10px;
    list-style: initial !important;
    /* margin-right: 10px; */
}

.notif_subhead {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 6px;
    ;
}

.notif_descri {
    /* padding-left: 20px; */
    font-size: 14px;
}

.notif_head {
    font-size: 20px;
    font-weight: 700;
    color: #000;
    text-align: center;
    padding-bottom: 5px;
}

.plpopupNotification .plcontent {
    max-height: 300px;
    overflow: auto;
    padding: 10px;
}

body .inputField_balnce {
    width: 168px;
    margin-top: 0;
    margin-left: 10px;
    height: 30px;

}

body .clinicalSave-margn {
    margin-top: 1px;
}

.profrndlynte .gridPatientName .patientDueInr {
    float: unset;
    margin-left: 45px;
    font-size: 13px;
}

.webCamImageWrap .uploadbtnleft {
    margin-left: 20px;
}

.uploadCondtiontext {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 600;
    color: red;
}

.uploadIconhisory {
    right: 0;
}

.oldhistoryimge {
    display: inline-block;
    width: 80px;
    margin-right: 7px;
    margin-top: 7px;
}

.oldhistoryimgeWrapr {
    width: 635px;
}

.appoinmentSatusWrap .oldhistory_descr {
    width: 95%;
}

.patientoldHistoryEdit {
    position: absolute;
    right: 0px;
    top: 0;
    background: rgb(224 224 224 / 66%);
    padding: 0 3px;
    border: 1px solid #ddd;
}

.oldHistoryprevimg {
    display: inline-block;
    width: 16%;
    padding: 10px;
    position: relative;
    margin: 10px;
    border: 1px solid #ddd
}

.previewimg_wraper {
    float: right;
    width: 80%;
}

.oldHistoryprevimg .previwimg {
    height: 65px;
}

.oldHistoryprevimg .closeimg {
    position: absolute;
    right: -10px;
    top: -15px;
    width: 20px;
    cursor: pointer;
}

.dueamounttxt {
    font-size: 13px;
    color: red;
    font-weight: 600;
    /* float: unset; */
}

.advAmountTxt {
    color: green;
    font-weight: 600;
}

.bloodgrp_sec {
    padding-top: 14px;
}

.grid_flex {
    display: flex;
    justify-content: space-between;
}

.gridIconItem-padd {
    padding-top: 2px;
}

.oldhistoryimglist {
    height: 46px;
    width: 80px;
}

.appoinmentDateDiv-height {
    height: auto;
    display: flex;
    align-items: center;
}

.caseSheetPrintWrap .patientComplaintLabel {
    float: none;
    width: auto;
}

.PrintSimpleForm .patientDetailItem {
    padding-top: 0;
}

.oldhistry_editlink {
    text-decoration: none;
}

.logo-wrapper {
    display: flex;
    justify-content: space-between;
}

.footerida_wraper {
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    align-items: center;
}

.pulseLogo .footerpurple_logoimg {
    width: 160px;
    height: 80px;
}

.footer_subwraper {
    text-align: left;
    margin-top: 20px;
    border-top: 1px solid #d9d6d6;
    padding-top: 16px;
}

.footer_subtext {
    padding-right: 20px;
}

.footersubtextLink {
    font-size: 14px;
    color: #706d6d;
    text-decoration: none;
    font-family: 'Montserrat-SemiBold';
}

.footersubtextLink:hover {
    color: #706d6d;
}

.footer-padd {
    padding: 30px 0 16px;
}

.footer_subdflex {
    display: flex;
}

.about_wrapper {
    padding: 80px 195px;
    height: 100vh;
}

.terms_wrapper {
    padding: 80px 0px;
}

.mainhead_ida {
    text-align: center;
    font-size: 40px;
    padding-bottom: 20px;
    font-family: "Montserrat-SemiBold";
}

.about_paratext {
    font-family: "Montserrat-Regular";
    font-size: 16px;
    line-height: 28px;
    text-align: center;
}

.termsBlock {
    margin: 0px;
}

.termsHead {
    font-weight: 400;
    display: block;
}

.termsHead,
.termsPara {
    margin: 0px;
}

.termsPara {
    font-family: "Montserrat-Regular";
    font-size: 16px;
    font-weight: 300;
}

.termsHead {
    font-family: "Montserrat-SemiBold";
    font-size: 16px;
    font-weight: 300;
}

.pulseLogoright img {
    width: 110px;
}

.logo-powerdby {
    position: absolute;
    right: -37px;
    top: 32px;
}

.logo-powerdbyinner {
    position: absolute;
    left: 98px;
    top: 50px;
}


/* dashboard view start*/
/* .subheader_topsec{
    padding: 15px 15px 0 85px
} */
.breadcrumb_text {
    font-size: 16px;
    text-decoration: none;
    color: #000;
    padding-left: 5px;
}

.breadcrumb_backarow {
    font-size: 17px;
}

.chart_block {
    display: flex;
    align-items: center;
}

.chart_textsec {
    /* float: left; */
    font-size: 13px;
    text-transform: uppercase;
}

.chart_sec {
    /* float: right; */
    font-size: 14px;
    font-weight: 600;
}

.chart_block .apexcharts-menu-icon {
    display: none;
}

.main_wrappersec {
    padding: 0px 15px 1px 85px
}

.main_wrapper_noleftview {
    padding: 0px 15px 1px 15px
}

.total_block {
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    padding: 10px 20px;
    width: 100%;
    margin-bottom: 20px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.total_subsec {
    display: flex;
    align-items: center;
    padding: 10px 0 5px;
}

.total_secicon {
    display: inline-block;
    width: 16%;
    font-size: 18px;
}

.total_sectext {
    display: inline-block;
    width: 84%;
}

.border_bottom {
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.total_sechead {
    font-size: 15px;
    padding-bottom: 7px;
}

.total_seccount {
    font-size: 20px;
}

.total_revenuecard {
    border-radius: .55rem;
    background: #fff;
    transition: .5s;
    border: 0;
    margin-bottom: 30px;
    position: relative;
    width: 100%;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    min-height: 510px;
}

.total_wrapper-paddTop {
    padding-top: 25px;
}

.total_revnuheader {
    color: #444;
    padding: 20px;
    position: relative;
    padding-bottom: 12px;
}

.toDoListPg .recall-paadTop {
    padding-bottom: 0px;
    padding-top: 24px;
}

.total_revnubody {
    color: #444;
    padding: 20px;
    font-weight: 400;
}

.total_revnuheader .total_revnuheadtxt {
    font-size: 16px;
    color: #444;
    position: relative;
}

.total_revnuheader .header-dropdown {
    position: absolute;
    top: 14px;
    right: 15px;
    list-style: none;
}

.total_revnuheader .header-dropdown li {
    display: inline-block;
    font-weight: 700;
    background: #eee;
    color: #777;
    font-size: 12px;
    border-radius: 32px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    margin: 0 2px;
    text-align: center;
}

.total_revnuheader .header-dropdown li a {
    color: #777;
    font-size: 12px;
    text-decoration: none;
}

.total_revnuheader .header-dropdown li:hover,
.total_revnuheader .header-dropdown li:active {
    background-color: #01b2c6;
    color: #fff;
    text-decoration: none;
}

.total_revnuheader .header-dropdown li:hover a,
.total_revnuheader .header-dropdown li:active a {
    color: #fff;
}

.card-rvnu {
    padding: 15px;
}

.card-rvnuhead {
    font-size: 18px;
    color: #fff;
    margin-bottom: 6px;
}

.card-rvnutxt {
    font-size: 13px;
}

.totalrevnu_chart {
    margin-top: 15px;
}

.visitors_card {
    border-radius: .55rem;
    background: #fff;
    transition: .5s;
    border: 0;
    margin-bottom: 30px;
    position: relative;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.visitors_card .xl-salmon {
    background: #ffd4c3;
}

.visitors_card .xl-slategray {
    background: #eceeef;
}

.visitors_card .xl-parpl {
    background-color: #efebf4;
}

.visitors_card .xl-khaki {
    background-color: #f9f1d8;
}

.visitrs_cardblock {
    color: #444;
    /* padding: 20px 18px; */
    font-weight: 400;
    display: flex;
}

.visior_subblock {
    /* display: inline-block;
    vertical-align: middle; */
    width: 65%;
    padding: 20px 15px;
}

.visior_subblock.visior_iconblock {
    background-color: #d6d0dd;
    width: 35%;
    display: flex;
    align-items: center;
}

.visior_subblock.visitor_women-bg {
    background-color: #f7ecc9;
}

.visior_subblock.visitor_block-bg {
    background-color: #fdc4ad;
}

.visitrs_cardouter {
    padding: 20px 10px;
    height: 175px;
}

.visitrs_card-h4 {
    font-size: 20px;
    padding-bottom: 5px;
    color: #000;
}

.visitrs_cardtxt {
    font-size: 14px;
    color: #000;
}

.patienthistry_wrpr {
    border-radius: 0.55rem;
    background: #fff;
    transition: .5s;
    border: 0;
    margin-bottom: 30px;
    position: relative;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    min-height: 510px;
}

.total_revnuheader .header-dropdown .dropdown,
.total_revnuheader .header-dropdown .dropdown:hover {
    background: transparent;
    cursor: pointer;
}

.total_revnuheader .header-dropdown .dropdown .dropdwnmenu {
    color: #007bff;
    font-size: 14px;
}

.patient-status-wrpr {
    border-radius: 0.55rem;
    background: #fff;
    transition: .5s;
    border: 0;
    margin-top: 15px;
    margin-bottom: 30px;
    position: relative;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.badge-success {
    background: transparent;
    border-color: #22af46;
    color: #22af46;
}

.badge-default {
    background: transparent;
    border-color: #9A9A9A;
    color: #9A9A9A;
}

.badge {
    font-weight: 400;
    padding: 4px 8px;
    text-transform: uppercase;
    line-height: 12px;
    border: 1px solid;
    margin: 5px;
}

.badge_section {
    text-align: right;
    /* padding-bottom: 20px; */
    padding-right: 15px;
}

.patient-status-wrpr .thead-dark {
    background-color: #000;
}

.patient-status-wrpr .thead-dark th {
    color: #fff;
    padding: 15px 5px;
    font-size: 14px;
    font-weight: 600;
}

.tbl_margin {
    margin: 0 15px;
}

.patient-status-wrpr tr:last-child {
    border-bottom: 1px solid transparent;
}

.patient-img {
    width: 50px;
    border-radius: 50%;
}

.todayappoimnt_wrapr {
    border-radius: 0.55rem;
    background: #fff;
    transition: .5s;
    border: 0;
    margin-bottom: 15px;
    position: relative;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    height: 282px;
    overflow: hidden;
}

.dash_AppmntRightBlock {
    padding: 5px;
    /* border-bottom: 1px solid #eaeaea; */
    width: 98%;
    clear: both;
    margin-top: 0;
    position: relative;
    background-color: #f3f2f2;
    margin-bottom: 6px;
    border-radius: 8px;
    margin-left: 4px;
    margin-right: 4px;
    display: flex;
    align-items: center;
}

.todyTimeSlot-appo {
    display: block;
    width: 60px;
    height: 50px;
    background: #0b3856;
    min-height: 100%;
    float: left;
    padding: 7px 6px;
    color: #fff;
    font-size: 13px;
    text-align: center;
    border-radius: 6px;
}

.consult-btn-appomnt {
    clear: both;
    text-align: right;
    color: #fff;
    background: transparent;
    margin: 0;
    border: 1px solid #0da113;
    color: #057c0a;
    padding: 1px 12px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 7px;
}

.consult-btn-appomnt .consultpatient-btntxt {
    text-decoration: none;
    color: #057c0a;
}

.todyDetails-appomnt {
    width: 75%;
    padding: 4px 0 0 10px;
    float: left;
    text-align: left;
}

.patntname-apponmnt {
    font-size: 11px;
    padding-bottom: 0px;
    color: #000;
    text-transform: uppercase;
    font-weight: 300;
}

.dctrname-apponmnt {
    font-size: 11px;
    text-transform: uppercase;
    color: #333;
}

.card-topsec {
    background-color: #ffd4c3;
    color: #000;
    margin-top: 10px;
    padding: 16px;
}

.card-topsechead {
    font-size: 16px;
    color: #000;
    margin-bottom: 6px;
}

.bg-tot_advnce {
    background-color: #bae5ed;
}

.bg-tot_due {
    background-color: #f3dd96;
}

body .consultpatient .consultpatient-btntxt {
    font-size: 12px;
}


.top_precribdmedcn-sec {
    background: #0cffff;
    display: inline-block;
    color: #0006c3;
    position: relative;
    padding: 5px 10px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    text-decoration: none;
    font-weight: 400;
    text-transform: capitalize;
    margin: 10px 13px;
    font-size: 12px;
    vertical-align: top;
    box-shadow: inset 0px 0px 30px rgba(0, 0, 0, 0.3), 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.top_precribdmedcn-sec:before {
    content: "";
    position: absolute;
    top: 0;
    left: -14px;
    width: 0;
    height: 0;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    border-right: 14px solid #0acdcd;
}

.top_precribdmedcn-wrpr {
    padding: 4px 0 20px;
}

.top_precribdmedcn-height {
    height: 245px;
    overflow: auto;
}

.upcomingappoinmt_wraper {
    height: 308px;
}

/* dashboard view End*/
.rightTagTxtgreen .healthbackground {
    color: grey;
    font-weight: bold;
}

.treatmentPriceTxt .ui-spinner.ui-corner-all.ui-widget.ui-widget-content input {
    width: 70px;
    height: 25px;
    position: absolute;
    left: -15px;
    border: 1px solid #ccc;
}

.ptInvoiiceAddWrap .treatmentPriceTxt .ui-spinner.ui-corner-all.ui-widget.ui-widget-content input {
    left: 250px;
    border: 1px solid #ccc;
}

.labOrderAddWrap .treatmentPlanHead {
    padding-right: 15px;
}

.treatmentCostTxt input[type="text"] {
    border: 1px solid #ccc;
}

.caseshtMedAdd .radioAreaType .precriptionDetails_PTour a {
    float: none !important;
}

.caseshtMedAdd .sideMenuContent .precriptionDetails_PTour {
    display: flex !important;
}

/*  */
.toDoListPop .react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 70%;
}

.toDoListPop .react-datepicker-wrapper input {
    width: 100% !important;
}

.toDoListPop #btnCancel:focus {
    border: none;
}

/* Total Pending Payment cssg   */
.pulseDashboard {
    padding-top: 15px;
}

.todayAppScroll {
    max-height: 223px;
    overflow-y: auto;
    height: 223px;
}

.toDoList-mb {
    margin-bottom: 15px;
}

.toDoImg {
    padding: 0px;
    background: transparent;
}

.toDoImg img {
    width: 50px;
    height: 50px;
    border-radius: 10px;
}

.toDoListPg .todyDetails-appomnt {
    width: 100%;
}

.toDoCover {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toDoListDate {
    display: flex;
    justify-content: space-between;
}

.toDoListPg .consult-btn-appomnt .consultpatient-btntxt {
    text-transform: none;
}

.toDoPending {
    border: 1px solid red;
    width: max-content;
    margin: 5px 0;
}

.consult-btn-appomnt.toDoPending .consultpatient-btntxt {
    color: red;
}

/* =============== TODO LIST POPUP CSS BEGINS HERE ======================= */
.toDoListPg {
    border-radius: 0.55rem;
    background: #fff;
    transition: .5s;
    border: 0;
    margin-bottom: 15px;
    position: relative;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    height: 282px;
}

.toDoListPg .modal-window {
    position: fixed;
    background-color: rgb(51 51 51 / 48%);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    /* pointer-events: none; */
    transition: all 0.3s;
}

.toDoListPg .modal-window:target {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
}

.toDoListPg .modal-window>div {
    width: 530px;
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background: white;
}

.toDoListPg .modal-window header {
    font-weight: bold;
}

.toDoListPg .modal-window h1 {
    font-size: 18px;
    margin: 0 0 7px;
    font-weight: 600;
    text-transform: capitalize;
}

.toDoListPg .modal-window .toDoCateDes {
    font-size: 13px;
}

.toDoListPg .modal-close {
    color: #282828;
    line-height: 23px;
    font-size: 20px;
    position: absolute;
    right: 8px;
    text-align: center;
    top: 8px;
    width: 26px;
    text-decoration: none;
    font-weight: 600;
    cursor: pointer;
    background: #fff;
    border-radius: 50%;
    border: 1px solid #939393;
}

.toDoListPg .modal-close:hover {
    color: black;
}

.toDoListPg .modal-window>div {
    border-radius: 1rem;
}

.toDoListPg .modal-window div:not(:last-of-type) {
    margin-bottom: 0px;
}

.toDoCateDes {
    font-size: 12px;
    text-transform: none;
    color: #333;
    margin-bottom: 15px;
}

.toDoListPg .modal-window span {
    width: 100%;
    display: block;
    float: left;
    font-size: 13px;
}

.toDoListPg .modal-window span img {
    width: 100%;
    padding: 0 0px 5px 0;
    transform: scale(1);
    height: 250px;
}

.toDoListPg .modal-window span img:hover {
    width: 100%;
    transform: scale(1.4);
    transition: all 0.3s;
    position: relative;
    z-index: 999;
}

.toDoListPg .total_revnuheader h2 {
    display: flex;
    justify-content: space-between;
}

.toDoListPg .total_revnuheader span {
    font-size: 12px;
    border: 1px solid green;
    padding: 5px 8px;
    border-radius: 8px;
    background: green;
    color: #fff;
    cursor: pointer;
}

.toDoListPg .dash_AppmntRightBlock {
    background-color: #f3595933;
    cursor: pointer;
}

.presMedicineAmnt p {
    margin: 0px;
    font-size: 12px;
    color: #282828b8;
}

.presMedWrap {
    width: 32%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #f3f2f2;
    margin: 0px 15px;
    margin: 5px;
    padding: 8px;
    border-radius: 6px;
    background: #f3f2f2;
}

.presMedAmont {
    font-weight: 600;
    color: #2d1152;
}

.preMedAmt h4 {
    margin-bottom: 8px;
    color: #282828b0;
}

.presMedWrap:hover {
    background-color: #ccc;
}

.ui-widget.ui-widget-content.ul-todolist {
    max-height: 280px;
    overflow: auto;
}

.toDoListPop-wrpr .addDataContent {
    overflow-x: unset;
}

.toDoListPop-wrpr .plpopup .plcontent {
    max-height: unset;
    overflow: unset;
}

.toDoListPop-wrpr {
    z-index: 9;
}

.ul-todolist.autoSearch1 {
    border-top: unset;
}

.todolist-sectn .ui-widget.ui-widget-content {
    border: unset;
}

.img-todolist {
    position: relative;
    /* overflow: hidden; */
    /* z-index: 9; */
}

.img-todolist img {
    transition: transform 0.3s ease;
    position: relative;
}

.img-todolist:hover img {
    transform: scale(5.0);
    z-index: 999;
    /* width: 80px;
    height: 80px;
    position: absolute; */
}

.greentext-donebtn {
    border: 1px solid green;
}

.consult-btn-appomnt.toDoPending .consultpatient-btntxt.greentext-done {
    color: green;
}

/* ===== DASHBOARD UPCOMING APPONTMNT CSS BEGINS  ===== */
.presMedAmont {
    width: 200px;
    text-align: right;
    font-size: 18px;
}

.upcomingApp p {
    margin: 0;
    font-size: 12px;
}

.upcomingApp h3 {
    color: #fff;
    font-size: 15px;
    margin: 0;
}

.upcomingApp .dctrname-apponmnt,
.upcomingApp .patntname-apponmnt {
    text-transform: none;
    padding: 0;
}

.upcomingApp .patntname-apponmnt {
    font-weight: 600;
}

.upcomingApp .dash_AppmntRightBlock {
    background: #bae5ed;
    border-radius: 8px;
}

.upcomingApp .todyTimeSlot-appo {
    background: #066374;
    display: block;
    width: 60px;
    height: 50px;
    min-height: 100%;
    float: left;
    padding: 7px 5px;
    color: #fff;
    font-size: 13px;
    text-align: center;
    border-radius: 6px;
}

.upcomingApponmtmnt .total_sechead {
    padding-bottom: 10px;
    padding-left: 5px;
    padding-top: 10px;
    font-size: 16px;
    color: #444;
}

.total_seccount {
    font-size: 16px;
    margin: 0;
}

.total_subsec {
    padding: 5px 15px 5px;
}

.total-sec-wrpr .total_subsec {
    min-height: 95px;
}

.total_block {
    padding: 1px 0px;
    margin-bottom: 15px;
}

.todayAppScroll.toDoScroll {
    overflow-y: auto;
    height: 223px;
    background: #ffff;
    max-height: 223px;
    border-radius: 10px;
    padding: 5px;
}

.todayAppScroll-max {
    max-height: 218px;
    height: 218px;
}

.todayAppScroll.toDoScroll.recall-box {
    max-height: 255px;
    height: 255px;
}

.toDoListPg .total_revnuheader {
    padding: 10px 20px;
}

.toDoListPg .dash_AppmntRightBlock {
    background-color: #f3595933;
    cursor: pointer;
    margin: 0;
    margin-bottom: 7px;
    width: 100%;
    min-height: 58px;
}

.toDoListPg.recall-wrpar {
    overflow: hidden;
    max-height: 308px;
    height: 308px;
}

.upcomingApp.todayAppScroll {
    max-height: 248px;
    overflow-y: auto;
    height: 248px;
}

.todayAppScroll.nodataFnd {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: red;
    font-weight: 500;
    max-height: 210px;
    height: 210px;
}

.todayAppScroll.nodataFnd p,
.todayAppScroll.nodataFndTodo p {
    border: 1px solid #28282863;
    margin: 0;
    padding: 5px 15px;
    border-radius: 6px;
}

.todayAppScroll.nodataFndTodo {
    height: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: red;
    font-weight: 500;
}

/* ===== DASHBOARD UPCOMING APPONTMNT CSS ENDS  ===== */

/***** lab order start ******/
.laborder-wraper .text-mainHead {
    font-size: 16px;
}

.laborder-wraper .lab-orderheadsectn {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 12px;
}

.list-tablesec .table {
    border: 1px solid #ddd;
    margin-top: 14px;
}

.list-tablesec .table th,
.list-tablesec .table td {
    font-size: 13px;
    padding: 13px 5px;
}

.statusbar-btns {
    background-color: #999494;
    color: #ffff;
    font-size: 12px;
    padding: 5px 7px;
    font-weight: 500;
    margin-right: 1px;
    font-family: "Montserrat-SemiBold";
    text-transform: uppercase;
    cursor: pointer;
}

.list-tablesec .search-section {
    vertical-align: middle;
}

.list-tablesec .search-section .label-text {
    font-size: 13px;
    padding-right: 15px;
}

.list-tablesec .search-section .input-width {
    width: 305px;
}

input.inputField.input-width::placeholder {
    font-size: 11px;
}

.td-textNo {
    color: var(--header-top-green);
    font-family: "OpenSans-Bold";
}

.td-textName {
    color: var(--header-top-green);
}

.text-right {
    text-align: right;
}

.list-tablesec .icon-phone {
    background-color: #dbdada;
    padding: 3px 6px;
    border-radius: 100%;
    margin-right: 6px;
}

.list-tablesec .icon-right {
    background-color: #999494;
    padding: 0px 8px 0px;
    border-radius: 100%;
    margin-right: 6px;
    color: #fff;
    font-size: 16px;
    margin-left: 15px;

}

.list-tablesec .select_status {
    /* background-color: #b94a48; */
    background-color: #1db4b9;
    color: #fff;
    border: 1px solid #1db4b9;
    border-radius: 5px;
    /* font-family: "OpenSans-Bold"; */
}

.list-tablesec .select_status option {
    color: #fff;
}

.btn-laborder {
    display: inline-block;
    background-image: linear-gradient(to bottom, #60c060, #4E9C4D);
    background-color: #60c060;
    font-size: 14px;
    padding: 4px 20px;
}

.lab-profsectn {
    padding: 0 0 0 15px;
}

.btn-laborder-cancel {
    background-image: linear-gradient(to bottom, #fcfcfc, #d6d6d6);
    background-color: #60c060;
    color: #000;
    border: 1px solid #d1d1d1;
}

.laborder-savewrpr .bg-color-right {
    background-color: #ececec;
}

.labOrder-savesectn {
    background-color: #fff;
    margin-top: 12px;
}

.labOrdersave-headsectn {
    border-bottom: 1px solid #d4d4d4;
}

.lab-order-top {
    display: inline-block;
    width: 33%;
    border-right: 1px solid #d4d4d4;
    padding: 4px 10px;
}

.labOrder-savesectn {
    position: relative;
    border: 1px solid #d4d4d4;
}

.close-laborder {
    position: absolute;
    right: 10px;
    font-size: 15px;
    color: red;
    font-family: "OpenSans-Bold";
    cursor: pointer;
}

.lab-order-top:last-child {
    border-right: unset;
}

.lab-order-toplabl {
    color: #000;
    font-family: "OpenSans-Bold";
    font-size: 11px;
    padding-bottom: 0px;
}

.laborder-divtext {
    font-family: "OpenSans-Bold";
    color: var(--header-top-green);
    cursor: pointer;
}

.laborder-divtext select {
    border: none;
    color: var(--header-top-green);
    background-image: linear-gradient(45deg, transparent 50%, #000 50%), linear-gradient(135deg, #000 50%, transparent 50%), linear-gradient(to right, transparent, transparent);
    background-position: calc(100% - 15px) calc(1em + 0px), calc(100% - 9px) calc(1em + 0px), 100% 0;
    background-size: 4px 5px, 6px 5px, 6.5em 2.5em;
    background-repeat: no-repeat;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    /* box-sizing: border-box; */
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 0 25px 0 0;
    border: 1px solid #ced4da;
    padding: 6px 10px;
    border-radius: 4px;
    width: 100%;
}

.labOrdersave-details {
    /* text-align: center; */
    /* width: 320px; */
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.labOrdersectn-div {
    padding-bottom: 10px;
}

.labOrdersectn-div label {
    display: inline-block;
    width: 20%;
    text-align: right;
    padding-right: 12px;
}

.labOrdersectn-span {
    display: inline-block;
    width: 80%;
    word-break: break-word;
}

.labOrdersectn-spantxt {
    font-family: "OpenSans-Bold";
    color: #000;
    text-transform: uppercase;
}

.labOrdersectn-span select {
    border: none;
    color: var(--header-top-green);
    background-repeat: no-repeat;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    /* box-sizing: border-box; */
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 0 25px 0 0;
    background-image: linear-gradient(45deg, transparent 50%, #d4d4d4 50%), linear-gradient(135deg, #d4d4d4 50%, transparent 50%), linear-gradient(to right, transparent, transparent);
    background-position: calc(100% - 15px) calc(1em + 4px), calc(100% - 9px) calc(1em + 4px), 100% 0;
    background-size: 4px 5px, 6px 5px, 6.5em 2.5em;
}

.labOrdersectn-span select:focus,
.laborder-divtext select:focus,
.laborder-leftdropdwn select:focus {
    outline: none;
}

.nofutr-appoinmts {
    padding: 10px 20px;
    position: absolute;
    top: 24%;
    right: 7%;
    background-color: antiquewhite;
    border-radius: 7px;
    color: #a10202;
    font-family: "OpenSans-Bold";
    font-size: 14px;
    opacity: 0.7;
}

.laborder-leftmenu li {
    list-style-type: none;
    padding-left: 14px;
    padding-top: 14px;
    padding-bottom: 14px;
    color: #000;
    margin-bottom: 0;
    background: #fff;
    cursor: pointer;
    border-bottom: 1px solid #ececec;
}

.laborder-leftmenu {
    padding-top: 0px;
    padding-left: 0px;
    overflow: auto;
    min-height: 400px;
    margin-bottom: 0;
    border: 1px solid #d4d4d4;
}

.disabledDiv .laborder-leftmenu {
    padding-top: 10px;
    padding-left: 0px;
    height: 785px;
    background: #ececec;
    margin-bottom: 0;
    border-top: none;
}

.disabledDiv .laborder-leftmenu li {
    background: #ececec;
    border-bottom: 1px solid #d4d4d4;
}

.laborder-leftmenu li:hover {
    background-color: var(--header-bottom-green);

}

.laborder-leftdropdwn {
    border-bottom: 0px solid #d4d4d4;
    /* padding: 10px 15px 0 15px;
    background: #ececec; */
}

.laborder-leftdropdwn select {
    color: #000;
    border-bottom: 1px solid #d4d4d4;
    border-top: 1px solid #d4d4d4;
    border-left: 1px solid #d4d4d4;
    border-right: 1px solid #d4d4d4;
    /* padding: 10px 30px 10px 20px; */
    padding: 14px;
    background-color: #fff;
    background-image:
        linear-gradient(45deg, transparent 50%, #958f8f 50%),
        linear-gradient(135deg, #958f8f 50%, transparent 50%),
        linear-gradient(to right, transparent, transparent);
    background-position:
        calc(100% - 15px) calc(1em + 10px),
        calc(100% - 9px) calc(1em + 10px),
        100% 0;
    background-size:
        4px 5px,
        6px 5px,
        6.5em 2.5em;
    background-repeat: no-repeat;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    /* box-sizing: border-box; */
    -webkit-appearance: none;
    -moz-appearance: none;
    /* border-bottom: unset; */
    border-radius: 8px;
    margin-bottom: 10px;
}

.start-red {
    color: red;
}

.plpopup.addLabpopup {
    width: 700px;
}

.cursor-pointer {
    cursor: pointer;
}

.addLabpopup .close {
    position: absolute;
    top: 0;
    right: 22px
}

.innerRow-lab {
    padding: 0 8px;
    /* border: 1px solid #F2F2F2; */
    width: 65%;
    display: flex;
}

.dashboardtable table td.vertical-middle {
    vertical-align: middle;
}

.total_block.upcomingApponmtmnt {
    background-color: unset;
    box-shadow: unset;
}

.addComentBox {
    width: 100%;
}

.addComentBox-textarea {
    width: 100%;
    height: 70px;
    overflow: auto;
    margin-top: 15px;
    border-radius: 10px;
    border: 1px solid #a59f9f;
}

.addComentBox {
    text-align: right;
    padding-bottom: 10px;
}

.addcomment-btn {
    border: 1px solid #108910;
    background: #108910;
    color: #fff;
    padding: 3px 15px;
    border-radius: 6px;
}

.todoList-popupheadtxt {
    background-color: #e1e1e1;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 10px 10px 0 0;
}

.toDoListPg .modal-window .todoList-popuptop-padd {
    padding: 0;
}

.todoList-popupwrperbody {
    padding: 20px;
}

/* .innerRow-lab:nth-child(odd) {
    background-color: #ffff;
} */

/****** lab order End ******/
.needfollowup-inline {
    display: inline-block;
    width: 50%;
}

.recall-inline {
    display: inline-block;
    width: 50%;
    text-align: right;
    padding-right: 30px;
    display: inline-flex;
    justify-content: right;
}

.followup-button {
    border: 1px solid #cc2222;
    /* background-color: #cc2222; */
    color: #cc2222;
    width: 55%;
    text-align: center;
    padding: 5px 15px;
    /* box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); */
    margin-bottom: 10px;
    margin-top: 10px;
}

.recall-button {
    border: 1px solid #cc2222;
    /* background-color: #cc2222; */
    width: 45%;
    text-align: center;
    padding: 5px 15px;
    color: #cc2222;
    /* box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); */
    margin-bottom: 10px;
    margin-top: 10px;
}

.starimp {
    color: red;
}

.labOrderListWrap .lab-orderheadsectn {
    background: #ececec;
    padding: 10px 10px 10px 10px;
    float: left;
    width: 99.5%;
    margin: 0 auto;
    margin: 7px;
}

.labOrderListWrap .list-tablesec {
    margin: 0 0 0 6px;
}

.labOrderListWrap .list-tablesec .search-section .input-width {
    margin: 0;
}

.lbListAction {
    color: var(--header-top-green);
    text-decoration: underline;
    cursor: pointer;
}

.labOrderListWrap .paginationSection {
    padding: 30px 0px 60px;
}

.labOrderListWrap .pagination>.active>a,
.labOrderListWrap .pagination>.active>a:focus,
.labOrderListWrap .pagination>.active>a:hover,
.labOrderListWrap .pagination>.active>span,
.labOrderListWrap .pagination>.active>span:focus,
.labOrderListWrap .pagination>.active>span:hover {
    z-index: 1;
    color: #fff;
    background-color: var(--header-top-green);
    border-color: var(--header-top-green);
    cursor: default;
}

.list-tablesec .table th {
    background: #ececec;
}

.laborder-savewrpr {
    min-height: 400px;
    margin: 0 -1px;
    margin-bottom: 40px;
    overflow: auto;
}

.labOrdersectn-span select.form-control {
    border: 1px solid #ced4da;
    /* width: 162px; */
    width: 200px;
    padding: 6px 10px;
}
.labOrdersectn-span-date input{
    width: 200px;
}

.labOrderListWrap .teethWrap {
    border-bottom: none;
}

.disabledDiv,
.disabledDiv .laborder-divtext select,
body .disabledDiv .form-control,
.disabledDiv.laborder-leftdropdwn select {
    background: #ececec !important;
    cursor: not-allowed;
    ;
    user-select: none;
    pointer-events: none;
    opacity: 0.6;
}

.changeWrkNm {
    margin-left: 35px;
    border: 1px solid var(--header-top-green);
    padding: 2px 12px;
    color: #fff;
    background: var(--header-top-green);
    text-transform: capitalize;
    font-family: 'OpenSans-Regular';
    font-weight: 100;
    border-radius: 5px;
    margin-bottom: 5px;
    font-size: 12px;
}

.disabledDiv .laborder-leftmenu,
.disabledDiv.laborder-leftmenu li {
    background-color: #ececec;
    opacity: 0.6;
}

.laborderlist-ul {
    padding: 0;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    height: 180px;
    overflow: auto;

}

.laborderlist-li {
    padding: 7px 10px;
    list-style-type: none;
}

.laborderlist-li:hover {
    background-color: #5d9cec;
}

.inputField.laborder-input {
    padding: 20px 14px;
    color: var(--header-top-green);
    background-image: linear-gradient(45deg, transparent 50%, #000 50%), linear-gradient(135deg, #000 50%, transparent 50%), linear-gradient(to right, transparent, transparent);
    background-position: calc(100% - 15px) calc(1em + 8px), calc(100% - 9px) calc(1em + 8px), 100% 0;
    background-size: 4px 5px, 6px 5px, 6.5em 2.5em;
    background-repeat: no-repeat;
    /* margin: 0; */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    /* box-sizing: border-box; */
    -webkit-appearance: none;
    -moz-appearance: none;
}

.lbOrderNodata {
    width: 92vw;
    margin: 0 auto;
    display: table-caption;
}

/*  */
table.labOrderListTable {
    border-collapse: collapse;
    border-bottom: 1px solid rgb(0, 0, 0, 0.2);

    max-width: 2000px;
    width: 97%;

    margin: 10px auto 20px auto;
}

.labOrderListTable th {
    padding: 15px 5px;
    text-align: left;
    font-size: 14px;
    color: #444;
}

.labOrderListTable td {
    padding: 7px;
    border-bottom: 1px solid rgb(0 0 0 / 11%);
    border: 0px solid rgb(0 0 0 / 11%);
    font-size: 13px;
}

.labOrderListTable tbody tr {
    border-bottom: 1px solid #ddd;
}

.tblStatusClr {
    padding: 3px 15px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    width: 100%;
    justify-content: center;
}

.lbActive {
    background: #95ffac;

}

.lbSent {
    background: grey;
}

.lbInProd {
    background: pink;
}

.lbInTransist {
    background: red;
}

.lbRecieved {
    background: blue;
}

.lboverdue {
    background: green;
}


.labOrderListWrap .visior_subblock.visior_iconblock {
    background-color: #d6d0dd;
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.labOrderListWrap .visior_subblock {
    width: 65%;
    padding: 10px 20px;
    background: #d6d0dd8c;
}

.labOrderListWrap .visitrs_cardblock {
    margin-bottom: 5px;
}

.labOrderListWrap .visitrs_card-h4 {
    font-size: 18px;
    padding-bottom: 0;
}

.labOrderListWrap .visior_subblock.statusName h4 {
    font-size: 15px;
    font-weight: normal;
}

.toDoListPg.recall-wrpar.labOrderStatus {
    max-height: 325px;
    height: 325px;
}

.labOrderListWrap .todayAppScroll-max {
    max-height: 218px;
    height: 218px;
    max-height: 274px;
    height: 274px;
    display: contents;
}

.labOrderListWrap .todayappoimnt_wrapr {
    max-height: 325px;
    height: 325px;
}

.laborderStatusInfo {
    padding: 0 5px;
}

.labOrderListWrap .activeCount {
    background-color: #5cbf76c9 !important;
}

.labOrderListWrap .activeCountBg {
    background-color: #5cbf7685;
}

.labOrderListWrap .sentCount {
    background-color: #346368c7 !important
}

.labOrderListWrap .sentCountBg {
    background-color: #344c6891 !important
}

.labOrderListWrap .prodCount {
    background-color: #98b0cc !important
}

.labOrderListWrap .prodCountBg {
    background-color: #98b0ccb0 !important
}

.labOrderListWrap .transistCount {
    background-color: #5d8caade !important
}

.labOrderListWrap .transistCountBg {
    background-color: #5d8caabf !important
}

.labOrderListWrap .receivedCount {
    background-color: #5c53acbd !important
}

.labOrderListWrap .receivedCountBg {
    background-color: #5c53aca8 !important
}

.labOrderListWrap .overdueCount {
    background-color: #423c6cbd !important
}

.labOrderListWrap .overdueCountBg {
    background-color: #423c6c75 !important
}

.speech_block {
    font-size: 18px;
}

.position-relative {
    position: relative;
}

.speechBlock_sectn svg {
    color: #0075ff;
    margin-right: -23px;
    font-size: 16px;
}

.speechBlock_sectn,
.speech_block {
    position: absolute;
    top: 25px;
    right: -15px;
    cursor: pointer;
}

.speech_block .video-icon ::after,
.speech_block .video-icon::before {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    right: 0;
    z-index: -1;
    bottom: 0;
    left: 17px;
    border-radius: 100%;
    border: 1px solid #0075ff;
}

.speech_block .video-icon::before {
    animation: ripple 2s linear infinite;
    width: 25px;
    height: 25px;
}

.speech_block .video-icon,
.speech_block .notifi-icon {
    display: inline-block;
    /* width: 35px;
    height: 35px; */
    z-index: 3;
    text-align: center;
    padding-top: 5px;
    font-size: 16px;
    position: absolute;
    left: -12px;
    top: 12px;
    transform: translateY(-50%) translateX(-50%);
    background-color: var(--greenColor);
    border-radius: 100%;
    color: #0075ff;
}

/*  */
.fixed_header {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}

.fixed_header tbody {
    display: block;
    width: 100%;
    overflow: auto;
    height: 225px;
}

.fixed_header thead tr {
    display: block;
}

.fixed_header th,
.fixed_header td {
    padding: 3px;
    text-align: left;
    width: 140px;
}

.fixed_header .patientDctrsectn-td {
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/*  */
.speechBlock_sectn .micIcon {
    font-size: 16px;
    margin-left: 9px;
}

.speech_block.initialMic .video-icon::before {
    animation: ripple 2s linear infinite;
    width: 25px;
    height: 25px;
}

.speech_lang {
    position: absolute;
    top: 60px;
    left: 0px;
    width: 200px;
    background-color: #fff;
    padding: 6px 15px;
    z-index: 9;
    border-radius: 3px;
    box-shadow: 0 0 7px #b7b7b7;
    display: flex;
    justify-content: space-between;
}

.speech_lang:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 12px 10px;
    border-color: transparent transparent #fff transparent;
    position: absolute;
    top: -10px;
    left: 3px;
    filter: drop-shadow(0px -2px 2px #b7b7b7);
    -webkit-filter: drop-shadow(0px -2px 2px #b7b7b7);
    -moz-filter: drop-shadow(0px -2px 2px #b7b7b7);
    -ms-filter: drop-shadow(0px -2px 2px #b7b7b7);
    -o-filter: drop-shadow(0px -2px 2px #b7b7b7);
}

.speech-labl {
    padding-bottom: 4px;
}

.speechBlock_speaking {
    top: 0;
}

.treatmentAmount-right {
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
}

.speechBlock_Change {
    top: 6px
}

.speechBlock_speaking_change {
    top: -20px;
}

.clinicalField .speechinputSelect {
    box-shadow: unset;
}

/*  */
.todayAppScroll.nodataFnd.graphDataNotfound {
    max-height: 380px;
    height: 380px;
}

.labOrderListTable.fixed_header thead {
    overflow-y: scroll;
    padding-right: 0px;
    display: block;
}

.labOrderListTable.fixed_header tr {
    width: 100%;
    display: flex;
}

.labOrderListTable.fixed_header td,
.labOrderListTable.fixed_header th {
    flex-basis: 100%;
    flex-grow: 2;
    display: block;
    text-align: left;
    padding: 5px;
}

.pdf-name {
    position: absolute;
    bottom: -20px;
    left: 0;
    font-size: 12px;
    display: -webkit-box;
    max-width: 145px;
    float: left;
    height: auto;
    line-height: 14px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: context-menu;
}

body .dashboardSearchList .inputField,
body .dashboardSearchList .textareaField {
    height: 34px;
}


/* @media print {

    html, body {
      height:100vh; 
      margin: 0 !important; 
      padding: 0 !important;
      overflow: hidden;
    }

} */

.typeDropdwon-ul {
    padding-left: 0px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    cursor: pointer;
    height: 125px;
    overflow: auto;
}

.typeDropdwon-ul li {
    list-style-type: none;
    padding: 5px;
}

.gotoButtons {
    /* background: #0da5a9; */
    color: #fff;
    padding: 2px 13px;
    border-radius: 5px;
    cursor: pointer;
    margin: 3px;
}

.datepicker-w.form-control {
    width: 155px;
}

@media print {
    .no-print {
        display: none !important;
    }
}


.cus-checkbox-wrpr.form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.cus-checkbox-wrpr.form-group label {
    position: relative;
    cursor: pointer;
}

.cus-checkbox-wrpr.form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #db0b0b;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
}

.cus-checkbox-wrpr.form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 7px;
    width: 6px;
    height: 11px;
    border: solid #db0b0b;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.bg-whitesec {
    background-color: #fff;
    padding: 10px 5px 4px;
    border-radius: 4px;
}

.text-right {
    text-align: right;
}

body .saVeBtn.bg-bluebtn {
    background-image: linear-gradient(to bottom, #00afef, #005fef);
    font-weight: 300;
    font-size: 13px;
}

.pl_casesheetWrap .clinicalDate.datepicker-casesheet input {
    height: 24px;
}
.div-flex{
    display: flex;
    gap: 2px;
    flex-wrap: wrap;
    justify-content: center;
}